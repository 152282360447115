/**
 * @file   src\containers\notifications\List.tsx
 * @brief  Notifications list page.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import NotificationCard from '../../components/NotificationCard';
import Pagination from '../../components/Pagination';
import { PAGE_SIZE, DEFAULT_PAGE_INDEX,DATE_TIME_FORMAT_DISPLAY } from '../../utils/constants';
import { IListNotificationParams } from '../../interfaces/Notification';
import { getNotificationList } from '../../store/actions/notificationActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import Loader from '../../components/Loader';
import moment from 'moment';


// Default params for api request
const notificationDefaultParams: IListNotificationParams = {
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
};

const Notifications = () => {
  // Create action dispatch object.
  const dispatch = useAppDispatch();

  // Access redux state variables
  const { notificationListApiData, notificationListApiLoading, notificationListApiSuccess } = useAppSelector(
    (state: RootState) => state.notification,
  );

  // Initialize component state variables.
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationApiParams, setNotificationApiParams] = useState<any>(notificationDefaultParams);

  // api call to get organization list
  useEffect(() => {
    dispatch(getNotificationList(notificationApiParams));
  }, [notificationApiParams]);

  // handle pagination
  useEffect(() => {
    setNotificationApiParams((apiParams: any) => ({
      ...apiParams,
      Page: currentPage,
    }));
  }, [currentPage]);

  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.Notifications" />
        </h3>
      </div>
      <div className="content-sub">
        <div className="content-area-padding">
        {notificationListApiSuccess && (notificationListApiData?.Notifications==null || notificationListApiData?.Notifications?.length == 0 || !notificationListApiData) ? (
              <div className="text-center mt-5">No results found.</div>
            ) : (
              notificationListApiSuccess &&
              notificationListApiData?.Notifications?.length > 0 &&
              notificationListApiData?.Notifications?.map((notification: any) => (


          <NotificationCard content={notification.Message} date={ moment(notification?.CreatedAt).format(DATE_TIME_FORMAT_DISPLAY)}  />
        ))
    )}
          {/* <NotificationCard content="20 Teaching staff have been added Modern American School." date="Jan 22, 2020" time="3:30 PM" />
          <NotificationCard content="Modern American School students have completed all the assigned goals/surveys." date="Jan 22, 2020" time="3:30 PM" />
          <NotificationCard content="Modern American School has subscribed to a Yearly plan. " date="Jan 22, 2020" time="3:30 PM" /> */}
          {notificationListApiSuccess && notificationListApiData?.Notifications?.length > 0 && notificationListApiData?.TotalCount > PAGE_SIZE && (
            <Pagination
              pageSize={PAGE_SIZE}
              totalitems={notificationListApiData.TotalCount}
              pageLimit={PAGE_SIZE}
              setCurrentPage={(page: number) => setCurrentPage(page)}
              currentPage={currentPage - 1}
              prevPage={-1}
              itemsDisplayed={notificationListApiData?.Notifications?.length}
            />
          )}
        </div>
      </div>
      {notificationListApiLoading && <Loader />}
    </>
  );
};
export default Notifications;
