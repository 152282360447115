import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { OrganizationTypeApis } from '../../utils/apiUrls';
import { IOrganizationTypeListRequest, IOrganisationTypeForm, IOrganizationsByOrgTypeRequest } from '../../interfaces/OrganizationTypeInterface';

// API call to get organization type list.
export const getOrganizationTypeList = createAsyncThunk('/organiztionType/list', async (request: IOrganizationTypeListRequest, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${OrganizationTypeApis.LIST_ORGANIZATIONTYPE}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});
// Api call to get organization type.
export const getOrganizationTypeDropDownList = createAsyncThunk('/organizationtype/dropdownlist',async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${OrganizationTypeApis.LIST_ORGANIZATIONTYPE_DROPDOWN}`,{});
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
})
// API call to add organization type.
export const addOrganizationType = createAsyncThunk('/organiztionType/add', async (request: IOrganisationTypeForm, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${OrganizationTypeApis.ADD_ORGANIZATIONTYPE}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});
// API call to update organization type.
export const updateOrganizationType = createAsyncThunk('/organiztionType/update', async (request: IOrganisationTypeForm, { rejectWithValue }) => {
    try {
        const { data } = await axios.put(`${OrganizationTypeApis.UPDATE_ORGANIZATIONTYPE}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});
// Api call to get user roles by organization type.
export const getUserRolesByOrgType = createAsyncThunk('/organiztionType/userroles', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${OrganizationTypeApis.ROLES_BY_ORG_TYPE}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});
// API call to view organization type detail.
export const viewOrganizationType = createAsyncThunk('/organiztionType/view', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${OrganizationTypeApis.VIEW_ORGANIZATIONTYPE}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});
// Api call to get organization name by organization type.
export const getOrganizationNameByOrgType = createAsyncThunk('/organiztionType/organizationname', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${OrganizationTypeApis.ORGANIZATION_NAME_BY_ORG_TYPE}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});
// Api call to get category details by organization.
export const getCategoryDetailsById = createAsyncThunk('/organiztionType/categorydetails', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${OrganizationTypeApis.CATEGORY_DETAILS_BY_ORG_ID}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});
// API call to get organizations by organization type.
export const listOrganizationsByOrgType = createAsyncThunk('/organiztionType/listOrganizations', async (request: IOrganizationsByOrgTypeRequest, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${OrganizationTypeApis.LIST_ORGANIZATIONS_BY_ORG_TYPE}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});
// Api call to get tags  by organization type.
export const getUserTagsByOrgType = createAsyncThunk('/organiztionType/tags', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${OrganizationTypeApis.TAGS_BY_ORG_TYPE}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});