import { useState, useEffect } from 'react'

function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const henadler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(henadler)
        };

    }, [value, delay]);
    return debouncedValue;
}
export default useDebounce