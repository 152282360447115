import React from 'react';
const SortIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14">
            <g id="Group_12432" data-name="Group 12432" transform="translate(1462 -1814)">
                <path id="Polygon_1" data-name="Polygon 1" d="M4.5,0,9,6H0Z" transform="translate(-1462 1814)" fill="#e6e6f6" />
                <path id="Polygon_2" data-name="Polygon 2" d="M4.5,0,9,6H0Z" transform="translate(-1453 1828) rotate(180)" fill="#e6e6f6" />
            </g>
        </svg>
    );
}

export default SortIcon;
