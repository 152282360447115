/**
 * @file   src/components/ProtectedRoute.tsx
 * @brief  Protected Route Component
 * @date   Mar, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { getFromLocalStorage } from '../utils/helper';

interface Props {
  children: React.JSX.Element;
}

const ProtectedRoute = ({ children }: Props) => {
  const location = useLocation();
  const storageData = getFromLocalStorage('MI_USR_DATA');
  const isAuthenticated = storageData !== null && storageData?.AccessToken !== '';

  /* Check whether the user is authenticated or not and redirected to login page. */
  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
