/**
 * @file   src\store\actions\SubscriptionPlanActions.ts
 * @brief  This file is responsible for creating asynchronous Subscription Plan based api call.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { SubscriptionPlanApis } from '../../utils/apiUrls';
import { ISubscriptionPlanForm,ISubscriptionPlanListApiParams,IViewSubscriptionPlanApiParams,IDeleteSubscriptionPlanApiParams,IOrganizationListRequest } from '../../interfaces/SubscriptionPlanInterface';

// API call to get  Subscription Plan Validity.

export const getSubscriptionPlanValidity = createAsyncThunk('/getPlanValidities',async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.get(`${SubscriptionPlanApis.LIST_SUBSCRIPTION_PLAN_VALIDITY}`,{});
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
})
// Api call to add Subscription Plan
export const addSubscriptionPlan = createAsyncThunk('/addsubscription', async (request: ISubscriptionPlanForm, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${SubscriptionPlanApis.ADD_SUBSCRIPTION_PLAN}`, request);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.data);
    }
  });
// API call to get Subscription Plan list.
export const getSubscriptionPlanList = createAsyncThunk('/listPlans', async (request: ISubscriptionPlanListApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SubscriptionPlanApis.LIST_SUBSCRIPTION_PLANS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get Subscription Plan details
export const getSubscriptionPlanDetails = createAsyncThunk('/subscriptiondetails', async (request: IViewSubscriptionPlanApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SubscriptionPlanApis.VIEW_SUBSCRIPTION_PLANS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API to delete Subscription Plan
export const deleteSubscriptionPlan = createAsyncThunk('/deleteSubscriptionPlan', async (request: IDeleteSubscriptionPlanApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SubscriptionPlanApis.DELETE_SUBSCRIPTION_PLAN}`,request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get subscribed organization list.
export const getSubscribedOrganizations = createAsyncThunk('/getSubscribedOrganizations', async (request: IOrganizationListRequest, { rejectWithValue }) => {
  try {
      const { data } = await axios.post(`${SubscriptionPlanApis.LIST_SUBSCRIBED_ORGANIZATIONS}`,request);
      return data;
  } catch (error: any) {
      return rejectWithValue(error?.data);
  }
});
// Api call to edit Subscription Plan
export const editSubscriptionPlan = createAsyncThunk('/editsubscription', async (request: ISubscriptionPlanForm, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SubscriptionPlanApis.EDIT_SUBSCRIPTION_PLAN}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});