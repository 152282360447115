/**
 * @file   src\store\actions\dashboardActions.ts
 * @brief  This file is responsible for creating asynchronous dashboard based api call.
 * @date   JAN, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { DashboardApi } from '../../utils/apiUrls';
import { IFeedbackFormApiParams } from '../../interfaces/FeedbackInterface';
import { IUserCountListApiParams, IOverallPerformanceApiParams, ISubcategoryPerformanceApiParams } from '../../interfaces/dashboardInterface';

// Api call to get list of orders
export const getOrganizationTypeList = createAsyncThunk('/dashboard/orderslist', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${DashboardApi.ORGANIZATIONTYPE_LIST}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to get list users count
export const getUserCountByOrganizationType = createAsyncThunk('/dashboard/usercountlist', async (request: IUserCountListApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${DashboardApi.USERS_COUNT_LIST}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to get over all performance
export const getOverallPerformanceByOrganizationType = createAsyncThunk('/dashboard/overallperformance', async (request: IOverallPerformanceApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${DashboardApi.OVERALL_PERFORMANCE}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to get list users count
export const sendMailContactAdmin = createAsyncThunk('/sendMailContactAdmin', async (request: IFeedbackFormApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${DashboardApi.CONTACT_ADMIN}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to get subcategory wise over all performance
export const getOverallPerformanceBySubcategory = createAsyncThunk(
  '/dashboard/subcategorywiseperformance',
  async (request: ISubcategoryPerformanceApiParams, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${DashboardApi.CATEGORY_WISE_OVERALL_PERFORMANCE}`, request);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.data);
    }
  },
);
