import React from 'react';
const AttachmentIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="26" viewBox="0 0 21 26">
            <path id="draft_FILL1_wght400_GRAD0_opsz48" d="M161.875,201A1.922,1.922,0,0,1,160,199.125v-21.25A1.922,1.922,0,0,1,161.875,176h11.281L180,182.844v16.281A1.922,1.922,0,0,1,178.125,201Zm10.344-17.312h5.906l-5.906-5.812Z" transform="translate(-159.5 -175.5)" fill="#9fe6ea" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" />
        </svg>
    );
}

export default AttachmentIcon;
