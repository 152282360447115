/**
 * @file   src\store\actions\resourceActions.ts
 * @brief  This file is responsible for creating asynchronous resorces based api call.
 * @date   FEB, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { ResourceApis } from '../../utils/apiUrls';
import {
  IReportedApiParams,
  IResourceApiParams,
  IResourceForm,
  IIncentiveApiParams,
  IResolveResourceReport,
  ILinkResource,
  IAttachmentData,
  IReportResourceDataRequest,
} from '../../interfaces/ResourceInterface';

// Api call to add motivational and incentive resource
export const addResource = createAsyncThunk('/addresource', async (request: IResourceForm, { rejectWithValue }) => {
  try {
    const options = {
      headers: {
        GoalID: request.GoalID,
      },
    };
    const { data } = await axios.post(`${ResourceApis.ADD_RESOURCE}`, request, options);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to add motivational and incentive resource
export const editResource = createAsyncThunk('/editResource', async (request: IResourceForm, { rejectWithValue }) => {
  try {
    const options = {
      headers: {
        GoalID: request.GoalID,
      },
    };
    const { data } = await axios.post(`${ResourceApis.EDIT_RESOURCE}`, request, options);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get resource details
export const resourcedetails = createAsyncThunk('/resourcedetails/', async (resourceId: string, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ResourceApis.VIEW_RESOURCE}/${resourceId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get motivational resource list.
export const getMotivationalList = createAsyncThunk('/resources', async (request: IResourceApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${ResourceApis.LIST_MOTIVATIONALRESOURCE}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get incentive resource list.
export const getIncentiveResourceList = createAsyncThunk('/incentiveResource', async (request: IIncentiveApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${ResourceApis.LIST_INCENTIVE_RESOURCE}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get incentive resource list.
export const getGoalIncentiveResourceList = createAsyncThunk('/getGoalIncentiveResourceList', async (request: IIncentiveApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${ResourceApis.LIST_GOAL_INCENTIVE_RESOURCE}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get reported resource list.
export const getReportedResourceList = createAsyncThunk('/reportedResource', async (request: IReportedApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${ResourceApis.LIST_REPORTED_RESOURCE}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to get all motivational resource list based on category.
export const getViewAllMotivationalResource = createAsyncThunk('/resourcesViewall', async (request: IResourceApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${ResourceApis.VIEW_ALL_MOTIVATIONAL_RESOURCE}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API to resolve resource report
export const resolveResourceReport = createAsyncThunk('/resolveResourceReport', async (request: IResolveResourceReport, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ResourceApis.RESOLVE_RESOURCE_REPORT, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API to link resource
export const linkResource = createAsyncThunk('/linkResource', async (request: ILinkResource, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ResourceApis.LINK_RESOURCE, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API to delete resource
export const deleteResource = createAsyncThunk('/deleteResource', async (request: string, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete(`${ResourceApis.DELETE_RESOURCE}/${request}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API to delete resource
export const deleteResourceAttachment = createAsyncThunk('/deleteResourceAttachment', async (request: IAttachmentData, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete(ResourceApis.DELETE_RESOURCE_ATTACHMENT, { data: request });
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get Report Reason List.
export const getReportReasonList = createAsyncThunk('/getReportReasonList', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${ResourceApis.LIST_REPORT_REASONS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to add Resource Report.
export const addResourceReport = createAsyncThunk('/addResourceReport', async (request: IReportResourceDataRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${ResourceApis.ADD_RESOURCE_REPORT}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to get resource group list.
export const listResourceGroupByType = createAsyncThunk('/listResourceGroupByType', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${ResourceApis.LIST_GROUP_BY_TYPE}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});