/**
 * @file   src/containers/plans/PlanList.tsx
 * @brief  List of available subscription plans page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PlanCard from './TeacherPlanListCard';
import { listSubscriptionPlans } from '../../store/actions/profileActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { IPlanInfo } from '../../interfaces/ProfileInterface';
import { RootState } from '../../store';
import Loader from '../../components/Loader';
import { MessageToaster } from '../../utils/ToastUtil';
import { getFromLocalStorage } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { resetSubscriptionPayment } from '../../store/slices/profileSlice';

const ChoosePlan = () => {
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // Message toaster object creation
  const toastObj = new MessageToaster();
  // Route navigation object creation
  const navigate = useNavigate();
  // Accessing redux state variables
  const {
    getSubscriptionPlansData,
    getSubscriptionPlansLoading,
    getSubscriptionPlansResponseMessage,
    getSubscriptionPlansSuccess,
    addSubscriptionPaymentApiLoading,
    addSubscriptionPaymentApiResponseMessage,
    addSubscriptionPaymentApiSuccess,
  } = useAppSelector((state: RootState) => state.profile);

  const userData = getFromLocalStorage('MI_USR_DATA');
  // Intial loading . api call to list subscription plans
  useEffect(() => {
    dispatch(listSubscriptionPlans({ OrganizationId: userData.OrganizationId, Page: 0 }));
    return () => {
      dispatch(resetSubscriptionPayment());
    };
  }, []);
  // handling error scenario
  useEffect(() => {
    if (!getSubscriptionPlansLoading && !getSubscriptionPlansSuccess && getSubscriptionPlansResponseMessage.length > 0) {
      toastObj.toastError(getSubscriptionPlansResponseMessage);
    }
  }, [getSubscriptionPlansLoading]);
  // handle subscription plan payment success.
  useEffect(() => {
    if (!addSubscriptionPaymentApiLoading && addSubscriptionPaymentApiSuccess) {
      toastObj.toastSuccess(addSubscriptionPaymentApiResponseMessage);
      navigate('/vieworganization');
    }
  }, [addSubscriptionPaymentApiLoading]);

  return (
    <div>
      <Container className="d-flex align-items-center">
        <div className="gradient-img py-5 w-100 br-10">
          <div className="text-center mb-4">
            <h3>Choose Plan</h3>
          </div>
          <div className="d-flex justify-content-center">
            <Col xl={9}>
              <div className="my-4">
                <Row>
                  {getSubscriptionPlansSuccess &&
                    getSubscriptionPlansData?.SubscriptionPlans &&
                    getSubscriptionPlansData.SubscriptionPlans.map((plan: IPlanInfo) => (
                      <Col xl={4}>
                        <PlanCard
                          duration={plan.PlanType}
                          price={plan.Amount}
                          plantype={plan.PlanType}
                          appusersno={plan.NoOfMobAppUsers}
                          webusersno={plan.NoOfWebUsers}
                          planid={plan.PlanID}
                          discount={plan.Discount}
                          planname={plan.PlanName}
                        />
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
            {getSubscriptionPlansLoading && <Loader />}
          </div>
        </div>
      </Container>
    </div>
  );
};
export default ChoosePlan;
