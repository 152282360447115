/**
 * @file   src\validations\surveySchema.ts
 * @brief  This file is responsible for defining survey validation schemas.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';

// Add Goal Step 1 Form Schema
export const CREATE_SURVEY_SCHEMA = yup.object({
  Title: yup.string().trim().required(useIntlActionMessages('Form.SurveyTitle.Required')),
  Description: yup.string().trim().required(useIntlActionMessages('Form.SurveyDescription.Required')),
  CategoryID: yup.number().required(useIntlActionMessages('Form.CategoryID.Required')).min(1, useIntlActionMessages('Form.CategoryID.Required')),
  SubCategoryId: yup.array().required(useIntlActionMessages('Form.ClassID.Required')).min(1, useIntlActionMessages('Form.ClassID.Required')),
  PriorityId: yup.number().required(useIntlActionMessages('Form.Priority.Required')).min(1, useIntlActionMessages('Form.Priority.Required')),
  StartTime: yup.string().trim().required(useIntlActionMessages('Form.StartDateWithTime.Required')),
  EndTime: yup.string().trim().required(useIntlActionMessages('Form.EndDateWithTime.Required')),
  KudosPoint: yup.number().typeError(useIntlActionMessages('Form.Goals.kudos.Number')).required(useIntlActionMessages('Form.SurveyKudos.Required')).min(1, useIntlActionMessages('Form.SurveyKudos.Required')),
  });
