/**
 * @file   src\containers\organizations\Overview.tsx
 * @brief  Overview page.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, isUserAdmin, getFromLocalStorage, isAdminTeacherManager } from '../../utils/helper';
import { Button, Col, Breadcrumb, Tab, Tabs, Row, Badge } from 'react-bootstrap';
import { slide as Filter } from 'react-burger-menu';
import Select from '../../components/MASelect';
import Close from '../../assets/img/Close.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { BUCKET_URL, DEFAULT_PAGE_INDEX, DEFAULT_SELECT_OPTION_NUMBER, PAGE_SIZE } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { listUsersWithToDo } from '../../store/actions/overviewAction';
import { RootState } from '../../store';
import { ISelectOption, ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import Default from '../../assets/img/default.jpg';
import { getCategoryList } from '../../store/actions/organizationActions';
import { getUserTagsByOrgType } from '../../store/actions/organizationTypeActions';
import OverviewListUsers from '../../components/OverviewListUsers';
import { getProofsCount } from '../../store/actions/userActions';
import { RoleTypeIds, ReviewProofStatus } from '../../utils/enums';
import { getCurrentOrgDetails } from '../../utils/helper';
import { SortOrder } from '../../utils/enums';
import useDebounce from '../../hooks/useDebounce';

const listUsersWithToDoApiDefaultParams = {
  CategoryId: 0,
  OrganizationID: '',
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
  Search: '',
  SortColumn: '',
  SortOrder: '',
  Status: 1,
  SubCategoryId: [],
  Tags: [],
};
const overviewListDefaultFilters = {
  CategoryId: 0,
  SubCategoryId: [],
  Tags: [],
};

const Overview = () => {
  const navigate = useNavigate();
  // Location object
  const location = useLocation();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Accessing redux state variables
  const { listUsersWithToDoApiData, listUsersWithToDoApiLoading, listUsersWithToDoApiResponseCode, listUsersWithToDoApiSuccess } = useAppSelector(
    (state: RootState) => state.overview,
  );
  const { getCatergoryListAPiData, getCategoryListApiLoading } = useAppSelector((state: RootState) => state.organization);
  const { tagsByOrgTypeData, tagsByOrgTypeLoading, rolesByOrgTypeData } = useAppSelector((state: RootState) => state.organizationType);
  const { getProofCountApiData } = useAppSelector((state: RootState) => state.user);
  const orgDetail = getFromLocalStorage('ORG_DETAIL');
  const userData = getFromLocalStorage('MI_USR_DATA');
  // Initialize component state variables.
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [categoryList, setCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [tagList, setTagList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [selectedSubCategory, setSelectedSubCategory] = useState<ISelectOptionsNumber[]>([]);
  const [selectedTag, setSelectedTag] = useState<ISelectOptionsNumber[]>([]);
  const [listUsersWithToDoApiParams, setListUsersWithToDoApiParams] = useState<any>(listUsersWithToDoApiDefaultParams);
  const [overviewUserListFilters, setOverviewUserListFilters] = useState<any>(overviewListDefaultFilters);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [isOpenOverviewFilter, setIsOpenOverviewFilter] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');
  const [completedCount, setCompletedCount] = useState<number>(0);
  const [inProgressCount, setInProgressCount] = useState<number>(0);
  const [notStartedCount, setNotStartedCount] = useState<number>(0);
  const [overdueCount, setOverdueCount] = useState<number>(0);
  const textProoftobeReviewed = useIntlMessages('text.ProoftobeReviewed');
  const textCompletionProof = useIntlMessages('text.CompletionProof');
  const textInProgressProof = useIntlMessages('text.InProgressProof');

  // Invoke debounce component on intervel.
  const debouncedSearch = useDebounce(searchText, 500);

  // Set API params on bebounced search goal list.
  useEffect(() => {
    setListUsersWithToDoApiParams((apiParams: any) => ({
      ...apiParams,
      Search: searchText,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPage(DEFAULT_PAGE_INDEX);
  }, [debouncedSearch]);

  useEffect(() => {
    dispatch(getCategoryList({ OrganizationId: orgDetail?.OrganizationId }));
    dispatch(
      getUserTagsByOrgType({
        OrganizationID: orgDetail?.OrganizationId,
        searchText: '',
      }),
    );
    setSelectedTag(location?.state?.selectedTags);
    const tags = location?.state?.selectedTags ? location?.state?.selectedTags.map((tag: any) => tag.value) : [];
    setListUsersWithToDoApiParams((apiParams: any) => ({
      ...apiParams,
      OrganizationID: orgDetail?.OrganizationId,
      Tags: tags,
    }));
    if (userData.RoleTypeId === RoleTypeIds.ORGANIZATION_ADMIN) {
      dispatch(
        getProofsCount({
          OrganizationID: getCurrentOrgDetails().OrganizationID,
        }),
      );
    } else if ([RoleTypeIds.APP_USER_MANAGER, RoleTypeIds.ORGANIZATION_MANGER].includes(userData.RoleTypeId)) {
      dispatch(
        getProofsCount({
          OrganizationID: userData.OrganizationId,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (listUsersWithToDoApiParams.OrganizationID != '') dispatch(listUsersWithToDo(listUsersWithToDoApiParams));
  }, [listUsersWithToDoApiParams]);

  // Get category details by organization
  useEffect(() => {
    if (getCatergoryListAPiData && getCatergoryListAPiData.length > 0) {
      const categoryData = getCatergoryListAPiData.map(
        (category: any): ISelectOptionsNumber => ({
          label: category.CategoryName,
          value: +category.CategoryId,
        }),
      );
      setCategoryList(categoryData);
    } else {
      // If ResponseData is null, update category state with null
      setCategoryList([]);
    }
  }, [getCategoryListApiLoading]);

  // Get tags by organization type
  useEffect(() => {
    // if (organizationTypeValue && tagsByOrgTypeData && tagsByOrgTypeData.Tags) {
    if (tagsByOrgTypeData && tagsByOrgTypeData.Tags) {
      const options = tagsByOrgTypeData.Tags.map(
        (tag: string): ISelectOption => ({
          value: tag,
          label: tag.charAt(0).toUpperCase() + tag.slice(1),
        }),
      );
      setTagList(options);
    } else {
      setTagList([]);
    }
  }, [tagsByOrgTypeLoading]);

  // Set API params on filter change goal list.
  useEffect(() => {
    setListUsersWithToDoApiParams((apiParams: any) => ({
      ...apiParams,
      Page: currentPage,
      SortColumn: sortField,
      SortOrder: sortOrder,
    }));
  }, [currentPage, sortField, sortOrder]); // , sortField, sortOrder]);

  // Get tags by organization type
  useEffect(() => {
    // if (organizationTypeValue && tagsByOrgTypeData && tagsByOrgTypeData.Tags) {
    if (listUsersWithToDoApiSuccess && listUsersWithToDoApiResponseCode > 0) {
      if (listUsersWithToDoApiData) {
        if (listUsersWithToDoApiData?.StatusCount?.length > 0) {
          setCompletedCount(listUsersWithToDoApiData?.StatusCount.find((status: any) => status.Status === 'Completed')?.status_wise_count || 0);
          setInProgressCount(listUsersWithToDoApiData?.StatusCount.find((status: any) => status.Status === 'In Progress')?.status_wise_count || 0);
          setNotStartedCount(listUsersWithToDoApiData?.StatusCount.find((status: any) => status.Status === 'Not Started')?.status_wise_count || 0);
          setOverdueCount(listUsersWithToDoApiData?.StatusCount.find((status: any) => status.Status === 'Overdue')?.status_wise_count || 0);
        }
      }
    }
  }, [listUsersWithToDoApiLoading]);

  const handleTabChange = (key: string) => {
    const status = (() => {
      switch (key) {
        case 'Completed':
          return 1;
        case 'INProgress':
          return 2;
        case 'NotStarted':
          return 3;
        case 'Overdue':
          return 4;
        default:
          return 1;
      }
    })();
    setListUsersWithToDoApiParams((apiparams: any) => ({
      ...apiparams,
      Status: status,
      Page: DEFAULT_PAGE_INDEX,
      PageSize: PAGE_SIZE,
    }));
  };
  // Get sub categories based on category
  const handleCategoryChange = (event: any) => {
    const selectedCategoryId = event.value;
    setSelectedCategory(event);
    const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === selectedCategoryId.toString());
    if (category) {
      const subcategories = category.Subcategory.map((subcategory: any) => ({
        label: subcategory.classname,
        value: subcategory.classid,
      }));
      setSubCategoryList(subcategories);
      setSelectedSubCategory([]);
    } else {
      setSubCategoryList([]);
    }
    setOverviewUserListFilters((info: any) => ({
      ...info,
      CategoryId: selectedCategoryId,
    }));
  };
  const handleSubCategoryChange = (event: any) => {
    if (event) {
      const subcategories = event.map((subcategory: any) => subcategory.value);
      setSelectedSubCategory(event);
      setOverviewUserListFilters((info: any) => ({
        ...info,
        SubCategoryId: subcategories,
      }));
    }
  };

  const handleTagChange = (event: any) => {
    if (event) {
      const tags = event.map((tag: any) => tag.value);
      setSelectedTag(event);
      setOverviewUserListFilters((info: any) => ({
        ...info,
        Tags: tags,
      }));
    }
  };
  // Handle Goal Filter click.
  const handleOverviewFilterClick = () => {
    setIsOpenOverviewFilter(true);
  };
  // Handle Goal Filter close.
  const handleOverviewFilterClose = () => {
    setIsOpenOverviewFilter(false);
  };
  // handle apply filter
  const handleFilterChange = () => {
    setListUsersWithToDoApiParams((apiParams: any) => ({
      ...apiParams,
      CategoryId: overviewUserListFilters.CategoryId,
      SubCategoryId: overviewUserListFilters.SubCategoryId,
      Tags: overviewUserListFilters.Tags,
    }));
    setCurrentPage(DEFAULT_PAGE_INDEX);
    setIsFilterApplied(true);
    setIsOpenOverviewFilter(false);
  };

  // handle reset filter
  const handleFilterReset = () => {
    setOverviewUserListFilters((apiParams: any) => ({
      ...apiParams,
      CategoryId: 0,
      SubCategoryId: [],
      Tags: [],
    }));
    setListUsersWithToDoApiParams((apiParams: any) => ({
      ...apiParams,
      CategoryId: 0,
      SubCategoryId: [],
      Tags: [],
    }));
    setIsFilterApplied(false);
    setSelectedCategory(DEFAULT_SELECT_OPTION_NUMBER);
    setSelectedSubCategory([]);
    setSelectedTag([]);
    setIsOpenOverviewFilter(false);
  };
  // Search field change event.
  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };

  // Handle Search Click.
  const handleSearchClick = () => {
    setListUsersWithToDoApiParams((apiParams: any) => ({
      ...apiParams,
      Search: searchText,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPage(DEFAULT_PAGE_INDEX);
  };

  // Handle Search Field input key down.
  const handleSearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setListUsersWithToDoApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchText,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPage(DEFAULT_PAGE_INDEX);
    } else {
      setSearchText(event.target.value);
    }
  };

  // Sort header change event.
  const changeSortField = (field: string) => {
    let newSortOrder = SortOrder.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // get the label for tabs
  const getUserLabel = (userLevel: number) => {
    let tabLabel = '';
    if (rolesByOrgTypeData.length > 0) {
      const element = rolesByOrgTypeData.filter((role: any) => userLevel === role.RoleTypeID);
      tabLabel = element.length === 1 ? element[0].Name : '';
    } else {
      tabLabel = '';
    }
    return tabLabel;
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.Overview" />
        </h3>
        <Col md="auto">
          {isAdminTeacherManager() ? (
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item onClick={() => navigate('/vieworganization')}>
                <FormattedMessage id="Hd.Dashboard" />
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <FormattedMessage id="Hd.Overview" />
              </Breadcrumb.Item>
            </Breadcrumb>
          ) : (
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
                <FormattedMessage id="Hd.ManageOrganizations" />
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => navigate('/vieworganization', { state: { organizationId: location?.state?.organizationId, selectedTags: location?.state?.selectedTags } })}
              >
                <FormattedMessage id="Hd.ViewOrganization" />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <FormattedMessage id="Hd.Overview" />
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        </Col>
      </div>
      {isAdminTeacherManager() ? null : (
        <div className="mb-4">
          <div className="org-selected">
            <img className="thumbnail-image" src={orgDetail?.ImageURL ? BUCKET_URL + orgDetail?.ImageURL : Default} alt="user pic" />
            <div>
              <h6 className="mb-1">{orgDetail?.Name}</h6>
              <small>{orgDetail?.Address}</small>
            </div>
          </div>
        </div>
      )}
      {isUserAdmin() ? null : (
        <div className="content-sub">
          <div className="content-area-padding d-flex justify-content-between align-items-center">
            <h6 className="mb-0">{textProoftobeReviewed}</h6>
            <div>
              <Button variant="primary" onClick={() => navigate(`/reviewproof/${ReviewProofStatus.COMPLETED}`)} disabled={getProofCountApiData.CompletionProofCount === 0}>
                {textCompletionProof} <Badge bg="danger">{getProofCountApiData && getProofCountApiData.CompletionProofCount && getProofCountApiData.CompletionProofCount}</Badge>
              </Button>
              <Button
                variant="primary"
                className="ms-3"
                onClick={() => navigate(`/reviewproof/${ReviewProofStatus.IN_PROGRESS}`)}
                disabled={getProofCountApiData.InProgressProofCount === 0}
              >
                {textInProgressProof} <Badge bg="danger">{getProofCountApiData && getProofCountApiData.InProgressProofCount && getProofCountApiData.InProgressProofCount}</Badge>
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className="content-sub">
        <div className="content-sub-header header-light-bg d-flex align-items-center justify-content-between">
          <h4>
            {getUserLabel(RoleTypeIds.APP_USER)} <FormattedMessage id="SubHd.StudentswithTO-DOItems" />
          </h4>
          <Filter
            onOpen={handleOverviewFilterClick}
            onClose={handleOverviewFilterClose}
            isOpen={isOpenOverviewFilter}
            width={450}
            right
            pageWrapId={'filter-wrapper'}
            outerContainerId={'outer-container'}
            customCrossIcon={<img src={Close} />}
            burgerButtonClassName={isFilterApplied ? 'active' : ''}
          >
            <div id="filter-wrapper" className="filter-main">
              <h4 className="mb-5">
                <FormattedMessage id="Hd.Filter" />
              </h4>
              <Col className="form-sm">
                <Row>
                  <Col>
                    <Select label="Category" options={categoryList} value={selectedCategory} placeholder="Select" onChange={(e: any) => handleCategoryChange(e)} />
                  </Col>
                  <Col>
                    <Select
                      label="Sub Category"
                      id="ClassID"
                      name="ClassID"
                      options={subCategoryList}
                      value={selectedSubCategory}
                      placeholder="Select"
                      onChange={(e: any) => handleSubCategoryChange(e)}
                      isMulti
                    />
                  </Col>
                </Row>
                <Col>
                  <Select label="Tags" id="Tags" name="Tags" options={tagList} value={selectedTag} placeholder="Select Tags" onChange={(e: any) => handleTagChange(e)} isMulti />
                </Col>
              </Col>
              <Row className="mt-4">
                <Col>
                  <Button variant="outline-primary" className="w-100" onClick={handleFilterReset}>
                    <FormattedMessage id="Button.ClearFilter" />
                  </Button>
                </Col>
                <Col>
                  <Button variant="primary" className="w-100" onClick={handleFilterChange}>
                    <FormattedMessage id="Button.ApplyFilters" />
                  </Button>
                </Col>
              </Row>
            </div>
          </Filter>
        </div>
        <div className="content-area-padding">
          <Tabs defaultActiveKey="Completed" transition={false} id="noanim-tab-example" className="mb-3" onSelect={(key: any) => handleTabChange(key)}>
            <Tab eventKey="Completed" title={`${useIntlMessages('Status.Completed')} (${completedCount})`}>
              <OverviewListUsers
                listUsersWithToDoApiData={listUsersWithToDoApiData}
                currentPage={currentPage}
                setCurrentPage={(page: number) => setCurrentPage(page)}
                status={listUsersWithToDoApiParams.Status}
                handleSearchChange={handleSearchChange}
                handleSearchClick={handleSearchClick}
                handleSearchKeyDown={handleSearchKeyDown}
                changeSortField={changeSortField}
                searchText={searchText}
              />
            </Tab>
            <Tab eventKey="INProgress" title={`${useIntlMessages('Status.INProgress')} (${inProgressCount})`}>
              <OverviewListUsers
                listUsersWithToDoApiData={listUsersWithToDoApiData}
                currentPage={currentPage}
                setCurrentPage={(page: number) => setCurrentPage(page)}
                status={listUsersWithToDoApiParams.Status}
                handleSearchChange={handleSearchChange}
                handleSearchClick={handleSearchClick}
                handleSearchKeyDown={handleSearchKeyDown}
                changeSortField={changeSortField}
                searchText={searchText}
              />
            </Tab>
            <Tab eventKey="NotStarted" title={`${useIntlMessages('Status.NotStarted')} (${notStartedCount})`}>
              <OverviewListUsers
                listUsersWithToDoApiData={listUsersWithToDoApiData}
                currentPage={currentPage}
                setCurrentPage={(page: number) => setCurrentPage(page)}
                status={listUsersWithToDoApiParams.Status}
                handleSearchChange={handleSearchChange}
                handleSearchClick={handleSearchClick}
                handleSearchKeyDown={handleSearchKeyDown}
                changeSortField={changeSortField}
                searchText={searchText}
              />
            </Tab>
            <Tab eventKey="Overdue" title={`${useIntlMessages('Status.Overdue')} (${overdueCount})`}>
              <OverviewListUsers
                listUsersWithToDoApiData={listUsersWithToDoApiData}
                currentPage={currentPage}
                setCurrentPage={(page: number) => setCurrentPage(page)}
                status={listUsersWithToDoApiParams.Status}
                handleSearchChange={handleSearchChange}
                handleSearchClick={handleSearchClick}
                handleSearchKeyDown={handleSearchKeyDown}
                changeSortField={changeSortField}
                searchText={searchText}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
      {/* <div className="content-sub">
        <div className="content-sub-header header-light-bg d-flex align-items-center">
          <h4>
            <FormattedMessage id="SubHd.StudentsRescheduled" />
          </h4>
          <Col className="btn-container d-flex justify-content-end">
            <Col lg={4} className="form-sm">
              <Search placeholder={useIntlMessages('PH.SearchSurvey')} size="sm" />
            </Col>
          </Col>
        </div>
        <div className="content-area-padding">
          <Table striped hover responsive>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="Label.Name" />
                  <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                    <Sort />
                  </Link>
                </th>
                <th>
                  <FormattedMessage id="Label.SubCategories" />
                  <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                    <Sort />
                  </Link>
                </th>
                <th>
                  <FormattedMessage id="Label.Category" />
                  <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                    <Sort />
                  </Link>
                </th>
                <th>
                  <FormattedMessage id="Label.DateofJoining" />
                  <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                    <Sort />
                  </Link>
                </th>
                <th className="w-50px"></th>
              </tr>
            </thead>
            <tbody>
              {listUsersWithToDoApiData &&
                listUsersWithToDoApiData.Users &&
                listUsersWithToDoApiData.Users.length > 0 &&
                listUsersWithToDoApiData.Users.map((user: any) => (
                  <tr>
                    <td>
                      {' '}
                      <div className="d-flex align-items-center">
                        <span className="td-user-image">
                          <img src={UserImg} className="w-100" alt="user" />
                        </span>
                        <label>Vivan Franklin</label>
                      </div>
                    </td>
                    <td>Math, Science... +3</td>
                    <td>Education, Fitness </td>
                    <td>05/03/2023</td>
                    <td>
                      <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                        <ViewIcon />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {listUsersWithToDoApiData && listUsersWithToDoApiData.Users && listUsersWithToDoApiData.Users.length > 0 && listUsersWithToDoApiData.TotalCount > PAGE_SIZE && (
            <Pagination
              pageSize={PAGE_SIZE}
              totalitems={listUsersWithToDoApiData.TotalCount}
              pageLimit={PAGE_SIZE}
              setCurrentPage={(page: number) => setCurrentPage(page)}
              currentPage={currentPage - 1}
              prevPage={-1}
              itemsDisplayed={listUsersWithToDoApiData?.Users?.length}
            />
          )}
        </div>
      </div> */}
    </>
  );
};
export default Overview;
