/**
 * @file   src\validations\organisationTypeSchema.ts
 * @brief  This file is responsible for defining organisation type validation schemas.
 * @date   Feb, 20224
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { NAME_REGEX} from '../utils/constants';

// Organisation Form Schema
export const ORGANISATION_TYPE_SCHEMA = yup.object({
  OrganizationTypeName: yup
    .string()
    .trim()
    // .max(99, useIntlActionMessages('Form.OrganisationName.Max'))
    .required(useIntlActionMessages('Form.OrganisationType.Required')),
    OrganizationTypeAdmin: yup
    .string()
    .trim()
    // .max(99, useIntlActionMessages('Form.OrganisationName.Max'))
    .required(useIntlActionMessages('Form.Custom.Username.Required')).matches(NAME_REGEX, useIntlActionMessages('Username.Valid')),
    OrganizationTypeManager: yup
    .string()
    .trim()
    // .max(99, useIntlActionMessages('Form.OrganisationName.Max'))
    .required(useIntlActionMessages('Form.Custom.Username.Required')).matches(NAME_REGEX, useIntlActionMessages('Username.Valid')),
    OrganizationTypeAppUserManager: yup
    .string()
    .trim()
    // .max(99, useIntlActionMessages('Form.OrganisationName.Max'))
    .required(useIntlActionMessages('Form.Custom.Username.Required')).matches(NAME_REGEX, useIntlActionMessages('Username.Valid')),
    OrganizationTypeAppUser: yup
    .string()
    .trim()
    // .max(99, useIntlActionMessages('Form.OrganisationName.Max'))
    .required(useIntlActionMessages('Form.Custom.Username.Required')).matches(NAME_REGEX, useIntlActionMessages('Username.Valid')),
    OrganizationTypeAccountabilityPartner: yup
    .string()
    .trim()
    // .max(99, useIntlActionMessages('Form.OrganisationName.Max'))
    .required(useIntlActionMessages('Form.Custom.Username.Required')).matches(NAME_REGEX, useIntlActionMessages('Username.Valid')),
});
