import React from 'react';
import '../assets/css/OrgListCard.scss';
import Default from '../assets/img/default.jpg';
import { BUCKET_URL } from '../utils/constants';

const OrgListCard = (props: any) => {
  return (
    <div className={'orglist-main ' + (props.selected ? 'selected' : '')} onClick={props.handleClick} key={props.orgId}>
      <div className="org-list">
        <img className="thumbnail-image" src={props.image ? BUCKET_URL + props.image : Default} alt="user pic" />
        <div className="org-details">
          <h6>{props.label}</h6>
          <small>{props.sublabel}</small>
        </div>
      </div>
    </div>
  );
};
export default OrgListCard;
