import React from 'react';
const EditIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.017" viewBox="0 0 16.001 16.017">
            <path id="edit" d="M121.159,231.019A1.189,1.189,0,0,1,120,229.86V218.266a1.189,1.189,0,0,1,1.159-1.159h7.826l-1.159,1.159h-6.667V229.86h11.594v-6.725l1.159-1.159v7.884a1.189,1.189,0,0,1-1.159,1.159ZM126.957,224.063Zm3.517-6.8.831.812-5.5,5.485v1.662h1.643l5.527-5.527.812.812-5.855,5.874h-3.285V223.1Zm3.3,3.246-3.3-3.246,1.932-1.932a1.167,1.167,0,0,1,1.643.019l1.623,1.643a1.156,1.156,0,0,1,.329.821,1.064,1.064,0,0,1-.348.8Z" transform="translate(-120 -215.002)" fill="#A9C4F9" />
        </svg>
    );
}

export default EditIcon;
