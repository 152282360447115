/**
 * @file   src\components\Footer.tsx
 * @brief  Footer of the web pages
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

const Footer =()=>{
    const date = new Date();
    return(
        <div className="p-3 text-center">
        <p className="mb-0 font-12">Copyright © {date.getFullYear()} MirrorIM</p>

        </div>
    );
}
export default Footer;