import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";


const Paginate = (props: any) => {

  // Component state.
  const [forceVal, setInitialVal] = useState(0)
  const [newPage, setNewPage] = useState("");

  // Page change state.
  useEffect(() => {
    setInitialVal(props.currentPage)
  }, [props.currentPage,newPage])

  // State change for inventory confirmation: Page reset if any pending unsaved inventory.
  useEffect(() => {
    if (props.isPageChanged !== "") {
      if (props.prevPage >= 0) {
        setInitialVal(props.prevPage);
        setNewPage(props.isPageChanged ? props.isPageChanged : "");
      }
    }
    else {
      setNewPage(props.isPageChanged ? props.isPageChanged : "");
    }
  }, [props.isPageChanged]);

  // Page click event.
  const handlePageClick = (event: any) => {
    setInitialVal(event.selected)
    props.setCurrentPage(event.selected + 1);
  }


  return (
    <div className="d-md-flex justify-content-between align-items-center">
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={Math.ceil(props.totalitems / props.pageLimit)}
        onPageChange={handlePageClick}
        forcePage={forceVal}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    </div>
  );
}

export default Paginate;