/**
 * @file   src\containers\ResetPassword.tsx
 * @brief  Login page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages } from '../utils/helper';
import { Button, Col } from 'react-bootstrap';
import icnEye from '../assets/img/icnEye.svg';
import eyeOff from '../assets/img/eye-off.svg';
import Input from '../components/MAInput';
import HomeLayout from '../components/HomeLayout';
import { RESET_PWD_SCHEMA } from '../validations/authSchema';
import { IResetPasswordForm } from '../interfaces/AuthInterface';
import { validateForm } from '../utils/formValidation';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks';
import { resetPassword } from '../store/actions/authActions';
import { RootState } from '../store';
import { MessageToaster } from '../utils/ToastUtil';
import Loader from '../components/Loader';
import { resetResetPassword } from '../store/slices/authSlice';

const resetPasswordAPIDefaultParams = {
  DeviceType: 'web',
  Email: '',
  Otp: '',
  Password: '',
  ConfirmPassword: '',
  Token: '',
};
const ResetPassword = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Route params object creation
  const params = useParams();
  // Toast object creation.
  const toast = new MessageToaster();
  const { resetPasswordApiLoading, resetPasswordApiSuccess, resetPasswordResponseCode, resetPasswordResponseMessage } = useAppSelector((state: RootState) => state.authentication);
  // Component state variables
  const [errorFields, setErrorFields] = useState<any>({});
  const [pwdResetForm, setPwdResetForm] = useState<IResetPasswordForm>(resetPasswordAPIDefaultParams);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // Reset Slice.
  useEffect(() => {
    return () => {
      dispatch(resetResetPassword());
    };
  }, []);

  useEffect(() => {
    const token = params.token;
    setPwdResetForm((info) => ({
      ...info,
      Token: token ?? '',
    }));
  }, []);

  // Handle reset password Success/Failure.
  useEffect(() => {
    if (resetPasswordResponseCode > 0 && resetPasswordApiSuccess) {
      toast.toastSuccess(resetPasswordResponseMessage);
      navigate('/signin');
    } else if (resetPasswordResponseCode > 0 && !resetPasswordApiSuccess) {
      toast.toastError(resetPasswordResponseMessage);
    }
  }, [resetPasswordApiLoading]);

  // handle form fields validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPwdResetForm((info) => ({
      ...info,
      [name]: value,
    }));
    const validateObj =
      name == 'ConfirmPassword'
        ? {
            [name]: value,
            Password: pwdResetForm.Password,
          }
        : {
            [name]: value,
          };
    const errorresult = await validateForm(validateObj, RESET_PWD_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };
  // Show/hide password text.
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  // Submit handler Change Password.
  const onSubmit = async () => {
    let errorresult: any = null;
    errorresult = await validateForm(pwdResetForm, RESET_PWD_SCHEMA, errorFields);
    if (Object.keys(errorresult).length === 0) {
      dispatch(resetPassword(pwdResetForm));
    } else {
      setErrorFields({ ...errorresult });
    }
  };
  return (
    <HomeLayout>
      <div className="login-main">
        <h2 className="mb-5">
          <FormattedMessage id="Hd.Reset" />
        </h2>
        <Col className="position-relative">
          <Input
            label={useIntlMessages('Label.NewPassword')}
            id="Password"
            name="Password"
            type={!showPassword ? 'password' : 'text'}
            placeholder={useIntlMessages('PH.Password')}
            maxLength={200}
            errorMessage={errorFields?.Password}
            value={pwdResetForm.Password}
            onChange={onInputHandleChange}
          />
          <Button variant="outline-secondary" className="btn-eye" onClick={togglePassword}>
            <img src={!showPassword ? icnEye : eyeOff} alt="" />
          </Button>
        </Col>
        <Col className="position-relative">
          <Input
            label={useIntlMessages('Label.ConfirmPassword')}
            id="ConfirmPassword"
            name="ConfirmPassword"
            type={!showPassword ? 'password' : 'text'}
            placeholder={useIntlMessages('PH.Password')}
            maxLength={200}
            errorMessage={errorFields?.ConfirmPassword}
            value={pwdResetForm.ConfirmPassword}
            onChange={onInputHandleChange}
          />
          <Button variant="outline-secondary" className="btn-eye" onClick={togglePassword}>
            <img src={!showPassword ? icnEye : eyeOff} alt="" />
          </Button>
        </Col>
        <Button className="w-100 mb-4" variant="primary" onClick={onSubmit}>
          <FormattedMessage id="Button.Reset" />
        </Button>
        <div className="text-center">
          <Link to="/signin" className="text-center">
            <FormattedMessage id="Link.BacktoSignIn" />
          </Link>
        </div>
        {resetPasswordApiLoading && <Loader />}
      </div>
    </HomeLayout>
  );
};
export default ResetPassword;
