import React from 'react';
const AudioIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.098" height="20.256" viewBox="0 0 22.098 20.256">
            <g id="ResourceAudio" transform="translate(0 -1)">
                <g id="Group_13302" data-name="Group 13302" transform="translate(0 1)">
                    <path id="Path_11121" data-name="Path 11121" d="M18.211,3.163,17.188,4.695a8.28,8.28,0,0,1,0,12.867l1.023,1.531a10.106,10.106,0,0,0,0-15.931ZM16.159,6.236,15.126,7.783a4.576,4.576,0,0,1,0,6.693l1.033,1.547a6.435,6.435,0,0,0,0-9.786ZM11.049,1a1.516,1.516,0,0,0-1.411.63A39.821,39.821,0,0,1,4.878,6.093a2.428,2.428,0,0,1-1.444.432H1.841A1.842,1.842,0,0,0,0,8.366V13.89a1.842,1.842,0,0,0,1.841,1.841H3.434a2.423,2.423,0,0,1,1.443.432,39.8,39.8,0,0,1,4.759,4.464,1.519,1.519,0,0,0,1.412.629,1.842,1.842,0,0,0,1.841-1.841V2.841A1.842,1.842,0,0,0,11.049,1Z" transform="translate(0 -1)" fill="#9fe6ea" fill-rule="evenodd" />
                </g>
            </g>
        </svg>

    );
}

export default AudioIcon;
