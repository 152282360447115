/**
 * @file   src\containers\organizations\SystemAdminAdd.tsx
 * @brief  System Admin add page.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Input from '../../components/MAInput';
import { useIntlMessages, useIntlActionMessages, preventWhitespaceInput } from '../../utils/helper';
import icnEye from '../../assets/img/icnEye.svg';
import eyeOff from '../../assets/img/eye-off.svg';
import { useNavigate } from 'react-router-dom';
import { validateForm } from '../../utils/formValidation';
import { CREATE_SYSTEM_ADMIN_SCHEMA } from '../../validations/userSchema';
import { addSystemAdmin } from '../../store/actions/userActions';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { resetSystemAdminAdd } from '../../store/slices/userSlice';
import { MessageToaster } from '../../utils/ToastUtil';
import { NumberValues } from '../../utils/enums';

const SystemAdminAdd = () => {
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  // Access redux state variables
  const { addSystemAdminApiLoading, addSystemAdminApiSuccess, addSystemAdminApiResponseCode, addSystemAdminApiResponseMessage } = useAppSelector((state: RootState) => state.user);
  // Initialize component state variables.
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<any>({});
  const [accountFormData, setAccountFormData] = useState<any>({
    AdminEmailId: '',
    AdminPassword: '',
    phoneNo: '',
  });

  // Reset the form after creating user
  useEffect(() => {
    return () => {
      dispatch(resetSystemAdminAdd());
    };
  }, []);
  // Add event listener for Enter key press
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
        // Check if all required fields are filled
        if (isFormValid()) {
          event.preventDefault();
          onSubmitAdmin();
        }
      }
    };
    const isFormValid = () => {
      return Object.values(errorFields).every((error) => !error);
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [errorFields]);
  // Show message after system admin form submit, success/failure
  useEffect(() => {
    if (addSystemAdminApiResponseCode === 3028 && addSystemAdminApiSuccess) {
      toast.toastSuccess(addSystemAdminApiResponseMessage);
      navigate('/manageuser');
    } else if (addSystemAdminApiResponseCode > 0 && addSystemAdminApiResponseMessage && !addSystemAdminApiSuccess) {
      toast.toastError(addSystemAdminApiResponseMessage);
    }
  }, [addSystemAdminApiLoading, addSystemAdminApiResponseCode, addSystemAdminApiSuccess, addSystemAdminApiResponseMessage]);

  // handle admin form fields validation
  const onAdminInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAccountFormData((info: any) => ({
      ...info,
      [name]: value,
    }));
    const validateObj = {
      [name]: value,
    };
    const errorResult = await validateForm(validateObj, CREATE_SYSTEM_ADMIN_SCHEMA, errorFields);
    setErrorFields(errorResult);
  };
  // Show/hide password text.
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  // Handle system admin form submit.
  const onSubmitAdmin = async () => {
    const errorResult = await validateForm(accountFormData, CREATE_SYSTEM_ADMIN_SCHEMA, setErrorFields);
    if (Object.keys(errorResult).length > 0) {
      setErrorFields(errorResult);
      const firstErrorField = Object.keys(errorResult)[0];
      const inputFields = document.getElementsByName(firstErrorField);
      if (inputFields.length > 0 && inputFields[0] instanceof HTMLInputElement) {
        inputFields[0].focus();
      }
    } else {
      addSystemAdminAccount();
    }
  };
  // Api call to create system admin account.
  const addSystemAdminAccount = () => {
    const requestData = {
      EmailId: accountFormData.AdminEmailId,
      Password: accountFormData.AdminPassword,
      phoneNo: accountFormData.phoneNo,
    };
    dispatch(addSystemAdmin(requestData));
  };

  return (
    <>
      <Input
        autoFocus
        label={useIntlMessages('Label.EmailAddress')}
        name="AdminEmailId"
        id="AdminEmailId"
        type="text"
        placeholder={useIntlActionMessages('PH.Enter.Email')}
        maxLength={NumberValues.NUM_100}
        onChange={onAdminInputHandleChange}
        errorMessage={errorFields?.AdminEmailId}
      />
      <Input
        label={useIntlMessages('Label.PhoneNum')}
        id="phoneNo"
        name="phoneNo"
        type="text"
        placeholder={useIntlActionMessages('PH.Enter.Phone')}
        maxLength={NumberValues.NUM_20}
        errorMessage={errorFields?.phoneNo}
        onChange={onAdminInputHandleChange}
      />
      <Col className="position-relative">
        <Input
          label={useIntlMessages('Label.Password')}
          id="AdminPassword"
          name="AdminPassword"
          type={!showPassword ? 'password' : 'text'}
          placeholder={useIntlMessages('PH.Password')}
          maxLength={NumberValues.NUM_100}
          onChange={onAdminInputHandleChange}
          errorMessage={errorFields?.AdminPassword}
          onKeyDown={preventWhitespaceInput}
        />
        <Button variant="outline-secondary" className="btn-eye" onClick={togglePassword}>
          <img src={!showPassword ? icnEye : eyeOff} alt="" />
        </Button>
      </Col>
      <Row className="mt-5 mb-5">
        <Col>
          <Button variant="outline-primary" className="w-100" onClick={() => navigate('/manageuser')}>
            {useIntlActionMessages('Button.Cancel')}
          </Button>
        </Col>
        <Col>
          <Button
            variant="primary"
            className="w-100"
            onClick={onSubmitAdmin}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSubmitAdmin();
              }
            }}
          >
            {useIntlActionMessages('Button.AddUser')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SystemAdminAdd;
