const Activities = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21">
      <defs>
        <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fad900" />
          <stop offset="1" stop-color="#f5933d" />
        </linearGradient>
      </defs>
      <path
        id="assignment_turned_in_FILL1_wght400_GRAD0_opsz48"
        d="M127.575,150.775l6.925-6.925-1.075-1.075-5.85,5.85L124.55,145.6l-1.05,1.05ZM121.5,156a1.494,1.494,0,0,1-1.5-1.5v-15a1.494,1.494,0,0,1,1.5-1.5h5.125a2.218,2.218,0,0,1,.8-1.438,2.486,2.486,0,0,1,3.15,0,2.218,2.218,0,0,1,.8,1.438H136.5a1.494,1.494,0,0,1,1.5,1.5v15a1.494,1.494,0,0,1-1.5,1.5Zm7.5-16.925a.9.9,0,1,0-.613-.263A.839.839,0,0,0,129,139.075Z"
        transform="translate(-119.5 -135.5)"
        stroke="rgba(0,0,0,0)"
        stroke-miterlimit="10"
        stroke-width="1"
        fill="url(#linear-gradient)"
      />
    </svg>
  );
};
export default Activities;
