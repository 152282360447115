/**
 * @file   src\containers\organizations\GoalStep3.tsx
 * @brief  Goal add Third step.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from 'react';
import '../../assets/css/GoalSurvay.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { Button, Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import AttachmentCard from '../../components/AttachmentCard';
import Task from '../../assets/img/icon/Task';
import Event from '../../assets/img/icon/Event';
import EventThumb from '../../assets/img/icon/EventThumb';
import TaskThumb from '../../assets/img/icon/TaskThumb';
import AddTaskEvent from './AddTaskEvent';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { ISelectOptionsNumber, ISelectOption } from '../../interfaces/GeneralInterface';
import { IAddEventParams, IAddTaskParams } from '../../interfaces/OrganisationInterface';
import { ADD_EVENT_SCHEMA, ADD_TASK_SCHEMA } from '../../validations/organisationSchema';
import { validateForm } from '../../utils/formValidation';
import {
  addGoalTask,
  addGoalEvent,
  getEventsTasks,
  deleteEventsTasks,
  getEventDetails,
  getTaskDetails,
  editEventDetails,
  editTaskDetails,
} from '../../store/actions/organizationActions';
import moment from 'moment';
import { MessageToaster } from '../../utils/ToastUtil';
import { resetaddGoalStepThree } from '../../store/slices/organizationSlice';
import Loader from '../../components/Loader';
import { DATE_TIME_FORMAT } from '../../utils/constants';
import EditConfirmPopup from '../../components/DeleteConfirmation';

const defaultTaskDetails = {
  TaskId: 0,
  CategoryId: 0,
  Description: '',
  EndDateWithTime: '',
  GoalId: 0,
  Name: '',
  StartDateWithTime: '',
  SubCategories: [0],
};
const defaultEventDetails = {
  EventId: 0,
  CategoryId: 0,
  Description: '',
  Duration: '',
  EventEndDate: '',
  GoalId: 0,
  Name: '',
  SubCategoryIds: [0],
  EventStartDate: '',
};

const defaultTaskErrors = {
  CategoryId: '',
  Description: useIntlActionMessages('Task.Description.Required.Error'),
  EndDateWithTime: useIntlActionMessages('EndDate.Required.Error'),
  GoalId: '',
  Name: useIntlActionMessages('Taskname.Required.Error'),
  StartDateWithTime: useIntlActionMessages('StartDate.Required.Error'),
  SubCategories: '',
};

const defaultEventErrors = {
  CategoryId: '',
  Description: useIntlActionMessages('Event.Description.Error'),
  EventStartDate: useIntlActionMessages('StartDate.Required.Error'),
  EventEndDate: useIntlActionMessages('EndDate.Required.Error'),
  GoalId: '',
  Name: useIntlActionMessages('Eventname.Required.Error'),
  SubCategoryIds: '',
};
const eventEditErrors = {
  CategoryId: '',
  Description: '',
  EventStartDate: '',
  EventEndDate: '',
  GoalId: '',
  Name: '',
  SubCategoryIds: '',
};
const taskEditErrors = {
  CategoryId: '',
  Description: '',
  EndDateWithTime: '',
  GoalId: '',
  Name: '',
  StartDateWithTime: '',
  SubCategories: '',
};
const GoalStep3 = (props: any) => {
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // Message toast object creation
  const toastObj = new MessageToaster();

  // accessing redux state variables
  const {
    addEventApiSuccess,
    addEventApiLoading,
    addTaskApiLoading,
    addTaskApiSuccess,
    getCatergoryListAPiData,
    getTaskEventsApiData,
    getTaskEventsApiSuccess,
    getTaskEventsApiLoading,
    deleteTaskEventsApiLoading,
    deleteTaskEventsApiSuccess,
    deleteTaskEventsApiErrorMessage,
    addTaskApiResponseMessage,
    addEventApiResponseMessage,
    getEventDetailsApiLoading,
    getEventDetailsApiData,
    getEventDetailsApiResponseMessage,
    getEventDetailsApiSuccess,
    getTaskDetailsApiData,
    getTaskDetailsApiLoading,
    getTaskDetailsApiSuccess,
    getTaskDetailsApiResponseMessage,
    getGoalDetailAPiData,
    editEventApiLoading,
    editEventApiSuccess,
    editEventApiResponseMessage,
    editTaskApiLoading,
    editTaskApiResponseMessage,
    editTaskApiSuccess,
  } = useAppSelector((state: RootState) => state.organization);

  // component state variables
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [categoryList, setCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<ISelectOptionsNumber[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOption[]>([]);
  const [taskDetails, setTaskDetails] = useState<IAddTaskParams>(defaultTaskDetails);
  const [eventDetails, setEventDetails] = useState<IAddEventParams>(defaultEventDetails);
  const [eventErrors, setEventErrors] = useState<any>(defaultEventErrors);
  const [taskErrors, setTaskErrors] = useState<any>(defaultTaskErrors);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [context, setContext] = useState<string>('Task');
  const [editTask, setEditTask] = useState<boolean>(false);
  const [editEvent, setEditEvent] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);
  const [skip, setSkip] = useState<boolean>(false);
  const [deleteTask, setDeleteTask] = useState<boolean>(false);
  const [deleteEvent, setDeleteEvent] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDateError, setEndDateError] = useState<string>('');
  const [addEdit, setAddEdit] = useState<string>('add');
  const subCategoryError = useIntlMessages('Subcategory.required.Error');

  // Call to get events tasks API
  const getEventsTasksList = () => {
    const request = {
      GoalId: props.activeGoalID,
      Page: 0,
      PageSize: 0,
    };
    dispatch(getEventsTasks(request));
  };
  // Call to delete events tasks API
  const deleteTaskEvent = (type: string, id: string) => {
    setDeleteEvent(false);
    setDeleteTask(false);
    dispatch(
      deleteEventsTasks({
        Type: type,
        id: id,
      }),
    );
  };
  // Initial loading
  useEffect(() => {
    window.scrollTo(0, 0);
    setEventDetails((event) => ({
      ...event,
      GoalId: props.activeGoalID,
    }));
    setTaskDetails((task) => ({
      ...task,
      GoalId: props.activeGoalID,
    }));
    getEventsTasksList();
    setSelectedCategory([{ label: getGoalDetailAPiData?.CategoryName, value: getGoalDetailAPiData?.CategoryId }]);
    const subcategoriesForSelection = getGoalDetailAPiData?.Class?.map((subcategory: any) => ({
      label: subcategory.classname,
      value: subcategory.classid,
    }));
    const subCategories = getGoalDetailAPiData?.Class?.map((subcategory: any) => subcategory.classid);
    setSelectedSubCategory(subcategoriesForSelection);
    setEventDetails((prev: IAddEventParams) => ({
      ...prev,
      CategoryId: getGoalDetailAPiData?.IsGeneral ? 0 : getGoalDetailAPiData?.CategoryId,
      SubCategoryIds: getGoalDetailAPiData?.IsGeneral ? [] : subCategories,
    }));
    setTaskDetails((prev: IAddTaskParams) => ({
      ...prev,
      CategoryId: getGoalDetailAPiData?.IsGeneral ? 0 : getGoalDetailAPiData?.CategoryId,
      SubCategories: getGoalDetailAPiData?.IsGeneral ? [] : subCategories,
    }));
    setEventErrors((prev: any) => ({
      ...prev,
      CategoryId: '',
      SubCategoryIds: '',
    }));
    setTaskErrors((prev: any) => ({
      ...prev,
      SubCategories: '',
      CategoryId: '',
    }));
    if (!getGoalDetailAPiData?.IsGeneral) {
      const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === getGoalDetailAPiData?.CategoryId.toString());
      if (category) {
        const subcategories = category.Subcategory.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setSubCategoryList(subcategories);
      }
    }
    setOpen(false);
  }, []);

  // get category list and create options for category select box
  useEffect(() => {
    if (getCatergoryListAPiData && getCatergoryListAPiData.length > 0) {
      const categoryData = getCatergoryListAPiData.map(
        (category: any): ISelectOptionsNumber => ({
          label: category.CategoryName,
          value: +category.CategoryId,
        }),
      );
      setCategoryList(categoryData);
    }
  }, [getCatergoryListAPiData]);
  // show add task api success and failure error message
  useEffect(() => {
    if (addTaskApiSuccess) {
      if (skip) {
        props.onApiResponse(3);
      } else if (addTaskApiResponseMessage.length > 0) {
        toastObj.toastSuccess(addTaskApiResponseMessage);
        getEventsTasksList();
        clearTaskEventData();
      }
    } else if (!addTaskApiSuccess && !addTaskApiLoading && addTaskApiResponseMessage.length > 0) {
      toastObj.toastError(addTaskApiResponseMessage);
    }
  }, [addTaskApiLoading]);
  // show success or error message after event editing
  useEffect(() => {
    if (editEventApiSuccess) {
      toastObj.toastSuccess(editEventApiResponseMessage);
      setEditId(0);
      getEventsTasksList();
      clearTaskEventData();
    } else if (!editEventApiSuccess && !editEventApiLoading && editEventApiResponseMessage.length > 0) {
      toastObj.toastError(editEventApiResponseMessage);
    }
  }, [editEventApiLoading]);
  // show success or error message after task editing
  useEffect(() => {
    if (editTaskApiSuccess) {
      toastObj.toastSuccess(editTaskApiResponseMessage);
      setEditId(0);
      getEventsTasksList();
      clearTaskEventData();
    } else if (!editTaskApiSuccess && !editTaskApiLoading && editTaskApiResponseMessage.length > 0) {
      toastObj.toastError(editTaskApiResponseMessage);
    }
  }, [editTaskApiLoading]);
  // show add event api success message and error message
  useEffect(() => {
    if (addEventApiSuccess) {
      toastObj.toastSuccess(addEventApiResponseMessage);
      getEventsTasksList();
      clearTaskEventData();
    } else if (!addEventApiSuccess && !addEventApiLoading && addEventApiResponseMessage.length > 0) {
      toastObj.toastError(addEventApiResponseMessage);
    }
  }, [addEventApiLoading]);
  // show  delete task event api success message and error message
  useEffect(() => {
    if (deleteTaskEventsApiSuccess) {
      toastObj.toastSuccess(deleteTaskEventsApiErrorMessage);
      getEventsTasksList();
      clearTaskEventData();
    } else if (!deleteTaskEventsApiSuccess && !deleteTaskEventsApiLoading && deleteTaskEventsApiErrorMessage.length > 0) {
      toastObj.toastError(deleteTaskEventsApiErrorMessage);
    }
  }, [deleteTaskEventsApiLoading]);
  // set up event details for editing
  useEffect(() => {
    if (getEventDetailsApiSuccess && Object.keys(getEventDetailsApiData).length > 0) {
      const startDateObj = new Date(getEventDetailsApiData.StartDateWithTime);
      const endDateObj = new Date(getEventDetailsApiData.EndDateWithTime);
      setStartDate(startDateObj);
      setEndDate(endDateObj);
      setOpen(true);
      setContext('Event');
      setAddEdit('edit');
      if (!getGoalDetailAPiData?.IsGeneral) {
        setSelectedCategory([{ label: getEventDetailsApiData.CategoryName, value: getEventDetailsApiData.CategoryId }]);
        setSelectedSubCategory([{ label: getEventDetailsApiData.SubCategories[0].classname, value: getEventDetailsApiData.SubCategories[0].classid }]);
      }
      const eventDetails = {
        Name: getEventDetailsApiData.Name,
        CategoryId: getGoalDetailAPiData?.IsGeneral ? 0 : getEventDetailsApiData.CategoryId,
        Description: getEventDetailsApiData.Description,
        EventStartDate: moment(startDateObj).utc().format(DATE_TIME_FORMAT),
        EventEndDate: moment(endDateObj).utc().format(DATE_TIME_FORMAT),
        GoalId: props.activeGoalID,
        SubCategoryIds: getGoalDetailAPiData?.IsGeneral ? [] : getEventDetailsApiData.SubCategories.map((category: any) => category.classid),
      };
      setEventDetails(eventDetails);
      setEventErrors(eventEditErrors);
    } else if (!getEventDetailsApiLoading && !getEventDetailsApiSuccess && getEventDetailsApiResponseMessage.length > 0) {
      toastObj.toastError(getEventDetailsApiResponseMessage);
    }
  }, [getEventDetailsApiLoading]);
  // set up task details for editing
  useEffect(() => {
    if (getTaskDetailsApiSuccess && Object.keys(getTaskDetailsApiData).length > 0) {
      const startDateObj = new Date(getTaskDetailsApiData.StartDate);
      const endDateObj = new Date(getTaskDetailsApiData.EndDate);
      setStartDate(startDateObj);
      setEndDate(endDateObj);
      setOpen(true);
      setContext('Task');
      setAddEdit('edit');
      if (!getGoalDetailAPiData?.IsGeneral) {
        setSelectedCategory([{ label: getTaskDetailsApiData.CategoryName, value: getTaskDetailsApiData.CategoryId }]);
        setSelectedSubCategory([{ label: getTaskDetailsApiData.Classes[0].Classname, value: getTaskDetailsApiData.Classes[0].Classid }]);
      }
      const taskDetails = {
        CategoryId: getGoalDetailAPiData?.IsGeneral ? 0 : getTaskDetailsApiData.CategoryId,
        Description: getTaskDetailsApiData.Description,
        EndDateWithTime: moment(endDateObj).utc().format(DATE_TIME_FORMAT),
        GoalId: props.activeGoalID,
        Name: getTaskDetailsApiData.TaskName,
        StartDateWithTime: moment(startDateObj).utc().format(DATE_TIME_FORMAT),
        SubCategories: getGoalDetailAPiData?.IsGeneral ? [] : getTaskDetailsApiData.Classes.map((category: any) => category.Classid),
      };
      setTaskDetails(taskDetails);
      setTaskErrors(taskEditErrors);
    } else if (!getTaskDetailsApiLoading && !getTaskDetailsApiSuccess && getTaskDetailsApiResponseMessage.length > 0) {
      toastObj.toastError(getTaskDetailsApiResponseMessage);
    }
  }, [getTaskDetailsApiLoading]);
  // works on unmounting. used to reset add goal step three
  useEffect(() => {
    return () => {
      dispatch(resetaddGoalStepThree());
    };
  }, []);

  // handle category select box change
  const handleCategoryChange = (event: ISelectOption, context: string) => {
    const selectedCategoryId = event.value;
    setTaskDetails((task) => ({
      ...task,
      CategoryId: parseInt(selectedCategoryId),
    }));
    setEventDetails((event) => ({
      ...event,
      CategoryId: parseInt(selectedCategoryId),
    }));
    setSelectedCategory([event]);
    const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === selectedCategoryId.toString());
    if (category) {
      const subcategories = category.Subcategory.map((subcategory: any) => ({
        label: subcategory.classname,
        value: subcategory.classid,
      }));
      setSubCategoryList(subcategories);
      setSelectedSubCategory([]);
    }
    if (context === 'Event') {
      setEventErrors({ ...eventErrors, CategoryId: '' });
    } else {
      setTaskErrors({ ...taskErrors, CategoryId: '' });
    }
  };
  // handle sub category change select box
  const handleSubcategoryChange = (event: any, context: string) => {
    setSelectedSubCategory(event);
    const subcategories = event.map((subcategory: any) => subcategory.value);
    setEventDetails((data) => ({
      ...data,
      SubCategoryIds: subcategories,
    }));
    setTaskDetails((task) => ({
      ...task,
      SubCategories: subcategories,
    }));
    if (context === 'Event' && event.length == 1) {
      setEventErrors({ ...eventErrors, SubCategoryIds: '' });
    } else if (context === 'Event' && event.length == 0) {
      setEventErrors({ ...eventErrors, SubCategoryIds: subCategoryError });
    } else if (context === 'Task' && event.length == 0) {
      setTaskErrors({ ...taskErrors, SubCategories: subCategoryError });
    } else {
      setTaskErrors({ ...taskErrors, SubCategories: '' });
    }
  };
  // date change in date pickers
  const handleDateChange = (date: any, context: string) => {
    if (context === 'Event_Startdate') {
      setEventDetails((event) => ({
        ...event,
        EventStartDate: `${moment(date).local().format(DATE_TIME_FORMAT)}`,
        Duration: moment(date).local().format('hh:mm'),
      }));
      setStartDate(date);
      setEventErrors({ ...eventErrors, EventStartDate: '' });
      validateStartAndEndDate(date, endDate, true);
    } else if (context === 'Event_Enddate') {
      setEventDetails((event) => ({
        ...event,
        EventEndDate: `${moment(date).local().format(DATE_TIME_FORMAT)}`,
        Duration: moment(date).local().format('hh:mm'),
      }));
      setEndDate(date);
      setEventErrors({ ...eventErrors, EventEndDate: '' });
      validateStartAndEndDate(startDate, date, false);
    } else if (context === 'Task_Startdate') {
      setTaskDetails((task) => ({
        ...task,
        StartDateWithTime: `${moment(date).local().format(DATE_TIME_FORMAT)}`,
      }));
      setStartDate(date);
      setTaskErrors({ ...taskErrors, StartDateWithTime: '' });
      validateStartAndEndDate(date, endDate, true);
    } else {
      setTaskDetails((task) => ({
        ...task,
        EndDateWithTime: `${moment(date).local().format(DATE_TIME_FORMAT)}`,
      }));
      setEndDate(date);
      setTaskErrors({ ...taskErrors, EndDateWithTime: '' });
      validateStartAndEndDate(startDate, date, false);
    }
  };

  // Validate start and end date
  const validateStartAndEndDate = async (startDate: any, endDate: any, isValidateStartDate: any) => {
    setStartDateError(...['']);
    setEndDateError(...['']);
    if (startDate != null && endDate != null) {
      if (startDate >= endDate) {
        if (isValidateStartDate) {
          setStartDateError(...['Start Date & Time should be earlier than End Date & Time.']);
        } else {
          setEndDateError(...['End Date & Time should be later than Start Date & Time.']);
        }
      }
    }
  };

  // handle input validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>, context: string) => {
    const { name, value } = event.target;
    if (context === 'Event') {
      setEventDetails((info) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      const errorResult = await validateForm(validateObj, ADD_EVENT_SCHEMA, eventErrors);
      setEventErrors(errorResult);
    } else {
      setTaskDetails((info) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      const errorResult = await validateForm(validateObj, ADD_TASK_SCHEMA, taskErrors);
      setTaskErrors(errorResult);
    }
  };
  // handle add task add or edit submit button
  const handleSubmitBtn = async () => {
    if (startDateError.length <= 0 && endDateError.length <= 0) {
      if (context === 'Task') {
        const errorResult = await validateForm(taskDetails, ADD_TASK_SCHEMA, taskErrors);
        if (Object.keys(errorResult).length === 0 && editId === 0) {
          dispatch(addGoalTask(taskDetails));
        } else if (Object.keys(errorResult).length === 0 && editId !== 0) {
          const taskData = taskDetails as any;
          taskData.TaskId = getTaskDetailsApiData.TaskId;
          dispatch(editTaskDetails(taskData));
        } else {
          setErrorVisible(true);
        }
      } else {
        const errorResult = await validateForm(eventDetails, ADD_EVENT_SCHEMA, eventErrors);
        if (Object.keys(errorResult).length === 0 && editId === 0) {
          dispatch(addGoalEvent(eventDetails));
        } else if (Object.keys(errorResult).length === 0 && editId !== 0) {
          const eventData = eventDetails as any;
          eventData.EventId = getEventDetailsApiData.EventId;
          dispatch(editEventDetails(eventData));
        } else {
          setErrorVisible(true);
        }
      }
    }
  };
  // reset event and task details
  const clearTaskEventData = () => {
    document.body.style.overflow = 'scroll';
    setOpen(false);
    setErrorVisible(false);
    setEditId(0);
    const taskData = defaultTaskDetails;
    taskData.GoalId = props.activeGoalID;
    taskData.CategoryId = getGoalDetailAPiData?.IsGeneral ? 0 : getGoalDetailAPiData?.CategoryId;
    taskData.SubCategories = getGoalDetailAPiData?.IsGeneral ? [] : getGoalDetailAPiData?.Class?.map((subcategory: any) => subcategory.classid);
    setTaskDetails(taskData);
    const eventData = defaultEventDetails;
    eventData.GoalId = props.activeGoalID;
    eventData.SubCategoryIds = getGoalDetailAPiData?.IsGeneral ? [] : getGoalDetailAPiData?.Class?.map((subcategory: any) => subcategory.classid);
    eventData.CategoryId = getGoalDetailAPiData?.IsGeneral ? 0 : getGoalDetailAPiData?.CategoryId;
    if (!getGoalDetailAPiData?.IsGeneral) {
      const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === getGoalDetailAPiData?.CategoryId.toString());
      if (category) {
        const subcategories = category.Subcategory.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setSubCategoryList(subcategories);
      }
      const subcategoriesForSelection = getGoalDetailAPiData?.Class?.map((subcategory: any) => ({
        label: subcategory.classname,
        value: subcategory.classid,
      }));
      setSelectedSubCategory(subcategoriesForSelection);
    }
    setEventDetails(eventData);
    setTaskErrors(defaultTaskErrors);
    setEventErrors(defaultEventErrors);
    setStartDate(null);
    setEndDate(null);
    setAddEdit('add');
  };
  // get list of tasks
  const getTasksList = () => {
    if (getTaskEventsApiSuccess && Object.keys(getTaskEventsApiData).length > 0) {
      const tasks = getTaskEventsApiData.List.map((task: any) => {
        if (task.Type === 'task') {
          return (
            <AttachmentCard
              fileType={<TaskThumb />}
              filename={task.Name}
              deleteOption
              type="task"
              id={task.Id}
              onDelete={() => {
                setDeleteId(task.Id);
                setDeleteTask(true);
              }}
              handleClick={() => {
                setEditId(task.Id);
                setEditTask(true);
              }}
            />
          );
        }
      });
      return tasks;
    }
  };
  // get list of events
  const getEventList = () => {
    if (getTaskEventsApiSuccess && Object.keys(getTaskEventsApiData).length > 0) {
      const tasks = getTaskEventsApiData.List.map((event: any) => {
        if (event.Type !== 'task') {
          return (
            <AttachmentCard
              fileType={<EventThumb />}
              filename={event.Name}
              deleteOption
              type="event"
              id={event.Id}
              onDelete={() => {
                setDeleteId(event.Id);
                setDeleteEvent(true);
              }}
              handleClick={() => {
                setEditId(event.Id);
                setEditEvent(true);
              }}
            />
          );
        }
      });
      return tasks;
    }
  };
  // handle next button
  const handleNextButton = () => {
    if (getTaskEventsApiSuccess && Object.keys(getTaskEventsApiData).length > 0 && getTaskEventsApiData.List.length === 0) {
      toastObj.toastError(useIntlActionMessages('AddTask.Event.Error'));
    } else {
      props.onApiResponse(3);
    }
  };
  // call event and task details api
  const fetchDetails = (context: string) => {
    setEditEvent(false);
    setEditTask(false);
    if (context === 'Event') {
      const requestBody = {
        GoalId: props.activeGoalID,
        EventId: editId,
      };
      dispatch(getEventDetails(requestBody));
    } else {
      const requestBody = {
        GoalId: props.activeGoalID,
        TaskId: editId,
      };
      dispatch(getTaskDetails(requestBody));
    }
  };
  // add task with goal details when user clicks on skip
  const addDefaultTaskDuringSkip = () => {
    setSkip(true);
    const categoryIds = getGoalDetailAPiData?.Class?.map((category: any) => category.classid);
    const startDateObj = new Date(getGoalDetailAPiData.StartDate);
    const endDateObj = new Date(getGoalDetailAPiData.EndDate);
    const requestBody: IAddTaskParams = {
      CategoryId: getGoalDetailAPiData.CategoryId,
      Description: getGoalDetailAPiData.Description,
      EndDateWithTime: moment(endDateObj).utc().format(DATE_TIME_FORMAT),
      GoalId: getGoalDetailAPiData.GoalId,
      Name: getGoalDetailAPiData.GoalName[0].toUpperCase() + getGoalDetailAPiData.GoalName.slice(1),
      StartDateWithTime: moment(startDateObj).utc().format(DATE_TIME_FORMAT),
      SubCategories: categoryIds,
    };
    dispatch(addGoalTask(requestBody));
  };

  return (
    <>
      <div className="mb-5">
        <div className="text-center">
          <h3>
            <FormattedMessage id="Hd.AddTasksEvents" />
          </h3>
          <p>
            <FormattedMessage id="SubHd.AddTasksEvents" />
          </p>
        </div>
        <div className="d-flex justify-content-center my-5">
          <Col xl={4}>
            <Row>
              <Col className="resource-button">
                <div className="custom-berger-menu">
                  <Filter
                    width={450}
                    right
                    isOpen={open}
                    onOpen={() => {
                      setOpen(true);
                      setContext('Task');
                      setEditId(0);
                      document.body.style.overflow = 'hidden';
                    }}
                    onClose={() => clearTaskEventData()}
                    pageWrapId={'filter-wrapper'}
                    outerContainerId={'outer-container'}
                    customCrossIcon={<img src={Close} />}
                    customBurgerIcon={
                      <Button variant="secondary" className="btn-icon-top w-100" onClick={() => setOpen(true)}>
                        <span>
                          <Task />
                        </span>
                        <FormattedMessage id="Button.AddTask" />
                      </Button>
                    }
                  >
                    <AddTaskEvent
                      context={context}
                      categories={categoryList}
                      subcategories={subCategoryList}
                      changeCategory={handleCategoryChange}
                      changeSubCategory={handleSubcategoryChange}
                      changeDate={handleDateChange}
                      validateField={onInputHandleChange}
                      taskFormErrors={taskErrors}
                      eventFormErrors={eventErrors}
                      errorVisible={errorVisible}
                      eventData={eventDetails}
                      taskData={taskDetails}
                      startDate={startDate}
                      endDate={endDate}
                      category={selectedCategory}
                      subCategory={selectedSubCategory}
                      isGeneral={getGoalDetailAPiData?.IsGeneral}
                      goalEndDate={new Date(getGoalDetailAPiData?.EndDate)}
                      goalStartDate={new Date(getGoalDetailAPiData?.StartDate)}
                      addEdit={addEdit}
                      startDateError={startDateError}
                      endDateError={endDateError}
                    />
                    <Row className="mt-4 pb-4 d-flex">
                      <Col>
                        <Button variant="outline-primary" className="w-100" onClick={clearTaskEventData}>
                          <FormattedMessage id="Button.Cancel" />
                        </Button>
                      </Col>
                      <Col>
                        <Button variant="primary" className="w-100" onClick={handleSubmitBtn}>
                          <FormattedMessage id="Button.Save" />
                        </Button>
                      </Col>
                    </Row>
                  </Filter>
                </div>
              </Col>
              <Col className="resource-button">
                <div className="custom-berger-menu">
                  <Filter
                    width={450}
                    right
                    isOpen={open}
                    onOpen={() => {
                      setOpen(true);
                      setContext('Event');
                      setEditId(0);
                      document.body.style.overflow = 'hidden';
                    }}
                    onClose={() => clearTaskEventData()}
                    pageWrapId={'filter-wrapper'}
                    outerContainerId={'outer-container'}
                    customCrossIcon={<img src={Close} />}
                    customBurgerIcon={
                      <Button variant="secondary" className="btn-icon-top w-100" onClick={() => setOpen(true)}>
                        <span>
                          <Event />
                        </span>
                        <FormattedMessage id="Button.AddEvent" />
                      </Button>
                    }
                  >
                    <AddTaskEvent
                      context={context}
                      categories={categoryList}
                      subcategories={subCategoryList}
                      changeCategory={handleCategoryChange}
                      changeSubCategory={handleSubcategoryChange}
                      changeDate={handleDateChange}
                      validateField={onInputHandleChange}
                      taskFormErrors={taskErrors}
                      eventFormErrors={eventErrors}
                      errorVisible={errorVisible}
                      eventData={eventDetails}
                      taskData={taskDetails}
                      startDate={startDate}
                      endDate={endDate}
                      category={selectedCategory}
                      subCategory={selectedSubCategory}
                      isGeneral={getGoalDetailAPiData?.IsGeneral}
                      goalEndDate={new Date(getGoalDetailAPiData?.EndDate)}
                      goalStartDate={new Date(getGoalDetailAPiData?.StartDate)}
                      addEdit={addEdit}
                      startDateError={startDateError}
                      endDateError={endDateError}
                    />
                    <Row className="mt-4 pb-4 d-flex">
                      <Col>
                        <Button variant="outline-primary" className="w-100" onClick={clearTaskEventData}>
                          <FormattedMessage id="Button.Cancel" />
                        </Button>
                      </Col>
                      <Col>
                        <Button variant="primary" className="w-100" onClick={handleSubmitBtn}>
                          <FormattedMessage id="Button.Save" />
                        </Button>
                      </Col>
                    </Row>
                  </Filter>
                </div>
              </Col>
            </Row>
          </Col>
        </div>
        {getTaskEventsApiSuccess && Object.keys(getTaskEventsApiData).length > 0 && getTaskEventsApiData.List.length > 0 && (
          <h6 className="text-center">
            <FormattedMessage id="Label.Items" /> ({getTaskEventsApiSuccess && Object.keys(getTaskEventsApiData).length > 0 && getTaskEventsApiData.List.length})
          </h6>
        )}
        <div className="attachment-list d-flex justify-content-center">
          <Col xl={10}>
            <Row className="d-flex justify-content-center">
              <Col xl={6}>{getTasksList()}</Col>
              <Col xl={6}>{getEventList()}</Col>
            </Row>
          </Col>
        </div>
        <div className="text-center mt-3">
          <p>
            <FormattedMessage id="text.Goalnotes" />
          </p>
        </div>
        {addEventApiLoading || addTaskApiLoading || getTaskEventsApiLoading || deleteTaskEventsApiLoading || getEventDetailsApiLoading || getTaskDetailsApiLoading ? (
          <Loader />
        ) : null}
        <div className="d-flex justify-content-center">
          <Col xl={10}>
            <div className="btn-container d-flex justify-content-end my-4">
              {getTaskEventsApiSuccess && Object.keys(getTaskEventsApiData).length > 0 && getTaskEventsApiData.List.length === 0 && (
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    addDefaultTaskDuringSkip();
                  }}
                >
                  <FormattedMessage id="Button.Skip" />
                </Button>
              )}
              <Button variant="primary" onClick={() => handleNextButton()}>
                <FormattedMessage id="Button.Next" />
              </Button>
            </div>
          </Col>
        </div>
        <EditConfirmPopup
          show={editTask}
          handleClose={() => setEditTask(false)}
          onCancel={() => setEditTask(false)}
          onOkay={() => fetchDetails('Task')}
          title="Edit Task ?"
          content="Are you sure you want to edit this task?"
        />
        <EditConfirmPopup
          show={editEvent}
          handleClose={() => setEditEvent(false)}
          onCancel={() => setEditEvent(false)}
          onOkay={() => fetchDetails('Event')}
          title="Edit Event ?"
          content="Are you sure you want to edit this event?"
        />
        <EditConfirmPopup
          show={deleteTask}
          handleClose={() => setDeleteTask(false)}
          onCancel={() => setDeleteTask(false)}
          onOkay={() => deleteTaskEvent('task', deleteId)}
          title="Delete Task ?"
          content="Are you sure you want to delete this task?"
        />
        <EditConfirmPopup
          show={deleteEvent}
          handleClose={() => setDeleteEvent(false)}
          onCancel={() => setDeleteEvent(false)}
          onOkay={() => deleteTaskEvent('event', deleteId)}
          title="Delete Event ?"
          content="Are you sure you want to delete this event?"
        />
      </div>
    </>
  );
};
export default GoalStep3;
