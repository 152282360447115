import React from 'react';
const RightArrowIcon = () => {
    return (
        <svg width="6.838" height="11.959" viewBox="0 0 6.838 11.959">
            <defs>
                <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#fad900" />
                    <stop offset="1" stop-color="#f5933d" />
                </linearGradient>
            </defs>
            <path id="RightArrow" d="M5.982,4.777,10.5.251a.851.851,0,0,1,1.207,0,.862.862,0,0,1,0,1.211L6.587,6.589a.853.853,0,0,1-1.179.025L.249,1.465A.855.855,0,0,1,1.456.255Z" transform="translate(0 11.959) rotate(-90)" fill="url(#linear-gradient)" />
        </svg>

    );
}

export default RightArrowIcon;
