/**
 * @file   src\components\ResourceInfoDetails.tsx
 * @brief  404 page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../../assets/css/style.scss';
import '../../assets/css/Resource.scss';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Breadcrumb, Row, Accordion, Nav, NavDropdown } from 'react-bootstrap';
import More from '../../assets/img/More.svg';
import { end } from '@popperjs/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import RepIssueIcn from '../../assets/img/icn-warn.svg';
import icnCheck from '../../assets/img/check.svg';
import UserImg from '../../assets/img/Userimg.jpg';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import { RootState } from '../../store';
import { useIntlActionMessages, getFromLocalStorage } from '../../utils/helper';
import { resourcedetails, resolveResourceReport, deleteResource } from '../../store/actions/resourceActions';
import Default from '../../assets/img/resource_grey.png';
import { BUCKET_URL } from '../../utils/constants';
import { FILE_TYPES_SUPPORTED } from '../../utils/enums';
import Loader from '../../components/Loader';
import downdocicn from '../../assets/img/icn-download-doc.png';
import { IResolveResourceReport } from '../../interfaces/ResourceInterface';
import { MessageToaster } from '../../utils/ToastUtil';
import { resetResolveReport, resetDeleteResource, resetResourceDetail } from '../../store/slices/resourceSlice';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import Report from './Report';
import { isUserAdmin } from '../../utils/helper';
// Component to show resource informations
const ResourceInfoDetail = () => {
  // Navigation object
  const navigate = useNavigate();
  // Location object
  const location = useLocation();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Create route param object to access the route parameters.
  const params = useParams();
  // Message toaster object creation
  const toastObj = new MessageToaster();

  const storageData = getFromLocalStorage('MI_USR_DATA');
  // Initialize language variables.
  const categoryText = useIntlActionMessages('Label.Category');
  const SubCategoryText = useIntlActionMessages('Label.SubCategory');
  const NooftimesGoalsText = useIntlActionMessages('Label.NooftimesGoals');
  const TodoCompletionText = useIntlActionMessages('Label.TodoCompletion');
  const DownloadFileText = useIntlActionMessages('Label.DownloadFile');
  const UnsupportedFileTypeText = useIntlActionMessages('Label.UnsupportedFileType');
  const NoattachmentsFoundText = useIntlActionMessages('Label.NoattachmentsFound');
  const ResolveText = useIntlActionMessages('Label.Resolve');
  const ResolvedText = useIntlActionMessages('Label.Resolved');
  const TimeText = useIntlActionMessages('Label.Time');
  const TimesText = useIntlActionMessages('Label.Times');
  const EditResourceError = useIntlActionMessages('Form.authorization');
  const DeleteResourceError = useIntlActionMessages('Form.authorization');
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const [isCreatedUser, setIsCreatedUser] = useState<boolean>(false);
  const [showReportPopup, setShowReportPopup] = useState(false);
  // Access redux state variables
  const {
    resourceDetail,
    resourceDetailApiLoading,
    resourceDetailViewApiSuccess,
    resolveReportApiLoading,
    resolveReportApiErrorMessage,
    resolveReportApiSuccess,
    deleteResourceApiLoading,
    deleteResourceApiResponseMessage,
    deleteResourceApiSuccess,
  } = useAppSelector((state: RootState) => state.resource);
  const [isReportUser, setIsReportUser] = useState(false);
  // Resetting resolve resource report  works on unmounting
  useEffect(() => {
    return () => {
      dispatch(resetDeleteResource());
      dispatch(resetResolveReport());
      dispatch(resetResourceDetail());
    };
  }, []);

  useEffect(() => {
    if (isUserAdmin() === false) {
      setIsReportUser(true);
    }
  }, []);
  // call view api on initial loading
  useEffect(() => {
    dispatch(resetResolveReport());
    fetchResourceDetails();
  }, [params.resourceId]);
  // showing resolve resource report success and error messages
  useEffect(() => {
    if (resolveReportApiSuccess) {
      toastObj.toastSuccess(resolveReportApiErrorMessage);
      fetchResourceDetails();
    } else if (!resolveReportApiSuccess && resolveReportApiErrorMessage.length > 0) {
      toastObj.toastError(resolveReportApiErrorMessage);
    }
  }, [resolveReportApiLoading]);
  // handle resource detail response
  useEffect(() => {
    if (resourceDetailViewApiSuccess) {
      if (resourceDetail?.CreatedUserId == storageData?.UserId) {
        setIsCreatedUser(true);
      } else {
        setIsCreatedUser(false);
      }
    }
  }, [resourceDetailApiLoading]);

  // show  delete Resource api success message and error message
  useEffect(() => {
    if (deleteResourceApiSuccess) {
      toastObj.toastSuccess(deleteResourceApiResponseMessage);
      navigate('/resources', { state: { tabId: location?.state?.tabId } });
    } else if (!deleteResourceApiSuccess && deleteResourceApiResponseMessage.length > 0) {
      toastObj.toastError(deleteResourceApiResponseMessage);
    }
  }, [deleteResourceApiLoading]);

  // Api call to resolve resource report
  const resolveReport = (request: IResolveResourceReport) => {
    dispatch(resolveResourceReport(request));
  };
  // Api call to fetch resource details
  const fetchResourceDetails = () => {
    const id: string = String(params.resourceId) || '0';
    dispatch(resourcedetails(id));
  };
  // Function to display attachments
  const renderAttachment = (attachment: any) => {
    const fileType = attachment.resourcefilepath.toLowerCase().split('.').pop();
    if (!attachment.resourcefilepath) {
      return <p>{NoattachmentsFoundText}</p>;
    }
    if (FILE_TYPES_SUPPORTED.image.includes(fileType)) {
      return <img src={BUCKET_URL + attachment.resourcefilepath} width="100%" className="att-img-vid" />;
    } else if (FILE_TYPES_SUPPORTED.video.includes(fileType)) {
      return <ReactPlayer url={BUCKET_URL + attachment.resourcefilepath} controls width="100%" className="att-img-vid" />;
    } else if (FILE_TYPES_SUPPORTED.audio.includes(fileType)) {
      return <ReactAudioPlayer src={BUCKET_URL + attachment.resourcefilepath} controls />;
    } else if (FILE_TYPES_SUPPORTED.document.includes(fileType)) {
      return (
        <a href={BUCKET_URL + attachment.resourcefilepath} target="_blank" rel="noopener noreferrer" className="">
          <img src={downdocicn} alt="" /> {DownloadFileText}
        </a>
      );
    } else {
      return <p>{UnsupportedFileTypeText}</p>;
    }
  };
  // Handle delete confirmation
  const ShowDeleteConfirmPopup = () => {
    if (isCreatedUser) {
      setIsShowDeleteConfirmPopup(true);
    } else {
      toastObj.toastInfo(DeleteResourceError);
    }
  };
  // Api call to delete resource
  const handleDeleteResourcesClick = () => {
    if (params?.resourceId) {
      const resourceId: string = params?.resourceId;
      dispatch(deleteResource(resourceId));
    }
  };
  // handle edit resource
  const editResources = () => {
    if (isCreatedUser) {
      if (params?.resourceId) {
        const resourceId: string = params?.resourceId;
        if (location?.state?.tabId == 0) {
          navigate('/addmotivation', { state: { resourceId: resourceId } });
        } else {
          navigate('/addincentivecontent', { state: { resourceId: resourceId } });
        }
      }
    } else {
      toastObj.toastInfo(EditResourceError);
    }
  };
  // handle no of time label
  const getLabel = () => {
    return resourceDetail?.UserCount === 1 ? TimeText : TimesText;
  };

  const handleShowReportPopup = () => {
    setShowReportPopup(true);
  };
  const handleCloseReportPopup = () => {
    setShowReportPopup(false);
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>{resourceDetail && resourceDetail?.ResourceName}</h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            {location?.state?.tabId == 0 && (
              <Breadcrumb.Item onClick={() => navigate('/resources', { state: { tabId: 0 } })}>
                <FormattedMessage id="Hd.MotivationalResources" />
              </Breadcrumb.Item>
            )}
            {location?.state?.tabId == 1 && (
              <Breadcrumb.Item onClick={() => navigate('/resources', { state: { tabId: 1 } })}>
                <FormattedMessage id="Hd.IncentiveContents" />
              </Breadcrumb.Item>
            )}
            {location?.state?.tabId == 2 && (
              <Breadcrumb.Item onClick={() => navigate('/resources', { state: { tabId: 2 } })}>
                <FormattedMessage id="Hd.ReportedContents" />
              </Breadcrumb.Item>
            )}
            {location?.state?.tabId == 3 && (
              <Breadcrumb.Item onClick={() => navigate(`/goaldetail/${location?.state?.goalId}`)}>
                <FormattedMessage id="Hd.GoalDetails" />
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item active>
              <FormattedMessage id="Label.ResourceDetails" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="rep-contIssue-wr">
        <Accordion defaultActiveKey="1">
          {location?.state?.tabId == 2 && (
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h6>
                  {' '}
                  <img src={RepIssueIcn} alt="" className="Rep-IssueIcn" />
                  <FormattedMessage id="Label.ReportedIssue" />
                </h6>
              </Accordion.Header>
              {resourceDetail?.Reasons?.map((reason: any, index: any) => (
                <Accordion.Body key={index} className="acco-body">
                  <Row className="d-flex border-bottom r-rep-contIssue">
                    <Col md={10} className="flex-grow-1">
                      <h6>{reason?.reason}</h6>
                      <p className="p-gray">{reason?.comments}</p>
                    </Col>
                    <Col md={2}>
                      {!reason.isresolved ? (
                        <Button
                          variant="primary"
                          size="sm"
                          className="res-addMot-btn float-end btn-resolv"
                          onClick={() =>
                            resolveReport({
                              ReportId: reason?.reportedid.toString(),
                              ResourceId: reason?.resourceid.toString(),
                            })
                          }
                        >
                          {ResolveText}
                        </Button>
                      ) : (
                        <Button variant="primary" size="sm" className="res-addMot-btn float-end btn-resolved">
                          <img src={icnCheck} alt="" />
                          {ResolvedText}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Accordion.Body>
              ))}
            </Accordion.Item>
          )}
          <Accordion.Item eventKey="1">
            {location?.state?.tabId != 3 && (
              <Nav className="more-dropdown">
                <NavDropdown align={end} className="icon-btn-more" title={<img src={More} alt="more" />}>
                  <NavDropdown.Item onClick={editResources}>
                    <FormattedMessage id="Button.Edit" />
                  </NavDropdown.Item>
                  {resourceDetail?.UserCount === null && (
                    <NavDropdown.Item onClick={(e) => ShowDeleteConfirmPopup()}>
                      <FormattedMessage id="Button.Delete" />
                    </NavDropdown.Item>
                  )}
                  {params.isReportedTab !== undefined && isUserAdmin() === false && (
                    <NavDropdown.Item onClick={handleShowReportPopup}>
                      <FormattedMessage id="Button.Report" />
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              </Nav>
            )}
            <Accordion.Header>
              <div className="content-sub-header d-flex justify-content-between align-items-center">
                <h6>
                  <FormattedMessage id="Label.ResourceDetails" />
                </h6>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="d-flex r-rep-contIssue">
                <Col md={6}>
                  <div className="res-det-img">
                    <img src={resourceDetail && resourceDetail?.ResourceThumbnail ? BUCKET_URL + resourceDetail?.ResourceThumbnail : Default} alt="" className="w-100 res--img" />
                    <div className="img-overlay">
                      <h5 className="card-title"> {useIntlActionMessages('Label.CreatedBy')}</h5>
                      <div className="d-flex">
                        <div className="created-pro-img">
                          <img src={UserImg} alt="" />
                        </div>
                        <div className="creater-name-sec">
                          <h6>{resourceDetail && resourceDetail?.CreatedUser}</h6>
                          <p className="teach-desi">{resourceDetail && resourceDetail?.CreatedUserRole}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="r-sec-rgt ">
                  <div>
                    <h5>{resourceDetail && resourceDetail?.ResourceName}</h5>
                    <p className="p-gray text-capitalize">{resourceDetail && resourceDetail?.Description}</p>
                  </div>
                  <div>
                    <Row className="label-with-content ms-0 mr-0 border-top">
                      {resourceDetail && resourceDetail?.CategoryName && (
                        <Col xl={3} lg={3} md={6}>
                          <label>
                            <FormattedMessage id={categoryText} />
                          </label>
                          <p>{resourceDetail?.CategoryName}</p>
                        </Col>
                      )}
                      {resourceDetail && resourceDetail?.ClassName && (
                        <Col xl={3} lg={3} md={6}>
                          <label>
                            <FormattedMessage id={SubCategoryText} />
                          </label>
                          {resourceDetail?.ClassName.map((classItem: any, index: number) => (
                            <p key={index} className="SubCategory">
                              {classItem.classname}
                              {index !== resourceDetail?.ClassName?.length - 1 && ','}
                            </p>
                          ))}
                        </Col>
                      )}
                      {resourceDetail && resourceDetail?.UserCount && (
                        <Col xl={4} lg={3} md={6}>
                          <label>{NooftimesGoalsText}</label>
                          <p>
                            {resourceDetail?.UserCount} {getLabel()}
                          </p>
                        </Col>
                      )}
                      {resourceDetail && resourceDetail?.TodoCompletionRate > 0 && (
                        <Col xl={4} lg={3} md={6}>
                          <label>{TodoCompletionText}</label>
                          <p>{resourceDetail?.TodoCompletionRate}</p>
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="content-sub-header d-flex justify-content-between align-items-center">
                <h6>
                  {useIntlActionMessages('Label.Attachments')}
                  {resourceDetail && resourceDetail.Attachments && resourceDetail.Attachments.length > 0 ? <span> ({resourceDetail.Attachments.length})</span> : <span> (0)</span>}
                </h6>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="content-area-padding">
                <Row>
                  {resourceDetail && resourceDetail.Attachments ? (
                    resourceDetail.Attachments.map((attachment: any, index: any) => (
                      <Col md={6} key={index} className="res-att mb-3">
                        {renderAttachment(attachment)}
                        <p className="mt-2">{attachment?.display_name}</p>
                      </Col>
                    ))
                  ) : (
                    <p>{NoattachmentsFoundText}</p>
                  )}
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <DeleteConfirmPopup
          show={isShowDeleteConfirmPopup}
          handleClose={() => setIsShowDeleteConfirmPopup(false)}
          onCancel={() => setIsShowDeleteConfirmPopup(false)}
          onOkay={() => handleDeleteResourcesClick()}
          title={useIntlActionMessages('Text.DeleteConfirmation.Resource')}
          content={useIntlActionMessages('Text.DeleteConfirmation.Resource.Details.Desc')}
        />
        <Report show={showReportPopup} resourceId={params.resourceId} reportedBy={storageData?.UserId} onClose={handleCloseReportPopup} />
      </div>
      {(resourceDetailApiLoading || resolveReportApiLoading || deleteResourceApiLoading) && <Loader />}
    </>
  );
};
export default ResourceInfoDetail;
