/**
 * @file   src\store\slices\settingsSlice.ts
 * @brief  This file is responsible for save settings based slices to call actions and state management.
 * @date   JULY, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { ISettingsSlice } from '../../interfaces/SettingsInterface';
import { createOrUpdateSystemAdminPermissions, getOnboardingProcessColumns, getSettings, getSystemAdminPermissions, getUserRolePermissions, saveSettings } from '../actions/settingsAction';

// Initial state
const settingsDefaultState: ISettingsSlice = {
  saveSettingsApiLoading: false,
  saveSettingsApiSuccess: false,
  saveSettingsApiData: {},
  saveSettingsApiResponseCode: 0,
  saveSettingsApiResponseMessage: '',
  getOnboardingProcessColumnsApiLoading: false,
  getOnboardingProcessColumnsApiSuccess: false,
  getOnboardingProcessColumnsApiData: {},
  getOnboardingProcessColumnsApiResponseCode: 0,
  getOnboardingProcessColumnsApiResponseMessage: '',
  getUserRolePermissionsApiLoading: false,
  getUserRolePermissionsApiSuccess: false,
  getUserRolePermissionsApiData: {},
  getUserRolePermissionsApiResponseCode: 0,
  getUserRolePermissionsApiResponseMessage: '',
  getSettingsApiLoading: false,
  getSettingsApiSuccess: false,
  getSettingsApiData: {},
  getSettingsApiResponseCode: 0,
  getSettingsApiResponseMessage: '',
  createOrUpdateSystemAdminPermissionsApiLoading: false,
  createOrUpdateSystemAdminPermissionsApiSuccess: false,
  createOrUpdateSystemAdminPermissionsApiData: {},
  createOrUpdateSystemAdminPermissionsApiResponseCode: 0,
  createOrUpdateSystemAdminPermissionsApiResponseMessage: '',
  getSystemAdminPermissionsApiLoading: false,
  getSystemAdminPermissionsApiSuccess: false,
  getSystemAdminPermissionsApiData: {},
  getSystemAdminPermissionsApiResponseCode: 0,
  getSystemAdminPermissionsApiResponseMessage: '',
};
// Survey slice
const settingsSlice = createSlice({
  name: 'settings',
  initialState: settingsDefaultState,
  reducers: {
    resetGetSettings: (state) => {
      state.getSettingsApiData = {};
      state.getSettingsApiLoading = false;
      state.getSettingsApiResponseCode = 0;
      state.getSettingsApiResponseMessage = '';
      state.getSettingsApiSuccess = false;
    },
    resetSaveSettings:(state) => {
      state.saveSettingsApiData = {};
      state.saveSettingsApiLoading = false;
      state.saveSettingsApiResponseCode = 0;
      state.saveSettingsApiResponseMessage = '';
      state.saveSettingsApiSuccess = false;
    },
    resetCreateOrUpdateSystemAdminPermissions:(state) => {
      state.createOrUpdateSystemAdminPermissionsApiData = {};
      state.createOrUpdateSystemAdminPermissionsApiLoading = false;
      state.createOrUpdateSystemAdminPermissionsApiResponseCode = 0;
      state.createOrUpdateSystemAdminPermissionsApiResponseMessage = '';
      state.createOrUpdateSystemAdminPermissionsApiSuccess = false;
    },
  },
  extraReducers(builder) {
    // save settings api starts
    builder.addCase(saveSettings.pending, (state) => {
      state.saveSettingsApiLoading = true;
      state.saveSettingsApiSuccess = false;
      state.saveSettingsApiResponseCode = 0;
    });
    // save settings api success
    builder.addCase(saveSettings.fulfilled, (state, action) => {
      state.saveSettingsApiLoading = false;
      state.saveSettingsApiSuccess = true;
      state.saveSettingsApiResponseCode = action.payload.ResponseCode;
      state.saveSettingsApiData = action.payload.ResponseData;
      state.saveSettingsApiResponseMessage = action.payload.ResponseMessage;
    });
    // save settings api failure
    builder.addCase(saveSettings.rejected, (state, action: any) => {
      state.saveSettingsApiLoading = false;
      state.saveSettingsApiSuccess = false;
      state.saveSettingsApiResponseCode = action.payload.ResponseCode;
      state.saveSettingsApiResponseMessage = action.payload.ResponseMessage;
    });
    // get Onboarding Process Columns api starts
    builder.addCase(getOnboardingProcessColumns.pending, (state) => {
      state.getOnboardingProcessColumnsApiLoading = true;
      state.getOnboardingProcessColumnsApiSuccess = false;
      state.getOnboardingProcessColumnsApiResponseCode = 0;
    });
    // get Onboarding Process Columns api success
    builder.addCase(getOnboardingProcessColumns.fulfilled, (state, action) => {
      state.getOnboardingProcessColumnsApiLoading = false;
      state.getOnboardingProcessColumnsApiSuccess = true;
      state.getOnboardingProcessColumnsApiResponseCode = action.payload.ResponseCode;
      state.getOnboardingProcessColumnsApiData = action.payload.ResponseData;
    });
    // get Onboarding Process Columns api failure
    builder.addCase(getOnboardingProcessColumns.rejected, (state, action: any) => {
      state.getOnboardingProcessColumnsApiLoading = false;
      state.getOnboardingProcessColumnsApiSuccess = false;
      state.getOnboardingProcessColumnsApiResponseCode = action.payload.ResponseCode;
      state.getOnboardingProcessColumnsApiResponseMessage = action.payload.ResponseMessage;
    });
    // get Onboarding Process Columns api starts
    builder.addCase(getUserRolePermissions.pending, (state) => {
      state.getUserRolePermissionsApiLoading = true;
      state.getUserRolePermissionsApiSuccess = false;
      state.getUserRolePermissionsApiResponseCode = 0;
    });
    // get Onboarding Process Columns api success
    builder.addCase(getUserRolePermissions.fulfilled, (state, action) => {
      state.getUserRolePermissionsApiLoading = false;
      state.getUserRolePermissionsApiSuccess = true;
      state.getUserRolePermissionsApiResponseCode = action.payload.ResponseCode;
      state.getUserRolePermissionsApiData = action.payload.ResponseData;
    });
    // get Onboarding Process Columns api failure
    builder.addCase(getUserRolePermissions.rejected, (state, action: any) => {
      state.getUserRolePermissionsApiLoading = false;
      state.getUserRolePermissionsApiSuccess = false;
      state.getUserRolePermissionsApiResponseCode = action.payload.ResponseCode;
      state.getUserRolePermissionsApiResponseMessage = action.payload.ResponseMessage;
    });
    // get settings api starts
    builder.addCase(getSettings.pending, (state) => {
      state.getSettingsApiLoading = true;
      state.getSettingsApiSuccess = false;
      state.getSettingsApiResponseCode = 0;
    });
    // get settings api success
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.getSettingsApiLoading = false;
      state.getSettingsApiSuccess = true;
      state.getSettingsApiResponseCode = action.payload.ResponseCode;
      state.getSettingsApiData = action.payload.ResponseData;
    });
    // get settings api failure
    builder.addCase(getSettings.rejected, (state, action: any) => {
      state.getSettingsApiLoading = false;
      state.getSettingsApiSuccess = false;
      state.getSettingsApiResponseCode = action.payload.ResponseCode;
      state.getSettingsApiResponseMessage = action.payload.ResponseMessage;
    });
    // create or update system admin permissions api starts
    builder.addCase(createOrUpdateSystemAdminPermissions.pending, (state) => {
      state.createOrUpdateSystemAdminPermissionsApiLoading = true;
      state.createOrUpdateSystemAdminPermissionsApiSuccess = false;
      state.createOrUpdateSystemAdminPermissionsApiResponseCode = 0;
    });
    // create or update system admin permissions api success
    builder.addCase(createOrUpdateSystemAdminPermissions.fulfilled, (state, action) => {
      state.createOrUpdateSystemAdminPermissionsApiLoading = false;
      state.createOrUpdateSystemAdminPermissionsApiSuccess = true;
      state.createOrUpdateSystemAdminPermissionsApiResponseCode = action.payload.ResponseCode;
      state.createOrUpdateSystemAdminPermissionsApiData = action.payload.ResponseData;
      state.createOrUpdateSystemAdminPermissionsApiResponseMessage = action.payload.ResponseMessage;
    });
    // create or update system admin permissions api failure
    builder.addCase(createOrUpdateSystemAdminPermissions.rejected, (state, action: any) => {
      state.createOrUpdateSystemAdminPermissionsApiLoading = false;
      state.createOrUpdateSystemAdminPermissionsApiSuccess = false;
      state.createOrUpdateSystemAdminPermissionsApiResponseCode = action.payload.ResponseCode;
      state.createOrUpdateSystemAdminPermissionsApiResponseMessage = action.payload.ResponseMessage;
    });
    // create or update system admin permissions api starts
    builder.addCase(getSystemAdminPermissions.pending, (state) => {
      state.getSystemAdminPermissionsApiLoading = true;
      state.getSystemAdminPermissionsApiSuccess = false;
      state.getSystemAdminPermissionsApiResponseCode = 0;
    });
    // create or update system admin permissions api success
    builder.addCase(getSystemAdminPermissions.fulfilled, (state, action) => {
      state.getSystemAdminPermissionsApiLoading = false;
      state.getSystemAdminPermissionsApiSuccess = true;
      state.getSystemAdminPermissionsApiResponseCode = action.payload.ResponseCode;
      state.getSystemAdminPermissionsApiData = action.payload.ResponseData;
      state.getSystemAdminPermissionsApiResponseMessage = action.payload.ResponseMessage;
    });
    // create or update system admin permissions api failure
    builder.addCase(getSystemAdminPermissions.rejected, (state, action: any) => {
      state.getSystemAdminPermissionsApiLoading = false;
      state.getSystemAdminPermissionsApiSuccess = false;
      state.getSystemAdminPermissionsApiResponseCode = action.payload.ResponseCode;
      state.getSystemAdminPermissionsApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});
// Export actions
export const { resetGetSettings, resetSaveSettings, resetCreateOrUpdateSystemAdminPermissions } = settingsSlice.actions;

// Export reducer.
export default settingsSlice.reducer;
