/**
 * @file   src\store\actions\surveyActions.ts
 * @brief  This file is responsible for creating survey based api call.
 * @date   JULY, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { SurveyApis } from '../../utils/apiUrls';
import {
  IGetUsersBySurveyIdAPIRequest,
  IGetAssignmentStudentsListAPIRequest,
  IAssignmentStatusCountApiRequest,
  IStudentSurveyResponseAPIRequest,
} from '../../interfaces/SurveyInterface';
// API call to add survey step 1
export const createSurvey = createAsyncThunk('/createSurvey', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.CREATE_SURVEY}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to list surveys
export const getSurveyList = createAsyncThunk('/getSurveyList', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.LIST_SURVEY}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to list surveys
export const getAnswerTypeList = createAsyncThunk('/getAnswerTypeList', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${SurveyApis.LIST_ANSWER_TYPES}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to list survey form
export const getSurveyFormList = createAsyncThunk('/getSurveyFormList', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.LIST_SURVEY_FORMS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to add survey form
export const addSurveyForm = createAsyncThunk('/addSurveyForm', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.ADD_SURVEY_FORM}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to update survey form
export const updateSurveyForm = createAsyncThunk('/updateSurveyForm', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.UPDATE_SURVEY_FORM}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to delte survey form
export const deleteSurveyForm = createAsyncThunk('/deleteSurveyForm', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.DELETE_SURVEY_FORMS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to delte survey form
export const finishSurvey = createAsyncThunk('/finishSurvey', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.FINISH_SURVEY}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get survey details
export const getSurveyDetails = createAsyncThunk('/getSurveyDetails/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.GET_SURVEY_DETAILS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get survey form details
export const getSurveyFormDetails = createAsyncThunk('/getSurveyFormDetails/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.SURVEY_FORM_DETAILS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get assigned users to survey
export const getUsersBySurveyId = createAsyncThunk('/getUsersBySurveyId', async (request: IGetUsersBySurveyIdAPIRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.GET_USERS_BY_SURVEY_ID}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to update survey
export const updateSurvey = createAsyncThunk('/updateSurvey/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.UPDATE_SURVEY}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to get assigned users to survey
export const getAssignmentStudentsList = createAsyncThunk('/getAssignmentStudentsList', async (request: IGetAssignmentStudentsListAPIRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.GET_ASSIGNMENT_STUDENTS_LIST}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to get assignment count for each status
export const getAssignmentStatusCount = createAsyncThunk('/getAssignmentStatusCount', async (request: IAssignmentStatusCountApiRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.GET_ASSIGNMENT_STATUS_COUNT}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to get assigned users to survey
export const getStudentSurveyResponse = createAsyncThunk('/getStudentSurveyResponse', async (request: IStudentSurveyResponseAPIRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.GET_SURVEY_QUESTION_RESPONSE_LIST}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to upload template(survey form)
export const uploadSurveyForm = createAsyncThunk('/uploadSurveyForm/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.UPLOAD_SURVEY_FORM}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to delete survey form
export const deleteSurveyFormAttachment = createAsyncThunk('/deleteSurveyFormAttachment', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete(`${SurveyApis.DELETE_SURVEY_FORM_ATTACHMENT}`, { data: request });
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to delete survey
export const deleteSurvey = createAsyncThunk('/deleteSurvey', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete(`${SurveyApis.DELETE_SURVEY}`, { data: request });
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get users associated to survey
export const listusersassociatedtosurvey = createAsyncThunk('/listusersassociatedtosurvey', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${SurveyApis.LIST_USERS_ASSOCIATED_TO_SURVEY}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
