/**
 * @file   src\store\slices\dashboardSlice.ts
 * @brief  This file is responsible for creating dashboard based slices to call actions and state management.
 * @date   JAN, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { getOrganizationTypeList, getUserCountByOrganizationType, getOverallPerformanceByOrganizationType, sendMailContactAdmin, getOverallPerformanceBySubcategory } from '../actions/dashboardActions';
import { useIntlActionMessages } from '../../utils/helper';
import { IDashboardSlice } from '../../interfaces/dashboardInterface';

// Initial state
const dashboardDefaultState: IDashboardSlice = {
  organizationTypeList: [],
  organizationTypeListLoading: false,
  organizationTypeListSuccess: false,
  organizationTypeListErrorCode: 0,
  organizationTypeListErrorMessage: '',
  userCountListData: [],
  userCountListApiResponseCode: 0,
  userCountListApiLoading: false,
  userCountListApiSuccess: false,
  userCountListApiResponseMessage: '',
  overallPerformanceListData: [],
  overallPerformanceListApiResponseCode: 0,
  overallPerformanceListApiLoading: false,
  overallPerformanceListApiSuccess: false,
  overallPerformanceListApiResponseMessage: '',
  contactAdminMessageData: [],
  contactAdminMessageApiResponseCode: 0,
  contactAdminMessageApiLoading: false,
  contactAdminMessageApiSuccess: false,
  contactAdminMessageApiResponseMessage: '',
  subcategorywiseoverallPerformanceListData: [],
  subcategorywiseoverallPerformanceListApiResponseCode: 0,
  subcategorywiseoverallPerformanceListApiLoading: false,
  subcategorywiseoverallPerformanceListApiSuccess: false,
  subcategorywiseoverallPerformanceListApiResponseMessage: '',
};

// Dashboard slice
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: dashboardDefaultState,
  reducers: {
    resetDashboard: (state) => {
      state.organizationTypeList = [];
      state.organizationTypeListLoading = false;
      state.organizationTypeListSuccess = false;
      state.organizationTypeListErrorCode = 0;
      state.organizationTypeListErrorMessage = '';
    },
    resestUserCountList: (state) => {
      state.userCountListData = {};
      state.userCountListApiLoading = false;
      state.userCountListApiSuccess = false;
      state.userCountListApiResponseCode = 0;
      state.userCountListApiResponseMessage = '';
    },
    resetOverallPerformanceList: (state) => {
      state.overallPerformanceListData = {};
      state.overallPerformanceListApiLoading = false;
      state.overallPerformanceListApiSuccess = false;
      state.overallPerformanceListApiResponseCode = 0;
      state.overallPerformanceListApiResponseMessage = '';
    },
    resetContactAdminMessage: (state) => {
      state.contactAdminMessageData = {};
      state.contactAdminMessageApiLoading = false;
      state.contactAdminMessageApiSuccess = false;
      state.contactAdminMessageApiResponseCode = 0;
      state.contactAdminMessageApiResponseMessage = '';
    },
    resetSubcategorywiseOverallPerformanceList: (state) => {
      state.subcategorywiseoverallPerformanceListData = {};
      state.subcategorywiseoverallPerformanceListApiLoading = false;
      state.subcategorywiseoverallPerformanceListApiSuccess = false;
      state.subcategorywiseoverallPerformanceListApiResponseCode = 0;
      state.subcategorywiseoverallPerformanceListApiResponseMessage = '';
    },
  },
  extraReducers(builder) {
    // get orders list api starts
    // getOrganizationTypeList is the action name defined in actions/dashboardActions
    builder.addCase(getOrganizationTypeList.pending, (state) => {
      state.organizationTypeListLoading = true;
      state.organizationTypeListSuccess = false;
      state.organizationTypeListErrorCode = 0;
      state.organizationTypeListErrorMessage = '';
    });
    // get orders list api success
    builder.addCase(getOrganizationTypeList.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.organizationTypeList = data?.orders || [];
      state.organizationTypeListLoading = false;
      state.organizationTypeListSuccess = true;
      state.organizationTypeListErrorCode = error;
    });
    // get orders list api failure
    builder.addCase(getOrganizationTypeList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.organizationTypeListLoading = false;
      state.organizationTypeListSuccess = false;
      state.organizationTypeListErrorCode = error;
      state.organizationTypeListErrorMessage = useIntlActionMessages(error && error !== 0 ? error.toString() : '');
    });
    // User list  api starts
    builder.addCase(getUserCountByOrganizationType.pending, (state) => {
      state.userCountListApiLoading = true;
      state.userCountListApiSuccess = false;
      state.userCountListApiResponseCode = 0;
    });
    // User list api success
    builder.addCase(getUserCountByOrganizationType.fulfilled, (state, action) => {
      state.userCountListApiLoading = false;
      state.userCountListApiSuccess = true;
      state.userCountListApiResponseCode = action.payload.ResponseCode;
      state.userCountListData = action.payload.ResponseData;
      state.userCountListApiResponseMessage = action.payload.ResponseMessage;
    });
    // User list api failure
    builder.addCase(getUserCountByOrganizationType.rejected, (state, action: any) => {
      state.userCountListApiLoading = false;
      state.userCountListApiSuccess = false;
      state.userCountListApiResponseCode = action.payload.ResponseCode;
      state.userCountListApiResponseMessage = action.payload.ResponseMessage;
    });

    // overall performance api starts
    builder.addCase(getOverallPerformanceByOrganizationType.pending, (state) => {
      state.overallPerformanceListApiLoading = true;
      state.overallPerformanceListApiSuccess = false;
      state.overallPerformanceListApiResponseCode = 0;
    });
    // overall performance api success
    builder.addCase(getOverallPerformanceByOrganizationType.fulfilled, (state, action) => {
      state.overallPerformanceListApiLoading = false;
      state.overallPerformanceListApiSuccess = true;
      state.overallPerformanceListApiResponseCode = action.payload.ResponseCode;
      state.overallPerformanceListData = action.payload.ResponseData;
      state.overallPerformanceListApiResponseMessage = action.payload.ResponseMessage;
    });
    // overall performance api failure
    builder.addCase(getOverallPerformanceByOrganizationType.rejected, (state, action: any) => {
      state.overallPerformanceListApiLoading = false;
      state.overallPerformanceListApiSuccess = false;
      state.overallPerformanceListApiResponseCode = action.payload.ResponseCode;
      state.overallPerformanceListApiResponseMessage = action.payload.ResponseMessage;
    });
    // sendMail to Contact Admin api starts
    builder.addCase(sendMailContactAdmin.pending, (state) => {
      state.contactAdminMessageApiLoading = true;
      state.contactAdminMessageApiSuccess = false;
      state.contactAdminMessageApiResponseCode = 0;
    });
    // sendMail to Contact Admin api success
    builder.addCase(sendMailContactAdmin.fulfilled, (state, action) => {
      state.contactAdminMessageApiLoading = false;
      state.contactAdminMessageApiSuccess = true;
      state.contactAdminMessageApiResponseCode = action.payload.ResponseCode;
      state.contactAdminMessageData = action.payload.ResponseData;
      state.contactAdminMessageApiResponseMessage = action.payload.ResponseMessage;
    });
    // sendMail to Contact Admin api failure
    builder.addCase(sendMailContactAdmin.rejected, (state, action: any) => {
      state.contactAdminMessageApiLoading = false;
      state.contactAdminMessageApiSuccess = false;
      state.contactAdminMessageApiResponseCode = action.payload.ResponseCode;
      state.contactAdminMessageApiResponseMessage = action.payload.ResponseMessage;
    });
    // subcategory wise overall performance api starts
    builder.addCase(getOverallPerformanceBySubcategory.pending, (state) => {
      state.subcategorywiseoverallPerformanceListApiLoading = true;
      state.subcategorywiseoverallPerformanceListApiSuccess = false;
      state.subcategorywiseoverallPerformanceListApiResponseCode = 0;
    });
    // subcategory wise overall performance api success
    builder.addCase(getOverallPerformanceBySubcategory.fulfilled, (state, action) => {
      state.subcategorywiseoverallPerformanceListApiLoading = false;
      state.subcategorywiseoverallPerformanceListApiSuccess = true;
      state.subcategorywiseoverallPerformanceListApiResponseCode = action.payload.ResponseCode;
      state.subcategorywiseoverallPerformanceListData = action.payload.ResponseData;
      state.subcategorywiseoverallPerformanceListApiResponseMessage = action.payload.ResponseMessage;
    });
    // subcategory wise overall performance api failure
    builder.addCase(getOverallPerformanceBySubcategory.rejected, (state, action: any) => {
      state.subcategorywiseoverallPerformanceListApiLoading = false;
      state.subcategorywiseoverallPerformanceListApiSuccess = false;
      state.subcategorywiseoverallPerformanceListApiResponseCode = action.payload.ResponseCode;
      state.subcategorywiseoverallPerformanceListApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});

// Export actions
export const { resetDashboard, resestUserCountList, resetOverallPerformanceList,resetContactAdminMessage, resetSubcategorywiseOverallPerformanceList } = dashboardSlice.actions;

// Export reducer.
export default dashboardSlice.reducer;
