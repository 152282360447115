/**
 * @file   src\validations\IncentiveResourceSchema.ts
 * @brief  This file is responsible for defining incentive resource validation schemas.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { NumberValues } from '../utils/enums';


// Incentive Resource Schema
export const INCENTIVE_RESOURCE_SCHEMA = yup.object({

  ResourceName: yup
    .string()
    .trim()
    .max(NumberValues.NUM_100, useIntlActionMessages('Form.ResourceName.Max'))
    .required(useIntlActionMessages('Form.ResourceName.Required')),
  Description: yup
    .string()
    .required(useIntlActionMessages('Form.Description.Required'))
    .max(NumberValues.NUM_1000, useIntlActionMessages('Form.Description.Max.Allow')),
});
