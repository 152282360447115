import React from 'react';
const SurveyThumbIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31.484" height="28.902" viewBox="0 0 31.484 28.902">
            <g id="Group_12773" data-name="Group 12773" transform="translate(0 0.5)">
                <g id="Group_12125" data-name="Group 12125" transform="translate(0 0)">
                    <path id="Path_10336" data-name="Path 10336" d="M14.141,31.938H10a1.892,1.892,0,0,1-1.749-1.3L6.2,24.192h5.958l1.185,4.094A3.881,3.881,0,0,0,14.58,30.1c.979.8.35,1.835-.439,1.835" transform="translate(-0.905 -3.536)" fill="#ff3b30" />
                    <path id="Subtraction_1" data-name="Subtraction 1" d="M12.888,24.53h0a2.052,2.052,0,0,1-.657-.11A13.585,13.585,0,0,1,8.89,22.338,20.159,20.159,0,0,0,1.5,18.391,11.321,11.321,0,0,1,0,12.372,11.084,11.084,0,0,1,1.659,6.116c3.165-.764,5.962-2.8,8-4.28A15.654,15.654,0,0,1,12.312.127,1.707,1.707,0,0,1,12.956,0c1.215,0,2.479,1.269,3.468,3.48a22.129,22.129,0,0,1,1.649,8.778,22.3,22.3,0,0,1-1.6,8.8C15.488,23.263,14.183,24.53,12.888,24.53ZM9.849,14.989A22.784,22.784,0,0,0,11,20.1c.448,1.027,1.087,1.616,1.754,1.616s1.312-.59,1.758-1.618a21.41,21.41,0,0,0,1.38-8.053A19.2,19.2,0,0,0,14.6,4.462C14.123,3.422,13.5,2.85,12.85,2.85s-1.276.573-1.758,1.613A20.766,20.766,0,0,0,9.837,9.485h.5a2.752,2.752,0,0,1,0,5.5Z" transform="translate(12.911)" fill="#1c7c67" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" />
                    <path id="Path_10339" data-name="Path 10339" d="M11.37,19.179H5.727a5.81,5.81,0,0,1,0-11.619h5.737a15.128,15.128,0,0,0-1.131,5.931,15.259,15.259,0,0,0,1.037,5.688" transform="translate(0 -1.105)" fill="#1c7c67" />
                </g>
            </g>
        </svg>
    );
}

export default SurveyThumbIcon;
