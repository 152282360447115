import React, {  } from "react";
import { stringFormat } from "../utils/StringBuilder";
import { useIntlMessages } from '../utils/helper';
import Paginate from '../components/Paginate';


const Pagination = (props: any) => {


  return (
    <div className="d-md-flex justify-content-between align-items-center">
      <p className="m-0">
        {stringFormat(
          useIntlMessages("Label.Pagination"),
          props.itemsDisplayed > 0
            ? (props.totalitems === 1 ? 1 : props.currentPage)  * props.pageSize + 1
            : 0,
          (props.totalitems === 1 ? 1 : props.currentPage)  * props.pageSize + props.itemsDisplayed,
          props.totalitems,
        )}
      </p>
      <Paginate pageSize={props.pageSize} totalitems={props.totalitems} pageLimit={props.pageSize}
        setCurrentPage={(page: number) => props.setCurrentPage(page)}
        currentPage={props.currentPage}
        prevPage={props.prevPage}
        itemsDisplayed={props.itemsDisplayed}
      />
    </div>
  );
}

export default Pagination;