import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import '../assets/css/Input.scss';
import SearchIcon from '../assets/img/icon/Search';

const Search = (props: any) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchValue(props.value || ''); // Update the internal state when the value prop changes
  }, [props.value]);

  const handleInputChange = (event: any) => {
    setSearchValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div className="search-main">
      <Form>
        <div className="form">
          <InputGroup>
            <Form.Control
              type="search"
              placeholder={props.placeholder}
              size={props.size}
              value={searchValue} // Use the searchValue state instead of props.value
              onChange={handleInputChange} // Call handleInputChange to update internal state
              onKeyDown={props.onKeyDown}
              onKeyUp={props.onKeyUp}
            />
            <Button variant="outline-secondary" id="button-addon2" onClick={props.onClick}>
              <SearchIcon />
            </Button>
          </InputGroup>
        </div>
      </Form>
    </div>
  );
};

export default Search;
