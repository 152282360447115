/**
 * @file   src\validations\authSchema.ts
 * @brief  This file is responsible for defining authentication validation schemas.
 * @date   May, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../utils/constants';

// SignIn form validation schema.
export const SIGNIN_SCHEMA = yup.object({
  username: yup
    .string()
    .trim()
    // .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    // .required(useIntlActionMessages('LoginForm.Email.Required')),
    .required(useIntlActionMessages('LoginForm.EmailUsername.Required')),
  password: yup.string().trim().required(useIntlActionMessages('Form.Password.Required')),
});

// forgot password form schema
export const FORGOT_PWD_SCHEMA = yup.object({
  Email: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('LoginForm.Email.Required')),
});

// Reset password schema
export const RESET_PWD_SCHEMA = yup.object({
  Password: yup.string().trim().matches(PASSWORD_REGEX, useIntlActionMessages('Form.Password.Criteria')).required(useIntlActionMessages('Form.Password.Required')),
  ConfirmPassword: yup
    .string()
    .required(useIntlActionMessages('Form.Password.Required'))
    .oneOf([yup.ref('Password')], useIntlActionMessages('Form.Password.NotMatch')),
});

// Change password schema
export const CHANGE_PWD_SCHEMA = yup.object({
  OldPassword: yup.string().trim().required(useIntlActionMessages('Form.CurrentPassword.Required')),
  // Newpassword: yup.string().trim().required(useIntlActionMessages('Form.Password.Required')).matches(PASSWORD_REGEX, useIntlActionMessages('Form.Password.Criteria')),
  Newpassword: yup.string().trim().matches(PASSWORD_REGEX, useIntlActionMessages('Form.Password.Criteria')).required(useIntlActionMessages('Form.NewPassword.Required')),
  ConfirmPassword: yup
    .string()
    .required(useIntlActionMessages('Form.CnfPassword.Required'))
    .oneOf([yup.ref('Newpassword')], useIntlActionMessages('Form.Password.NotMatch')),
});
