/**
 * @file   src\containers\organizations\ReviewProof.tsx
 * @brief  Review Proof page.
 * @date   March, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useEffect, useState, useRef } from 'react';
import '../../assets/css/Home.scss';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Breadcrumb, Button, Nav, NavDropdown } from 'react-bootstrap';
import Select from '../../components/MASelect';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RightIcon from '../../assets/img/icon/RightArrow';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import { listProofs } from '../../store/actions/userActions';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { BUCKET_URL, PAGE_SIZE_REVIEW_PROOF } from '../../utils/constants';
import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';
import { IListReviewProofsRequest } from '../../interfaces/UserInterface';
import { IMessageAssignmentApi } from '../../interfaces/GoalInterface';
import { IuploadFilePathUpdateMultipleParams } from '../../interfaces/OrganisationInterface';
import { ReviewProofSort, ReviewProofStatus, RoleTypeIds, NumberValues, FILE_TYPES_SUPPORTED } from '../../utils/enums';
import { useParams, Link } from 'react-router-dom';
import { resetReviewProofs } from '../../store/slices/userSlice';
import { getFromLocalStorage, getCurrentOrgDetails, useIntlActionMessages, checkValidFile, getFileTypeNumber, bytesToSize } from '../../utils/helper';
import { approveAssignment, rejectAssignment, sendAssignmentMessage } from '../../store/actions/goalAction';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import { MessageToaster } from '../../utils/ToastUtil';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import Input from '../../components/MAInput';
import { end } from '@popperjs/core';
import AddIcon from '../../assets/img/icon/Add';
import PopupRecorder from '../../components/PopupRecorder';
import Image from '../../assets/img/icon/Image';
import Audio from '../../assets/img/icon/Audio';
import Video from '../../assets/img/icon/Video';
import Doc from '../../assets/img/icon/Attachment';
import AttachmentCard from '../../components/AttachmentCard';
import { resetUploadFile, resetUploadFilePathUpdateMultiple } from '../../store/slices/organizationSlice';
import { resetAssignmentMessage } from '../../store/slices/goalSlice';
import { uploadFile, uploadFilePathUpdateMultiple } from '../../store/actions/organizationActions';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { resetApproveAssignment, resetRejectAssignment } from '../../store/slices/goalSlice';

const showBy = [
  { label: 'Show by Name', value: ReviewProofSort.STUDENT_NAME },
  { label: 'Show by Task', value: ReviewProofSort.TASK },
];
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

// Call to get  file Icon
const getIcon = (fileType: string) => {
  switch (fileType?.toLowerCase()) {
    case 'audio':
      return <Audio />;
    case 'image':
      return <Image />;
    case 'video':
      return <Video />;
    case 'document':
      return <Doc />;
    default:
      return <Image />;
  }
};
const ReviewProof = () => {
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // Route params object
  const params = useParams();
  // Message toast object creation
  const toastObj = new MessageToaster();
  // Ref for  input element on the page
  const fileUploadRef = useRef<any>();
  // Route navigation object
  const navigate = useNavigate();

  // access redux state variables
  const { listProofsApiData, listProofsApiLoading, listProofsApiSuccess } = useAppSelector((state: RootState) => state.user);
  const {
    approveAssignmentApiLoading,
    approveAssignmentApiSuccess,
    approveAssignmentApiResponseMessage,
    sendAssignmentMessageApiResponseCode,
    sendAssignmentMessageApiResponseMessage,
    sendAssignmentMessageApiSuccess,
    rejectAssignmentApiLoading,
    rejectAssignmentApiResponseMessage,
    rejectAssignmentApiSuccess,
  } = useAppSelector((state: RootState) => state.goal);
  const {
    uploadFileApiSuccess,
    uploadFileResponseCode,
    uploadFileResponseMessage,
    uploadFileApiData,
    uploadFilePathUpdateMultipleApiSuccess,
    uploadFilePathUpdateMultipleResponseCode,
    uploadFilePathUpdateMultipleResponseMessage,
  } = useAppSelector((state: RootState) => state.organization);

  const userData = getFromLocalStorage('MI_USR_DATA');
  let orgId;
  if (userData.RoleTypeId === RoleTypeIds.ORGANIZATION_ADMIN) {
    orgId = getCurrentOrgDetails().OrganizationID;
  } else {
    orgId = userData.OrganizationId;
  }

  const ImageUploadFileError = useIntlActionMessages('Image.Upload.FileError');
  const AddMessageSuccess = useIntlActionMessages('Message.Success');

  // Component state variables
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [apiParams, setApiParams] = useState<IListReviewProofsRequest>({
    OrganizationID: orgId,
    Page: 1,
    PageSize: PAGE_SIZE_REVIEW_PROOF,
    PageSort: ReviewProofSort.STUDENT_NAME,
    Status: params.status !== undefined ? Number(params.status) : 0,
  });
  const [sortOption, setSortOption] = useState<any>(showBy[0]);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [popUpContext, setPopUpContext] = useState<string>('Approve');
  const [proofId, setProofId] = useState<any>('');
  const [isOpenMessage, setIsOpenMessage] = useState<boolean>(false);
  const [messageCharacterCount, setMessageCharacterCount] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [showPopup, setShowPopup] = useState(false);
  const [recordType, setRecordType] = useState<string>('');
  const [fileUploadBegin, setFileUploadBegin] = useState<boolean>(false);
  const [fileUploadError, setFileUploadError] = useState<string>('');
  const [fileUploadType, setFileUploadType] = useState<string>('');
  const [upImageAttachment, setUpImageAttachment] = useState<string | null>(null);
  const [upImageAttachmentDetails, setUpImageAttachmentDetails] = useState<any>({});
  const [uploadedFileInfo, setUploadedFileInfo] = useState<any>([]);
  const [uploadAttachment, setUploadAttachment] = useState<boolean>(false);
  const [isOpenRejectComment, setIsOpenRejectComment] = useState<boolean>(false);
  const [activeStudentDetails, setActiveStudentDetails] = useState<any>({});
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);
  const [rejectMsg, setRejectMsg] = useState<string>('');
  const [rejectMsgCount, setRejectMsgCount] = useState<number>(0);

  // call list proofs api on intial laoding ,page change , sort change
  useEffect(() => {
    dispatch(listProofs(apiParams));
  }, [apiParams.Page, apiParams.PageSort]);
  // reset list review proofs on unmount
  useEffect(() => {
    return () => {
      dispatch(resetReviewProofs());
      dispatch(resetApproveAssignment());
      dispatch(resetRejectAssignment());
      dispatch(resetAssignmentMessage());
    };
  }, []);
  // show success and error message on approve proof
  useEffect(() => {
    if (approveAssignmentApiSuccess && approveAssignmentApiResponseMessage.length > 0) {
      toastObj.toastSuccess(approveAssignmentApiResponseMessage);
      const params = apiParams;
      params.Page = 1;
      setCurrentPage(1);
      dispatch(listProofs(params));
    } else if (!approveAssignmentApiLoading && !approveAssignmentApiSuccess && approveAssignmentApiResponseMessage.length > 0) {
      toastObj.toastError(approveAssignmentApiResponseMessage);
    }
  }, [approveAssignmentApiLoading]);
  // show success and error message on reject proof
  useEffect(() => {
    if (rejectAssignmentApiSuccess && rejectAssignmentApiResponseMessage.length > 0) {
      toastObj.toastSuccess(rejectAssignmentApiResponseMessage);
      const params = apiParams;
      params.Page = 1;
      setCurrentPage(1);
      dispatch(listProofs(params));
    } else if (!rejectAssignmentApiLoading && !rejectAssignmentApiSuccess && rejectAssignmentApiResponseMessage.length > 0) {
      toastObj.toastError(rejectAssignmentApiResponseMessage);
      setRejectMsgCount(0);
      setRejectMsg('');
    }
  }, [rejectAssignmentApiLoading]);

  // Show messages after upload file message api
  useEffect(() => {
    if (uploadFilePathUpdateMultipleApiSuccess && uploadFilePathUpdateMultipleResponseCode > 0 && uploadFilePathUpdateMultipleApiSuccess) {
      setUploadAttachment(false);
      dispatch(resetUploadFilePathUpdateMultiple());
      setFileUploadBegin(false);
      toastObj.toastSuccess(AddMessageSuccess);
      clearMessage();
    }
  }, [uploadFilePathUpdateMultipleResponseCode, uploadFilePathUpdateMultipleResponseMessage]);

  // Show messages upon save message api
  useEffect(() => {
    if (message && !uploadAttachment && sendAssignmentMessageApiResponseCode > 0 && sendAssignmentMessageApiSuccess) {
      toastObj.toastSuccess(sendAssignmentMessageApiResponseMessage);
      clearMessage();
      setIsOpenMessage(false);
    }
  }, [sendAssignmentMessageApiResponseCode, sendAssignmentMessageApiResponseMessage]);

  // Handle the upload path api response
  useEffect(() => {
    const submitUploadsAndSubsequentActions = async () => {
      if (fileUploadBegin && uploadFileResponseCode > 0 && uploadFileApiSuccess && upImageAttachmentDetails.size) {
        uploadAttachmentFileToBucket();
        dispatch(resetUploadFile());
        if (fileUploadRef.current) {
          fileUploadRef.current.value = '';
        }
        // setFileUploadBegin(false);
      }
    };
    submitUploadsAndSubsequentActions();
  }, [uploadFileResponseCode, uploadFileResponseMessage]);

  // Set active student data
  const handleFilterOpen = (proof: any) => {
    setMessage('');
    setIsOpenMessage(true);
    const studentInfo = {
      // "TaskID": ,
      UserID: apiParams.PageSort === ReviewProofSort.STUDENT_NAME ? proof.UserID : proof.Proofs[0].userid,
      UserName: proof.Name,
      // "Status": "Completed",
      // "ReviewStatus": "Approved",
      Attachements: proof.Proofs,
      assignmentdetailid: apiParams.PageSort === ReviewProofSort.STUDENT_NAME ? proof.Proofs[0].assignmentdetailid : proof.Proofs[0].assignmentdetailid,
    };
    setActiveStudentDetails(studentInfo);
  };

  // Put file to bucket after getting the path
  const uploadAttachmentFileToBucket = async () => {
    const uploadURL = uploadFileApiData;
    if (uploadURL) {
      // PUT request: upload file to S3
      const result = await fetch(uploadURL, {
        method: 'PUT',
        body: upImageAttachment,
      });
      if (result.status == 200) {
        const fileSize = bytesToSize(upImageAttachmentDetails.size);
        const fileInfo = { ...upImageAttachmentDetails, bucketURL: uploadURL, size: fileSize };
        setUpImageAttachmentDetails(fileInfo);
        setUploadedFileInfo([...uploadedFileInfo, fileInfo]);
        setFileUploadBegin(false);
      } else {
        setFileUploadError('Failed to Upload.');
        setFileUploadBegin(false);
        if (fileUploadRef.current) {
          fileUploadRef.current.value = '';
        }
      }
    }
  };
  // handle pagination change
  const changePage = (pageNo: number) => {
    setApiParams((prev: any) => ({
      ...prev,
      Page: pageNo,
    }));
    setCurrentPage(pageNo);
  };
  // create review proof status string
  const getProofStatusString = () => {
    const status = params.status || '0';
    let statusString;
    if (Number(status) === ReviewProofStatus.COMPLETED) {
      statusString = 'Completion';
    } else {
      statusString = 'In Progress';
    }
    return statusString;
  };
  // call approve reject api for tasks
  const confirmApproveReject = () => {
    if (popUpContext === 'Approve') {
      setShowPopUp(false);
      dispatch(
        approveAssignment({
          AssignmentDetailId: proofId.toString(),
        }),
      );
    } else {
      setRejectOpen(false);
      dispatch(
        rejectAssignment({
          AssignmentDetailId: proofId.toString(),
          Reason: rejectMsg,
        }),
      );
    }
  };
  // handle Message Change
  const onMessageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageCharacterCount(event.target.value.length);
    // Check if message is empty
    if (!event.target.value.trim()) {
      setMessageError(useIntlActionMessages('Error.MessageRequired'));
    } else {
      setMessage(event.target.value);
    }
  };
  const onRejectMessageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectMsgCount(event.target.value.length);
    // Check if message is empty
    if (!event.target.value.trim()) {
      setMessageError(useIntlActionMessages('Error.MessageRequired'));
    } else {
      setRejectMsg(event.target.value);
    }
  };
  // handle send message submit
  const handleSendMessage = async () => {
    if (message) {
      const requestParams: IMessageAssignmentApi = {
        AssignmentDetailId: activeStudentDetails.assignmentdetailid,
        Message: message,
      };
      dispatch(sendAssignmentMessage(requestParams));
      setIsOpenRejectComment(false);
    }

    if (uploadedFileInfo.length > 0) {
      const uplodedFileInfo = uploadedFileInfo.map((file: any) => {
        return {
          FileName: file.name,
          DisplayName: file.displayName,
          FileSize: file.size,
          FileType: file.typeNumber.toString(),
        };
      });
      setUploadAttachment(true);
      // upload attachments
      const reqParams: IuploadFilePathUpdateMultipleParams = {
        Files: uplodedFileInfo,
        Type: 'goal_review_comments',
        TypeId: activeStudentDetails.assignmentdetailid,
      };
      // Api request to update file info after bucket upload and save organization info
      dispatch(uploadFilePathUpdateMultiple(reqParams));
    }
  };
  // handle send message submit
  const handleAssignmentUploadMessage = (fileType: string) => {
    setFileUploadType(fileType);
    const acceptedFileExtensions = FILE_TYPES_SUPPORTED[fileType as keyof typeof FILE_TYPES_SUPPORTED] || [];
    const acceptString = acceptedFileExtensions.map((ext) => `.${ext}`).join(',');
    if (fileUploadRef?.current) {
      fileUploadRef.current.accept = acceptString;
      fileUploadRef.current.click();
      // document.getElementById('file-3')?.click();
      // const fileField = document.getElementById('file-3');
      // if (fileField) {
      //   fileField.click();
      // }
    }
  };
  const uploadResourceFile = async (e: any) => {
    setFileUploadBegin(true);
    setFileUploadError('');
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const lastDotIndex = file?.name?.lastIndexOf('.');
      const fileNameWithoutExtension = file?.name?.slice(0, lastDotIndex);
      const fileExtension = file?.name?.slice(lastDotIndex + 1);
      const modifiedFileName = fileNameWithoutExtension + new Date().getTime() + '.' + fileExtension;
      // replacing special characters with underscores
      const formattedFilename = modifiedFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
      const isValid = checkValidFile(formattedFilename, fileUploadType, file.size);

      if (isValid) {
        setUpImageAttachment(e.target.files[0]);
        const fileType = { value: fileUploadType };
        const fileInfo = {
          name: formattedFilename,
          displayName: file.name,
          type: fileExtension,
          size: file.size,
          typeNumber: getFileTypeNumber(fileType),
          fileType: fileUploadType,
        };
        setUpImageAttachmentDetails(fileInfo);
        const formData = new FormData();
        formData.append('UploadExtension', fileInfo.type);
        formData.append('UploadObjectKey', fileInfo.name);
        formData.append('UploadType', 'goal_review_comments');
        dispatch(uploadFile(formData));
      } else {
        setFileUploadError(ImageUploadFileError);
        setFileUploadBegin(false);
        if (fileUploadRef.current) {
          fileUploadRef.current.value = '';
        }
      }
    } else {
      setFileUploadBegin(false);
    }
  };
  const handleShowPopup = (type: string) => {
    setIsOpenMessage(false);
    setRecordType(type);
    setShowPopup(true);
    setUploadedFileInfo([]);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
    clearMessage();
    setUploadedFileInfo([]);
  };
  const clearMessage = () => {
    setMessage('');
    setUploadedFileInfo([]);
    dispatch(resetAssignmentMessage());
  };
  // Remove Attachment from listing
  const deleteAttachment = (deleteIndex: number) => {
    const newUploadedFileInfo = [...uploadedFileInfo];
    setUploadedFileInfo(newUploadedFileInfo.filter((item: any, index: number) => index != deleteIndex));
  };
  // set proof reject data and show popover to enter reject message
  const showReject = (proof: any) => {
    setPopUpContext('Reject');
    setProofId(proof.assignmentdetailid);
    setRejectOpen(true);
  };
  // reset reject data
  const handleRejectClose = () => {
    setRejectOpen(false);
    setRejectMsg('');
    setRejectMsgCount(0);
  };
  return (
    <>
      {listProofsApiLoading && <Loader />}
      <DeleteConfirmation
        show={showPopUp}
        handleClose={() => setShowPopUp(false)}
        title={popUpContext}
        content={popUpContext === 'Approve' ? 'Are you sure you want to approve this proof?' : 'Are you sure you want to reject this proof?'}
        onOkay={() => confirmApproveReject()}
        onCancel={() => setShowPopUp(false)}
      />
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>Review Proof</h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/vieworganization')}>
              <FormattedMessage id="Hd.Dashboard" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate('/overview')}>
              <FormattedMessage id="Hd.Overview" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Review Proof</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* <Col xl={2} lg={4} md={4} className='form-sm'>
          <Select
            label={useIntlMessages("Label.OrganizationType")}
            options={organization}
            value={organizationValue}
            placeholder="Select"
            onChange={() => console.log('todo')}
          // error="Error"
          />
        </Col> */}
      </div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h5 className="mb-0">{getProofStatusString()} Proof</h5>
        <Col xl={2} lg={3} md={4} className="form-sm">
          <Select
            options={showBy}
            placeholder="Select"
            onChange={(e: any) => {
              setSortOption(e);
              setCurrentPage(1);
              setApiParams((prev: IListReviewProofsRequest) => ({
                ...prev,
                PageSort: e.value,
                Page: 1,
              }));
            }}
            value={sortOption}
          />
        </Col>
      </div>
      {listProofsApiSuccess &&
        apiParams.PageSort === ReviewProofSort.STUDENT_NAME &&
        listProofsApiData.ProofList &&
        listProofsApiData.ProofList.map((proof: any) => (
          <div className="content-sub">
            <div className="content-sub-header d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex align-items-center userthumnail">
                  <span className="td-user-image">
                    <img src={BUCKET_URL + proof.LogoImage} className="w-100" alt="user" />
                  </span>
                  <label>
                    {proof.Name}
                    <span className="d-block font-xs">{proof.OrganizationName}</span>
                  </label>
                </div>
              </div>
              <Col sm="auto">
                <Button variant="dark" size="sm" className="icon-btn" onClick={() => navigate(`/userDetailsTab/${proof.UserID}/6`)}>
                  <RightIcon />
                </Button>
              </Col>
            </div>
            <div className="content-area-padding">
              <Row>
                {proof.Proofs.map((attachment: any) => (
                  <Col xl={6}>
                    <div className="content-sub content-sub-secondary">
                      <div className="content-sub-header d-flex justify-content-between align-items-center">
                        <div>
                          <div className="d-flex align-items-center userthumnail">
                            <label>
                              {attachment.proofs[0].mastername}
                              <span className="d-block font-xs">{moment(attachment.proofs[0].createdat).local().format('MMM DD, YYYY')}</span>
                            </label>
                          </div>
                        </div>
                        <Col sm="auto">
                          <Button variant="dark" size="sm" className="icon-btn">
                            <RightIcon />
                          </Button>
                        </Col>
                      </div>
                      <div className="content-area-padding">
                        <h5 className="text-center">Attachments ({attachment.proofs[0].proof_details.length})</h5>
                        <div className="reviewproof-main">
                          <Slider {...settings}>
                            {attachment.proofs[0].proof_details.map((details: any) => {
                              if (details.filetype === 'Audio') {
                                return (
                                  <div>
                                    <ReactAudioPlayer src={BUCKET_URL + details.proofpath} autoPlay={false} controls />
                                    <p>{details.display_name}</p>
                                  </div>
                                );
                              } else if (details.filetype === 'Video') {
                                return (
                                  <div>
                                    <ReactPlayer url={BUCKET_URL + details.proofpath} controls width="100%" />
                                    <p>{details.display_name}</p>
                                  </div>
                                );
                              } else {
                                return (
                                  <div>
                                    <img src={BUCKET_URL + details.proofpath} height="400px" width="400px" />
                                  </div>
                                );
                              }
                            })}
                          </Slider>
                        </div>
                        <Row className="mt-2">
                          <Col>
                            <div className="custom-berger-menu ms-3">
                              <Filter
                                width={450}
                                right
                                pageWrapId={'filter-wrapper'}
                                outerContainerId={'outer-container'}
                                customCrossIcon={<img src={Close} />}
                                customBurgerIcon={
                                  <Col>
                                    <Button variant="outline-primary" className="w-100">
                                      <FormattedMessage id="Button.AddMessage" />
                                    </Button>
                                  </Col>
                                }
                                isOpen={isOpenMessage}
                                onOpen={() => handleFilterOpen(proof)}
                                onClose={() => setIsOpenMessage(false)}
                              >
                                <div id="filter-wrapper" className="filter-main">
                                  <Scrollbars className="h-80vh">
                                    <h4 className="mb-4">
                                      <FormattedMessage id="Button.AddMessage" />
                                    </h4>

                                    <Col className="form-sm  position-relative">
                                      <Input
                                        // label={useIntlActionMessages('Label.Message.Description')}
                                        id="Message"
                                        name="Message"
                                        type="textarea"
                                        placeholder={useIntlActionMessages('Label.Message.Description')}
                                        maxLength={NumberValues.NUM_1000}
                                        as="textarea"
                                        rows={3}
                                        onChange={onMessageChange}
                                        errorMessage={messageError}
                                        value={message}
                                      />
                                      <span className="txtMessage-count">
                                        {messageCharacterCount}/{NumberValues.NUM_1000}
                                      </span>
                                    </Col>
                                    <Row>
                                      <Col>
                                        <Nav className="overlay-dropdown">
                                          <NavDropdown
                                            align={end}
                                            id="nav-dropdown-dark-example"
                                            title={
                                              <Button variant="secondary" className="btn-icon-top w-100">
                                                <span>
                                                  <AddIcon />
                                                </span>
                                                <FormattedMessage id="Button.AddVideo" />
                                              </Button>
                                            }
                                          >
                                            <NavDropdown.Item onClick={() => handleAssignmentUploadMessage('video')}>
                                              <FormattedMessage id="Link.UploadVideo" />
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleShowPopup('video')}>
                                              <FormattedMessage id="Link.RecordVideo" />
                                            </NavDropdown.Item>
                                          </NavDropdown>
                                        </Nav>
                                      </Col>
                                      <Col>
                                        <Nav className="overlay-dropdown">
                                          <NavDropdown
                                            align={end}
                                            id="nav-dropdown-dark-example"
                                            title={
                                              <Button variant="secondary" className="btn-icon-top w-100">
                                                <span>
                                                  <AddIcon />
                                                </span>
                                                <FormattedMessage id="Button.AddAudio" />
                                              </Button>
                                            }
                                          >
                                            <NavDropdown.Item onClick={() => handleAssignmentUploadMessage('audio')}>
                                              <FormattedMessage id="Link.UploadAudio" />
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleShowPopup('audio')}>
                                              <FormattedMessage id="Link.RecordAudio" />
                                            </NavDropdown.Item>
                                          </NavDropdown>
                                        </Nav>
                                      </Col>
{showPopup&&
                                      <PopupRecorder
                                        show={showPopup}
                                        onClose={handleClosePopup}
                                        video={recordType == 'video' ? true : false}
                                        audio={recordType == 'audio' ? true : false}
                                        fileType={recordType}
                                        devicesByType={recordType}
                                      />
}
                                      <input
                                        ref={fileUploadRef}
                                        type="file"
                                        name="file-3[]"
                                        id="file-3"
                                        className="inputfile inputfile-3 d-none"
                                        data-multiple-caption="{count} files selected"
                                        multiple={false}
                                        accept="audio/*,video/*"
                                        onChange={(e: any) => uploadResourceFile(e)}
                                      />
                                      <div className="error text-center pt-2"> {fileUploadError}</div>
                                      {uploadedFileInfo.length}
                                      {uploadedFileInfo.map((filedetails: any, index: number) => (
                                        <Col mt={6} key={index} className="addmessage-main mb-2" sm={12}>
                                          <AttachmentCard
                                            fileType={getIcon(filedetails.fileType)}
                                            filename={filedetails.displayName}
                                            category={filedetails.size}
                                            deleteOption
                                            onDelete={() => deleteAttachment(index)}
                                          />
                                        </Col>
                                      ))}
                                    </Row>
                                  </Scrollbars>
                                  <Row className="mt-4 pb-4">
                                    <Col>
                                      <Button variant="outline-primary" className="w-100" onClick={() => setIsOpenMessage(false)}>
                                        <FormattedMessage id="Button.Cancel" />
                                      </Button>
                                    </Col>
                                    <Col>
                                      <Button variant="primary" className="w-100" onClick={handleSendMessage}>
                                        <FormattedMessage id="Button.SendMessage" />
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Filter>
                            </div>
                          </Col>
                          {params.status === '1' && (
                            <Col>
                              <div className="custom-berger-menu">
                                <Filter
                                  width={450}
                                  right
                                  pageWrapId={'filter-wrapper'}
                                  outerContainerId={'outer-container'}
                                  customCrossIcon={<img src={Close} />}
                                  customBurgerIcon={
                                    <Col>
                                      <Button variant="outline-primary" className="w-100">
                                        Reject
                                      </Button>
                                    </Col>
                                  }
                                  isOpen={rejectOpen}
                                  onOpen={() => showReject(attachment)}
                                  onClose={handleRejectClose}
                                >
                                  <div id="filter-wrapper" className="filter-main">
                                    <Scrollbars className="h-80vh">
                                      <h4 className="mb-4">
                                        <FormattedMessage id="Hd.ReasonforRejection" />
                                      </h4>
                                      <Col className="form-sm  position-relative">
                                        <Input
                                          label="Reason"
                                          id="Message"
                                          name="Message"
                                          type="textarea"
                                          placeholder={useIntlActionMessages('PH.SpecifyReasonRejection')}
                                          maxLength={NumberValues.NUM_1000}
                                          as="textarea"
                                          rows={3}
                                          onChange={onRejectMessageChange}
                                          errorMessage={messageError}
                                          value={rejectMsg}
                                        />
                                        <span className="txtMessage-count">
                                          {rejectMsgCount}/{NumberValues.NUM_1000}
                                        </span>
                                      </Col>
                                    </Scrollbars>
                                    <Row className="mt-4 pb-4">
                                      <Col>
                                        <Button variant="outline-primary" className="w-100" onClick={handleRejectClose}>
                                          <FormattedMessage id="Button.Cancel" />
                                        </Button>
                                      </Col>
                                      <Col>
                                        <Button variant="primary" className="w-100" onClick={confirmApproveReject}>
                                          <FormattedMessage id="Button.Submit" />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Filter>
                              </div>
                            </Col>
                          )}
                          {params.status === '1' && (
                            <Col>
                              <Button
                                variant="primary"
                                className="w-100"
                                onClick={() => {
                                  setPopUpContext('Approve');
                                  setProofId(attachment.assignmentdetailid);
                                  setShowPopUp(true);
                                }}
                              >
                                Approve
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ))}
      {listProofsApiSuccess &&
        apiParams.PageSort === ReviewProofSort.TASK &&
        listProofsApiData.ProofList &&
        listProofsApiData.ProofList.map((proof: any) => (
          <div className="content-sub">
            <div className="content-sub-header d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex align-items-center userthumnail">
                  <label>
                    {proof.Name}
                    <span className="d-block font-xs">{moment(proof.CreatedAt).local().format('MMM DD, YYYY')}</span>
                  </label>
                </div>
              </div>
              <Col sm="auto">
                <Button variant="dark" size="sm" className="icon-btn">
                  <RightIcon />
                </Button>
              </Col>
            </div>
            <div className="content-area-padding">
              <Row>
                {proof.Proofs.map((attachment: any) => (
                  <Col xl={6}>
                    <div className="content-sub content-sub-secondary">
                      <div className="content-sub-header d-flex justify-content-between align-items-center">
                        <div>
                          <div className="d-flex align-items-center userthumnail">
                            <span className="td-user-image">
                              <img src={BUCKET_URL + attachment.proofs[0].logoimage} className="w-100" alt="user" />
                            </span>
                            <label>
                              {attachment.proofs[0].name}
                              <span className="d-block font-xs">{attachment.proofs[0].organizationname}</span>
                            </label>
                          </div>
                        </div>
                        <Col sm="auto">
                          <Button variant="dark" size="sm" className="icon-btn" onClick={() => navigate(`/userDetailsTab/${attachment.proofs[0].userid}/6}`)}>
                            <RightIcon />
                          </Button>
                        </Col>
                      </div>
                      <div className="content-area-padding">
                        <h5 className="text-center">Attachments ({attachment.proofs[0].proof_details.length})</h5>
                        <div className="reviewproof-main">
                          <Slider {...settings}>
                            {attachment.proofs[0].proof_details.map((details: any) => {
                              if (details.filetype === 'Audio') {
                                return (
                                  <div>
                                    <ReactAudioPlayer src={BUCKET_URL + details.proofpath} autoPlay={false} controls />
                                    <p>{details.display_name}</p>
                                  </div>
                                );
                              } else if (details.filetype === 'Video') {
                                return (
                                  <div>
                                    <ReactPlayer url={BUCKET_URL + details.proofpath} controls width="100%" />
                                    <p>{details.display_name}</p>
                                  </div>
                                );
                              } else {
                                return (
                                  <div>
                                    <img src={BUCKET_URL + details.proofpath} height="400px" width="400px" />
                                  </div>
                                );
                              }
                            })}
                          </Slider>
                        </div>
                        <Row className="mt-2">
                          <Col>
                            <div className="custom-berger-menu ms-3">
                              <Filter
                                width={450}
                                right
                                pageWrapId={'filter-wrapper'}
                                outerContainerId={'outer-container'}
                                customCrossIcon={<img src={Close} />}
                                customBurgerIcon={
                                  <Col>
                                    <Button variant="outline-primary" className="w-100">
                                      <FormattedMessage id="Button.AddMessage" />
                                    </Button>
                                  </Col>
                                }
                                isOpen={isOpenMessage}
                                onOpen={() => handleFilterOpen(proof)}
                                onClose={() => setIsOpenMessage(false)}
                              >
                                <div id="filter-wrapper" className="filter-main">
                                  <Scrollbars className="h-80vh">
                                    <h4 className="mb-4">
                                      <FormattedMessage id="Button.AddMessage" />
                                    </h4>
                                    <Col className="form-sm  position-relative">
                                      <Input
                                        // label={useIntlActionMessages('Label.Message.Description')}
                                        id="Message"
                                        name="Message"
                                        type="textarea"
                                        placeholder={useIntlActionMessages('Label.Message.Description')}
                                        maxLength={NumberValues.NUM_1000}
                                        as="textarea"
                                        rows={3}
                                        onChange={onMessageChange}
                                        errorMessage={messageError}
                                        value={message}
                                      />
                                      <span className="txtMessage-count">
                                        {messageCharacterCount}/{NumberValues.NUM_1000}
                                      </span>
                                    </Col>
                                    <Row>
                                      <Col>
                                        <Nav className="overlay-dropdown">
                                          <NavDropdown
                                            align={end}
                                            id="nav-dropdown-dark-example"
                                            title={
                                              <Button variant="secondary" className="btn-icon-top w-100">
                                                <span>
                                                  <AddIcon />
                                                </span>
                                                <FormattedMessage id="Button.AddVideo" />
                                              </Button>
                                            }
                                          >
                                            <NavDropdown.Item onClick={() => handleAssignmentUploadMessage('video')}>
                                              <FormattedMessage id="Link.UploadVideo" />
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleShowPopup('video')}>
                                              <FormattedMessage id="Link.RecordVideo" />
                                            </NavDropdown.Item>
                                          </NavDropdown>
                                        </Nav>
                                      </Col>
                                      <Col>
                                        <Nav className="overlay-dropdown">
                                          <NavDropdown
                                            align={end}
                                            id="nav-dropdown-dark-example"
                                            title={
                                              <Button variant="secondary" className="btn-icon-top w-100">
                                                <span>
                                                  <AddIcon />
                                                </span>
                                                <FormattedMessage id="Button.AddAudio" />
                                              </Button>
                                            }
                                          >
                                            <NavDropdown.Item onClick={() => handleAssignmentUploadMessage('audio')}>
                                              <FormattedMessage id="Link.UploadAudio" />
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleShowPopup('audio')}>
                                              <FormattedMessage id="Link.RecordAudio" />
                                            </NavDropdown.Item>
                                          </NavDropdown>
                                        </Nav>
                                      </Col>

                                      <PopupRecorder
                                        show={showPopup}
                                        onClose={handleClosePopup}
                                        video={recordType == 'video' ? true : false}
                                        audio={recordType == 'audio' ? true : false}
                                        fileType={recordType}
                                        devicesByType={recordType}
                                      />
                                      <input
                                        ref={fileUploadRef}
                                        type="file"
                                        name="file-3[]"
                                        id="file-3"
                                        className="inputfile inputfile-3 d-none"
                                        data-multiple-caption="{count} files selected"
                                        multiple={false}
                                        accept="audio/*,video/*"
                                        onChange={(e: any) => uploadResourceFile(e)}
                                      />
                                      <div className="error text-center pt-2"> {fileUploadError}</div>
                                      {uploadedFileInfo.length}
                                      {uploadedFileInfo.map((filedetails: any, index: number) => (
                                        <Col mt={6} key={index} className="addmessage-main mb-2" sm={12}>
                                          <AttachmentCard
                                            fileType={getIcon(filedetails.fileType)}
                                            filename={filedetails.displayName}
                                            category={filedetails.size}
                                            deleteOption
                                            onDelete={() => deleteAttachment(index)}
                                          />
                                        </Col>
                                      ))}
                                    </Row>
                                  </Scrollbars>
                                  <Row className="mt-4 pb-4">
                                    <Col>
                                      <Button variant="outline-primary" className="w-100" onClick={() => setIsOpenMessage(false)}>
                                        <FormattedMessage id="Button.Cancel" />
                                      </Button>
                                    </Col>
                                    <Col>
                                      <Button variant="primary" className="w-100" onClick={handleSendMessage}>
                                        <FormattedMessage id="Button.SendMessage" />
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Filter>
                            </div>
                          </Col>
                          {params.status === '1' && (
                            <Col>
                              <div className="custom-berger-menu">
                                <Filter
                                  width={450}
                                  right
                                  pageWrapId={'filter-wrapper'}
                                  outerContainerId={'outer-container'}
                                  customCrossIcon={<img src={Close} />}
                                  customBurgerIcon={
                                    <Col>
                                      <Button variant="outline-primary" className="w-100">
                                        Reject
                                      </Button>
                                    </Col>
                                  }
                                  isOpen={rejectOpen}
                                  onOpen={() => showReject(attachment)}
                                  onClose={handleRejectClose}
                                >
                                  <div id="filter-wrapper" className="filter-main">
                                    <Scrollbars className="h-80vh">
                                      <h4 className="mb-4">
                                        <FormattedMessage id="Hd.ReasonforRejection" />
                                      </h4>
                                      <Col className="form-sm  position-relative">
                                        <Input
                                          label="Reason"
                                          id="Message"
                                          name="Message"
                                          type="textarea"
                                          placeholder={useIntlActionMessages('PH.SpecifyReasonRejection')}
                                          maxLength={NumberValues.NUM_1000}
                                          as="textarea"
                                          rows={3}
                                          onChange={onRejectMessageChange}
                                          errorMessage={messageError}
                                          value={rejectMsg}
                                        />
                                        <span className="txtMessage-count">
                                          {rejectMsgCount}/{NumberValues.NUM_1000}
                                        </span>
                                      </Col>
                                    </Scrollbars>
                                    <Row className="mt-4 pb-4">
                                      <Col>
                                        <Button variant="outline-primary" className="w-100" onClick={handleRejectClose}>
                                          <FormattedMessage id="Button.Cancel" />
                                        </Button>
                                      </Col>
                                      <Col>
                                        <Button variant="primary" className="w-100" onClick={confirmApproveReject}>
                                          <FormattedMessage id="Button.Submit" />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Filter>
                              </div>
                            </Col>
                          )}
                          {params.status === '1' && (
                            <Col>
                              <Button
                                variant="primary"
                                className="w-100"
                                onClick={() => {
                                  setPopUpContext('Approve');
                                  setProofId(attachment.assignmentdetailid);
                                  setShowPopUp(true);
                                }}
                              >
                                Approve
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ))}
      {listProofsApiSuccess && listProofsApiData.ProofList && listProofsApiData.ProofList.length > 0 && listProofsApiData.TotalCount > PAGE_SIZE_REVIEW_PROOF && (
        <Pagination
          pageSize={PAGE_SIZE_REVIEW_PROOF}
          totalitems={listProofsApiData.TotalCount}
          pageLimit={PAGE_SIZE_REVIEW_PROOF}
          setCurrentPage={(page: number) => changePage(page)}
          currentPage={currentPage - 1}
          prevPage={-1}
          itemsDisplayed={listProofsApiData.ProofList.length}
        />
      )}
    </>
  );
};
export default ReviewProof;
