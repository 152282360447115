/**
 * @file   src\containers\SetupPassword.tsx
 * @brief  Profile Creation page.
 * @date   Nov, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useState } from 'react';
import '../assets/css/myprof.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, preventWhitespaceInput, getFromLocalStorage, setItemLocalStorage } from '../utils/helper';
import { Col, Button } from 'react-bootstrap';
import icnEye from '../assets/img/icnEye.svg';
import eyeOff from '../assets/img/eye-off.svg';
import Input from '../components/MAInput';
import { useNavigate } from 'react-router-dom';
import { IChangePasswordForm } from '../interfaces/AuthInterface';
import { useAppDispatch, useAppSelector } from '../hooks';
import { changePassword } from '../store/actions/authActions';
import { validateForm } from '../utils/formValidation';
import { CHANGE_PWD_SCHEMA } from '../validations/authSchema';
import { RootState } from '../store';
import { MessageToaster } from '../utils/ToastUtil';
import { resetChangePassword } from '../store/slices/authSlice';
import { RoleTypeIds } from '../utils/enums';
import { Row } from 'react-bootstrap';
import Logo from '../assets/img/Logo.svg';
const changePasswordAPIDefaultParams = {
  ConfirmPassword: '',
  Newpassword: '',
  OldPassword: '',
};
const SetupPassword = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  const { changePasswordApiLoading, changePasswordApiSuccess, changePasswordResponseCode, changePasswordResponseMessage } = useAppSelector(
    (state: RootState) => state.authentication,
  );
  // Initialize component state variables.
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [changePasswordForm, setChangePasswordForm] = useState<IChangePasswordForm>(changePasswordAPIDefaultParams);
  const [errorFields, setErrorFields] = useState<any>({});

  // works on unmounting.
  useEffect(() => {
    return () => {
      dispatch(resetChangePassword());
    };
  }, []);

  // Handle change password Success/Failure.
  useEffect(() => {
    if (changePasswordResponseCode > 0 && changePasswordApiSuccess) {
      const storageData = getFromLocalStorage('MI_USR_DATA');
      const userData = storageData ? storageData : {};
      userData.IsPasswordChanged = true;
      setItemLocalStorage('MI_USR_DATA', userData);
      toast.toastSuccess(changePasswordResponseMessage);
      if (userData.RoleTypeId === RoleTypeIds.SYSTEM_ADMIN && userData.IsProfileCreated == false) navigate('/profilecreate');
      else navigate('/myprofile');
    } else if (changePasswordResponseCode > 0 && !changePasswordApiSuccess) {
      toast.toastError(changePasswordResponseMessage);
    }
  }, [changePasswordApiLoading]);

  // Show/hide password text.
  const togglePassword = (field: string) => {
    if (field == 'OldPassword') setShowOldPassword(!showOldPassword);
    else if (field == 'NewPassword') setShowNewPassword(!showNewPassword);
    else if (field == 'ConfirmPassword') setShowConfirmPassword(!showConfirmPassword);
  };
  // Set form on input change, handle form fields validation.
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setChangePasswordForm((info: any) => ({
      ...info,
      [name]: value,
    }));
    const validateObj =
      name == 'ConfirmPassword'
        ? {
            [name]: value,
            Newpassword: changePasswordForm.Newpassword,
          }
        : {
            [name]: value,
          };
    let errorresult: any = [];
    errorresult = await validateForm(validateObj, CHANGE_PWD_SCHEMA, errorFields);
    setErrorFields({ ...errorresult });
  };
  // Submit handler Change Password.
  const onSubmit = async () => {
    let errorresult: any = null;
    errorresult = await validateForm(changePasswordForm, CHANGE_PWD_SCHEMA, errorFields);
    if (Object.keys(errorresult).length === 0) {
      dispatch(changePassword(changePasswordForm));
    } else {
      setErrorFields({ ...errorresult });
    }
  };
  return (
    <>
      <div className="profileCreation addMotiv-res">
        <Row className="g-0">
          <Col xl={2} lg={3} md={3}>
            <div className="col-left d-flex justify-content-center text-center col-left-h-100vh">
              <div className="prof-details">
                <img src={Logo} alt="" />
              </div>
            </div>
          </Col>
          <Col xl={10} g={9} md={9}>
            <Row className="profile-inn-cont g-0">
              <Col className="d-flex justify-content-between">
                <h3>
                  <FormattedMessage id="Label.ChangePassword" />
                </h3>
              </Col>
              <Row className="mt-5">
                <div className="content-sub">
                  <div className="content-area-padding p-4">
                    <Col xl={5}>
                      <Col className="position-relative">
                        <Input
                          label={useIntlMessages('Label.CurrentPassword')}
                          id="OldPassword"
                          name="OldPassword"
                          type={!showOldPassword ? 'password' : 'text'}
                          placeholder={useIntlMessages('PH.Password')}
                          maxLength={200}
                          errorMessage={errorFields?.OldPassword}
                          onChange={onInputHandleChange}
                          autoFocus={true}
                          autoComplete="new-password"
                        />
                        <Button variant="outline-secondary" className="btn-eye" onClick={() => togglePassword('OldPassword')}>
                          <img src={!showOldPassword ? eyeOff : icnEye} alt="" />
                        </Button>
                      </Col>
                      <Col className="position-relative">
                        <Input
                          label={useIntlMessages('Label.NewPassword')}
                          id="Newpassword"
                          name="Newpassword"
                          type={!showNewPassword ? 'password' : 'text'}
                          placeholder={useIntlMessages('PH.Password')}
                          maxLength={200}
                          errorMessage={errorFields?.Newpassword}
                          onChange={onInputHandleChange}
                          onKeyDown={preventWhitespaceInput}
                          autoComplete="new-password"
                        />
                        <Button variant="outline-secondary" className="btn-eye" onClick={() => togglePassword('NewPassword')}>
                          <img src={!showNewPassword ? eyeOff : icnEye} alt="" />
                        </Button>
                      </Col>
                      <Col className="position-relative">
                        <Input
                          label={useIntlMessages('Label.ConfirmPassword')}
                          id="ConfirmPassword"
                          name="ConfirmPassword"
                          type={!showConfirmPassword ? 'password' : 'text'}
                          placeholder={useIntlMessages('PH.Password')}
                          maxLength={200}
                          errorMessage={errorFields?.ConfirmPassword}
                          onChange={onInputHandleChange}
                          onKeyDown={preventWhitespaceInput}
                          autoComplete="new-password"
                        />
                        <Button variant="outline-secondary" className="btn-eye" onClick={() => togglePassword('ConfirmPassword')}>
                          <img src={!showConfirmPassword ? eyeOff : icnEye} alt="" />
                        </Button>
                      </Col>
                      <div className="btn-container d-flex justify-content-end my-4">
                        <Button variant="primary" onClick={onSubmit}>
                          <FormattedMessage id="Button.ChangePassword" />
                        </Button>
                      </div>
                    </Col>
                  </div>
                </div>
              </Row>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default SetupPassword;
