import { toast } from 'react-toastify';

export class MessageToaster {
  /**
   * Provides methods to toast error and success messages.
   * uses react-toastify package
   * toastProperties : determines where the toast appears on the screen and other properties
   */
  toastProperties: any;
  constructor() {
    this.toastProperties = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    };
  }

  toastError(message: string) {
    toast.error(message, this.toastProperties);
  }

  toastSuccess(message: string) {
    toast.success(message, this.toastProperties);
  }

  toastNotify(html: React.JSX.Element, options: any) {
    toast(html, options);
  }

  toastInfo(message: string) {
    toast.info(message, this.toastProperties);
  }
}
