import React from 'react';

const ResourceIcon = () => {
    return (
        <svg width="22" height="20.895" viewBox="0 0 22 20.895">
            <defs>
                <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#fad900" />
                    <stop offset="1" stop-color="#f5933d" />
                </linearGradient>
            </defs>
            <g id="Icn_Resource" transform="translate(0.005 -0.003)">
                <path id="Path_10323" data-name="Path 10323" d="M.231,5.867l10.607,5.252a.376.376,0,0,0,.314,0l10.6-5.042a.448.448,0,0,0,0-.788L11.152.037a.376.376,0,0,0-.314,0L.231,5.069a.448.448,0,0,0,0,.788Z" fill="url(#linear-gradient)" />
                <path id="Path_10324" data-name="Path 10324" d="M21.76,11.623l-2.968-1.467-7.639,3.63a.376.376,0,0,1-.314,0L3.2,10,.241,11.412a.448.448,0,0,0,0,.788l10.607,5.252a.376.376,0,0,0,.314,0l10.6-5.042a.448.448,0,0,0,0-.788Z" transform="translate(-0.001 -1.274)" fill="url(#linear-gradient)" />
                <path id="Path_10325" data-name="Path 10325" d="M21.76,17.623l-2.968-1.467-7.639,3.63a.376.376,0,0,1-.314,0L3.2,16,.241,17.412a.448.448,0,0,0,0,.788l10.607,5.253a.376.376,0,0,0,.314,0l10.6-5.042A.448.448,0,0,0,21.76,17.623Z" transform="translate(-0.001 -2.589)" fill="url(#linear-gradient)" />
            </g>
        </svg>

    );
}

export default ResourceIcon;
