import React from 'react';
import '../assets/css/AttachmentmentCard.scss';
import { Row, Col, Button } from 'react-bootstrap';
import Delete from '../assets/img/icon/Delete';
import View from '../assets/img/icon/View';
import Default from '../assets/img/resource_grey.png';
import { BUCKET_URL } from '../utils/constants';
import Edit from '../assets/img/icon/Edit';
const CompetenciesCard = (props: any) => {
  return (
    <div className={'attachmentmentcard-main' + (props.selected ? ' selected' : '')} key={`${props.filename}_${props.id}`} onClick={props.handleRowClick}>
      <Row>
        <Col xs="auto" className="d-flex align-items-center attachment-icon">
          {props.fileType}
        </Col>
        {props?.File ? (
          <Col xs="auto" className="d-flex align-items-center attachment-icon">
            <img src={props.File ? BUCKET_URL + props.File : Default} alt="Resource" />
          </Col>
        ) : (
          ' '
        )}
        <Col className="attachment-name d-flex align-items-center" onClick={props.handleClick}>
          <p>
            {props.filename}
            <small>{props.category}</small>
          </p>
        </Col>
        {props?.editOption ? (
          <Col xs="auto" className="attachment-delete pe-0">
            <Button variant="secondary" size="sm" className="icon-btn my-2" onClick={() => props.onEdit()}>
            <Edit />
            </Button>
          </Col>
        ) : (
          ' '
        )}
        {props?.deleteOption ? (
          <Col xs="auto" className="attachment-delete">
            <Button variant="dark" size="sm" className="icon-btn my-2" onClick={() => props.onDelete()}>
              <Delete />
            </Button>
          </Col>
        ) : (
          ' '
        )}
      </Row>
    </div>
  );
};
export default CompetenciesCard;
