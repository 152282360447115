import '../assets/css/AttachmentmentCard.scss';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { IConfirmPopUpProps } from '../interfaces/GeneralInterface';

const DeleteConfirmation = ({ show, onCancel, onOkay, handleClose, title, content }: IConfirmPopUpProps) => {
  return (
    <Modal show={show} onHide={handleClose} centered size="sm">
      <Modal.Body className="text-center">
        <h6 className="mb-4">{title}</h6>
        <p>{content}</p>
        <div className="pop-btn-container mb-3">
          <Button variant="outline-primary" onClick={onCancel}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={onOkay} className="ms-3">
            <FormattedMessage id="Button.OK" />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DeleteConfirmation;
