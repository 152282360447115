/**
 * @file   src\components\ManageAppSetting.tsx
 * @brief  404 page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../../assets/css/manageSettings.scss';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Accordion, Form } from 'react-bootstrap';
import Select from '../../components/MASelect';
import ListCard from '../../components/OrgListCard';
import { slide as Filter } from 'react-burger-menu';
import EmpAcad from '../../assets/img/emp-acad.png';
import AmeUni from '../../assets/img/ame-uni.png';
import ChildAmer from '../../assets/img/chi-amer-sc.png';
import GlobUni from '../../assets/img/glo-uni.png';
import IntAcada from '../../assets/img/int-acad.png';
import Orgimg from "../../assets/img/Org-image.png";
import Close from "../../assets/img/Close.svg";
import { useIntlMessages,useIntlActionMessages} from '../../utils/helper';

const ManageappAccountabilityPartner = () => {
    const organization = [
        { label: 'Audio', value: '1' },
        { label: 'Video', value: '2' },
        { label: 'Image', value: '3' },
    ];
    const SubHd=useIntlMessages('SubHd.AbilityforStudents')
    const [orgTypeValue, setOrgTypeValue] = useState<any>(organization[0]);
    return (
        <>
            <div className='manageSetting'>
                <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
                    <h3><FormattedMessage id="HD.ManageAppSettings" /></h3>
                    {/* <Col md="auto">
                        <Breadcrumb className="breadcrumb">
                            <Breadcrumb.Item >
                                <FormattedMessage id="Hd.Resources" />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item >
                                <FormattedMessage id="Hd.MotivationalResources" />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <FormattedMessage id="Label.AddMotivationalResources" />
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col> */}
                </div>
            </div>
            <Row>
                <Col md="4">
                    <Select
                        label={useIntlActionMessages('Label.OrgType')}
                        options={organization}
                        value={orgTypeValue}
                        placeholder={useIntlMessages('PH.Select')}
                    />
                </Col>
            </Row>
            <Row className='d-flex mb-4'>
                <Col md="4" className='me-auto'>
                    <Select
                        label={useIntlActionMessages('Label.User')}
                        options={organization}
                        value={orgTypeValue}
                        placeholder={useIntlMessages('PH.Select')}
                    />
                </Col>
                <Col md="auto" className='d-flex align-items-center'>
                    <div className='custom-berger-menu me-3'>
                        <Filter width={450} right pageWrapId={"filter-wrapper"} outerContainerId={"outer-container"} customCrossIcon={<img src={Close} />} customBurgerIcon={<div className="org-selected">
                            <img className="thumbnail-image"
                                src={Orgimg}
                                alt="user pic"
                            />
                            <div className='org-details'>
                                <h6>Modern American School</h6>
                                <small>5744 Forest Ln</small>
                            </div>
                        </div>}>
                            <div id='filter-wrapper' className='filter-main'>
                                <h4 className='mb-5'><FormattedMessage id="Hd.Organization" /></h4>
                                <ListCard
                                    image={Orgimg}
                                    label="Modern American School"
                                    sublabel="5744 Forest Ln"
                                    selected
                                />
                                <ListCard
                                    image={EmpAcad}
                                    label="Empower Academy"
                                    sublabel="5744 Forest Ln"
                                />
                                <ListCard
                                    image={AmeUni}
                                    label="American University"
                                    sublabel="5744 Forest Ln"
                                />
                                <ListCard
                                    image={ChildAmer}
                                    label="Children's American School"
                                    sublabel="5744 Forest Ln"
                                />
                                <ListCard
                                    image={GlobUni}
                                    label="Global University"
                                    sublabel="5744 Forest Ln"
                                />
                                <ListCard
                                    image={IntAcada}
                                    label="International Academy"
                                    sublabel="5744 Forest Ln"
                                />
                            </div>
                        </Filter>
                    </div>
                </Col>
            </Row>
            <Row>
            <h4>{useIntlMessages('Label.OnboardingProcess')} </h4>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className='border-bottom'>
                            <h6><FormattedMessage id="Students" /></h6></Accordion.Header>
                        <Accordion.Body>
                            <Row className='d-flex r-rep-contIssue'>
                                <Col md={5}>
                                    <div>
                                        <Form className='mb-4'>
                                            <h6>{useIntlMessages('Label.Gender')}</h6>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`}  className="d-flex mb-3">
                                                    <Form.Check
                                                        label="Mandatory"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                    <Form.Check
                                                        label="Optional "
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                     <Form.Check
                                                        label="Hidden"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                        <Form className='mb-4'>
                                            <h6>{useIntlMessages('Label.DateofBirth')}</h6>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`}  className="d-flex mb-3">
                                                    <Form.Check
                                                        label="Mandatory"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                    <Form.Check
                                                        label="Optional "
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                     <Form.Check
                                                        label="Hidden"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                        <Form className='mb-4'>
                                            <h6>{useIntlMessages('Label.HighestEducation')}</h6>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`}  className="d-flex mb-3">
                                                    <Form.Check
                                                        label="Mandatory"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                    <Form.Check
                                                        label="Optional "
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                     <Form.Check
                                                        label="Hidden"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                        <Form className='mb-4'>
                                            <h6>{useIntlMessages('Label.address')}</h6>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`}  className="d-flex mb-3">
                                                    <Form.Check
                                                        label="Mandatory"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                    <Form.Check
                                                        label="Optional "
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                     <Form.Check
                                                        label="Hidden"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                    </div>
                                </Col>
                                <Col md={7}>
                                <div>
                                        <Form className='mb-4'>
                                            <h6>{useIntlMessages('Label.City')}</h6>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`}  className="d-flex mb-3">
                                                    <Form.Check
                                                        label="Mandatory"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                    <Form.Check
                                                        label="Optional "
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                     <Form.Check
                                                        label="Hidden"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                        <Form className='mb-4'>
                                            <h6>{useIntlMessages('Label.State')}</h6>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`}  className="d-flex mb-3">
                                                    <Form.Check
                                                        label="Mandatory"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                    <Form.Check
                                                        label="Optional "
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                     <Form.Check
                                                        label="Hidden"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                        <Form className='mb-4'>
                                            <h6>{useIntlMessages('Label.Zip')}</h6>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`}  className="d-flex mb-3">
                                                    <Form.Check
                                                        label="Mandatory"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                    <Form.Check
                                                        label="Optional "
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                     <Form.Check
                                                        label="Hidden"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className='border-bottom'>
                            <h6><FormattedMessage id="Permissions" /></h6></Accordion.Header>
                        <Accordion.Body>
                            <Row className='d-flex r-rep-contIssue'>
                                <Col md={10} className='flex-grow-1 '>
                                    <Form className='permision-from-check mb-4'>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`}  className="d-flex mb-3">
                                                    <Row>
                                                        <Col md={12} className='d-flex'>
                                                        <h6>{SubHd}</h6>
                                                    <Form.Check
                                                        label="Mandatory"
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                    <Form.Check
                                                        label="Optional "
                                                        name="group1"
                                                        type="radio"
                                                        id={`inline-${type}-1`}
                                                    />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                        </Form>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>
        </>
    )
}
export default ManageappAccountabilityPartner;