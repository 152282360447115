import React from 'react';
const DeleteIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19">
            <defs>
                <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#fad900" />
                    <stop offset="1" stop-color="#f5933d" />
                </linearGradient>
            </defs>
            <path id="delete_FILL1_wght400_GRAD0_opsz48" d="M162.525,234a1.538,1.538,0,0,1-1.5-1.5V218.25H160v-1.5h4.7V216h6.6v.75H176v1.5h-1.025V232.5a1.538,1.538,0,0,1-1.5,1.5Zm2.65-3.65h1.5v-9.975h-1.5Zm4.15,0h1.5v-9.975h-1.5Z" transform="translate(-159.5 -215.5)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill="url(#linear-gradient)" />
        </svg>
    );
}

export default DeleteIcon;
