import React from 'react';
const VideoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="play_circle_FILL1_wght400_GRAD0_opsz48" d="M89.09,193.1,97.1,188l-8.01-5.1ZM92,200a11.611,11.611,0,0,1-4.65-.945,12.09,12.09,0,0,1-6.4-6.4,11.987,11.987,0,0,1,0-9.33,11.992,11.992,0,0,1,2.58-3.81,12.3,12.3,0,0,1,3.825-2.565,11.987,11.987,0,0,1,9.33,0,11.981,11.981,0,0,1,6.375,6.375,11.987,11.987,0,0,1,0,9.33,12.3,12.3,0,0,1-2.565,3.825,11.992,11.992,0,0,1-3.81,2.58A11.685,11.685,0,0,1,92,200Z" transform="translate(-80 -176)" fill="#9fe6ea" />
        </svg>
    );
}

export default VideoIcon;
