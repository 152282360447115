/**
 * @file   src\store\slices\authSlice.ts
 * @brief  This file is responsible for creating authentication based slices to call actions and state management.
 * @date   JAN, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { loginUser, changePassword, forgotPassword, resetPassword } from '../actions/authActions';
import { IAuthSlice } from '../../interfaces/AuthInterface';

// Initial state
const authDefaultState: IAuthSlice = {
  loginApiData: {},
  responseCode: 0,
  loginApiLoading: false,
  loginApiSuccess: false,
  responseMessage: '',
  changePasswordApiData: {},
  changePasswordResponseCode: 0,
  changePasswordApiLoading: false,
  changePasswordApiSuccess: false,
  changePasswordResponseMessage: '',
  forgotPasswordApiData: {},
  forgotPasswordResponseCode: 0,
  forgotPasswordApiLoading: false,
  forgotPasswordApiSuccess: false,
  forgotPasswordResponseMessage: '',
  resetPasswordApiLoading: false,
  resetPasswordApiSuccess: false,
  resetPasswordResponseCode: 0,
  resetPasswordResponseMessage: '',
  resetPasswordApiData: {},
};

// Authentication slice
const authSlice = createSlice({
  name: 'authentication',
  initialState: authDefaultState,
  reducers: {
    resetLogin: (state) => {
      state.loginApiData = {};
      state.responseCode = 0;
      state.loginApiLoading = false;
      state.loginApiSuccess = false;
      state.responseMessage = '';
    },
    resetChangePassword: (state) => {
      state.changePasswordApiLoading = false;
      state.changePasswordApiSuccess = false;
      state.changePasswordResponseCode = 0;
      state.changePasswordResponseMessage = '';
      state.changePasswordApiData = {};
    },
    resetForgotPassword: (state) => {
      state.forgotPasswordApiLoading = false;
      state.forgotPasswordApiSuccess = false;
      state.forgotPasswordResponseCode = 0;
      state.forgotPasswordResponseMessage = '';
      state.forgotPasswordApiData = {};
    },
    resetResetPassword: (state) => {
      state.resetPasswordApiLoading = false;
      state.resetPasswordApiSuccess = false;
      state.resetPasswordResponseCode = 0;
      state.resetPasswordResponseMessage = '';
      state.resetPasswordApiData = {};
    },
  },
  extraReducers(builder) {
    // login api starts
    builder.addCase(loginUser.pending, (state) => {
      state.loginApiLoading = true;
      state.loginApiSuccess = false;
      state.responseCode = 0;
    });
    // login api success
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loginApiLoading = false;
      state.loginApiSuccess = true;
      state.responseCode = action.payload.ResponseCode;
      state.loginApiData = action.payload.ResponseData;
    });
    // login api failure
    builder.addCase(loginUser.rejected, (state, action: any) => {
      state.loginApiLoading = false;
      state.loginApiSuccess = false;
      state.responseCode = action.payload.ResponseCode;
      state.responseMessage = action.payload.ResponseMessage;
    });
    // change password api starts
    builder.addCase(changePassword.pending, (state) => {
      state.changePasswordApiLoading = true;
      state.changePasswordApiSuccess = false;
      state.changePasswordResponseCode = 0;
    });
    // change password api success
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.changePasswordApiLoading = false;
      state.changePasswordApiSuccess = true;
      state.changePasswordResponseCode = action.payload.ResponseCode;
      state.changePasswordResponseMessage = action.payload.ResponseData;
    });
    // change password api failure
    builder.addCase(changePassword.rejected, (state, action: any) => {
      state.changePasswordApiLoading = false;
      state.changePasswordApiSuccess = false;
      state.changePasswordResponseCode = action.payload.ResponseCode;
      state.changePasswordResponseMessage = action.payload.ResponseMessage;
    });
    // forgot password api starts
    builder.addCase(forgotPassword.pending, (state) => {
      state.forgotPasswordApiLoading = true;
      state.forgotPasswordApiSuccess = false;
      state.forgotPasswordResponseCode = 0;
    });
    // forgot password api success
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgotPasswordApiLoading = false;
      state.forgotPasswordApiSuccess = true;
      state.forgotPasswordResponseCode = action.payload.ResponseCode;
      state.forgotPasswordResponseMessage = action.payload.ResponseMessage;
    });
    // forgot password api failure
    builder.addCase(forgotPassword.rejected, (state, action: any) => {
      state.forgotPasswordApiLoading = false;
      state.forgotPasswordApiSuccess = false;
      state.forgotPasswordResponseCode = action.payload.ResponseCode;
      state.forgotPasswordResponseMessage = action.payload.ResponseMessage;
    });
    // reset password api starts
    builder.addCase(resetPassword.pending, (state) => {
      state.resetPasswordApiLoading = true;
      state.resetPasswordApiSuccess = false;
      state.resetPasswordResponseCode = 0;
    });
    // reset password api success
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resetPasswordApiLoading = false;
      state.resetPasswordApiSuccess = true;
      state.resetPasswordResponseCode = action.payload.ResponseCode;
      state.resetPasswordResponseMessage = action.payload.ResponseMessage;
    });
    // reset password api failure
    builder.addCase(resetPassword.rejected, (state, action: any) => {
      state.resetPasswordApiLoading = false;
      state.resetPasswordApiLoading = false;
      state.resetPasswordResponseCode = action.payload.ResponseCode;
      state.resetPasswordResponseMessage = action.payload.ResponseMessage;
    });
  },
});

// Export actions
export const { resetLogin, resetChangePassword, resetForgotPassword, resetResetPassword } = authSlice.actions;
// Export reducer.
export default authSlice.reducer;
