import React from 'react';
import '../assets/css/StudentCard.scss';
import { Row, Col } from 'react-bootstrap';

const SurveyStudentCard = (props: any) => {
  return (
    <div className={'studentcard-main' + (props.selected ? ' selected' : '')}>
      <Row>
        <Col xs="auto" className="d-flex align-items-center student-img">
          <img src={props.image} alt="Student" />
        </Col>
        <Col className="student-name d-flex align-items-center">
          <p>
            {props.name} {props?.attachment ? <small>{props.attachment} Attachment</small> : ' '}{' '}
          </p>
        </Col>
      </Row>
    </div>
  );
};
export default SurveyStudentCard;
