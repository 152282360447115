/**
 * @file   src\store\slices\surveySlice.ts
 * @brief  This file is responsible for creating survey based slices to call actions and state management.
 * @date   JULY, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { ISurveySlice } from '../../interfaces/SurveyInterface';
import {
  createSurvey,
  getSurveyList,
  getAnswerTypeList,
  addSurveyForm,
  getSurveyFormList,
  deleteSurveyForm,
  finishSurvey,
  getSurveyDetails,
  getSurveyFormDetails,
  updateSurveyForm,
  updateSurvey,
  uploadSurveyForm,
  getUsersBySurveyId,
  deleteSurveyFormAttachment,
  getAssignmentStudentsList,
  getAssignmentStatusCount,
  getStudentSurveyResponse,
  deleteSurvey,
  listusersassociatedtosurvey,
} from '../actions/surveyAction';

// Initial state
const surveyDefaultState: ISurveySlice = {
  createSurveyApiLoading: false,
  createSurveyApiSuccess: false,
  createSurveyApiData: {},
  createSurveyApiResponseCode: 0,
  createSurveyApiResponseMessage: '',
  listSurveyApiLoading: false,
  listSurveyApiSuccess: false,
  listSurveyApiData: {},
  listSurveyApiResponseCode: 0,
  listSurveyApiResponseMessage: '',
  answerTypeApiLoading: false,
  answerTypeApiSuccess: false,
  answerTypeApiData: {},
  answerTypeApiResponseCode: 0,
  answerTypeApiResponseMessage: '',
  addSurveyFormApiLoading: false,
  addSurveyFormApiSuccess: false,
  addSurveyFormApiData: {},
  addSurveyFormApiResponseCode: 0,
  addSurveyFormApiResponseMessage: '',
  updateSurveyFormApiLoading: false,
  updateSurveyFormApiSuccess: false,
  updateSurveyFormApiData: {},
  updateSurveyFormApiResponseCode: 0,
  updateSurveyFormApiResponseMessage: '',
  surveyFormListApiLoading: false,
  surveyFormListApiSuccess: false,
  surveyFormListApiData: {},
  surveyFormListApiResponseCode: 0,
  surveyFormListApiResponseMessage: '',
  deleteSurveyFormApiLoading: false,
  deleteSurveyFormApiSuccess: false,
  deleteSurveyFormApiData: {},
  deleteSurveyFormApiResponseCode: 0,
  deleteSurveyFormApiResponseMessage: '',
  deleteSurveyFormAttachmentApiLoading: false,
  deleteSurveyFormAttachmentApiSuccess: false,
  deleteSurveyFormAttachmentApiData: {},
  deleteSurveyFormAttachmentApiResponseCode: 0,
  deleteSurveyFormAttachmentApiResponseMessage: '',
  finishSurveyApiLoading: false,
  finishSurveyApiSuccess: false,
  finishSurveyApiData: {},
  finishSurveyApiResponseCode: 0,
  finishSurveyApiResponseMessage: '',
  surveyDetailApiLoading: false,
  surveyDetailApiSuccess: false,
  surveyDetailApiResponseCode: 0,
  surveyDetailApiResponseMessage: '',
  surveyDetailApiData: {},
  surveyFormDetailApiLoading: false,
  surveyFormDetailApiSuccess: false,
  surveyFormDetailApiResponseCode: 0,
  surveyFormDetailApiResponseMessage: '',
  surveyFormDetailApiData: {},
  getUsersBySurveyIdApiLoading: false,
  getUsersBySurveyIdApiSuccess: false,
  getUsersBySurveyIdApiResponseCode: 0,
  getUsersBySurveyIdApiResponseMessage: '',
  getUsersBySurveyIdApiData: {},
  getAssignmentStudentsListApiLoading: false,
  getAssignmentStudentsListApiSuccess: false,
  getAssignmentStudentsListApiResponseCode: 0,
  getAssignmentStudentsListApiResponseMessage: '',
  getAssignmentStudentsListApiData: {},
  getAssignmentStatusCountApiLoading: false,
  getAssignmentStatusCountApiSuccess: false,
  getAssignmentStatusCountApiResponseCode: 0,
  getAssignmentStatusCountApiResponseMessage: '',
  getAssignmentStatusCountApiData: {},
  getStudentSurveyResponseApiLoading: false,
  getStudentSurveyResponseApiSuccess: false,
  getStudentSurveyResponseApiResponseCode: 0,
  getStudentSurveyResponseApiResponseMessage: '',
  getStudentSurveyResponseApiData: {},
  updateSurveyApiLoading: false,
  updateSurveyApiSuccess: false,
  updateSurveyApiResponseCode: 0,
  updateSurveyApiResponseMessage: '',
  updateSurveyApiData: {},
  uploadTemplateApiLoading: false,
  uploadTemplateApiSuccess: false,
  uploadTemplateApiResponseCode: 0,
  uploadTemplateApiResponseMessage: '',
  uploadTemplateApiData: {},
  deleteSurveyApiLoading: false,
  deleteSurveyApiSuccess: false,
  deleteSurveyApiResponseCode: 0,
  deleteSurveyApiResponseMessage: '',
  deleteSurveyApiData: {},
  listUsersAssociatedtoSurveyApiLoading: false,
  listUsersAssociatedtoSurveyApiSuccess: false,
  listUsersAssociatedtoSurveyApiResponseCode: 0,
  listUsersAssociatedtoSurveyApiResponseMessage: '',
  listUsersAssociatedtoSurveyApiData: {},
};
// Survey slice
const surveySlice = createSlice({
  name: 'survey',
  initialState: surveyDefaultState,
  reducers: {
    resetAddSurveyForm: (state) => {
      state.addSurveyFormApiLoading = false;
      state.addSurveyFormApiSuccess = false;
      state.addSurveyFormApiData = {};
      state.addSurveyFormApiResponseCode = 0;
      state.addSurveyFormApiResponseMessage = '';
    },
    resetUpdateSurveyForm: (state) => {
      state.updateSurveyFormApiLoading = false;
      state.updateSurveyFormApiSuccess = false;
      state.updateSurveyFormApiData = {};
      state.updateSurveyFormApiResponseCode = 0;
      state.updateSurveyFormApiResponseMessage = '';
    },
    resetCreateSurvey: (state) => {
      state.createSurveyApiLoading = false;
      state.createSurveyApiSuccess = false;
      state.createSurveyApiResponseCode = 0;
      state.createSurveyApiResponseMessage = '';
    },
    resetCreateSurveyApiData: (state) => {
      state.createSurveyApiData = {};
    },
    resetSurveyFormDetail: (state) => {
      state.surveyFormDetailApiLoading = false;
      state.surveyFormDetailApiSuccess = false;
      state.surveyFormDetailApiResponseCode = 0;
      state.surveyFormDetailApiResponseMessage = '';
      state.surveyFormDetailApiData = {};
    },
    resetDeleteForm: (state) => {
      state.deleteSurveyFormApiLoading = false;
      state.deleteSurveyFormApiSuccess = false;
      state.deleteSurveyFormApiResponseCode = 0;
      state.deleteSurveyFormApiResponseMessage = '';
      state.deleteSurveyFormApiData = {};
    },
    resetDeleteFormAttachment: (state) => {
      state.deleteSurveyFormAttachmentApiLoading = false;
      state.deleteSurveyFormAttachmentApiSuccess = false;
      state.deleteSurveyFormAttachmentApiResponseCode = 0;
      state.deleteSurveyFormAttachmentApiResponseMessage = '';
      state.deleteSurveyFormAttachmentApiData = {};
    },
    resetFinishSurvey: (state) => {
      state.finishSurveyApiLoading = false;
      state.finishSurveyApiSuccess = false;
      state.finishSurveyApiResponseCode = 0;
      state.finishSurveyApiResponseMessage = '';
      state.finishSurveyApiData = {};
    },
    resetSurveyDetail: (state) => {
      state.surveyDetailApiLoading = false;
      state.surveyDetailApiSuccess = false;
      state.surveyDetailApiResponseCode = 0;
      state.surveyDetailApiResponseMessage = '';
      state.surveyDetailApiData = {};
    },
    resetGetUsersBySurveyId: (state) => {
      state.getUsersBySurveyIdApiLoading = false;
      state.getUsersBySurveyIdApiSuccess = false;
      state.getUsersBySurveyIdApiResponseCode = 0;
      state.getUsersBySurveyIdApiResponseMessage = '';
      state.getUsersBySurveyIdApiData = {};
    },
    resetAssignmentStudentsList: (state) => {
      state.getAssignmentStudentsListApiData = {};
      state.getAssignmentStudentsListApiResponseCode = 0;
      state.getAssignmentStudentsListApiLoading = false;
      state.getAssignmentStudentsListApiSuccess = false;
      state.getAssignmentStudentsListApiResponseMessage = '';
    },
    resetGetAssignmentStatusCount: (state) => {
      state.getAssignmentStatusCountApiData = {};
      state.getAssignmentStatusCountApiResponseCode = 0;
      state.getAssignmentStatusCountApiLoading = false;
      state.getAssignmentStatusCountApiSuccess = false;
      state.getAssignmentStatusCountApiResponseMessage = '';
    },
    resetGetStudentSurveyResponse: (state) => {
      state.getStudentSurveyResponseApiData = {};
      state.getStudentSurveyResponseApiResponseCode = 0;
      state.getStudentSurveyResponseApiLoading = false;
      state.getStudentSurveyResponseApiSuccess = false;
      state.getStudentSurveyResponseApiResponseMessage = '';
    },
    resetUpdateSurvey: (state) => {
      state.updateSurveyApiLoading = false;
      state.updateSurveyApiSuccess = false;
      state.updateSurveyApiResponseCode = 0;
      state.updateSurveyApiResponseMessage = '';
    },
    resetDeleteSurvey: (state) => {
      state.deleteSurveyApiData = {};
      state.deleteSurveyApiResponseCode = 0;
      state.deleteSurveyApiLoading = false;
      state.deleteSurveyApiSuccess = false;
      state.deleteSurveyApiResponseMessage = '';
    },
    resetListUsersAssociatedtoSurveyApi: (state) => {
      state.listUsersAssociatedtoSurveyApiLoading = false;
      state.listUsersAssociatedtoSurveyApiSuccess = false;
      state.listUsersAssociatedtoSurveyApiResponseCode = 0;
      state.listUsersAssociatedtoSurveyApiData = {};
      state.listUsersAssociatedtoSurveyApiResponseMessage = '';
    },
  },
  extraReducers(builder) {
    // create survey api starts
    builder.addCase(createSurvey.pending, (state) => {
      state.createSurveyApiLoading = true;
      state.createSurveyApiSuccess = false;
      state.createSurveyApiResponseCode = 0;
    });
    // create survey api success
    builder.addCase(createSurvey.fulfilled, (state, action) => {
      state.createSurveyApiLoading = false;
      state.createSurveyApiSuccess = true;
      state.createSurveyApiResponseCode = action.payload.ResponseCode;
      state.createSurveyApiData = action.payload.ResponseData;
    });
    // create survey api failure
    builder.addCase(createSurvey.rejected, (state, action: any) => {
      state.createSurveyApiLoading = false;
      state.createSurveyApiSuccess = false;
      state.createSurveyApiResponseCode = action.payload.ResponseCode;
      state.createSurveyApiResponseMessage = action.payload.ResponseMessage;
    });
    // list survey api starts
    builder.addCase(getSurveyList.pending, (state) => {
      state.listSurveyApiLoading = true;
      state.listSurveyApiSuccess = false;
      state.listSurveyApiResponseCode = 0;
    });
    // list survey api success
    builder.addCase(getSurveyList.fulfilled, (state, action) => {
      state.listSurveyApiLoading = false;
      state.listSurveyApiSuccess = true;
      state.listSurveyApiResponseCode = action.payload.ResponseCode;
      state.listSurveyApiData = action.payload.ResponseData;
    });
    // list survey api failure
    builder.addCase(getSurveyList.rejected, (state, action: any) => {
      state.listSurveyApiLoading = false;
      state.listSurveyApiSuccess = false;
      state.listSurveyApiResponseCode = action.payload.ResponseCode;
      state.listSurveyApiResponseMessage = action.payload.ResponseMessage;
    });
    // list survey form api starts
    builder.addCase(getSurveyFormList.pending, (state) => {
      state.surveyFormListApiLoading = true;
      state.surveyFormListApiSuccess = false;
      state.surveyFormListApiResponseCode = 0;
      state.surveyFormListApiResponseMessage = '';
    });
    // list survey form api success
    builder.addCase(getSurveyFormList.fulfilled, (state, action) => {
      state.surveyFormListApiLoading = false;
      state.surveyFormListApiSuccess = true;
      state.surveyFormListApiResponseCode = action.payload.ResponseCode;
      state.surveyFormListApiData = action.payload.ResponseData;
      state.surveyFormListApiResponseMessage = action.payload.ResponseMessage;
    });
    // list survey form api failure
    builder.addCase(getSurveyFormList.rejected, (state, action: any) => {
      state.surveyFormListApiLoading = false;
      state.surveyFormListApiSuccess = false;
      state.surveyFormListApiResponseCode = action.payload.ResponseCode;
      state.surveyFormListApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete survey form api starts
    builder.addCase(deleteSurveyForm.pending, (state) => {
      state.deleteSurveyFormApiLoading = true;
      state.deleteSurveyFormApiSuccess = false;
      state.deleteSurveyFormApiResponseCode = 0;
    });
    // delete survey form api success
    builder.addCase(deleteSurveyForm.fulfilled, (state, action) => {
      state.deleteSurveyFormApiLoading = false;
      state.deleteSurveyFormApiSuccess = true;
      state.deleteSurveyFormApiResponseCode = action.payload.ResponseCode;
      state.deleteSurveyFormApiData = action.payload.ResponseData;
      state.deleteSurveyFormApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete survey form api failure
    builder.addCase(deleteSurveyForm.rejected, (state, action: any) => {
      state.deleteSurveyFormApiLoading = false;
      state.deleteSurveyFormApiSuccess = false;
      state.deleteSurveyFormApiResponseCode = action.payload.ResponseCode;
      state.deleteSurveyFormApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete survey form attachment api starts
    builder.addCase(deleteSurveyFormAttachment.pending, (state) => {
      state.deleteSurveyFormAttachmentApiLoading = true;
      state.deleteSurveyFormAttachmentApiSuccess = false;
      state.deleteSurveyFormAttachmentApiResponseCode = 0;
    });
    // delete survey form api attachment success
    builder.addCase(deleteSurveyFormAttachment.fulfilled, (state, action) => {
      state.deleteSurveyFormAttachmentApiLoading = false;
      state.deleteSurveyFormAttachmentApiSuccess = true;
      state.deleteSurveyFormAttachmentApiResponseCode = action.payload.ResponseCode;
      state.deleteSurveyFormAttachmentApiData = action.payload.ResponseData;
      state.deleteSurveyFormAttachmentApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete survey form api attachment failure
    builder.addCase(deleteSurveyFormAttachment.rejected, (state, action: any) => {
      state.deleteSurveyFormAttachmentApiLoading = false;
      state.deleteSurveyFormAttachmentApiSuccess = false;
      state.deleteSurveyFormAttachmentApiResponseCode = action.payload.ResponseCode;
      state.deleteSurveyFormAttachmentApiResponseMessage = action.payload.ResponseMessage;
    });
    // finish survey api starts
    builder.addCase(finishSurvey.pending, (state) => {
      state.finishSurveyApiLoading = true;
      state.finishSurveyApiSuccess = false;
      state.finishSurveyApiResponseCode = 0;
      state.finishSurveyApiResponseMessage = '';
    });
    // finish survey api success
    builder.addCase(finishSurvey.fulfilled, (state, action) => {
      state.finishSurveyApiLoading = false;
      state.finishSurveyApiSuccess = true;
      state.finishSurveyApiResponseCode = action.payload.ResponseCode;
      state.finishSurveyApiData = action.payload.ResponseData;
      state.finishSurveyApiResponseMessage = action.payload.ResponseMessage;
    });
    // finish survey form api failure
    builder.addCase(finishSurvey.rejected, (state, action: any) => {
      state.finishSurveyApiLoading = false;
      state.finishSurveyApiSuccess = false;
      state.finishSurveyApiResponseCode = action.payload.ResponseCode;
      state.finishSurveyApiResponseMessage = action.payload.ResponseMessage;
    });
    // list answer type api starts
    builder.addCase(getAnswerTypeList.pending, (state) => {
      state.answerTypeApiLoading = true;
      state.answerTypeApiSuccess = false;
      state.answerTypeApiResponseCode = 0;
      state.answerTypeApiResponseMessage = '';
    });
    // list answer type api success
    builder.addCase(getAnswerTypeList.fulfilled, (state, action) => {
      state.answerTypeApiLoading = false;
      state.answerTypeApiSuccess = true;
      state.answerTypeApiResponseCode = action.payload.ResponseCode;
      state.answerTypeApiData = action.payload.ResponseData;
      state.answerTypeApiResponseMessage = action.payload.ResponseMessage;
    });
    // list answer type api failure
    builder.addCase(getAnswerTypeList.rejected, (state, action: any) => {
      state.answerTypeApiLoading = false;
      state.answerTypeApiSuccess = false;
      state.answerTypeApiResponseCode = action.payload.ResponseCode;
      state.answerTypeApiResponseMessage = action.payload.ResponseMessage;
    });
    // Add survey form api starts
    builder.addCase(addSurveyForm.pending, (state) => {
      state.addSurveyFormApiLoading = true;
      state.addSurveyFormApiSuccess = false;
      state.addSurveyFormApiResponseCode = 0;
    });
    // Add survey form  api success
    builder.addCase(addSurveyForm.fulfilled, (state, action) => {
      state.addSurveyFormApiLoading = false;
      state.addSurveyFormApiSuccess = true;
      state.addSurveyFormApiResponseCode = action.payload.ResponseCode;
      state.addSurveyFormApiData = action.payload.ResponseData;
      state.addSurveyFormApiResponseMessage = action.payload.ResponseMessage;
    });
    // Add survey form  api failure
    builder.addCase(addSurveyForm.rejected, (state, action: any) => {
      state.addSurveyFormApiLoading = false;
      state.addSurveyFormApiSuccess = false;
      state.addSurveyFormApiResponseCode = action.payload.ResponseCode;
      state.addSurveyFormApiResponseMessage = action.payload.ResponseMessage;
    });
    // Update survey form api starts
    builder.addCase(updateSurveyForm.pending, (state) => {
      state.updateSurveyFormApiLoading = true;
      state.updateSurveyFormApiSuccess = false;
      state.updateSurveyFormApiResponseCode = 0;
    });
    // Update survey form api success
    builder.addCase(updateSurveyForm.fulfilled, (state, action) => {
      state.updateSurveyFormApiLoading = false;
      state.updateSurveyFormApiSuccess = true;
      state.updateSurveyFormApiResponseCode = action.payload.ResponseCode;
      state.updateSurveyFormApiData = action.payload.ResponseData;
      state.updateSurveyFormApiResponseMessage = action.payload.ResponseMessage;
    });
    // Update survey form type api failure
    builder.addCase(updateSurveyForm.rejected, (state, action: any) => {
      state.updateSurveyFormApiLoading = false;
      state.updateSurveyFormApiSuccess = false;
      state.updateSurveyFormApiResponseCode = action.payload.ResponseCode;
      state.updateSurveyFormApiResponseMessage = action.payload.ResponseMessage;
    });
    // get survey Detail api starts
    builder.addCase(getSurveyDetails.pending, (state) => {
      state.surveyDetailApiLoading = true;
      state.surveyDetailApiSuccess = false;
      state.surveyDetailApiResponseCode = 0;
      state.surveyDetailApiResponseMessage = '';
    });
    // get survey Detail api success
    builder.addCase(getSurveyDetails.fulfilled, (state, action) => {
      state.surveyDetailApiLoading = false;
      state.surveyDetailApiSuccess = true;
      state.surveyDetailApiResponseCode = action.payload.ResponseCode;
      state.surveyDetailApiData = action.payload.ResponseData;
      state.surveyDetailApiResponseMessage = action.payload.ResponseMessage;
    });
    // get survey Detail api failure
    builder.addCase(getSurveyDetails.rejected, (state, action: any) => {
      state.surveyDetailApiLoading = false;
      state.surveyDetailApiSuccess = false;
      state.surveyDetailApiResponseCode = action.payload.ResponseCode;
      state.surveyDetailApiResponseMessage = action.payload.ResponseMessage;
    });
    // get survey Detail api starts
    builder.addCase(getSurveyFormDetails.pending, (state) => {
      state.surveyFormDetailApiLoading = true;
      state.surveyFormDetailApiSuccess = false;
      state.surveyFormDetailApiResponseCode = 0;
      state.surveyFormDetailApiResponseMessage = '';
    });
    // get survey Detail api success
    builder.addCase(getSurveyFormDetails.fulfilled, (state, action) => {
      state.surveyFormDetailApiLoading = false;
      state.surveyFormDetailApiSuccess = true;
      state.surveyFormDetailApiResponseCode = action.payload.ResponseCode;
      state.surveyFormDetailApiData = action.payload.ResponseData;
      state.surveyFormDetailApiResponseMessage = action.payload.ResponseMessage;
    });
    // get survey Detail api failure
    builder.addCase(getSurveyFormDetails.rejected, (state, action: any) => {
      state.surveyFormDetailApiLoading = false;
      state.surveyFormDetailApiSuccess = false;
      state.surveyFormDetailApiResponseCode = action.payload.ResponseCode;
      state.surveyFormDetailApiResponseMessage = action.payload.ResponseMessage;
    });
    builder.addCase(getUsersBySurveyId.pending, (state) => {
      state.getUsersBySurveyIdApiLoading = true;
      state.getUsersBySurveyIdApiSuccess = false;
      state.getUsersBySurveyIdApiResponseCode = 0;
      state.getUsersBySurveyIdApiResponseMessage = '';
    });
    // get survey Detail api success
    builder.addCase(getUsersBySurveyId.fulfilled, (state, action) => {
      state.getUsersBySurveyIdApiLoading = false;
      state.getUsersBySurveyIdApiSuccess = true;
      state.getUsersBySurveyIdApiResponseCode = action.payload.ResponseCode;
      state.getUsersBySurveyIdApiData = action.payload.ResponseData;
      state.getUsersBySurveyIdApiResponseMessage = action.payload.ResponseMessage;
    });
    // get survey Detail api failure
    builder.addCase(getUsersBySurveyId.rejected, (state, action: any) => {
      state.getUsersBySurveyIdApiLoading = false;
      state.getUsersBySurveyIdApiSuccess = false;
      state.getUsersBySurveyIdApiResponseCode = action.payload.ResponseCode;
      state.getUsersBySurveyIdApiResponseMessage = action.payload.ResponseMessage;
    });
    // get survey student list api starts
    builder.addCase(getAssignmentStudentsList.pending, (state) => {
      state.getAssignmentStudentsListApiLoading = true;
      state.getAssignmentStudentsListApiSuccess = false;
      state.getAssignmentStudentsListApiResponseCode = 0;
      state.getAssignmentStudentsListApiResponseMessage = '';
    });
    // get survey student list api success
    builder.addCase(getAssignmentStudentsList.fulfilled, (state, action) => {
      state.getAssignmentStudentsListApiLoading = false;
      state.getAssignmentStudentsListApiSuccess = true;
      state.getAssignmentStudentsListApiResponseCode = action.payload.ResponseCode;
      state.getAssignmentStudentsListApiData = action.payload.ResponseData;
      state.getAssignmentStudentsListApiResponseMessage = action.payload.ResponseMessage;
    });
    // get survey student list api failure
    builder.addCase(getAssignmentStudentsList.rejected, (state, action: any) => {
      state.getAssignmentStudentsListApiLoading = false;
      state.getAssignmentStudentsListApiSuccess = false;
      state.getAssignmentStudentsListApiResponseCode = action.payload.ResponseCode;
      state.getAssignmentStudentsListApiResponseMessage = action.payload.ResponseMessage;
    });
    // get survey student list api starts
    builder.addCase(getAssignmentStatusCount.pending, (state) => {
      state.getAssignmentStatusCountApiLoading = true;
      state.getAssignmentStatusCountApiSuccess = false;
      state.getAssignmentStatusCountApiResponseCode = 0;
      state.getAssignmentStatusCountApiResponseMessage = '';
    });
    // get survey student list api success
    builder.addCase(getAssignmentStatusCount.fulfilled, (state, action) => {
      state.getAssignmentStatusCountApiLoading = false;
      state.getAssignmentStatusCountApiSuccess = true;
      state.getAssignmentStatusCountApiResponseCode = action.payload.ResponseCode;
      state.getAssignmentStatusCountApiData = action.payload.ResponseData;
      state.getAssignmentStatusCountApiResponseMessage = action.payload.ResponseMessage;
    });
    // get survey student list api failure
    builder.addCase(getAssignmentStatusCount.rejected, (state, action: any) => {
      state.getAssignmentStatusCountApiLoading = false;
      state.getAssignmentStatusCountApiSuccess = false;
      state.getAssignmentStatusCountApiResponseCode = action.payload.ResponseCode;
      state.getAssignmentStatusCountApiResponseMessage = action.payload.ResponseMessage;
    });
    // get student survey response api starts
    builder.addCase(getStudentSurveyResponse.pending, (state) => {
      state.getStudentSurveyResponseApiLoading = true;
      state.getStudentSurveyResponseApiSuccess = false;
      state.getStudentSurveyResponseApiResponseCode = 0;
      state.getStudentSurveyResponseApiResponseMessage = '';
    });
    // get student survey response api success
    builder.addCase(getStudentSurveyResponse.fulfilled, (state, action) => {
      state.getStudentSurveyResponseApiLoading = false;
      state.getStudentSurveyResponseApiSuccess = true;
      state.getStudentSurveyResponseApiResponseCode = action.payload.ResponseCode;
      state.getStudentSurveyResponseApiData = action.payload.ResponseData;
      state.getStudentSurveyResponseApiResponseMessage = action.payload.ResponseMessage;
    });
    // get student survey response api failure
    builder.addCase(getStudentSurveyResponse.rejected, (state, action: any) => {
      state.getStudentSurveyResponseApiLoading = false;
      state.getStudentSurveyResponseApiSuccess = false;
      state.getStudentSurveyResponseApiResponseCode = action.payload.ResponseCode;
      state.getStudentSurveyResponseApiResponseMessage = action.payload.ResponseMessage;
    });
    // update survey api starts
    builder.addCase(updateSurvey.pending, (state) => {
      state.updateSurveyApiLoading = true;
      state.updateSurveyApiSuccess = false;
      state.updateSurveyApiResponseCode = 0;
    });
    // update survey api success
    builder.addCase(updateSurvey.fulfilled, (state, action) => {
      state.updateSurveyApiLoading = false;
      state.updateSurveyApiSuccess = true;
      state.updateSurveyApiResponseCode = action.payload.ResponseCode;
      state.updateSurveyApiData = action.payload.ResponseData;
    });
    // update survey api failure
    builder.addCase(updateSurvey.rejected, (state, action: any) => {
      state.updateSurveyApiLoading = false;
      state.updateSurveyApiSuccess = false;
      state.updateSurveyApiResponseCode = action.payload.ResponseCode;
      state.updateSurveyApiResponseMessage = action.payload.ResponseMessage;
    });
    // upload template api starts
    builder.addCase(uploadSurveyForm.pending, (state) => {
      state.uploadTemplateApiLoading = true;
      state.uploadTemplateApiSuccess = false;
      state.uploadTemplateApiResponseCode = 0;
    });
    // upload template api success
    builder.addCase(uploadSurveyForm.fulfilled, (state, action) => {
      state.uploadTemplateApiLoading = false;
      state.uploadTemplateApiSuccess = true;
      state.uploadTemplateApiResponseCode = action.payload.ResponseCode;
      state.uploadTemplateApiData = action.payload.ResponseData;
    });
    // upload template api failure
    builder.addCase(uploadSurveyForm.rejected, (state, action: any) => {
      state.uploadTemplateApiLoading = false;
      state.uploadTemplateApiSuccess = false;
      state.uploadTemplateApiResponseCode = action.payload.ResponseCode;
      state.uploadTemplateApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete survey api starts
    builder.addCase(deleteSurvey.pending, (state) => {
      state.deleteSurveyApiLoading = true;
      state.deleteSurveyApiSuccess = false;
      state.deleteSurveyApiResponseCode = 0;
      state.deleteSurveyApiResponseMessage = '';
    });
    // delete survey api success
    builder.addCase(deleteSurvey.fulfilled, (state, action) => {
      state.deleteSurveyApiLoading = false;
      state.deleteSurveyApiSuccess = true;
      state.deleteSurveyApiResponseCode = action.payload.ResponseCode;
      state.deleteSurveyApiData = action.payload.ResponseData;
      state.deleteSurveyApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete survey api failure
    builder.addCase(deleteSurvey.rejected, (state, action: any) => {
      state.deleteSurveyApiLoading = false;
      state.deleteSurveyApiSuccess = false;
      state.deleteSurveyApiResponseCode = action.payload.ResponseCode;
      state.deleteSurveyApiResponseMessage = action.payload.ResponseMessage;
    }); // list users associated to survey api starts
    builder.addCase(listusersassociatedtosurvey.pending, (state) => {
      state.listUsersAssociatedtoSurveyApiLoading = true;
      state.listUsersAssociatedtoSurveyApiSuccess = false;
      state.listUsersAssociatedtoSurveyApiResponseCode = 0;
      state.listUsersAssociatedtoSurveyApiResponseMessage = '';
    });
    // list users associated to survey api success
    builder.addCase(listusersassociatedtosurvey.fulfilled, (state, action) => {
      state.listUsersAssociatedtoSurveyApiLoading = false;
      state.listUsersAssociatedtoSurveyApiSuccess = true;
      state.listUsersAssociatedtoSurveyApiResponseCode = action.payload.ResponseCode;
      state.listUsersAssociatedtoSurveyApiData = action.payload.ResponseData;
      state.listUsersAssociatedtoSurveyApiResponseMessage = action.payload.ResponseMessage;
    });
    // list users associated to survey api failure
    builder.addCase(listusersassociatedtosurvey.rejected, (state, action: any) => {
      state.listUsersAssociatedtoSurveyApiLoading = false;
      state.listUsersAssociatedtoSurveyApiSuccess = false;
      state.listUsersAssociatedtoSurveyApiResponseCode = action.payload.ResponseCode;
      state.listUsersAssociatedtoSurveyApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});

// Export actions
export const {
  resetCreateSurvey,
  resetDeleteForm,
  resetAddSurveyForm,
  resetSurveyFormDetail,
  resetAssignmentStudentsList,
  resetGetStudentSurveyResponse,
  resetCreateSurveyApiData,
  resetDeleteFormAttachment,
  resetUpdateSurveyForm,
  resetUpdateSurvey,
  resetDeleteSurvey,
  resetSurveyDetail,
  resetListUsersAssociatedtoSurveyApi
} = surveySlice.actions;

// Export reducer.
export default surveySlice.reducer;
