import React from 'react';
import '../assets/css/StudentCard.scss';
import { Row, Col } from 'react-bootstrap';
import Approved from '../assets/img/icon/Approved';
import Pending from '../assets/img/icon/Pending';
import { useIntlMessages } from '../utils/helper';

const StudentCard = (props: any) => {
  const LabelPending = useIntlMessages('Label.PendingStatus');
  const listStatus = () => {
    if (props?.reviewStatus == 'Approved') {
      return (
        <Col xs="auto" className="approved d-flex align-items-center">
          <Approved /> {props?.status}
        </Col>
      );
    } else if (props?.status == 'Not Started') {
      return (
        <Col xs="auto" className="pending d-flex align-items-center">
          {props?.status}
        </Col>
      );
    } else {
      return (
        <Col xs="auto" className="pending d-flex align-items-center">
          <Pending />
          {LabelPending}
        </Col>
      );
    }
  };
  return (
    <div className={'studentcard-main' + (props.selected ? ' selected' : '')}>
      <Row>
        <Col xs="auto" className="d-flex align-items-center student-img">
          <img src={props.image} alt="Student" />
        </Col>
        <Col className="student-name d-flex align-items-center">
          <p>
            {props.name} {props?.attachment ? <small>{props.attachment} Attachment</small> : ' '}{' '}
          </p>
        </Col>
        <>{listStatus()}</>
      </Row>
    </div>
  );
};
export default StudentCard;
