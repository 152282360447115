/**
 * @file   src\containers\EditProfile.tsx
 * @brief  Profile edit page.
 * @date   Jan, 2024
 * @author ZCO
 * @copyright (c) 2023, ZCO */

import { useState } from 'react';
import '../assets/css/myprof.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages } from '../utils/helper';
import { Col, Button, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Input from '../components/MAInput';
import Select from '../components/MASelect';
import Phone from '../components/MAPhoneInput';

const Myprofile = () => {
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const gender = [
        { label: 'Male', value: '1' },
        { label: 'Female', value: '2' }
    ];
    const city = [
        { label: 'City 1', value: '1' },
        { label: 'City 2', value: '2' }
    ];
    const state = [
        { label: 'State 1', value: '1' },
        { label: 'State 2', value: '2' }
    ];
    return (
        <>
            <div className="page-title d-flex justify-content-between align-items-center">
                <h3><FormattedMessage id="Hd.EditProfile" /></h3>
            </div>
            <div className="content-sub">
                <div className="content-area-padding">
                    <Col xl={10} lg={12}>
                        <Row>
                            <Col xl={6} lg={6}>
                                <Input
                                    label={useIntlMessages('Label.FirstName')}
                                    id='FirstName'
                                    name='FirstName'
                                    type='text'
                                    placeholder={useIntlMessages('Label.FirstName')}
                                    maxLength={200}
                                />
                            </Col>
                            <Col xl={6} lg={6}>
                                <Input
                                    label={useIntlMessages('Label.LastName')}
                                    id='LastName'
                                    name='LastName'
                                    type='text'
                                    placeholder={useIntlMessages('Label.LastName')}
                                    maxLength={200}
                                />
                            </Col>
                            <Col xl={6} lg={6}>
                                <div className="custom-calendar mb-4">
                                    <label className="form-label"><FormattedMessage id="Label.DateofBirth" /></label>
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                </div>
                            </Col>
                            <Col xl={6} lg={6}>
                                <Select
                                    label={useIntlMessages('Label.Gender')}
                                    options={gender}
                                    // value={gender}
                                    placeholder="Select"
                                />
                            </Col>
                            <Col xl={6} lg={6}>
                                <Input
                                    label={useIntlMessages('Label.Email')}
                                    id='Email'
                                    name='Email'
                                    type='text'
                                    placeholder={useIntlMessages('Label.Email')}
                                    maxLength={200}
                                />
                            </Col>
                            <Col xl={6} lg={6}>
                                {/* <Input
                                    label={useIntlMessages('Label.PhoneNum')}
                                    id='Phone'
                                    name='Phone'
                                    type='text'
                                    placeholder={useIntlMessages('Label.PhoneNum')}
                                    maxLength={200}
                                /> */}
                                <Phone label={useIntlMessages('Label.PhoneNum')} mask="+1 (999) 999-9999" />
                            </Col>
                            <Col xl={12}>
                                <Col className='form-sm'>
                                    <Input
                                        label={useIntlMessages('Label.Address')}
                                        id='Address'
                                        name='Address'
                                        type='text'
                                        placeholder={useIntlMessages('Label.Address')}
                                        maxLength={200}
                                        as="textarea"
                                        rows="3"
                                    />
                                </Col>
                            </Col>
                            <Col xl={6} lg={6}>
                                <Select
                                    label={useIntlMessages('Label.City')}
                                    options={city}
                                    // value={city}
                                    placeholder="Select"
                                />
                            </Col>
                            <Col xl={6} lg={6}>
                                <Select
                                    label={useIntlMessages('Label.State')}
                                    options={state}
                                    // value={state}
                                    placeholder="Select"
                                />
                            </Col>
                            <Col xl={6} lg={6}>
                                <Input
                                    label={useIntlMessages('Label.Zip')}
                                    id='Phone'
                                    name='Phone'
                                    type='text'
                                    placeholder={useIntlMessages('Label.Zip')}
                                    maxLength={200}
                                />
                            </Col>
                        </Row>

                        <div className='btn-container d-flex justify-content-end my-4'>
                            <Button variant="outline-primary">
                                <FormattedMessage id="Button.Cancel" />
                            </Button>
                            <Button variant="primary">
                                <FormattedMessage id="Button.Save" />
                            </Button>
                        </div>
                    </Col>
                </div>
            </div>
        </>
    )
}
export default Myprofile;