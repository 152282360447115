/**
 * @file   src\validations\userSchema.ts
 * @brief  This file is responsible for defining User validation schemas.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { EMAIL_REGEX, PASSWORD_REGEX, PHONE_NUMBER_REGEX,ZIP_REGEX_NEW,FIRST_NAME_REGEX } from '../utils/constants';

// User Form Validation Schema
export const USER_SCHEMA = yup.object({
  EmailId: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Form.UserEmail.Required')),
  OrganizationTypeId: yup.number().notOneOf([0], useIntlActionMessages('Form.OrgType.Required')).required(useIntlActionMessages('Form.OrgType.Required')),
  RoleId: yup.number().notOneOf([0], useIntlActionMessages('Form.UserRole.Required')).required(useIntlActionMessages('Form.UserRole.Required')),
  OrganizationID: yup.number().notOneOf([0], useIntlActionMessages('Form.SelectOrganizationName.Required')).required(useIntlActionMessages('Form.SelectOrganizationName.Required')),
  Firstname: yup.string().trim().test('validator-Firstname-required', function (value) {
    if (value && value.length > 0 ) {
      if (value.length < 2 || value.length > 100 || !FIRST_NAME_REGEX.test(value)) {
        return this.createError({
          path: this.path,
          message: useIntlActionMessages('Form.Firstname.Valid'),
        });
      } else {
        return true;
      }
    }  else {
      return true;
    }
  }),
  Lastname: yup.string().trim().test('validator-Lastname-required', function (value) {
    if (value && value.length > 0 ) {
      if (value.length < 2 || value.length > 100 || !FIRST_NAME_REGEX.test(value)) {
        return this.createError({
          path: this.path,
          message: useIntlActionMessages('Form.Lastname.Valid'),
        });
      } else {
        return true;
      }
    }  else {
      return true;
    }
  }),
  Phonenum: yup.string().trim().test('validator-Phoneno-required', function (value) {
    if (value && value.length > 0 ) {
      if ( !PHONE_NUMBER_REGEX.test(value)) {
        return this.createError({
          path: this.path,
          message: useIntlActionMessages('Form.Phoneno.Required'),
        });
      } else {
        return true;
      }
    }  else {
      return true;
    }
  }),
  Address: yup
    .string()
    .max(200)
    .test('validator-address-required', function (value) {
      if (value !== null && value !== undefined && value.length > 0 && value.length < 5) {
        return this.createError({
          path: this.path,
          message: useIntlActionMessages('Form.Address.Min'),
        });
      } else {
        return true;
      }
    }),
  CityId: yup.number(),
  StateId: yup.number(),
  DateOfBirth: yup.string(),
  Zip: yup
    .string()
    .matches(ZIP_REGEX_NEW, useIntlActionMessages('Form.Zip.Invalid')),
  Gender: yup.string(),
  CountryCode: yup.string(),
  HighestEducation: yup.string(),
});
// System admin form validation schema.
export const CREATE_SYSTEM_ADMIN_SCHEMA = yup.object({
  AdminPassword: yup.string().trim().required(useIntlActionMessages('Form.Password.Required')).matches(PASSWORD_REGEX, useIntlActionMessages('Form.Password.Criteria')),
  AdminEmailId: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('LoginForm.Email.Required')),
  phoneNo: yup.string().required(useIntlActionMessages('Form.Phoneno.Required')).matches(PHONE_NUMBER_REGEX, useIntlActionMessages('Form.Phoneno.Required')),
});
// User bulk upload Validation Schema
export const USER_UPLOAD_SCHEMA = yup.object({
  OrganizationTypeID: yup.number().notOneOf([0], useIntlActionMessages('Form.OrgType.Required')).required(useIntlActionMessages('Form.OrgType.Required')),
  UserRoleID: yup.number().notOneOf([0], useIntlActionMessages('Form.UserRole.Required')).required(useIntlActionMessages('Form.UserRole.Required')),
  OrganizationID: yup.string().required(useIntlActionMessages('Form.SelectOrganizationName.Required')),
});
