const MyProfileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
      <defs>
        <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fad900" />
          <stop offset="1" stop-color="#f5933d" />
        </linearGradient>
      </defs>
      <path
        id="myprofile"
        d="M10,20A10,10,0,0,1,2.929,2.929,10,10,0,1,1,17.071,17.071,9.935,9.935,0,0,1,10,20Zm0-9.02a8.816,8.816,0,0,0-2.123.265,12.932,12.932,0,0,0-2.214.789,2.06,2.06,0,0,0-.807.686,1.792,1.792,0,0,0-.31,1.046v1.415H15.455V13.766a1.792,1.792,0,0,0-.311-1.046,2.039,2.039,0,0,0-.818-.686,13.962,13.962,0,0,0-2.208-.783A8.594,8.594,0,0,0,10,10.98Zm0-6.435a2.332,2.332,0,0,0-1.731.7,2.444,2.444,0,0,0-.681,1.778,2.447,2.447,0,0,0,.681,1.775,2.323,2.323,0,0,0,1.731.7,2.323,2.323,0,0,0,1.731-.7,2.448,2.448,0,0,0,.682-1.775,2.444,2.444,0,0,0-.682-1.778A2.332,2.332,0,0,0,10,4.545Z"
        transform="translate(0.5 0.5)"
        stroke="rgba(0,0,0,0)"
        stroke-miterlimit="10"
        stroke-width="1"
        fill="url(#linear-gradient)"
      />
    </svg>
  );
};
export default MyProfileIcon;
