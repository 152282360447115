import React from 'react';

const DashboardIcon = () => {
  return (
<svg width="19" height="19" viewBox="0 0 19 19">
  <defs>
    <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#fad900"/>
      <stop offset="1" stop-color="#f5933d"/>
    </linearGradient>
  </defs>
  <path id="Icn_Dashboard" d="M150.325,241.825V236H158v5.824ZM140,245.53V236h7.676v9.529ZM150.325,254v-9.529H158V254ZM140,254v-5.824h7.676V254Z" transform="translate(-139.501 -235.501)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill="url(#linear-gradient)"/>
</svg>
  );
}

export default DashboardIcon;
