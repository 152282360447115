import React from 'react';
const TaskIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
            <path id="description_FILL1_wght400_GRAD0_opsz48" d="M163.975,191.75h8.05v-1.5h-8.05Zm0-4.25h8.05V186h-8.05ZM161.5,196a1.538,1.538,0,0,1-1.5-1.5v-17a1.538,1.538,0,0,1,1.5-1.5h9.025L176,181.475V194.5a1.538,1.538,0,0,1-1.5,1.5Zm8.275-13.85H174.5l-4.725-4.65Z" transform="translate(-160 -176)" fill="#fff" />
        </svg>
    );
}

export default TaskIcon;
