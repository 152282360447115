import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, Col, Row, Form } from 'react-bootstrap';
import '../assets/css/AttachmentmentCard.scss';
import { uploadFile } from '../store/actions/organizationActions';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import Loader from './Loader';
import { resetUploadFile } from '../store/slices/organizationSlice';
import { useRecordWebcam } from 'react-record-webcam';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useIntlActionMessages, getRandomString } from '../utils/helper';
import Record from '../assets/img/Record.svg';
import Pause from '../assets/img/Pause.svg';
import Play from '../assets/img/Play.svg';
import Stop from '../assets/img/Stop.svg';
import Cancel from '../assets/img/Cancel.svg';
import { MessageToaster } from '../utils/ToastUtil';
const PopupRecorder = (props: any) => {
  const {
    activeRecordings,
    cancelRecording,
    clearAllRecordings,
    clearError,
    clearPreview,
    closeCamera,
    createRecording,
    devicesById,
    devicesByType,
    download,
    errorMessage,
    muteRecording,
    openCamera,
    pauseRecording,
    resumeRecording,
    startRecording,
    stopRecording,
  } = useRecordWebcam();

  // Message toast object creation
  const toastObj = new MessageToaster();

  // Action dispatch object creation
  const dispatch = useAppDispatch(); // accessing redux state variables
  const popupHeadTextAudio = useIntlActionMessages('Head.AudioRecorder');
  const popupHeadTextVideo = useIntlActionMessages('Head.VideoRecorder');
  const AddMessageSuccess = useIntlActionMessages('Message.Success');

  const fileTypeExt = props.fileType == 'audio' ? 'mp3' : 'mp4';
  const fileTypeExtFormat = props.fileType == 'audio' ? 'audio/mp3' : 'video/mp4';
  const { uploadFileApiSuccess, uploadFileResponseCode, uploadFileApiData, uploadFileResponseMessage } = useAppSelector((state: RootState) => state.organization);

  const [blobUrl, setBlobUrl] = useState<any>();
  const [activeRecordingId, setActiveRecordingId] = useState<any>();
  const [mediaDetails, setMediaDetails] = useState<any>();
  const [fileRecordBegin, setFileRecordBegin] = useState<boolean>(false);
  const [recordingid, setRecordingID] = useState<any>(false);

  // Start recording
  const start = async () => {debugger
    const recording = await createRecording();
    if (recording) {
      setRecordingID(recording.id);
      await openCamera(recording.id);
    }
  };

  // Handle the upload path api response
  useEffect(() => {
    const uploadRecordedMedia = async () => {
      if (uploadFileResponseCode > 0 && uploadFileApiSuccess && fileRecordBegin) {
        uploadMediaFileToBucket();
        setFileRecordBegin(false);
        toastObj.toastSuccess(AddMessageSuccess);
        dispatch(resetUploadFile());
      }
    };
    uploadRecordedMedia();
  }, [uploadFileResponseCode, uploadFileResponseMessage]);

  // // Put file to bucket after getting the path
  const uploadMediaFileToBucket = async () => {
    const uploadURL = uploadFileApiData;
    const file = new Blob([blobUrl], { type: fileTypeExtFormat }); // Adjust the type as needed
    if (uploadURL) {
      const response = await fetch(uploadURL, {
        method: 'PUT',
        body: await (await fetch(blobUrl)).blob(),
        headers: {
          'Content-Type': props.fileType == 'audio' ? 'audio/wav' : fileTypeExtFormat,
        },
      });

      if (response.ok) {
        console.log('Upload successful');
      } else {
        setFileRecordBegin(false);
        console.error('Upload failed');
      }
      handleHidePopup();
    }
  };
  const handleStopRecording = async (recording: any) => {
    await stopRecording(recording.id);
    const blob = recording.previewRef?.current?.src;
    setBlobUrl(blob);
    setActiveRecordingId(recording.id);
  };
  const handleUpload = () => {
    if (blobUrl) {
      setFileRecordBegin(true);
      const file = new Blob([blobUrl], { type: fileTypeExtFormat }); // Adjust the type as needed
      setMediaDetails(file);
      const fileName = getRandomString() + '.' + fileTypeExt;
      const formData = new FormData();
      formData.append('UploadExtension', fileTypeExt);
      formData.append('UploadObjectKey', fileName);
      formData.append('UploadType', 'goal_review_comments');
      dispatch(uploadFile(formData));
      props.onClose();
    }
  };
  const addAudioElement = (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    setBlobUrl(url);
    const audio = document.createElement('audio');
    audio.src = url;
    audio.className = 'react-audio-player';
    audio.controls = true;
    const listelement = document.getElementById('listUploadedFile');
    if (listelement) {
      listelement.appendChild(audio);
    }
  };
  const handleHidePopup = async () => {
    if (recordingid && props.fileType == 'video') {
      cancelRecording(recordingid);
    }
    clearAllRecordings();
    props.onClose();
  };
  return (
    <>
      <Modal show={props.show} onHide={handleHidePopup} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{props.fileType == 'audio' ? popupHeadTextAudio : popupHeadTextVideo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.fileType == 'audio' ? (
            <div>
              <Col className="d-flex justify-content-center">
                <AudioRecorder
                  onRecordingComplete={addAudioElement}
                  audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                    // autoGainControl,
                    // channelCount,
                    // deviceId,
                    // groupId,
                    // sampleRate,
                    // sampleSize,
                  }}
                  onNotAllowedOrFound={(err) => console.table(err)}
                  // downloadOnSavePress={true}
                  downloadFileExtension="webm"
                  mediaRecorderOptions={{
                    audioBitsPerSecond: 128000,
                  }}
                  showVisualizer={true}
                />
              </Col>
              <Col className="d-flex justify-content-center">
                <Col xl={9}>
                  <div id="listUploadedFile" className="my-4"></div>
                </Col>
              </Col>
              <Col className="text-center">
                <Button onClick={handleUpload}>
                  <FormattedMessage id="Button.SendMessage" />
                </Button>
              </Col>
            </div>
          ) : (
            <div>
              <div className="space-x-2 text-center">
                <Button className="me-3" onClick={start}>
                  <FormattedMessage id="Button.Opencamera" />
                </Button>
                <Button variant="outline-primary" className="me-3" onClick={() => clearAllRecordings()}>
                  <FormattedMessage id="Button.Clearall" />
                </Button>
              </div>

              <div className="my-2 text-center">
                <p>{errorMessage ? `Error: ${errorMessage}` : ''}</p>
              </div>
              <div className="grid grid-cols-custom gap-4 my-4">
                {activeRecordings?.map((recording) => (
                  <div className="rounded-lg record-main" key={recording.id}>
                    <Col className="d-flex justify-content-center">
                      <Col xl={9}>
                        <video className="video-recorder" ref={recording.webcamRef} loop autoPlay playsInline />
                        <div className="record-button-nav">
                          <Button
                            variant="outline-light"
                            title="Record"
                            // inverted
                            disabled={recording.status === 'RECORDING' || recording.status === 'PAUSED'}
                            onClick={() => startRecording(recording.id)}
                          >
                            <img src={Record} />
                          </Button>
                          <Button
                            variant="outline-light"
                            title={recording.status === 'PAUSED' ? 'Play' : 'Pause'}
                            // inverted
                            disabled={recording.status !== 'RECORDING' && recording.status !== 'PAUSED'}
                            // toggled={recording.status === 'PAUSED'}
                            onClick={() => (recording.status === 'PAUSED' ? resumeRecording(recording.id) : pauseRecording(recording.id))}
                          >
                            {recording.status === 'PAUSED' ? <img src={Play} /> : <img src={Pause} />}
                          </Button>
                          {/* <Button
                        // inverted
                        // toggled={recording.isMuted}
                        onClick={() => muteRecording(recording.id)}
                      >
                        Mute
                      </Button> */}
                          <Button
                            variant="outline-light"
                            title="Stop"
                            // inverted
                            onClick={() => handleStopRecording(recording)}
                          >
                            <img src={Stop} />
                          </Button>
                          <Button
                            variant="outline-light"
                            title="Cancel"
                            // inverted
                            onClick={() => cancelRecording(recording.id)}
                          >
                            <img src={Cancel} />
                          </Button>
                        </div>
                      </Col>
                    </Col>
                    <div className={`${recording.previewRef.current?.src.startsWith('blob:') ? 'visible' : 'hidden'}`}>
                      <Col className="text-center mb-3 mt-4">
                        <h5>
                          <FormattedMessage id="Head.Preview" />
                        </h5>
                      </Col>
                      <Col className="d-flex justify-content-center">
                        <Col xl={9}>
                          <video className="video-recorder" ref={recording.previewRef} autoPlay loop playsInline />
                        </Col>
                      </Col>
                      <div className="text-center mt-3">
                        <Button onClick={handleUpload}>
                          <FormattedMessage id="Button.SendMessage" />
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="mx-3"
                          // inverted
                          onClick={() => download(recording.id)}
                        >
                          <FormattedMessage id="Button.Download" />
                        </Button>
                        <Button
                          variant="outline-primary"
                          // inverted
                          onClick={() => clearPreview(recording.id)}
                        >
                          <FormattedMessage id="Button.Clearpreview" />
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>{' '}
      {fileRecordBegin && <Loader />}
    </>
  );
};
export default PopupRecorder;
