const OrganizationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.618" height="23.001" viewBox="0 0 19.618 23.001">
      <defs>
        <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fad900" />
          <stop offset="1" stop-color="#f5933d" />
        </linearGradient>
      </defs>
      <path
        id="organization"
        d="M-8746-2352h-8v-10l8-3v13Zm-6-5v1h4v-1Zm0-2v1h4v-1Zm-4,7h-8v-22h8v22Zm-3-14v2h2v-2Zm-4,0v2h2v-2Zm4-3v2h2v-2Zm-4,0v2h2v-2Zm4-3v2h2v-2Zm-4,0v2h2v-2Z"
        transform="translate(8764.501 2374.5)"
        stroke="rgba(0,0,0,0)"
        stroke-miterlimit="10"
        stroke-width="1"
        fill="url(#linear-gradient)"
      />
    </svg>
  );
};
export default OrganizationIcon;
