/**
 * @file   src\store\actions\organizationActions.ts
 * @brief  This file is responsible for creating organization based api call.
 * @date   JUL, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { NotificationApis } from '../../utils/apiUrls';
import { IListNotificationParams } from '../../interfaces/Notification';

// Api call to get list of organizations
export const getNotificationList = createAsyncThunk('/getNotificationList', async (request: IListNotificationParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${NotificationApis.LIST_NOTIFICATION}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
