import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { EMAIL_REGEX, PASSWORD_REGEX, ZIP_REGEX } from '../utils/constants';

// create account form validation schema.
export const CREATE_ACCOUNT_SCHEMA = yup.object({
  password: yup.string().trim().required(useIntlActionMessages('Form.Password.Required')).matches(PASSWORD_REGEX, useIntlActionMessages('Form.Password.Criteria')),
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('LoginForm.Email.Required')),
  confirmPassword: yup
    .string()
    .required(useIntlActionMessages('Form.CnfPassword.Required'))
    .oneOf([yup.ref('password')], useIntlActionMessages('Form.Password.NotMatch')),
});

// Schema for subscription payment info form
export const PAYMENT_INFO_SCHEMA = yup.object({
  nameOnCard: yup.string().required().min(4),
  firstName: yup.string().required().min(3),
  lastName: yup.string().required().min(3),
  streetAddress: yup.string().required().min(10),
  apartmentNo: yup.string().required(),
  stateId: yup.number().required(),
  zip: yup.string().required().matches(ZIP_REGEX, useIntlActionMessages('Form.Zip.Invalid')),
});
