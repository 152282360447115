import React from 'react';
const AddIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="add_FILL1_wght400_GRAD0_opsz48" d="M208.036,314v-8.036H200v-1.929h8.036V296h1.929v8.036H218v1.929h-8.036V314Z" transform="translate(-200 -296)" fill="#fff" />
        </svg>
    );
}

export default AddIcon;
