/**
 * @file   src\components\Layout.tsx
 * @brief  This component used as a layout for the content which can be replaced.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/HomeLayout.scss'
import '../assets/css/TeachLogin.scss'
import Logo from "../assets/img/Logo.svg";
import logvector from "../assets/img/log-vector.svg";
import Carousel from 'react-bootstrap/Carousel';


const TeacherHomeLayout = ({ ...props }) => {
  return (
    <Container fluid>
      <Row>
        <Col md={12} lg={6} className='log-lft-bg d-flex justify-content-center'>
          <div className='slider-wrap text-center '>
          <div className='mr-logo text-center'>
              <img src={Logo} alt="" />
            </div>
            <Carousel>
              <Carousel.Item>
                <img src={logvector} alt="" />
                <Carousel.Caption>
                  <p>The system will aid organization delegates to upload motivational content in the form of pictures, video, and audio. It will help to set goals for a user and create events to achieve the goal.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img src={logvector} alt="" />
                <Carousel.Caption>
                  <p>The system will aid organization delegates to upload motivational content in the form of pictures, video, and audio. It will help to set goals for a user and create events to achieve the goal.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img src={logvector} alt="" />
                <Carousel.Caption>
                  <p>
                    The system will aid organization delegates to upload motivational content in the form of pictures, video, and audio. It will help to set goals for a user and create events to achieve the goal.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
        <Col md={12} lg={6} className='lg-rgt-b'>
          <div className='lg-rgt d-flex align-items-center justify-content-center'>
            <Col lg={7} md={7}>
              {props.children}
            </Col>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default TeacherHomeLayout;