/**
 * @file   src\containers\organizations\OrgTtypeDetails.tsx
 * @brief  Organization type details page.
 * @date   Dec, 2023
 * @author ZCO
 * @copyright (c) 2023, ZCO */

import { useEffect, useState } from 'react';
import '../../assets/css/OrgTypDetail.scss';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row, Breadcrumb, Table, Nav, NavDropdown } from 'react-bootstrap';
import More from '../../assets/img/More.svg';
import { end } from '@popperjs/core';
import Pagination from '../../components/Pagination';
import ViewIcon from '../../assets/img/icon/View';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { viewOrganizationType, listOrganizationsByOrgType } from '../../store/actions/organizationTypeActions';
import { RootState } from '../../store';
import { PAGE_SIZE, DEFAULT_PAGE_INDEX } from '../../utils/constants';
import Loader from '../../components/Loader';
import { OrganizationsSortField, RoleTypeIds, SortOrderType } from '../../utils/enums';
import Sort from '../../assets/img/icon/Sort';
import { useIntlActionMessages } from '../../utils/helper';

const OrganizationTypeDetails = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();

  // Create route param object to access the route parameters.
  const params = useParams();
  const organizationTypeId: number = params.organizationTypeId ? Number(params.organizationTypeId) : 0;
  const organizationsByOrganizationTypeAPIDefaultParams = {
    OrganizationTypeId: organizationTypeId,
    Page: DEFAULT_PAGE_INDEX,
    PageSize: PAGE_SIZE,
    SortColumn: '',
    SortOrder: '',
  };
  const { organizationTypeDetail, organizationTypeViewApiLoading, organizationTypeViewApiResponseCode, organizationTypeViewApiSuccess, organizations} = useAppSelector(
    (state: RootState) => state.organizationType,
  );

  // Initialize component state variables.

  const [organizationAdmin, setOrganizationAdmin] = useState<string>('');
  const [organizationManager, setOrganizationManager] = useState<string>('');
  const [organizationAppUserManager, setOrganizationAppUserManager] = useState<string>('');
  const [organizationAppUser, setOrganizationAppUser] = useState<string>('');
  const [organizationAccountabilityPartner, setOrganizationAccountabilityPartner] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [sortField, setSortField] = useState<string>(OrganizationsSortField.ORG_NAME);
  const [sortOrder, setSortOrder] = useState<string>(SortOrderType.ASC);
  const [organizationsByOrgTypeApiParams, setOrganizationsByOrgTypeApiParams] = useState<any>(organizationsByOrganizationTypeAPIDefaultParams);
  const labelNoData = useIntlActionMessages('Label.Nodata');
  // call view api on initial loading.
  useEffect(() => {
    dispatch(
      viewOrganizationType({
        OrganizationTypeId: organizationTypeId,
      }),
    );
    dispatch(listOrganizationsByOrgType(organizationsByOrganizationTypeAPIDefaultParams));
  }, []);

  // set data for organization type roles
  useEffect(() => {
    if (organizationTypeViewApiSuccess && organizationTypeViewApiResponseCode > 0) {
      setOrganizationAdmin(
        organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeWebsiteUsers.find((option: any) => option.roletypeid == RoleTypeIds.ORGANIZATION_ADMIN)?.rolename,
      );
      setOrganizationManager(
        organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeWebsiteUsers.find((option: any) => option.roletypeid == RoleTypeIds.ORGANIZATION_MANGER)?.rolename,
      );
      setOrganizationAppUserManager(
        organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeWebsiteUsers.find((option: any) => option.roletypeid == RoleTypeIds.APP_USER_MANAGER)?.rolename,
      );
      setOrganizationAppUser(organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeMobileUsers.find((option: any) => option.roletypeid == RoleTypeIds.APP_USER)?.rolename);
      setOrganizationAccountabilityPartner(
        organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeMobileUsers.find((option: any) => option.roletypeid == RoleTypeIds.ACCOUNTABILITY_PARTNER)?.rolename,
      );
    }
  }, [organizationTypeViewApiLoading]);

  // Set APi parameters.
  useEffect(() => {
    setOrganizationsByOrgTypeApiParams((apiParams: any) => ({
      ...apiParams,
      Page: currentPage,
      SortColumn: sortField,
      SortOrder: sortOrder,
    }));
  }, [currentPage, sortField, sortOrder]);

  // List organizations by org type.
  useEffect(() => {
    dispatch(listOrganizationsByOrgType(organizationsByOrgTypeApiParams));
  }, [organizationsByOrgTypeApiParams]);

  // Sort header change event.
  const changeSortField = (field: string) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // Handle Edit Organization Type
  const handleEditOrganizationType = () => {
    navigate('/editorganizationtype', { state: { organizationTypeId: organizationTypeId } });
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Label.OrgTypeDetail" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
              <FormattedMessage id="Hd.ManageOrganizations" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate('/listorganizationtypes')}>
              <FormattedMessage id="Label.ViewOrgType" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Label.OrgTypeDetails" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="content-sub mb-5">
        <div className="content-sub-header d-flex justify-content-between align-items-center">
          <div>
            <h4>{organizationTypeDetail && organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeName}</h4>
          </div>
          <Col xs="auto">
            <Nav>
              <NavDropdown align={end} className="icon-btn-more" title={<img src={More} alt="more" />}>
                <NavDropdown.Item onClick={handleEditOrganizationType}>
                  <FormattedMessage id="Link.EditOrganizationTypeInfo" />
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Col>
        </div>
        <div className="p-3">
          <Row>
            <Col>
              <h4>
                <FormattedMessage id="Hd.UserRole" />
              </h4>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={6}>
              <div className="sec-b sec-type-det-L">
                <div className="sec-type-det-L-header">
                  <h5>
                    <FormattedMessage id="Hd.tit-type" />
                  </h5>
                </div>
                <div className="sec-type-det-cont">
                  <Row className="p-3">
                    <Col>
                      <p className="small-text mb-0">User 1</p>
                      <p className="txt-white">{organizationAdmin}</p>
                    </Col>
                    <Col>
                      <p className="small-text mb-0">User 2</p>
                      <p className="txt-white">{organizationManager}</p>
                    </Col>
                    <Col>
                      <p className="small-text mb-0">User 3</p>
                      <p className="txt-white">{organizationAppUserManager}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="sec-b sec-type-det-L">
                <div className="sec-type-det-L-header">
                  <h5>
                    <FormattedMessage id="Hd.MobileApp" />
                  </h5>
                </div>
                <div className="sec-type-det-cont">
                  <Row className="p-3">
                    <Col>
                      <p className="small-text mb-0">User 1</p>
                      <p className="txt-white">{organizationAppUser}</p>
                    </Col>
                    <Col>
                      <p className="small-text mb-0">User 2</p>
                      <p className="txt-white">{organizationAccountabilityPartner}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div></div>
      </div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>Organizations</h3>
      </div>
      <div className="content-sub p-3">
        <Table striped hover responsive>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Label.OrgNametil" />
                <Link to="" className="ms-1" onClick={() => changeSortField(OrganizationsSortField.ORG_NAME)}>
                  <Sort />
                </Link>
              </th>
              <th>
                <FormattedMessage id="Label.NumofApp" />
                <Link to="" className="ms-1" onClick={() => changeSortField(OrganizationsSortField.ORG_APP_USERS)}>
                  <Sort />
                </Link>
              </th>
              <th>
                <FormattedMessage id="Label.NumofWeb" />
                <Link to="" className="ms-1" onClick={() => changeSortField(OrganizationsSortField.ORG_WEBSITE_USERS)}>
                  <Sort />
                </Link>
              </th>
              <th>
                <FormattedMessage id="Label.Subplan" />
                <Link to="" className="ms-1" onClick={() => changeSortField(OrganizationsSortField.ORG_SUBSCRIPTION_PLAN)}>
                  <Sort />
                </Link>
              </th>
              <th className="w-50px"></th>
            </tr>
          </thead>
          <tbody>
            {organizations &&
              organizations.OrganizationInfo &&
              organizations.OrganizationInfo.length > 0 &&
              organizations.OrganizationInfo.map((organization: any) => (
                <tr key={organization.OrganizationId}>
                  <td>{organization.OrganizationName}</td>
                  <td>{organization.NumberofAppUsers}</td>
                  <td>{organization.NumberofWebsiteUsers}</td>
                  <td>{organization.SubscriptionPlan}</td>
                  <td>
                    <Button
                      variant="secondary"
                      size="sm"
                      className="icon-btn"
                      onClick={() =>
                        navigate('/vieworganization', {
                          state: {
                            organizationId: organization.OrganizationId,
                            parentPage: 'orgtypeview',
                          },
                        })
                      }
                    >
                      <ViewIcon />
                    </Button>
                  </td>
                </tr>
              ))}
            {!organizations && (
              <tr>
                <td colSpan={5}>
                  <div className="content-sub content-area-padding border-top text-center">
                    <h6>{labelNoData}</h6>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {organizations && organizations.OrganizationInfo && organizations.OrganizationInfo.length > 0 && organizations.totalCount > PAGE_SIZE && (
          <Pagination
            pageSize={PAGE_SIZE}
            totalitems={organizations.totalCount}
            pageLimit={PAGE_SIZE}
            setCurrentPage={(page: number) => setCurrentPage(page)}
            currentPage={currentPage - 1}
            prevPage={-1}
            itemsDisplayed={organizations?.OrganizationInfo?.length}
          />
        )}
      </div>
      {organizationTypeViewApiLoading && <Loader />}
    </>
  );
};
export default OrganizationTypeDetails;
