import { createSlice } from '@reduxjs/toolkit';
import {
  getOrganizationTypeList,
  getOrganizationTypeDropDownList,
  addOrganizationType,
  getUserRolesByOrgType,
  viewOrganizationType,
  listOrganizationsByOrgType,
  getOrganizationNameByOrgType,
  getCategoryDetailsById,
  updateOrganizationType,
  getUserTagsByOrgType
} from '../actions/organizationTypeActions';
import { IOrganizationTypeSlice } from '../../interfaces/OrganizationTypeInterface';

// Initial state
const organizationTypeDefaultState: IOrganizationTypeSlice = {
  organizationTypeListApiLoading: false,
  organizationTypeListApiSuccess: false,
  organizationTypeList: {},
  totalCount: 0,
  organizationTypeListApiResponseCode: 0,
  organizationTypeListApiResponseMessage: '',
  organizationTypeDropDownData: {},
  organizationTypeDropDownLoading: false,
  organizationTypeDropDownResponseCode: 0,
  organizationTypeDropDownResponseMessage: '',
  organizationTypeDropDownSuccess: false,
  organizationTypeAddApiLoading: false,
  organizationTypeAddApiSuccess: false,
  organizationTypeAddApiResponseCode: 0,
  organizationTypeAddApiResponseMessage: '',
  organizationTypeUpdateApiLoading: false,
  organizationTypeUpdateApiSuccess: false,
  organizationTypeUpdateApiResponseCode: 0,
  organizationTypeUpdateApiResponseMessage: '',
  rolesByOrgTypeData: [],
  rolesByOrgTypeLoading: false,
  rolesByOrgTypeResponseCode: 0,
  rolesByOrgTypeResponseMessage: '',
  rolesByOrgTypeSuccess: false,
  organizationTypeViewApiLoading: false,
  organizationTypeViewApiSuccess: false,
  organizationTypeViewApiResponseCode: 0,
  organizationTypeViewApiResponseMessage: '',
  organizationTypeDetail: null,
  orgnameByOrgTypeData: [],
  orgnameByOrgTypeLoading: false,
  orgnameByOrgTypeResponseCode: 0,
  orgnameByOrgTypeResponseMessage: '',
  orgnameByOrgTypeSuccess: false,
  categoryDetailsByOrgIdData: [],
  categoryDetailsByOrgIdLoading: false,
  categoryDetailsByOrgIdResponseCode: 0,
  categoryDetailsByOrgIdResponseMessage: '',
  categoryDetailsByOrgIdSuccess: false,
  organiztionsByOrganizationTypeApiLoading: false,
  organiztionsByOrganizationTypeApiSuccess: false,
  organiztionsByOrganizationTypeApiResponseCode: 0,
  organiztionsByOrganizationTypeApiResponseMessage: '',
  organizations: null,
  tagsByOrgTypeData: [],
  tagsByOrgTypeLoading: false,
  tagsByOrgTypeResponseCode: 0,
  tagsByOrgTypeResponseMessage: '',
  tagsByOrgTypeSuccess: false,
};

// Organization Type slice
const organizationTypeSlice = createSlice({
  name: 'organiztionTypeList',
  initialState: organizationTypeDefaultState,
  reducers: {
    resetOrganizationTypeAdd: (state) => {
      state.organizationTypeAddApiLoading = false;
      state.organizationTypeAddApiSuccess = false;
      state.organizationTypeAddApiResponseCode = 0;
      state.organizationTypeAddApiResponseMessage = '';
    },
    resetOrganizationTypeUpdate: (state) => {
      state.organizationTypeUpdateApiLoading = false;
      state.organizationTypeUpdateApiSuccess = false;
      state.organizationTypeUpdateApiResponseCode = 0;
      state.organizationTypeUpdateApiResponseMessage = '';
    },
    resetOrgTypeNameRoles: (state) => {
      state.organizationTypeDropDownData = {};
      state.organizationTypeDropDownLoading = false;
      state.organizationTypeDropDownResponseCode = 0;
      state.organizationTypeDropDownResponseMessage = '';
      state.organizationTypeDropDownSuccess = false;
      state.rolesByOrgTypeData = [];
      state.rolesByOrgTypeLoading = false;
      state.rolesByOrgTypeResponseCode = 0;
      state.rolesByOrgTypeResponseMessage = '';
      state.rolesByOrgTypeSuccess = false;
      state.orgnameByOrgTypeData = [];
      state.orgnameByOrgTypeLoading = false;
      state.orgnameByOrgTypeResponseCode = 0;
      state.orgnameByOrgTypeResponseMessage = '';
      state.orgnameByOrgTypeSuccess = false;
    },
  },
  extraReducers(builder) {
    // user list api starts
    builder.addCase(getOrganizationTypeList.pending, (state) => {
      state.organizationTypeListApiLoading = true;
      state.organizationTypeListApiSuccess = false;
      state.organizationTypeListApiResponseCode = 0;
    });
    // user list api success
    builder.addCase(getOrganizationTypeList.fulfilled, (state, action) => {
      state.organizationTypeListApiLoading = false;
      state.organizationTypeListApiSuccess = true;
      state.organizationTypeListApiResponseCode = action.payload.ResponseCode;
      state.organizationTypeList = action.payload.ResponseData[0];
    });
    // user list api failure
    builder.addCase(getOrganizationTypeList.rejected, (state, action: any) => {
      state.organizationTypeListApiLoading = false;
      state.organizationTypeListApiSuccess = false;
      state.organizationTypeListApiResponseCode = action.payload.ResponseCode;
      state.organizationTypeListApiResponseMessage = action.payload.ResponseMessage;
    });
    builder.addCase(getOrganizationTypeDropDownList.pending, (state) => {
      state.organizationTypeDropDownLoading = true;
      state.organizationTypeDropDownSuccess = false;
      state.organizationTypeDropDownResponseCode = 0;
    });
    // user list api success
    builder.addCase(getOrganizationTypeDropDownList.fulfilled, (state, action) => {
      state.organizationTypeDropDownLoading = false;
      state.organizationTypeDropDownSuccess = true;
      state.organizationTypeDropDownResponseCode = action.payload.ResponseCode;
      state.organizationTypeDropDownData = action.payload.ResponseData;
    });
    // user list api failure
    builder.addCase(getOrganizationTypeDropDownList.rejected, (state, action: any) => {
      state.organizationTypeDropDownLoading = false;
      state.organizationTypeDropDownSuccess = false;
      state.organizationTypeDropDownResponseCode = action.payload.ResponseCode;
      state.organizationTypeDropDownResponseMessage = action.payload.ResponseMessage;
    });
    // organization type add api starts
    builder.addCase(addOrganizationType.pending, (state) => {
      state.organizationTypeAddApiLoading = true;
      state.organizationTypeAddApiSuccess = false;
      state.organizationTypeAddApiResponseCode = 0;
    });
    // organization type add api success
    builder.addCase(addOrganizationType.fulfilled, (state, action) => {
      state.organizationTypeAddApiLoading = false;
      state.organizationTypeAddApiSuccess = true;
      state.organizationTypeAddApiResponseCode = action.payload.ResponseCode;
      state.organizationTypeAddApiResponseMessage = action.payload.ResponseMessage;
    });
    // organization type add api failure
    builder.addCase(addOrganizationType.rejected, (state, action: any) => {
      state.organizationTypeAddApiLoading = false;
      state.organizationTypeAddApiSuccess = false;
      state.organizationTypeAddApiResponseCode = action.payload.ResponseCode;
      state.organizationTypeAddApiResponseMessage = action.payload.ResponseMessage;
    });
    // organization type update api starts
    builder.addCase(updateOrganizationType.pending, (state) => {
      state.organizationTypeUpdateApiLoading = true;
      state.organizationTypeUpdateApiSuccess = false;
      state.organizationTypeUpdateApiResponseCode = 0;
    });
    // organization type update api success
    builder.addCase(updateOrganizationType.fulfilled, (state, action) => {
      state.organizationTypeUpdateApiLoading = false;
      state.organizationTypeUpdateApiSuccess = true;
      state.organizationTypeUpdateApiResponseCode = action.payload.ResponseCode;
      state.organizationTypeUpdateApiResponseMessage = action.payload.ResponseMessage;
    });
    // organization type update api failure
    builder.addCase(updateOrganizationType.rejected, (state, action: any) => {
      state.organizationTypeUpdateApiLoading = false;
      state.organizationTypeUpdateApiSuccess = false;
      state.organizationTypeUpdateApiResponseCode = action.payload.ResponseCode;
      state.organizationTypeUpdateApiResponseMessage = action.payload.ResponseMessage;
    });
    // organization type view api starts
    builder.addCase(viewOrganizationType.pending, (state) => {
      state.organizationTypeViewApiLoading = true;
      state.organizationTypeViewApiSuccess = false;
      state.organizationTypeViewApiResponseCode = 0;
    });
    // organization type view api success
    builder.addCase(viewOrganizationType.fulfilled, (state, action) => {
      state.organizationTypeViewApiLoading = false;
      state.organizationTypeViewApiSuccess = true;
      state.organizationTypeViewApiResponseCode = action.payload.ResponseCode;
      state.organizationTypeDetail = action.payload.ResponseData;
    });
    // organization type view api failure
    builder.addCase(viewOrganizationType.rejected, (state, action: any) => {
      state.organizationTypeViewApiLoading = false;
      state.organizationTypeViewApiSuccess = false;
      state.organizationTypeViewApiResponseCode = action.payload.ResponseCode;
      state.organizationTypeViewApiResponseMessage = action.payload.ResponseMessage;
    });
    // user roles by org type api starts
    builder.addCase(getUserRolesByOrgType.pending, (state) => {
      state.rolesByOrgTypeLoading = true;
      state.rolesByOrgTypeSuccess = false;
      state.rolesByOrgTypeResponseCode = 0;
    });
    // user roles by org type api success
    builder.addCase(getUserRolesByOrgType.fulfilled, (state, action) => {
      state.rolesByOrgTypeLoading = false;
      state.rolesByOrgTypeSuccess = true;
      state.rolesByOrgTypeResponseCode = action.payload.ResponseCode;
      state.rolesByOrgTypeData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    // user roles by org type api failure
    builder.addCase(getUserRolesByOrgType.rejected, (state, action: any) => {
      state.rolesByOrgTypeLoading = false;
      state.rolesByOrgTypeSuccess = false;
      state.rolesByOrgTypeResponseCode = action.payload.ResponseCode;
      state.rolesByOrgTypeResponseMessage = action.payload.ResponseMessage;
    });
    // organization name by org type api starts
    builder.addCase(getOrganizationNameByOrgType.pending, (state) => {
      state.orgnameByOrgTypeLoading = true;
      state.orgnameByOrgTypeSuccess = false;
      state.orgnameByOrgTypeResponseCode = 0;
    });
    // organization name by org type api success
    builder.addCase(getOrganizationNameByOrgType.fulfilled, (state, action) => {
      state.orgnameByOrgTypeLoading = false;
      state.orgnameByOrgTypeSuccess = true;
      state.orgnameByOrgTypeResponseCode = action.payload.ResponseCode;
      state.orgnameByOrgTypeData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    // organization name by org type api failure
    builder.addCase(getOrganizationNameByOrgType.rejected, (state, action: any) => {
      state.orgnameByOrgTypeLoading = false;
      state.orgnameByOrgTypeSuccess = false;
      state.orgnameByOrgTypeResponseCode = action.payload.ResponseCode;
      state.orgnameByOrgTypeResponseMessage = action.payload.ResponseMessage;
    });
    // category details by org type api starts
    builder.addCase(getCategoryDetailsById.pending, (state) => {
      state.categoryDetailsByOrgIdLoading = true;
      state.categoryDetailsByOrgIdSuccess = false;
      state.categoryDetailsByOrgIdResponseCode = 0;
    });
    // category details by org type api success
    builder.addCase(getCategoryDetailsById.fulfilled, (state, action) => {
      state.categoryDetailsByOrgIdLoading = false;
      state.categoryDetailsByOrgIdSuccess = true;
      state.categoryDetailsByOrgIdResponseCode = action.payload.ResponseCode;
      state.categoryDetailsByOrgIdData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    // category details by org type api failure
    builder.addCase(getCategoryDetailsById.rejected, (state, action: any) => {
      state.categoryDetailsByOrgIdLoading = false;
      state.categoryDetailsByOrgIdSuccess = false;
      state.categoryDetailsByOrgIdResponseCode = action.payload.ResponseCode;
      state.categoryDetailsByOrgIdResponseMessage = action.payload.ResponseMessage;
    });
    // organizations by organization type api starts
    builder.addCase(listOrganizationsByOrgType.pending, (state) => {
      state.organiztionsByOrganizationTypeApiLoading = true;
      state.organiztionsByOrganizationTypeApiSuccess = false;
      state.organiztionsByOrganizationTypeApiResponseCode = 0;
    });
    // organizations by organization type api success
    builder.addCase(listOrganizationsByOrgType.fulfilled, (state, action) => {
      state.organiztionsByOrganizationTypeApiLoading = false;
      state.organiztionsByOrganizationTypeApiSuccess = true;
      state.organiztionsByOrganizationTypeApiResponseCode = action.payload.ResponseCode;
      state.organizations = action.payload.ResponseData;
    });
    // organizations by organization type api failure
    builder.addCase(listOrganizationsByOrgType.rejected, (state, action: any) => {
      state.organiztionsByOrganizationTypeApiLoading = false;
      state.organiztionsByOrganizationTypeApiSuccess = false;
      state.organiztionsByOrganizationTypeApiResponseCode = action.payload.ResponseCode;
      state.organiztionsByOrganizationTypeApiResponseMessage = action.payload.ResponseMessage;
    });


     // tags by org type api starts
     builder.addCase(getUserTagsByOrgType.pending, (state) => {
      state.tagsByOrgTypeLoading = true;
      state.tagsByOrgTypeSuccess = false;
      state.tagsByOrgTypeResponseCode = 0;
    });
    // tags by org type api success
    builder.addCase(getUserTagsByOrgType.fulfilled, (state, action) => {
      state.tagsByOrgTypeLoading = false;
      state.tagsByOrgTypeSuccess = true;
      state.tagsByOrgTypeResponseCode = action.payload.ResponseCode;
      state.tagsByOrgTypeData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    // tags by org type api failure
    builder.addCase(getUserTagsByOrgType.rejected, (state, action: any) => {
      state.tagsByOrgTypeLoading = false;
      state.tagsByOrgTypeSuccess = false;
      state.tagsByOrgTypeResponseCode = action.payload.ResponseCode;
      state.tagsByOrgTypeResponseMessage = action.payload.ResponseMessage;
    });
  },
});

// Export actions
export const { resetOrganizationTypeAdd, resetOrgTypeNameRoles, resetOrganizationTypeUpdate } = organizationTypeSlice.actions;

// Export reducer.
export default organizationTypeSlice.reducer;
