/**
 * @file   src\containers\organizations\SubscriptionSuccess.tsx
 * @brief  Successfully subscribed plan page.
 * @date   March, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import SubSuccess from '../../assets/img/SubscriptionSuccess.svg';
import { RootState } from '../../store';
import { useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router-dom';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const { currentPlan } = useAppSelector((state: RootState) => state.profile);
  return (
    <>
      <div className="content-sub">
        <div className="content-area-padding text-center py-5">
          <img src={SubSuccess} />
          <h5>{`You have subscribed to ${currentPlan} successfully.`}</h5>
          <Button className="mb-4 mt-3" variant="primary" type="submit" onClick={() => navigate('/vieworganization')}>
            <FormattedMessage id="Button.OK" />
          </Button>
        </div>
      </div>
    </>
  );
};
export default SubscriptionSuccess;
