/**
 * @file   src\containers\EndMessage.tsx
 * @brief  End Message.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

const EndMessage = () => {
  return (
    <p style={{ textAlign: 'center' }} className="p-2">
      <b>Yay! You have seen it all.</b>
    </p>
  );
};

export default EndMessage;
