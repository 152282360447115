import React from "react";
import '../assets/css/CompetenciesCard.scss'
import { Row, Col } from 'react-bootstrap';


const CompetenciesCard = (props: any) => {
    return (
        <div className="competenciescard-main">
           <Row >
            <Col xs="auto" className="count d-flex align-items-center"><h6>{props.count}</h6></Col>
            <Col className="competencies"><p>{props.competencies}</p></Col>
           </Row>
        </div>
    );
}
export default CompetenciesCard;