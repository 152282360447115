import React from 'react';
import { stringFormat } from '../utils/StringBuilder';
import { useIntlActionMessages } from '../utils/helper';

const UploadNote = (props: any) => {
  const labelUploadAttachmentSize = useIntlActionMessages('Label.Upload.Attachment.Size');
  const labelUploadAttachmentType = useIntlActionMessages('Label.Upload.Attachment.type');
  return (
    <ul>
      {props.fileSize && <li>{stringFormat(labelUploadAttachmentSize, '', props.fileSize)}</li>}
      {props.fileType && <li>{labelUploadAttachmentType}</li>}
    </ul>
  );
};

export default UploadNote;
