import React from 'react';
const ApprovedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11.551" viewBox="0 0 16 11.551">
            <path id="done_FILL0_wght400_GRAD0_opsz48_1_" data-name="done_FILL0_wght400_GRAD0_opsz48 (1)" d="M159.505,351.551,154,346.046l1.057-1.057,4.449,4.449L168.943,340,170,341.057Z" transform="translate(-154 -340)" fill="#34c759" opacity="0.8" />
        </svg>
    );
}

export default ApprovedIcon;
