import React from 'react';
import '../assets/css/SubscriptionCard.scss';
import Visibility from '../assets/img/icon/Visibility';
import GreenTick from '../assets/img/icon/GreenTick';
import { useIntlActionMessages } from '../utils/helper';

const PerfomanceCard = (props: any) => {
  return (
    <div className="subscription-main">
      <div className="subscription-price-details">
        <div className="subscription-price">
          <h1>
            $<span>{props?.price}</span>
          </h1>
          <small> {props?.duration}</small>
        </div>
        <div className="subscription-details">
          <h5>{props?.name}</h5>
          <ul>
            <li>
              <GreenTick /> {props?.websiteuser} {useIntlActionMessages('Text.WebsiteUsers')}
            </li>
            <li>
              <GreenTick /> {props?.mobileuser} {useIntlActionMessages('Text.MobileAppUsers')}
            </li>
          </ul>
        </div>
      </div>
      <div className="visibility">
        <Visibility /> <span>{props?.limited}</span>
      </div>
    </div>
  );
};
export default PerfomanceCard;