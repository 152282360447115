/**
 * @file   src\store\slices\overviewSlice.ts
 * @brief  This file is responsible for overview based slices to call actions and state management.
 * @date   OCTOBER, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { IOverviewSlice } from '../../interfaces/OverviewInterface';
import { listUsersWithToDo, getUserGoalsAndSurveysOverview } from '../actions/overviewAction';

// Initial state
const overviewDefaultState: IOverviewSlice = {
  listUsersWithToDoApiLoading: false,
  listUsersWithToDoApiSuccess: false,
  listUsersWithToDoApiData: {},
  listUsersWithToDoApiResponseCode: 0,
  listUsersWithToDoApiResponseMessage: '',
  getUserGoalsAndSurveysOverviewApiLoading: false,
  getUserGoalsAndSurveysOverviewApiSuccess: false,
  getUserGoalsAndSurveysOverviewApiResponseCode: 0,
  getUserGoalsAndSurveysOverviewApiResponseMessage: '',
  getUserGoalsAndSurveysOverviewApiData: {},
};
// Overview slice
const overviewSlice = createSlice({
  name: 'overview',
  initialState: overviewDefaultState,
  reducers: {},
  extraReducers(builder) {
    // list students api starts
    builder.addCase(listUsersWithToDo.pending, (state) => {
      state.listUsersWithToDoApiLoading = true;
      state.listUsersWithToDoApiSuccess = false;
      state.listUsersWithToDoApiResponseCode = 0;
      state.listUsersWithToDoApiData ={};
    });
    // list students api success
    builder.addCase(listUsersWithToDo.fulfilled, (state, action) => {
      state.listUsersWithToDoApiLoading = false;
      state.listUsersWithToDoApiSuccess = true;
      state.listUsersWithToDoApiResponseCode = action.payload.ResponseCode;
      state.listUsersWithToDoApiData = action.payload.ResponseData;
      state.listUsersWithToDoApiResponseMessage = action.payload.ResponseMessage;
    });
    // list students api failure
    builder.addCase(listUsersWithToDo.rejected, (state, action: any) => {
      state.listUsersWithToDoApiLoading = false;
      state.listUsersWithToDoApiSuccess = false;
      state.listUsersWithToDoApiResponseCode = action.payload.ResponseCode;
      state.listUsersWithToDoApiResponseMessage = action.payload.ResponseMessage;
    });
    // get User Goals And Surveys Overview api starts
    builder.addCase(getUserGoalsAndSurveysOverview.pending, (state) => {
      state.getUserGoalsAndSurveysOverviewApiLoading = true;
      state.getUserGoalsAndSurveysOverviewApiSuccess = false;
      state.getUserGoalsAndSurveysOverviewApiResponseCode = 0;
      state.getUserGoalsAndSurveysOverviewApiData = {};
    });
    // get User Goals And Surveys Overview api success
    builder.addCase(getUserGoalsAndSurveysOverview.fulfilled, (state, action) => {
      state.getUserGoalsAndSurveysOverviewApiLoading = false;
      state.getUserGoalsAndSurveysOverviewApiSuccess = true;
      state.getUserGoalsAndSurveysOverviewApiResponseCode = action.payload.ResponseCode;
      state.getUserGoalsAndSurveysOverviewApiData = action.payload.ResponseData;
      state.getUserGoalsAndSurveysOverviewApiResponseMessage = action.payload.ResponseMessage;
    });
    // get User Goals And Surveys Overview api failure
    builder.addCase(getUserGoalsAndSurveysOverview.rejected, (state, action: any) => {
      state.getUserGoalsAndSurveysOverviewApiLoading = false;
      state.getUserGoalsAndSurveysOverviewApiSuccess = false;
      state.getUserGoalsAndSurveysOverviewApiResponseCode = action.payload.ResponseCode;
      state.getUserGoalsAndSurveysOverviewApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});

// Export reducer.
export default overviewSlice.reducer;
