import React from 'react';
const NotFound = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="73.843" height="70.807" viewBox="0 0 73.843 70.807">
  <g id="_2834777_404_error_icon" data-name="2834777_404_error_icon" transform="translate(0 0)">
    <path id="Path_3" data-name="Path 3" d="M34.523,48.069v3.678h2.76V48.069a.75.75,0,0,1,.75-.75h1.3v-2.51h-1.3a.75.75,0,0,1-.75-.75V36.33h-2.76v7.729a.75.75,0,0,1-.75.75h-4.22a.751.751,0,0,1-.719-.965l6.1-20.337H32L26.044,44.165v3.154h7.729a.75.75,0,0,1,.75.75Z" transform="translate(-13.489 -15.052)" fill="none"/>
    <path id="Path_4" data-name="Path 4" d="M85.832,58.5V20.784A4.236,4.236,0,0,0,81.6,16.552H19.221a4.237,4.237,0,0,0-4.231,4.231V58.5ZM59.8,44.059a.745.745,0,0,1,.029-.208l6.14-21.3a.75.75,0,0,1,.721-.542H71.2a.751.751,0,0,1,.719.965l-6.1,20.337h2.462V35.58a.75.75,0,0,1,.75-.75h4.26a.75.75,0,0,1,.75.75v7.729h1.3a.75.75,0,0,1,.75.75v4.01a.75.75,0,0,1-.75.75h-1.3V52.5a.75.75,0,0,1-.75.75h-4.26a.75.75,0,0,1-.75-.75V48.819H60.547a.75.75,0,0,1-.75-.75ZM43.214,28.9a7.261,7.261,0,0,1,.589-2.979,7.028,7.028,0,0,1,1.565-2.242,6.777,6.777,0,0,1,2.277-1.431,7.572,7.572,0,0,1,5.338,0,6.8,6.8,0,0,1,2.274,1.431,7.071,7.071,0,0,1,1.568,2.242,7.3,7.3,0,0,1,.587,2.98V46.356a7.29,7.29,0,0,1-.586,2.978,7.064,7.064,0,0,1-1.569,2.243,6.79,6.79,0,0,1-2.274,1.432,7.548,7.548,0,0,1-5.339,0,6.784,6.784,0,0,1-2.276-1.432A7.014,7.014,0,0,1,43.8,49.335a7.251,7.251,0,0,1-.589-2.979ZM24.544,44.059a.745.745,0,0,1,.029-.208l6.14-21.3a.75.75,0,0,1,.721-.542h4.512a.751.751,0,0,1,.719.965l-6.1,20.337h2.462V35.58a.75.75,0,0,1,.75-.75h4.26a.75.75,0,0,1,.75.75v7.729h1.3a.75.75,0,0,1,.75.75v4.01a.75.75,0,0,1-.75.75h-1.3V52.5a.75.75,0,0,1-.75.75h-4.26a.75.75,0,0,1-.75-.75V48.819H25.294a.75.75,0,0,1-.75-.75Z" transform="translate(-13.489 -15.052)" fill="none"/>
    <path id="Path_5" data-name="Path 5" d="M33.187,81.165a.505.505,0,0,0-.445.37l-.506,2.727.08.1h36.19l.08-.1-.506-2.727a.506.506,0,0,0-.445-.37H33.187Z" transform="translate(-13.489 -15.052)" fill="none"/>
    <path id="Path_6" data-name="Path 6" d="M69.776,48.069v3.678h2.76V48.069a.75.75,0,0,1,.75-.75h1.3v-2.51h-1.3a.75.75,0,0,1-.75-.75V36.33h-2.76v7.729a.75.75,0,0,1-.75.75h-4.22a.751.751,0,0,1-.719-.965l6.1-20.337H67.251L61.3,44.165v3.154h7.729a.75.75,0,0,1,.75.75Z" transform="translate(-13.489 -15.052)" fill="none"/>
    <path id="Path_7" data-name="Path 7" d="M50.313,47.737a1.255,1.255,0,0,0,.965-.377,1.353,1.353,0,0,0,.375-1V28.9a1.357,1.357,0,0,0-.376-1,1.423,1.423,0,0,0-1.93,0,1.358,1.358,0,0,0-.372,1V46.356a1.352,1.352,0,0,0,.373,1,1.255,1.255,0,0,0,.965.377Z" transform="translate(-13.489 -15.052)" fill="none"/>
    <path id="Path_8" data-name="Path 8" d="M46.4,50.491a5.285,5.285,0,0,0,1.777,1.117,6.072,6.072,0,0,0,4.269,0,5.29,5.29,0,0,0,1.775-1.117,5.556,5.556,0,0,0,1.233-1.769,5.792,5.792,0,0,0,.456-2.366V28.9a5.8,5.8,0,0,0-.457-2.369,5.581,5.581,0,0,0-1.232-1.767,5.3,5.3,0,0,0-1.775-1.116,6.034,6.034,0,0,0-4.268,0A5.29,5.29,0,0,0,46.4,24.762a5.555,5.555,0,0,0-1.23,1.767,5.788,5.788,0,0,0-.458,2.368V46.356a5.778,5.778,0,0,0,.458,2.367A5.528,5.528,0,0,0,46.4,50.491ZM47.476,28.9a2.847,2.847,0,0,1,.794-2.046,2.919,2.919,0,0,1,4.086,0,2.846,2.846,0,0,1,.8,2.048V46.356a2.841,2.841,0,0,1-.8,2.046,2.918,2.918,0,0,1-4.086,0,2.84,2.84,0,0,1-.795-2.046Z" transform="translate(-13.489 -15.052)" fill="none"/>
    <path id="Path_9" data-name="Path 9" d="M14.989,60v1.972A4.236,4.236,0,0,0,19.221,66.2H81.6a4.236,4.236,0,0,0,4.231-4.231V60ZM50.41,64.758A1.656,1.656,0,1,1,52.066,63.1a1.656,1.656,0,0,1-1.656,1.656Z" transform="translate(-13.489 -15.052)" fill="none"/>
    <path id="Path_10" data-name="Path 10" d="M28.262,64.613H45.581L42.758,52.651H31.084Z" fill="none"/>
    <path id="Path_17" data-name="Path 17" d="M.656,0A.656.656,0,1,1,0,.656.656.656,0,0,1,.656,0Z" transform="translate(36.265 47.393)" fill="none"/>
    <path id="Path_11" data-name="Path 11" d="M81.6,15.052H19.221a5.738,5.738,0,0,0-5.731,5.731V61.972A5.738,5.738,0,0,0,19.221,67.7H43.032L40.21,79.665H33.187a2,2,0,0,0-1.92,1.6l-.506,2.727a1.552,1.552,0,0,0,1.555,1.87h36.19a1.552,1.552,0,0,0,1.555-1.87l-.506-2.728a2,2,0,0,0-1.92-1.6H60.611L57.789,67.7H81.6a5.738,5.738,0,0,0,5.731-5.731V20.784A5.738,5.738,0,0,0,81.6,15.052Zm-62.38,1.5H81.6a4.237,4.237,0,0,1,4.231,4.232V58.5H14.989V20.784a4.237,4.237,0,0,1,4.231-4.232ZM67.635,81.165a.506.506,0,0,1,.445.37l.506,2.727-.08.1H32.315l-.08-.1.506-2.727a.505.505,0,0,1,.445-.37H67.635Zm-8.564-1.5H41.751L44.573,67.7H56.247ZM81.6,66.2H19.221a4.236,4.236,0,0,1-4.231-4.231V60H85.832v1.972A4.236,4.236,0,0,1,81.6,66.2Z" transform="translate(-13.489 -15.052)" fill="#9cafce"/>
    <path id="Path_12" data-name="Path 12" d="M50.41,61.445A1.656,1.656,0,1,0,52.066,63.1a1.656,1.656,0,0,0-1.656-1.656Zm0,2.313a.656.656,0,1,1,.656-.656.656.656,0,0,1-.656.656Z" transform="translate(-13.489 -15.052)" fill="#9cafce"/>
    <path id="Path_13" data-name="Path 13" d="M25.294,48.819h7.729V52.5a.75.75,0,0,0,.75.75h4.26a.75.75,0,0,0,.75-.75V48.819h1.3a.75.75,0,0,0,.75-.75v-4.01a.75.75,0,0,0-.75-.75h-1.3V35.58a.75.75,0,0,0-.75-.75h-4.26a.75.75,0,0,0-.75.75v7.729H30.562l6.1-20.337a.751.751,0,0,0-.719-.965H31.434a.75.75,0,0,0-.721.542l-6.14,21.3a.745.745,0,0,0-.029.208v4.01a.75.75,0,0,0,.75.75Zm.75-4.654L32,23.507h2.939l-6.1,20.337a.751.751,0,0,0,.719.965h4.22a.75.75,0,0,0,.75-.75V36.33h2.76v7.729a.75.75,0,0,0,.75.75h1.3v2.51h-1.3a.75.75,0,0,0-.75.75v3.678h-2.76V48.069a.75.75,0,0,0-.75-.75H26.044Z" transform="translate(-13.489 -15.052)" fill="#9cafce"/>
    <path id="Path_14" data-name="Path 14" d="M45.368,51.577a6.784,6.784,0,0,0,2.276,1.432,7.548,7.548,0,0,0,5.339,0,6.79,6.79,0,0,0,2.274-1.432,7.064,7.064,0,0,0,1.569-2.243,7.291,7.291,0,0,0,.586-2.978V28.9a7.3,7.3,0,0,0-.587-2.98,7.07,7.07,0,0,0-1.568-2.242,6.8,6.8,0,0,0-2.274-1.431,7.572,7.572,0,0,0-5.338,0,6.777,6.777,0,0,0-2.277,1.431A7.028,7.028,0,0,0,43.8,25.917a7.261,7.261,0,0,0-.589,2.979V46.356a7.251,7.251,0,0,0,.589,2.979A7.014,7.014,0,0,0,45.368,51.577ZM44.714,28.9a5.788,5.788,0,0,1,.458-2.368,5.555,5.555,0,0,1,1.23-1.767,5.29,5.29,0,0,1,1.778-1.117,6.034,6.034,0,0,1,4.268,0,5.3,5.3,0,0,1,1.775,1.116,5.581,5.581,0,0,1,1.232,1.767,5.8,5.8,0,0,1,.457,2.369V46.356a5.792,5.792,0,0,1-.456,2.366,5.556,5.556,0,0,1-1.233,1.769,5.29,5.29,0,0,1-1.775,1.117,6.072,6.072,0,0,1-4.269,0A5.285,5.285,0,0,1,46.4,50.491a5.528,5.528,0,0,1-1.23-1.767,5.778,5.778,0,0,1-.458-2.367Z" transform="translate(-13.489 -15.052)" fill="#9cafce"/>
    <path id="Path_15" data-name="Path 15" d="M50.313,49.237a2.734,2.734,0,0,0,2.043-.834,2.841,2.841,0,0,0,.8-2.046V28.9a2.846,2.846,0,0,0-.8-2.048,2.919,2.919,0,0,0-4.086,0,2.847,2.847,0,0,0-.794,2.046V46.356a2.84,2.84,0,0,0,.795,2.046,2.734,2.734,0,0,0,2.043.835ZM48.976,28.9a1.358,1.358,0,0,1,.372-1,1.423,1.423,0,0,1,1.93,0,1.357,1.357,0,0,1,.376,1V46.356a1.353,1.353,0,0,1-.375,1,1.422,1.422,0,0,1-1.93,0,1.352,1.352,0,0,1-.373-1Z" transform="translate(-13.489 -15.052)" fill="#9cafce"/>
    <path id="Path_16" data-name="Path 16" d="M60.547,48.819h7.729V52.5a.75.75,0,0,0,.75.75h4.26a.75.75,0,0,0,.75-.75V48.819h1.3a.75.75,0,0,0,.75-.75v-4.01a.75.75,0,0,0-.75-.75h-1.3V35.58a.75.75,0,0,0-.75-.75h-4.26a.75.75,0,0,0-.75.75v7.73H65.814l6.1-20.337a.751.751,0,0,0-.719-.965H66.687a.75.75,0,0,0-.721.542l-6.14,21.3a.745.745,0,0,0-.029.208v4.01A.75.75,0,0,0,60.547,48.819Zm.75-4.654,5.954-20.658H70.19l-6.1,20.337a.751.751,0,0,0,.719.965h4.22a.75.75,0,0,0,.75-.75V36.33h2.76v7.729a.75.75,0,0,0,.75.75h1.3v2.51h-1.3a.75.75,0,0,0-.75.75v3.678h-2.76V48.069a.75.75,0,0,0-.75-.75H61.3Z" transform="translate(-13.489 -15.052)" fill="#9cafce"/>
  </g>
</svg>

    );
}

export default NotFound;
