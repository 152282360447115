/**
 * @file   src\store\slices\SubscriptionPlanSlice.ts
 * @brief  This file is responsible for creating Subscription based slices to call actions and state management.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { createSlice } from '@reduxjs/toolkit';
import {
  getSubscriptionPlanValidity,
  addSubscriptionPlan,
  getSubscriptionPlanList,
  getSubscriptionPlanDetails,
  deleteSubscriptionPlan,
  getSubscribedOrganizations,
  editSubscriptionPlan,
} from '../actions/subscriptionPlanActions';
import { ISubscriptionPlanSlice } from '../../interfaces/SubscriptionPlanInterface';

// Initial state of SubscriptionPlan slice
const SubscriptionPlanDefaultState: ISubscriptionPlanSlice = {
  subscriptionPlanValidityListData: {},
  subscriptionPlanValidityListApiResponseCode: 0,
  subscriptionPlanValidityListApiLoading: false,
  subscriptionPlanValidityListApiSuccess: false,
  subscriptionPlanValidityListApiResponseMessage: '',
  addSubscriptionPlanApiData: {},
  addSubscriptionPlanApiLoading: false,
  addSubscriptionPlanApiResponseCode: 0,
  addSubscriptionPlanApiResponseMessage: '',
  addSubscriptionPlanApiSuccess: false,
  subscriptionPlanListData: {},
  subscriptionPlanListApiLoading: false,
  subscriptionPlanListApiResponseCode: 0,
  subscriptionPlanListApiResponseMessage: '',
  subscriptionPlanListApiSuccess: false,
  subscriptionPlanDetailApiData: {},
  subscriptionPlanDetailApiLoading: false,
  subscriptionPlanDetailApiResponseCode: 0,
  subscriptionPlanDetailApiResponseMessage: '',
  subscriptionPlanDetailApiSuccess: false,
  deletesubscriptionPlanApiData: {},
  deletesubscriptionPlanApiLoading: false,
  deletesubscriptionPlanApiResponseCode: 0,
  deletesubscriptionPlanApiResponseMessage: '',
  deletesubscriptionPlanApiSuccess: false,
  organizationListApiData: {},
  organizationListApiLoading: false,
  organizationListApiResponseCode: 0,
  organizationListApiResponseMessage: '',
  organizationListApiSuccess: false,
  subscriptionPlanUpdateApiData: {},
  subscriptionPlanUpdateApiLoading: false,
  subscriptionPlanUpdateApiSuccess: false,
  subscriptionPlanUpdateApiResponseCode: 0,
  subscriptionPlanUpdateApiResponseMessage: '',
};

// SubscriptionPlan slice
const SubscriptionPlanSlice = createSlice({
  name: 'SubscriptionPlan',
  initialState: SubscriptionPlanDefaultState,
  reducers: {
    resetSubscriptionPlanAdd: (state) => {
      state.addSubscriptionPlanApiData = {};
      state.addSubscriptionPlanApiLoading = false;
      state.addSubscriptionPlanApiResponseCode = 0;
      state.addSubscriptionPlanApiResponseMessage = '';
      state.addSubscriptionPlanApiSuccess = false;
    },
    resetSubscriptionPlanList: (state) => {
      state.subscriptionPlanListData = {};
      state.subscriptionPlanListApiLoading = false;
      state.subscriptionPlanListApiResponseCode = 0;
      state.subscriptionPlanListApiResponseMessage = '';
      state.subscriptionPlanListApiSuccess = false;
    },
    resetSubscriptionPlanDetails: (state) => {
      state.subscriptionPlanDetailApiData = {};
      state.subscriptionPlanDetailApiLoading = false;
      state.subscriptionPlanDetailApiResponseCode = 0;
      state.subscriptionPlanDetailApiResponseMessage = '';
      state.subscriptionPlanDetailApiSuccess = false;
    },
    resetDeleteSubscriptionPlan: (state) => {
      state.deletesubscriptionPlanApiData = {};
      state.deletesubscriptionPlanApiResponseCode = 0;
      state.deletesubscriptionPlanApiResponseMessage = '';
      state.deletesubscriptionPlanApiLoading = false;
      state.deletesubscriptionPlanApiSuccess = false;
    },
    resetSubscriptionPlanUpdate: (state) => {
      state.subscriptionPlanUpdateApiData = {};
      state.subscriptionPlanUpdateApiLoading = false;
      state.subscriptionPlanUpdateApiSuccess = false;
      state.subscriptionPlanUpdateApiResponseCode = 0;
      state.subscriptionPlanUpdateApiResponseMessage = '';
    },
    resetSubscriptionPlanOrganizationList: (state) => {
      state.organizationListApiData = {};
      state.organizationListApiLoading = false;
      state.organizationListApiSuccess = false;
      state.organizationListApiResponseCode = 0;
      state.organizationListApiResponseMessage = '';
    },
  },
  extraReducers(builder) {
    // Top Subscription Plan validity api starts
    builder.addCase(getSubscriptionPlanValidity.pending, (state) => {
      state.subscriptionPlanValidityListApiLoading = true;
      state.subscriptionPlanValidityListApiSuccess = false;
      state.subscriptionPlanValidityListApiResponseCode = 0;
    });
    // Top Subscription Plan validity  api success
    builder.addCase(getSubscriptionPlanValidity.fulfilled, (state, action) => {
      state.subscriptionPlanValidityListApiLoading = false;
      state.subscriptionPlanValidityListApiSuccess = true;
      state.subscriptionPlanValidityListApiResponseCode = action.payload.ResponseCode;
      state.subscriptionPlanValidityListData = action.payload.ResponseData;
      state.subscriptionPlanValidityListApiResponseMessage = action.payload.ResponseMessage;
    });
    // Top Subscription Plan validity  api failure
    builder.addCase(getSubscriptionPlanValidity.rejected, (state, action: any) => {
      state.subscriptionPlanValidityListApiLoading = false;
      state.subscriptionPlanValidityListApiSuccess = false;
      state.subscriptionPlanValidityListApiResponseCode = action.payload.ResponseCode;
      state.subscriptionPlanValidityListApiResponseMessage = action.payload.ResponseMessage;
    });

    //  Subscription Plan add  api starts
    builder.addCase(addSubscriptionPlan.pending, (state) => {
      state.addSubscriptionPlanApiLoading = true;
      state.addSubscriptionPlanApiSuccess = false;
      state.addSubscriptionPlanApiResponseCode = 0;
    });
    //  Subscription Plan add api success
    builder.addCase(addSubscriptionPlan.fulfilled, (state, action) => {
      state.addSubscriptionPlanApiLoading = false;
      state.addSubscriptionPlanApiSuccess = true;
      state.addSubscriptionPlanApiResponseCode = action.payload.ResponseCode;
      state.addSubscriptionPlanApiData = action.payload.ResponseData;
      state.addSubscriptionPlanApiResponseMessage = action.payload.ResponseMessage;
    });
    //  Subscription Plan add api failure
    builder.addCase(addSubscriptionPlan.rejected, (state, action: any) => {
      state.addSubscriptionPlanApiLoading = false;
      state.addSubscriptionPlanApiSuccess = false;
      state.addSubscriptionPlanApiResponseCode = action.payload.ResponseCode;
      state.addSubscriptionPlanApiResponseMessage = action.payload.ResponseMessage;
    });

    //  Subscription Plan list  api starts
    builder.addCase(getSubscriptionPlanList.pending, (state) => {
      state.subscriptionPlanListApiLoading = true;
      state.subscriptionPlanListApiSuccess = false;
      state.subscriptionPlanListApiResponseCode = 0;
    });
    //  Subscription Plan list api success
    builder.addCase(getSubscriptionPlanList.fulfilled, (state, action) => {
      state.subscriptionPlanListApiLoading = false;
      state.subscriptionPlanListApiSuccess = true;
      state.subscriptionPlanListApiResponseCode = action.payload.ResponseCode;
      state.subscriptionPlanListData = action.payload.ResponseData;
      state.subscriptionPlanListApiResponseMessage = action.payload.ResponseMessage;
    });
    //  Subscription Plan list api failure
    builder.addCase(getSubscriptionPlanList.rejected, (state, action: any) => {
      state.subscriptionPlanListApiLoading = false;
      state.subscriptionPlanListApiSuccess = false;
      state.subscriptionPlanListApiResponseCode = action.payload.ResponseCode;
      state.subscriptionPlanListApiResponseMessage = action.payload.ResponseMessage;
    });

    //  Subscription Plan details  api starts
    builder.addCase(getSubscriptionPlanDetails.pending, (state) => {
      state.subscriptionPlanDetailApiLoading = true;
      state.subscriptionPlanDetailApiSuccess = false;
      state.subscriptionPlanDetailApiResponseCode = 0;
    });
    //  Subscription Plan details api success
    builder.addCase(getSubscriptionPlanDetails.fulfilled, (state, action) => {
      state.subscriptionPlanDetailApiLoading = false;
      state.subscriptionPlanDetailApiSuccess = true;
      state.subscriptionPlanDetailApiResponseCode = action.payload.ResponseCode;
      state.subscriptionPlanDetailApiData = action.payload.ResponseData;
      state.subscriptionPlanDetailApiResponseMessage = action.payload.ResponseMessage;
    });
    //  Subscription Plan details api failure
    builder.addCase(getSubscriptionPlanDetails.rejected, (state, action: any) => {
      state.subscriptionPlanDetailApiLoading = false;
      state.subscriptionPlanDetailApiSuccess = false;
      state.subscriptionPlanDetailApiResponseCode = action.payload.ResponseCode;
      state.subscriptionPlanDetailApiResponseMessage = action.payload.ResponseMessage;
    });
    //  Subscription Plan delete  api starts
    builder.addCase(deleteSubscriptionPlan.pending, (state) => {
      state.deletesubscriptionPlanApiLoading = true;
      state.deletesubscriptionPlanApiSuccess = false;
      state.deletesubscriptionPlanApiResponseCode = 0;
    });
    //  Subscription Plan delete api success
    builder.addCase(deleteSubscriptionPlan.fulfilled, (state, action) => {
      state.deletesubscriptionPlanApiLoading = false;
      state.deletesubscriptionPlanApiSuccess = true;
      state.deletesubscriptionPlanApiResponseCode = action.payload.ResponseCode;
      state.deletesubscriptionPlanApiData = action.payload.ResponseData;
      state.deletesubscriptionPlanApiResponseMessage = action.payload.ResponseMessage;
    });
    //  Subscription Plan delete api failure
    builder.addCase(deleteSubscriptionPlan.rejected, (state, action: any) => {
      state.deletesubscriptionPlanApiLoading = false;
      state.deletesubscriptionPlanApiSuccess = false;
      state.deletesubscriptionPlanApiResponseCode = action.payload.ResponseCode;
      state.deletesubscriptionPlanApiResponseMessage = action.payload.ResponseMessage;
    });

    //  Subscribed organizations list  api starts
    builder.addCase(getSubscribedOrganizations.pending, (state) => {
      state.organizationListApiLoading = true;
      state.organizationListApiSuccess = false;
      state.organizationListApiResponseCode = 0;
    });
    //  Subscribed organizations list  api success
    builder.addCase(getSubscribedOrganizations.fulfilled, (state, action) => {
      state.organizationListApiLoading = false;
      state.organizationListApiSuccess = true;
      state.organizationListApiResponseCode = action.payload.ResponseCode;
      state.organizationListApiData = action.payload.ResponseData;
      state.organizationListApiResponseMessage = action.payload.ResponseMessage;
    });
    //  Subscribed organizations list  api failure
    builder.addCase(getSubscribedOrganizations.rejected, (state, action: any) => {
      state.organizationListApiLoading = false;
      state.organizationListApiSuccess = false;
      state.organizationListApiResponseCode = action.payload.ResponseCode;
      state.organizationListApiResponseMessage = action.payload.ResponseMessage;
    });

    //  edit subscription plan api starts
    builder.addCase(editSubscriptionPlan.pending, (state) => {
      state.subscriptionPlanUpdateApiLoading = true;
      state.subscriptionPlanUpdateApiSuccess = false;
      state.subscriptionPlanUpdateApiResponseCode = 0;
    });
    //  edit subscription plan   api success
    builder.addCase(editSubscriptionPlan.fulfilled, (state, action) => {
      state.subscriptionPlanUpdateApiLoading = false;
      state.subscriptionPlanUpdateApiSuccess = true;
      state.subscriptionPlanUpdateApiResponseCode = action.payload.ResponseCode;
      state.organizationListApiData = action.payload.ResponseData;
      state.subscriptionPlanUpdateApiResponseMessage = action.payload.ResponseMessage;
    });
    //  edit subscription plan  api failure
    builder.addCase(editSubscriptionPlan.rejected, (state, action: any) => {
      state.subscriptionPlanUpdateApiLoading = false;
      state.subscriptionPlanUpdateApiSuccess = false;
      state.subscriptionPlanUpdateApiResponseCode = action.payload.ResponseCode;
      state.subscriptionPlanUpdateApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});
// Export actions
export const { resetSubscriptionPlanAdd, resetSubscriptionPlanList, resetSubscriptionPlanDetails, resetDeleteSubscriptionPlan, resetSubscriptionPlanUpdate,resetSubscriptionPlanOrganizationList } =
  SubscriptionPlanSlice.actions;
// Export reducer.
export default SubscriptionPlanSlice.reducer;
