import React from 'react'
import Select from 'react-select';
import { Form } from "react-bootstrap";
import '../assets/css/Input.scss'

const MASelect = (props:any) => {
  const error= props.error
  return (
    <div className='cut-select-label'>
      <div className='form-label'>{props.label}</div>
      <Select
        classNamePrefix='filter'
        name={props.name}
        placeholder={props.placeholder}
        // styles={colourStyles}
        value={props.value}
        onChange={props.onChange}
        isSearchable={true}
        options={props.options}
        isDisabled={props.isDisabled}
        isMulti={props.isMulti}
        menuIsOpen={props.menuIsOpen}
         />
        {error &&
            error !== "" && (
              <div>
              <Form.Text className="error">
                {error}
              </Form.Text>
              </div>
            ) }
    </div>
  )
};

export default MASelect;

