import React from "react";
import '../assets/css/PerfomanceCard.scss'
import { ProgressBar } from 'react-bootstrap';

const PerfomanceCard = (props: any) => {
    return (
        <div className="card-progress-main">
            <div className="card-progress">
                <div className="perfomance-perc">{props.percentage}%</div>
                <ProgressBar now={props.percentage} />
            </div>
            <label>{props.label}</label>
        </div>
    );
}
export default PerfomanceCard;