import React from 'react';

const NotificationIcon = () => {
    return (
        <svg width="16" height="20.379" viewBox="0 0 16 20.379">
            <defs>
                <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#fad900" />
                    <stop offset="1" stop-color="#f5933d" />
                </linearGradient>
                <linearGradient id="linear-gradient-2" x1="0.5" y1="-0.752" x2="1.313" y2="2.19" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#ffa50a" />
                    <stop offset="1" stop-color="#685700" />
                </linearGradient>
            </defs>
            <g id="Icn_Notifications" transform="translate(-331.781 -47.537)">
                <g id="Group_12081" data-name="Group 12081" transform="translate(331.781 47.537)">
                    <g id="Group_12082" data-name="Group 12082" transform="translate(0 0)">
                        <path id="Path_10314" data-name="Path 10314" d="M14.371,6.965A6.688,6.688,0,0,0,8,0,6.688,6.688,0,0,0,1.627,6.965,13.071,13.071,0,0,1,.162,13.78a1.719,1.719,0,0,0,1.574,2.441H14.264a1.719,1.719,0,0,0,1.574-2.441,13.057,13.057,0,0,1-1.466-6.815" transform="translate(0 0)" fill="url(#linear-gradient)" />
                        <path id="Path_10315" data-name="Path 10315" d="M3.947,14.466a3.38,3.38,0,0,0,6.748,0Z" transform="translate(0.679 2.734)" fill="url(#linear-gradient-2)" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default NotificationIcon;
