import React from 'react';
const GreenTickIcon = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="check_circle_FILL1_wght400_GRAD0_opsz48" d="M88.525-865.45l7.075-7.075-1.15-1.125-5.925,5.925-3-3L84.4-869.6ZM90-860a9.677,9.677,0,0,1-3.875-.787,10.144,10.144,0,0,1-3.187-2.15,10.143,10.143,0,0,1-2.15-3.187A9.676,9.676,0,0,1,80-870a9.738,9.738,0,0,1,.787-3.9,9.995,9.995,0,0,1,2.15-3.175,10.252,10.252,0,0,1,3.188-2.138A9.677,9.677,0,0,1,90-880a9.738,9.738,0,0,1,3.9.787,10.1,10.1,0,0,1,3.175,2.138,10.1,10.1,0,0,1,2.138,3.175A9.738,9.738,0,0,1,100-870a9.676,9.676,0,0,1-.787,3.875,10.251,10.251,0,0,1-2.138,3.188,9.994,9.994,0,0,1-3.175,2.15A9.738,9.738,0,0,1,90-860Z" transform="translate(-80 880)" fill="#7eea1e" />
        </svg>
    );
}

export default GreenTickIcon;
