import React from 'react';
const PendingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="18.667" viewBox="0 0 2 18.667">
            <path id="exclamation_FILL0_wght400_GRAD0_opsz48" d="M450,308.667V296h2v12.667Zm0,6v-2h2v2Z" transform="translate(-450 -296)" fill="#faaf00" />
        </svg>
    );
}

export default PendingIcon;
