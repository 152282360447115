/**
 * @file   src\containers\organizations\Org-type.tsx
 * @brief  Organization type listing page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, useIntlActionMessages } from '../../utils/helper';
import { Button, Col, Breadcrumb, Table } from 'react-bootstrap';
import Search from '../../components/MASearch';
import Pagination from '../../components/Pagination';
import ViewIcon from '../../assets/img/icon/View';
import { Link, useNavigate } from 'react-router-dom';
import Sort from '../../assets/img/icon/Sort';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getOrganizationTypeList } from "../../store/actions/organizationTypeActions";
import { RootState } from '../../store';
import useDebounce from '../../hooks/useDebounce';
import {
  PAGE_SIZE,
  DEFAULT_PAGE_INDEX
} from '../../utils/constants';
import { OrganizationTypeSortField, SortOrderType } from '../../utils/enums';
import Loader from '../../components/Loader';

const organizationTypeListAPIDefaultParams = {
  "Page": DEFAULT_PAGE_INDEX,
  "PageSize": PAGE_SIZE,
  "OrganizationTypeName": "",
  "SortColumn": OrganizationTypeSortField.ORG_TYPE_NAME,
  "SortOrder": SortOrderType.ASC
}

const OrganizationTypeList = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { organizationTypeList,
    organizationTypeListApiLoading
  } = useAppSelector((state: RootState) => state.organizationType);

  // Initialize component state variables.
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [searchText, setSearchText] = useState<string>('');
  const [sortField, setSortField] = useState<string>(OrganizationTypeSortField.ORG_TYPE_NAME);
  const [sortOrder, setSortOrder] = useState<string>(SortOrderType.ASC);
  const [organizationTypeListApiParams, setOrganizationTypeListApiParams] = useState<any>(organizationTypeListAPIDefaultParams);
  const labelNoData = useIntlActionMessages('Label.Nodata');

  // Invoke debounce component on intervel.
  const debouncedSearch = useDebounce(searchText, 500)

  // Call list apis on initial loading.
  useEffect(() => {
    dispatch(getOrganizationTypeList(organizationTypeListAPIDefaultParams));
    window.scrollTo(0, 0);
  }, []);

  // Set API params on bebounced search.
  useEffect(() => {
    setOrganizationTypeListApiParams((apiParams: any) => ({
      ...apiParams,
      "OrganizationTypeName": searchText,
      "Page": DEFAULT_PAGE_INDEX
    }));
    setCurrentPage(DEFAULT_PAGE_INDEX);
  }, [debouncedSearch]);

  // Get organization type list on filter change.
  useEffect(() => {
    dispatch(getOrganizationTypeList(organizationTypeListApiParams));
  }, [organizationTypeListApiParams]);

  // Set API params on filter change.
  useEffect(() => {
    setOrganizationTypeListApiParams((apiParams: any) => ({
      ...apiParams,
      "Page": currentPage,
      "SortColumn": sortField,
      "SortOrder": sortOrder
    }));
  }, [currentPage, sortField, sortOrder]);

  // Sort header change event.
  const changeSortField = (field: string) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // Search field change event.
  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };

  // Handle Search Click.
  const handleSearchClick = () => {
    setOrganizationTypeListApiParams((apiParams: any) => ({
      ...apiParams,
      "OrganizationTypeName": searchText,
      "Page": DEFAULT_PAGE_INDEX
    }));
    setCurrentPage(DEFAULT_PAGE_INDEX);
  };

  // Handle Search Field input key down.
  const handleSearchKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setOrganizationTypeListApiParams((apiParams: any) => ({
        ...apiParams,
        "OrganizationTypeName": searchText,
        "Page": DEFAULT_PAGE_INDEX
      }));
      setCurrentPage(DEFAULT_PAGE_INDEX);
    }
    else {
      setSearchText(event.target.value);
    }
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Label.OrgType" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
              <FormattedMessage id="Hd.ManageOrganizations" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Label.ViewOrgType" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="content-sub">
        <div className="content-sub-header header-light-bg d-flex justify-content-between align-items-center">
          <Col lg={3} className="form-sm">
            <Search
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
              onClick={handleSearchClick}
              placeholder={useIntlMessages('PH.SearchOrganizationType')} size="sm" />
          </Col>
          <Col md="auto" className="btn-container">
            <Button variant="primary" size="sm" onClick={() => navigate('/addorganizationtype')}>
              <FormattedMessage id="Button.AddOrganizationType" />
            </Button>
          </Col>
        </div>
        <div className="content-area-padding">
          <Table striped hover responsive>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="Label.OrgType" />
                  <Link to="" className="ms-1" onClick={() => changeSortField(OrganizationTypeSortField.ORG_TYPE_NAME)}>
                    <Sort />
                  </Link>
                </th>
                <th>
                  <FormattedMessage id="Label.NumberofOrganizations" />
                  <Link to="" className="ms-1" onClick={() => changeSortField(OrganizationTypeSortField.ORG_TYPE_ORGANIZATIONS)}>
                    <Sort />
                  </Link>
                </th>
                <th>
                  <FormattedMessage id="Label.NumberofAppUsers" />
                  <Link to="" className="ms-1" onClick={() => changeSortField(OrganizationTypeSortField.ORG_TYPE_APP_USERS)}>
                    <Sort />
                  </Link>
                </th>
                <th>
                  <FormattedMessage id="Label.NumberofWebUsers" />
                  <Link to="" className="ms-1" onClick={() => changeSortField(OrganizationTypeSortField.ORG_TYPE_WEBSITE_USERS)}>
                    <Sort />
                  </Link>
                </th>
                <th className="w-50px"></th>
              </tr>
            </thead>
            <tbody>
              {organizationTypeList && organizationTypeList.organizationTypes &&
                organizationTypeList.organizationTypes.length > 0 &&
                organizationTypeList.organizationTypes.map((organizationType: any) => (
                  <tr key={organizationType.organizationtypeid}>
                    <td>{organizationType.organizationtype}</td>
                    <td>{organizationType.NumberofOrganizations}</td>
                    <td>{organizationType.NumberofAppUsers}</td>
                    <td>{organizationType.NumberofWebsiteUsers}</td>
                    <td>
                      <Button variant="secondary" size="sm" className="icon-btn" onClick={() => navigate(`/orgtypedetails/${organizationType.organizationtypeid}`)}>
                        <ViewIcon />
                      </Button>
                    </td>
                  </tr>))}
              {(!organizationTypeList || (organizationTypeList && !organizationTypeList.organizationTypes &&
                <tr>
                  <td colSpan={5}>
                    <div className="content-sub content-area-padding border-top text-center">
                      <h6>{labelNoData}</h6>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {organizationTypeList && organizationTypeList.organizationTypes &&
            organizationTypeList.organizationTypes.length > 0 && organizationTypeList.totalCount > PAGE_SIZE && (
              <Pagination pageSize={PAGE_SIZE} totalitems={organizationTypeList.totalCount} pageLimit={PAGE_SIZE}
                setCurrentPage={(page: number) => setCurrentPage(page)}
                currentPage={currentPage - 1}
                prevPage={-1}
                itemsDisplayed={organizationTypeList?.organizationTypes?.length}
              />
            )}
        </div>
      </div>
      {organizationTypeListApiLoading && <Loader />}
    </>
  );
};
export default OrganizationTypeList;
