import React from 'react';

const SettingsIcon = () => {
    return (
        <svg width="22.312" height="22" viewBox="0 0 22.312 22">
            <defs>
                <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#fad900" />
                    <stop offset="1" stop-color="#f5933d" />
                </linearGradient>
            </defs>
            <path id="Icn_Settings" d="M88.085-859l-.525-3.307a7.038,7.038,0,0,1-1.05-.5,7.214,7.214,0,0,1-.971-.656l-3.1,1.418L80-866.35l2.835-2.074a3.12,3.12,0,0,1-.066-.538q-.013-.3-.013-.538t.013-.538a3.118,3.118,0,0,1,.066-.538L80-872.65l2.441-4.3,3.1,1.418a7.208,7.208,0,0,1,.971-.656,5.434,5.434,0,0,1,1.05-.473L88.085-880h4.83l.525,3.307a8.521,8.521,0,0,1,1.063.486,4.518,4.518,0,0,1,.958.669l3.1-1.418,2.441,4.3-2.835,2.021a3.7,3.7,0,0,1,.066.564q.013.3.013.564t-.013.551a3.652,3.652,0,0,1-.066.551L101-866.35l-2.441,4.3-3.1-1.418a8.72,8.72,0,0,1-.958.669,4.574,4.574,0,0,1-1.063.486L92.915-859Zm2.415-7.088a3.289,3.289,0,0,0,2.415-1,3.289,3.289,0,0,0,1-2.415,3.29,3.29,0,0,0-1-2.415,3.289,3.289,0,0,0-2.415-1,3.289,3.289,0,0,0-2.415,1,3.29,3.29,0,0,0-1,2.415,3.289,3.289,0,0,0,1,2.415A3.289,3.289,0,0,0,90.5-866.088Z" transform="translate(-79.345 880.5)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill="url(#linear-gradient)" />
        </svg>
    );
}

export default SettingsIcon;
