/**
 * @file   src\containers\competencies\List.tsx
 * @brief  List Competencies.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect } from 'react';
import '../../assets/css/Competencies.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlActionMessages, useIntlMessages, isUserAdmin, getFromLocalStorage, getCurrentOrgDetails, isAdminTeacherManager, isUserSystemAdmin } from '../../utils/helper';
import { Col, Row, Button, Accordion } from 'react-bootstrap';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Select from '../../components/MASelect';
import CompetenciesCard from '../../components/CompetenciesCard';
import ListCard from '../../components/OrgListCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loader from '../../components/Loader';
import Edit from '../../assets/img/icon/Edit';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCompetenciesList, getTopCompetenciesList, deleteCompetency, listGroupByType } from '../../store/actions/competencyActions';
import { PAGE_SIZE, DEFAULT_PAGE_INDEX, BUCKET_URL } from '../../utils/constants';
import Pagination from '../../components/Pagination';
import { ISelectOptionsNumber, ISelectOption } from '../../interfaces/GeneralInterface';
import Default from '../../assets/img/default.jpg';
import DatePicker from 'react-datepicker';
import Delete from '../../assets/img/icon/Delete';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import { getOrganizationTypeDropDownList, getOrganizationNameByOrgType } from '../../store/actions/organizationTypeActions';
import { IDeleteCompetencyApiParams } from '../../interfaces/CompetencyInterface';
import { MessageToaster } from '../../utils/ToastUtil';
import {
  resetDeleteCompetency,
  resetCompetenciesList,
  resetTopCompetenciesList,
  resetGroupByTypeList,
  resetSaveGroupByType,
  resetDeleteGroupByType,
} from '../../store/slices/competencySlice';
import { getUserTagsByOrgType } from '../../store/actions/organizationTypeActions';
import Checkbox from '../../components/MACheck';
import { IGroupRequestParams } from '../../interfaces/ResourceInterface';
import ManageGroups from '../../components/ManageGroups';
import { GroupType } from '../../utils/enums';
import { getSystemAdminPermissions } from '../../store/actions/settingsAction';

// Declare default params  for listing
const defaultApiParams = {
  DateRange: '',
  OrganizationID: '',
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
  StartDate: '',
  EndDate: '',
};
// Declare default params for listing top 6 competencies
const defaultTopListApiParams = {
  DateRange: '',
  OrganizationID: '',
  TagOptions: [],
  StartDate: '',
  EndDate: '',
};
// Filter params
const competencyListDefaultFilters = {
  Tags: [],
};
// Declare default params for group listing
const defaultParamsListGroups: IGroupRequestParams = {
  Page: 0,
  PageSize: 0,
  Type: 1,
  SearchText: '',
  PageSource: '',
};
// Group filter params
const competencyGroupListDefaultFilters = {
  GroupsIds: [],
  IsGeneral: 'false',
};
const CompetenciesList = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Message toaster object creation
  const toastObj = new MessageToaster();

  // Access redux state variables.
  const {
    competenciesListData,
    competenciesListApiSuccess,
    competenciesListApiLoading,
    competenciesTopListData,
    competenciesTopListApiSuccess,
    deleteCompetencyApiSuccess,
    deleteCompetencyApiResponseMessage,
    deleteCompetencyApiLoading,
    listGroupData,
    listGroupApiLoading,
  } = useAppSelector((state: RootState) => state.competency);

  const { organizationTypeDropDownData, organizationTypeDropDownSuccess, organizationTypeDropDownLoading, orgnameByOrgTypeData, orgnameByOrgTypeLoading, rolesByOrgTypeData } =
    useAppSelector((state: RootState) => state.organizationType);
  const { tagsByOrgTypeData, tagsByOrgTypeLoading } = useAppSelector((state: RootState) => state.organizationType);
  const { getSystemAdminPermissionsApiLoading, getSystemAdminPermissionsApiSuccess, getSystemAdminPermissionsApiData } = useAppSelector((state: RootState) => state.settings);

  // Declare constants for date filter
  const selectDays = [
    { label: 'Last 7 days', value: 'Last_7_days' },
    { label: 'Last 30 days', value: 'Last_30_days' },
    { label: 'Last 6 months', value: 'Last_6_month' },
    { label: 'Last 1 year', value: 'Last_1_year' },
    { label: 'Custom Range', value: 'Custom_Range' },
  ];
  // Handle top 6 tile style
  const tileClasses = ['dash-tile students', 'dash-tile accountability', 'dash-tile teachers', 'dash-tile managers', 'dash-tile admins', 'dash-tile greentile'];

  // Initialize component state variables.
  const NodataText = useIntlActionMessages('Label.Nodata');
  const [dateFilter, setDateFilter] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [apiParams, setApiParams] = useState<any>(defaultApiParams);
  const [apiTopListParams, setTopListApiParams] = useState<any>(defaultTopListApiParams);
  const [competencyListFilters, setCompetencyListFilters] = useState<any>(competencyListDefaultFilters);
  const [competencyGroupListFilters, setCompetencyGroupListFilters] = useState<any>(competencyGroupListDefaultFilters);
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<Array<any>>([]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOptionsNumber | null>();
  const [organisations, setOrganisations] = useState(orgnameByOrgTypeData);
  const [selectedOrganization, setSelectedOrganization] = useState<Array<any>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const [selectedCompetencyId, setSelectedCompetencyId] = useState<string | null>(null);
  const [selectedOrganizationID, setSelectedOrganizationID] = useState<any>(null);
  const [selectedTag, setSelectedTag] = useState<ISelectOptionsNumber[]>([]);
  const [tagList, setTagList] = useState<ISelectOptionsNumber[]>([]);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [isGroupFilterApplied, setIsGroupFilterApplied] = useState<boolean>(false);
  const [isOpenOverviewFilter, setIsOpenCompetencyFilter] = useState<boolean>(false);
  const [isOpenOverviewGroupFilter, setIsOpenCompetencyGroupFilter] = useState<boolean>(false);
  const [groupOptions, setGroupOptions] = useState<ISelectOption[]>([]);
  const [selectedGroups, setSelectedGroup] = useState<ISelectOption[]>([]);
  // const [isGeneral, setIsGeneral] = useState<boolean>(false);
  const [isGeneral, setIsGeneral] = useState<string | null>(null);
  const [hasAddNewCompetencyPermissionSystemAdmin, setHasAddNewCompetencyPermissionSystemAdmin] = useState<boolean>(false);
  const [isManageGroupsFilterOpen, setIsManageGroupsFilterOpen] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<{ content: string; onConfirm: () => void } | null>(null);
  const DeleteCompetencyError = useIntlActionMessages('Text.DeleteCompetencyError');
  const EditCompetencyError = useIntlActionMessages('Text.EditCompetencyError');

  // Get organization details based roles
  useEffect(() => {
    const userData = getFromLocalStorage('MI_USR_DATA');
    const OrganizationId = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : userData.OrganizationId;
    if (isAdminTeacherManager()) {
      setApiParams((apiParams: any) => ({
        ...apiParams,
        OrganizationID: OrganizationId,
      }));
      setTopListApiParams((prev: any) => ({
        ...prev,
        OrganizationID: OrganizationId,
      }));
    }
  }, [localStorage.getItem('CURRENT_ORG'), deleteCompetencyApiLoading]);
  // Reset the form after listing competency
  useEffect(() => {
    if (isUserSystemAdmin()) {
      const userData = getFromLocalStorage('MI_USR_DATA');
      dispatch(
        getSystemAdminPermissions({
          OrgTypeID: null,
          SysAdminID: userData.UserId,
        }),
      );
    }
    window.scrollTo(0, 0);
    // dispatch(listGroupByType(defaultParamsListGroups));
    return () => {
      dispatch(resetCompetenciesList());
      dispatch(resetTopCompetenciesList());
      dispatch(resetSaveGroupByType());
      dispatch(resetGroupByTypeList());
      dispatch(resetDeleteGroupByType());
    };
  }, []);

  // Set system admin permission
  useEffect(() => {
    if (isUserSystemAdmin()) {
      if (getSystemAdminPermissionsApiSuccess) {
        const hasAddNewCompetencyPermission = getSystemAdminPermissionsApiData[0]?.has_permission_to_add_new_competencies;
        setHasAddNewCompetencyPermissionSystemAdmin(hasAddNewCompetencyPermission);
      }
    }
  }, [getSystemAdminPermissionsApiLoading]);

  // Handle competency group list
  useEffect(() => {
    if (isManageGroupsFilterOpen === false) {
      dispatch(listGroupByType(defaultParamsListGroups));
    }
  }, [isManageGroupsFilterOpen]);

  // Works on initial rendering
  useEffect(() => {
    if (apiParams.OrganizationID || isUserAdmin()) {
      dispatch(getOrganizationTypeDropDownList({}));
      dispatch(getCompetenciesList(apiParams));
      dispatch(getTopCompetenciesList(apiTopListParams));
    }
  }, [apiParams, apiTopListParams, deleteCompetencyApiLoading]);
  // Handle pagination
  useEffect(() => {
    setApiParams((apiParams: any) => ({
      ...apiParams,
      Page: currentPage,
    }));
  }, [currentPage]);

  // Populate organization details when coming from organization page
  useEffect(() => {
    if (location?.state) {
      const { organizationId, OrganizationTypeId, OrganizationType } = location.state;
      if (OrganizationTypeId) {
        setOrganizationTypeValue({ label: OrganizationType, value: OrganizationTypeId });
      }
      if (organizationId) {
        dispatch(getOrganizationNameByOrgType({ OrganizationTypeId: parseInt(OrganizationTypeId) }));
      }
    }
  }, [location?.state]);
  // updating organization data based on loaded organization types
  useEffect(() => {
    if (location?.state && orgnameByOrgTypeData?.length > 0) {
      const { organizationId, OrganizationTypeId, OrganizationType } = location.state;
      setOrganizationTypeValue({ label: OrganizationType, value: OrganizationTypeId });
      const matchedOrganization = orgnameByOrgTypeData.find((org: any) => org.ID === organizationId);
      if (matchedOrganization) {
        setSelectedOrganization([matchedOrganization]);
        setTopListApiParams((prev: any) => ({
          ...prev,
          OrganizationID: organizationId.toString(),
        }));
        setApiParams((prev: any) => ({
          ...prev,
          OrganizationID: organizationId.toString(),
          Page: DEFAULT_PAGE_INDEX,
        }));
      }
    }
  }, [location?.state, orgnameByOrgTypeData]);
  //  setting default organization
  useEffect(() => {
    if (!location?.state && orgnameByOrgTypeData?.length > 0) {
      const defaultOrganization = orgnameByOrgTypeData[0];
      setSelectedOrganization([defaultOrganization]);
      setTopListApiParams((prev: any) => ({
        ...prev,
        OrganizationID: defaultOrganization.ID.toString(),
      }));
      setApiParams((prev: any) => ({
        ...prev,
        OrganizationID: defaultOrganization.ID.toString(),
        Page: DEFAULT_PAGE_INDEX,
      }));
    }
  }, [orgnameByOrgTypeData]);

  // Fetch organizations type details
  useEffect(() => {
    if (organizationTypeDropDownSuccess && organizationTypeDropDownData?.length > 0) {
      const orgTypeOptions = organizationTypeDropDownData.map(
        (orgtype: any): ISelectOptionsNumber => ({
          label: orgtype.orgTypeName,
          value: orgtype.orgTypeId,
        }),
      );
      setOrganizationTypeOptions([...orgTypeOptions]);
    }
  }, [organizationTypeDropDownLoading]);
  // Fetch organizations based on org type id
  useEffect(() => {
    if (orgnameByOrgTypeData && orgnameByOrgTypeData?.length > 0) {
      const updatedOrgs = orgnameByOrgTypeData.map((org: any, index: any) => ({
        ...org,
        selected: index === 0,
      }));
      setOrganisations(updatedOrgs);
    }
  }, [orgnameByOrgTypeData]);
  // Reset selected values when the route changes
  useEffect(() => {
    setOrganizationTypeValue(null);
    setSelectedOrganization([]);
    setDateFilter([]);
    if (isUserAdmin()) {
      setApiParams({
        ...apiParams,
        DateRange: '',
        OrganizationID: '',
        Page: DEFAULT_PAGE_INDEX,
      });
      setTopListApiParams({
        ...apiParams,
        DateRange: '',
        OrganizationID: '',
      });
    }
  }, [location]);
  useEffect(() => {
    if (organisations.length > 0) {
      const defaultOrg = organisations[0];
      const organizationID = defaultOrg.ID;
      dispatch(
        getUserTagsByOrgType({
          OrganizationID: organizationID,
          searchText: '',
        }),
      );
      if (location?.state?.selectedTags?.length > 0) {
        setSelectedTag(location.state.selectedTags);
        setIsFilterApplied(true);
      }
      const tags = location?.state?.selectedTags?.map((tag: any) => tag?.value);
      setApiParams((apiParams: any) => ({
        ...apiParams,
        Tags: tags,
      }));
      setTopListApiParams((apiTopParams: any) => ({
        ...apiTopParams,
        TagOptions: tags,
      }));
    }
  }, [organisations]);
  // Get tags by organization
  useEffect(() => {
    if (tagsByOrgTypeData && tagsByOrgTypeData.Tags) {
      const options = tagsByOrgTypeData.Tags.map(
        (tag: string): ISelectOption => ({
          value: tag,
          label: tag.charAt(0).toUpperCase() + tag.slice(1),
        }),
      );
      setTagList(options);
    } else {
      setTagList([]);
    }
  }, [tagsByOrgTypeLoading]);

  // Get competency group
  useEffect(() => {
    if (isManageGroupsFilterOpen === false) {
      if (listGroupData && listGroupData.Groups) {
        const options = listGroupData.Groups.map(
          (group: any): ISelectOption => ({
            value: group.ID,
            label: group.Name.charAt(0).toUpperCase() + group.Name.slice(1),
          }),
        );
        setGroupOptions(options);
      } else {
        setGroupOptions([]);
      }
    }
  }, [listGroupApiLoading]);

  // show  delete competency api success message and error message
  useEffect(() => {
    if (deleteCompetencyApiSuccess) {
      setIsShowDeleteConfirmPopup(false);
      toastObj.toastSuccess(deleteCompetencyApiResponseMessage);
      navigate('/competencies');
      dispatch(resetDeleteCompetency());
    } else if (!deleteCompetencyApiSuccess && deleteCompetencyApiResponseMessage.length > 0) {
      toastObj.toastError(deleteCompetencyApiResponseMessage);
      dispatch(resetDeleteCompetency());
    }
  }, [deleteCompetencyApiLoading]);
  // Handle delete confirmation
  const ShowDeleteConfirmPopup = (CompetencyID: string, OrganizationID: string, OptionTotCount: number) => {
    if (OptionTotCount > 0) {
      toastObj.toastInfo(DeleteCompetencyError);
    } else {
      setIsShowDeleteConfirmPopup(true);
      setSelectedCompetencyId(CompetencyID);
      setSelectedOrganizationID(OrganizationID);
      const competency = competenciesListData?.Competencies?.find((competency: any) => competency.CompetencyID === CompetencyID);
      const groupNames = competency?.Groups?.map((group: any) => group.group_name).join(', ') || '';
      // Set content for the popup based on whether groups are assigned or not
      let popupContent = '';
      if (isUserAdmin()) {
        popupContent = competency?.Groups?.length
          ? `${useIntlActionMessages('Text.DeleteConfirmation.CompetencyGroup.Desc')} ${groupNames}. ${useIntlActionMessages('Text.DeleteConfirmation.Competency.Desc')}`
          : useIntlActionMessages('Text.DeleteConfirmation.Competency.Desc');
      } else {
        popupContent = useIntlActionMessages('Text.DeleteConfirmation.Competency.Desc');
      }
      setPopupData({
        content: popupContent,
        onConfirm: () => handleDeleteCompetencyClick(),
      });
    }
  };
  // Handle edit confirmation
  const ShowEditConfirmPopup = (CompetencyID: string, OptionTotCount: number) => {
    if (OptionTotCount > 0) {
      toastObj.toastInfo(EditCompetencyError);
    } else {
      navigate('/editcompetency', {
        state: {
          CompetencyID: CompetencyID,
        },
      });
    }
  };
  // Api call to delete competency
  const handleDeleteCompetencyClick = () => {
    if (selectedCompetencyId) {
      const deleteParams: IDeleteCompetencyApiParams = {
        CompetencyID: selectedCompetencyId,
        OrganizationID: selectedOrganizationID,
      };
      dispatch(deleteCompetency(deleteParams));
    }
  };
  // Handle date  filter
  const handleDateFilter = (event: any) => {
    setDateFilter(event);
    setDateRange([null, null]);
    if (event.value === 'Custom_Range') {
      return;
    }
    const updatedApiParams = {
      ...apiParams,
      DateRange: event.value,
      Page: DEFAULT_PAGE_INDEX,
      StartDate: '',
      EndDate: '',
    };
    const updatedTopListApiParams = {
      ...apiTopListParams,
      DateRange: event.value,
      StartDate: '',
      EndDate: '',
    };
    setApiParams(updatedApiParams);
    setTopListApiParams(updatedTopListApiParams);
  };
  // Handle organization type change
  const handleOrgTypeChange = async (event: any) => {
    dispatch(resetCompetenciesList());
    dispatch(resetTopCompetenciesList());
    const selectedOrgTypeId = parseInt(event.value);
    setOrganizationTypeValue(event);
    dispatch(
      getOrganizationNameByOrgType({
        OrganizationTypeId: selectedOrgTypeId,
      }),
    );
  };
  // Handle organization selection
  const handleOrganisationSelect = (index: number) => {
    dispatch(resetCompetenciesList());
    dispatch(resetTopCompetenciesList());
    const updatedOrganisations = organisations.map((org: any, i: any) => ({
      ...org,
      selected: i === index,
    }));
    setOrganisations(updatedOrganisations);
    const selectedOrg = organisations[index];
    setSelectedOrganization([selectedOrg]);
    const organizationID = selectedOrg.ID;
    dispatch(
      getUserTagsByOrgType({
        OrganizationID: organizationID,
        searchText: '',
      }),
    );
    setTopListApiParams({
      ...apiTopListParams,
      OrganizationID: organizationID,
    });
    setApiParams({
      ...apiParams,
      OrganizationID: organizationID,
      Page: DEFAULT_PAGE_INDEX,
    });
    setOpen(false);
  };
  // convert tpo date format
  const formatDateToISO = (date: Date) => {
    const timezoneOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
    const adjustedDate = new Date(date.getTime() - timezoneOffset);
    return adjustedDate.toISOString().split('T')[0];
  };
  // Handle custom range
  const handleCustomDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setDateRange(dates);
    if (start && end) {
      const formattedStartDate = formatDateToISO(start);
      const formattedEndDate = formatDateToISO(end);
      const updatedApiParams = {
        ...apiParams,
        DateRange: 'Custom_Range',
        StartDate: formattedStartDate,
        EndDate: formattedEndDate,
        Page: DEFAULT_PAGE_INDEX,
      };
      const updatedTopListApiParams = {
        ...apiTopListParams,
        DateRange: 'Custom_Range',
        StartDate: formattedStartDate,
        EndDate: formattedEndDate,
      };
      setApiParams(updatedApiParams);
      setTopListApiParams(updatedTopListApiParams);
    }
  };
  // get the label for tabs
  const getTabLabel = (userLevel: number) => {
    let tabLabel = '';
    if (rolesByOrgTypeData.length > 0) {
      const element = rolesByOrgTypeData.filter((role: any) => userLevel === role.RoleTypeID);
      tabLabel = element.length === 1 ? element[0].Name : '';
    } else {
      tabLabel = '';
    }
    return tabLabel;
  };
  // handle tag change
  const handleTagChange = (event: any) => {
    if (event) {
      const tags = event.map((tag: any) => tag.value);
      setSelectedTag(event);
      setCompetencyListFilters((info: any) => ({
        ...info,
        Tags: tags,
      }));
    }
  };
  // handle apply filter
  const handleFilterChange = () => {
    setApiParams((apiParams: any) => ({
      ...apiParams,
      Tags: competencyListFilters.Tags,
    }));
    setTopListApiParams((apiTopParams: any) => ({
      ...apiTopParams,
      TagOptions: competencyListFilters.Tags,
    }));
    setCurrentPage(DEFAULT_PAGE_INDEX);
    setIsFilterApplied(true);
    setIsOpenCompetencyFilter(false);
  };
  // handle apply group filter
  const handleGroupFilterChange = () => {
    setApiParams((apiParams: any) => ({
      ...apiParams,
      IsGeneral: competencyGroupListFilters.IsGeneral,
      GroupsIds: competencyGroupListFilters.GroupsIds,
    }));
    setTopListApiParams((apiTopParams: any) => ({
      ...apiTopParams,
      IsGeneral: competencyGroupListFilters.IsGeneral,
      GroupsIds: competencyGroupListFilters.GroupsIds,
    }));
    setCurrentPage(DEFAULT_PAGE_INDEX);
    setIsGroupFilterApplied(true);
    setIsOpenCompetencyGroupFilter(false);
  };
  // handle reset filter
  const handleFilterReset = () => {
    setCompetencyListFilters((apiParams: any) => ({
      ...apiParams,
      Tags: [],
    }));
    setApiParams((apiParams: any) => ({
      ...apiParams,
      Tags: [],
    }));
    setTopListApiParams((apiTopParams: any) => ({
      ...apiTopParams,
      TagOptions: [],
    }));
    setIsFilterApplied(false);
    setSelectedTag([]);
    setIsOpenCompetencyFilter(false);
  };
  // handle reset filter groups
  const handleGroupFilterReset = () => {
    setCompetencyGroupListFilters((apiParams: any) => ({
      ...apiParams,
      IsGeneral: '',
      GroupsIds: [],
    }));
    setApiParams((apiParams: any) => ({
      ...apiParams,
      IsGeneral: '',
      GroupsIds: [],
    }));
    setTopListApiParams((apiTopParams: any) => ({
      ...apiTopParams,
      IsGeneral: '',
      GroupsIds: [],
    }));
    setIsGroupFilterApplied(false);
    setSelectedGroup([]);
    setIsGeneral('');
    setIsOpenCompetencyGroupFilter(false);
  };
  // Handle competency Filter click.
  const handleCompetencyFilterClick = () => {
    setIsOpenCompetencyFilter(true);
  };
  // Handle competency Filter close.
  const handleCompetencyFilterClose = () => {
    setIsOpenCompetencyFilter(false);
  };
  // Handle competency group Filter click.
  const handleCompetencyGroupFilterClick = () => {
    setIsOpenCompetencyGroupFilter(true);
  };
  // Handle competency Filter close.
  const handleCompetencyGroupFilterClose = () => {
    setIsOpenCompetencyGroupFilter(false);
  };
  // handle group changes
  const handleGroupChange = (event: any) => {
    if (event) {
      const groups = event.map((groups: any) => groups.value);
      setSelectedGroup(event);
      setCompetencyGroupListFilters((info: any) => ({
        ...info,
        GroupsIds: groups,
      }));
    }
  };
  // Handle General checkbox click
  const handleGeneralClick = (event: any) => {
    let isGeneralTemp = 'false';
    if (event.target.checked) {
      isGeneralTemp = 'true';
    }
    setIsGeneral(isGeneralTemp);
    setCompetencyGroupListFilters((info: any) => ({
      ...info,
      IsGeneral: isGeneralTemp,
    }));
  };

  // Handle group name setting Overlay button click.
  const handleGroupNameFiltertoClick = () => {
    setIsManageGroupsFilterOpen(true);
  };

  // Handle group name setting Overlay close
  const handleGroupNameFiltertoClose = () => {
    handleGroupNameFilterReset();
    setIsManageGroupsFilterOpen(false);
  };
  // handle group name setting Overlay reset
  const handleGroupNameFilterReset = () => {
    setIsManageGroupsFilterOpen(false);
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.Competencies" />
        </h3>
      </div>
      <Row className="justify-content-between">
        <Col className="d-flex align-items-center content-sub-header">
          {isUserAdmin() && (
            <Col className="assign-users assign-users-comp">
              <div className="custom-berger-menu">
                <Filter
                  onOpen={handleGroupNameFiltertoClick}
                  onClose={handleGroupNameFiltertoClose}
                  isOpen={isManageGroupsFilterOpen}
                  width={450}
                  right
                  pageWrapId={'filter-wrapper'}
                  outerContainerId={'outer-container'}
                  customCrossIcon={<img src={Close} />}
                  customBurgerIcon={
                    <Button className="me-3 w-100" variant="primary" size="sm">
                      <FormattedMessage id="Button.GroupManagement" />
                    </Button>
                  }
                >
                  {isManageGroupsFilterOpen === true && <ManageGroups isShow={isManageGroupsFilterOpen} groupType={GroupType.COMPETENCY} />}
                </Filter>
              </div>
            </Col>
          )}
          {/* <h6 className="m-0 me-3">
            <FormattedMessage id="SubHd.CompetenciesSelected" />
            {getTabLabel(RoleTypeIds.APP_USER)}(s)
          </h6> */}
          <Col xl={4} lg={4} md={4} className="form-sm max-w-140">
            <Select options={selectDays} placeholder={useIntlMessages('PH.Select')} value={dateFilter} onChange={(event: any) => handleDateFilter(event)} />
          </Col>
          {isUserAdmin() && (
            <Filter
              onOpen={handleCompetencyGroupFilterClick}
              onClose={handleCompetencyGroupFilterClose}
              isOpen={isOpenOverviewGroupFilter}
              width={450}
              right
              pageWrapId={'filter-wrapper'}
              outerContainerId={'outer-container'}
              customCrossIcon={<img src={Close} />}
              burgerButtonClassName={isGroupFilterApplied ? 'active' : ''}
            >
              <div id="filter-wrapper" className="filter-main">
                <h4 className="mb-5">
                  <FormattedMessage id="Hd.Filter" />
                </h4>
                <Col className="mb-3">
                  <Checkbox checked={isGeneral} type="Checkbox" label="General Competency" onChange={handleGeneralClick} />
                </Col>
                {!isGeneral && (
                  <Col className="form-sm">
                    <Select label="Competency Group" options={groupOptions} placeholder="Select Groups" onChange={handleGroupChange} value={selectedGroups} isMulti />
                  </Col>
                )}
                <Row className="mt-4">
                  <Col>
                    <Button variant="outline-primary" className="w-100" onClick={handleGroupFilterReset}>
                      <FormattedMessage id="Button.ClearFilter" />
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="primary" className="w-100" onClick={handleGroupFilterChange}>
                      <FormattedMessage id="Button.ApplyFilters" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Filter>
          )}
        </Col>
        <Col md="auto" className="d-flex align-items-center">
          {isUserAdmin() && (
            <Col className="w-180px form-sm me-3">
              <Select
                label={useIntlActionMessages('Label.OrgType')}
                options={organizationTypeOptions}
                value={organizationTypeValue}
                name="OrganizationTypeId"
                placeholder={useIntlMessages('PH.Select')}
                onChange={(e: any) => handleOrgTypeChange(e)}
              />
            </Col>
          )}
          {selectedOrganization && selectedOrganization?.length > 0 && selectedOrganization[0] && (
            <Col md="auto" className="d-flex align-items-center">
              <div className="custom-berger-menu me-3">
                <Filter
                  isOpen={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => setOpen(false)}
                  width={450}
                  right
                  pageWrapId={'filter-wrapper'}
                  outerContainerId={'outer-container'}
                  customCrossIcon={<img src={Close} />}
                  customBurgerIcon={
                    <div className="org-selected">
                      <div className="org-img-container">
                        <img
                          src={selectedOrganization && selectedOrganization?.length > 0 && selectedOrganization[0]?.Logo ? BUCKET_URL + selectedOrganization[0]?.Logo : Default}
                          alt="Organization Logo"
                          className="thumbnail-image"
                        />
                      </div>
                      <div className="org-details max-w-140">
                        <h6>{selectedOrganization && selectedOrganization?.length > 0 ? selectedOrganization[0]?.Name : 'No Organization Selected'}</h6>
                        <small>{selectedOrganization && selectedOrganization?.length > 0 ? selectedOrganization[0]?.Address : ''}</small>
                      </div>
                    </div>
                  }
                >
                  <div id="filter-wrapper" className="filter-main">
                    <h4 className="mb-5">
                      <FormattedMessage id="Hd.Organization" />
                    </h4>
                    {organisations.map((org: any, index: number) => (
                      <ListCard
                        key={index}
                        image={org?.Logo ? org.Logo : Default}
                        label={org?.Name}
                        sublabel={org?.Address}
                        selected={org?.selected}
                        handleClick={() => handleOrganisationSelect(index)}
                      />
                    ))}
                  </div>
                </Filter>
              </div>
            </Col>
          )}
          {selectedOrganization && selectedOrganization?.length > 0 && selectedOrganization[0] && (
            <Filter
              onOpen={handleCompetencyFilterClick}
              onClose={handleCompetencyFilterClose}
              isOpen={isOpenOverviewFilter}
              width={450}
              right
              pageWrapId={'filter-wrapper'}
              outerContainerId={'outer-container'}
              customCrossIcon={<img src={Close} />}
              burgerButtonClassName={isFilterApplied ? 'active me-2' : 'me-2'}
            >
              <div id="filter-wrapper" className="filter-main">
                <h4 className="mb-5">
                  <FormattedMessage id="Hd.Filter" />
                </h4>
                <Col className="form-sm">
                  <Select label="Tags" id="Tags" name="Tags" options={tagList} value={selectedTag} placeholder="Select Tags" onChange={(e: any) => handleTagChange(e)} isMulti />
                </Col>
                <Row className="mt-4">
                  <Col>
                    <Button variant="outline-primary" className="w-100" onClick={handleFilterReset}>
                      <FormattedMessage id="Button.ClearFilter" />
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="primary" className="w-100" onClick={handleFilterChange}>
                      <FormattedMessage id="Button.ApplyFilters" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Filter>
          )}
          <div>
            {(!isUserSystemAdmin() || (isUserSystemAdmin() && hasAddNewCompetencyPermissionSystemAdmin)) && (
              <Button variant="primary" size="sm" className='mt-6' onClick={() => navigate('/addcompetencies')}>
                <FormattedMessage id="Button.AddCompetency" />
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Col className="mb-4">
        {dateFilter?.value === 'Custom_Range' && (
          <Col xl={3} md={5}>
            <div className="custom-calendar form-sm">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={handleCustomDateRange}
                isClearable={true}
                className="form-control"
                placeholderText="Select Range"
                onFocus={(e) => e.target.blur()}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
          </Col>
        )}
      </Col>
      <div className="dashboard-tiles-main competencies-tiles-main">
        <Row>
          {competenciesTopListApiSuccess &&
            competenciesTopListData &&
            competenciesTopListData.map((competency: any, index: number) => (
              <Col key={index} xl lg={4} md={4}>
                <div className={tileClasses[index % tileClasses?.length]}>
                  <div className="dash-tile-bg">
                    <h2>{competency?.Count}</h2>
                    <div className="d-flex">
                      <span>{competency?.CompetencyName}</span>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
      <Accordion defaultActiveKey="0">
        {competenciesListApiSuccess && competenciesListData?.Competencies?.length > 0 ? (
          competenciesListData?.Competencies?.map((competency: any, index: any) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Button
                variant="dark"
                size="sm"
                className="icon-btn edit-btn more-dropdown"
                onClick={() => ShowEditConfirmPopup(competency?.CompetencyID, competency?.OptionTotCount)}
              >
                <Edit />
              </Button>
              <Button
                variant="dark"
                size="sm"
                className="icon-btn delete-btn"
                onClick={() => ShowDeleteConfirmPopup(competency?.CompetencyID, competency?.OrganizationId, competency?.OptionTotCount)}
              >
                <Delete />
              </Button>
              <Accordion.Header>
                <h6>{competency?.CompentancyName}</h6>
              </Accordion.Header>
              <Accordion.Body>
                {competency?.Options?.map((option: any, optionIndex: any) => (
                  <CompetenciesCard key={optionIndex} count={option?.competency_option_count.toString()} competencies={option?.competencyoption} />
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))
        ) : (
          <div className="content-sub content-area-padding border-top text-center p-4">
            <h6>{NodataText}</h6>
          </div>
        )}
      </Accordion>
      {competenciesListApiSuccess && competenciesListData?.Competencies?.length > 0 && competenciesListData.TotalCount > PAGE_SIZE && (
        <Pagination
          pageSize={PAGE_SIZE}
          totalitems={competenciesListData?.TotalCount}
          pageLimit={PAGE_SIZE}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          currentPage={currentPage - 1}
          prevPage={-1}
          itemsDisplayed={competenciesListData?.Competencies?.length}
        />
      )}
      <DeleteConfirmPopup
        show={isShowDeleteConfirmPopup}
        handleClose={() => setIsShowDeleteConfirmPopup(false)}
        onCancel={() => setIsShowDeleteConfirmPopup(false)}
        onOkay={() => {
          if (popupData?.onConfirm) {
            popupData.onConfirm();
          }
          setIsShowDeleteConfirmPopup(false);
        }}
        title={useIntlActionMessages('Text.DeleteConfirmation.Competency')}
        content={popupData?.content || ''}
      />
      {competenciesListApiLoading || (deleteCompetencyApiLoading && <Loader />)}
    </>
  );
};

export default CompetenciesList;
