/**
 * @file   src\containers\HowItWorks.tsx
 * @brief  How It Works.
 * @date   Dec, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Button } from 'react-bootstrap';
import Logo from '../assets/img/Logo.svg';
import { useNavigate } from 'react-router-dom';

const HowItWorks = () => {
    const navigate = useNavigate();
  return (
    <>
    <div className='generalheader'>
    <div className='container-fluid justify-content-between align-items-center d-flex'>
        <img src={Logo} />
        <Button variant="outline-light" onClick={() => navigate('/')}>Back</Button>
        </div>
    </div>
    <div className='container howitworks'>
      <h3 className="font-semibold mb-8">Welcome to Mirror IM: How It Works</h3>
      <p className="text-lg mb-4">
        Our platform is designed to empower organizations by streamlining user management, goal assignment, and progress tracking. Follow these simple steps to get started and
        manage your team effectively.
      </p>

      <h5>Step 1: Get Invited and Create Your Account</h5>
      <p className="mb-4">User accounts are added by the organization admin. Once added, you will receive an invitation email containing a unique link.</p>
      <ul className="list-decimal pl-8 mb-4">
        <li>Step 1.1: Open the invitation email and click on the provided link.</li>
        <li>Step 1.2: Complete your profile by providing the necessary details.</li>
        <li>Step 1.3: Set up a secure password and activate your account.</li>
      </ul>
      <p className="mb-4">Once registered, you can access the platform and explore its features.</p>

      <h5>Step 2: Set Up Your Organization Profile</h5>
      <p className="mb-4">Complete your organization’s profile by adding essential details like:</p>
      <ul className="list-decimal pl-8 mb-4">
        <li>Organization name</li>
        <li>User Roles, Categories, and Subcategories</li>
        <li>Administrator contact details</li>
      </ul>
      <p className="mb-4">This helps personalize the platform for your organization’s structure.</p>

      <h5>Step 3: Add and Manage App Users</h5>
      <p className="mb-4">Easily upload or add users (e.g., employees, team members) to the platform. Use features like:</p>
      <ul className="list-decimal pl-8 mb-4">
        <li>Batch Upload: Add multiple users using a CSV or Excel file.</li>
        <li>Individual Registration: Manually add users with their basic information.</li>
      </ul>
      <p className="mb-4">Once added, you can manage users, update their profiles, and track their activity.</p>

      <h5>Step 4: Create and Assign Goals</h5>
      <p className="mb-4">Assign meaningful goals to users based on your organization’s objectives:</p>
      <ul className="list-decimal pl-8 mb-4">
        <li>Create Goals: Define the goal name, description, and metrics.</li>
        <li>Assign Goals: Choose individual users or groups for specific goals.</li>
        <li>Set Deadlines: Track progress with clear timelines.</li>
      </ul>

      <h5>Step 5: Monitor User Progress</h5>
      <p className="mb-4">Get real-time insights into user activities and goal completion:</p>
      <ul className="list-decimal pl-8 mb-4">
        <li>View detailed reports and progress summaries.</li>
        <li>Identify areas for improvement with analytics.</li>
        <li>Celebrate achievements by recognizing milestones reached.</li>
      </ul>

      <h5>Step 6: Enhance Engagement</h5>
      <p className="mb-4">
        Motivate users by attaching resources like motivational content or competency-building materials to their tasks. These resources can help them achieve their goals
        efficiently.
      </p>

      <h5>Why Use Our Platform?</h5>
      <ul className="list-disc pl-8 mb-6">
        <li>Streamlined User Management: Centralize all user profiles and data in one place.</li>
        <li>Goal-Oriented: Easily assign, track, and evaluate goals.</li>
        <li>Actionable Insights: Use progress data to make informed decisions.</li>
        <li>Collaboration Tools: Empower teams with shared objectives and resources.</li>
      </ul>

      <h5>Start Managing Your Organization Today!</h5>
      <p>Get the best out of your team with Mirror App.</p>
    </div>
    </>
  );
};

export default HowItWorks;
