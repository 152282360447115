/**
 * @file   src\validations\organisationSchema.ts
 * @brief  This file is responsible for defining organisation validation schemas.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';

// Feedback Form Schema
export const FEEDBACK_SCHEMA = yup.object({
  ContactAdminSubject: yup
    .string()
    .trim()
    .required(useIntlActionMessages('Form.OrganisationName.Required'))
    .min(3, useIntlActionMessages('Form.OrganisationName.Min'))
    .max(100, useIntlActionMessages('Form.OrganisationName.Max')),
  ContactAdminMessage: yup
    .string()
    .required(useIntlActionMessages('Form.Address.Required'))
    .max(1000, useIntlActionMessages('Form.Address.Max'))
    .min(5, useIntlActionMessages('Form.Address.Min')),
});
