import React from 'react';
import { Form } from 'react-bootstrap';
import '../assets/css/Input.scss';

const Input = (props: any) => {
  return (
    <Form className={props.className}>
      <div className="form">
        <div>
          <Form.Check id={props.id}>
            <Form.Check.Input type={props.type} onChange={props.onChange} checked={props.checked} disabled={props.disabled} />
            <Form.Check.Label>{props.label}</Form.Check.Label>
          </Form.Check>
        </div>
      </div>
    </Form>
  );
};

export default Input;
