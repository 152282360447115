import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { OverviewApis } from '../../utils/apiUrls';

// API call to list users with to do items.
export const listUsersWithToDo = createAsyncThunk('/listUsersWithToDo', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${OverviewApis.LIST_USERS_WITH_TODO}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

// API call to get User Goals And Surveys Overview.
export const getUserGoalsAndSurveysOverview = createAsyncThunk('/getUserGoalsAndSurveysOverview', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${OverviewApis.GET_USER_GOALS_SURVEYS_OVERVIEW}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});