/**
 * @file   src/containers/profile/ChoosePlan.tsx
 * @brief  No active subscription plan page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { FormattedMessage } from 'react-intl';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BUCKET_URL } from '../../utils/constants';
import { getFromLocalStorage } from '../../utils/helper';

const ChoosePlan = () => {
  // navigation object creation
  const navigate = useNavigate();
  const userData = getFromLocalStorage('MI_USR_DATA');
  return (
    <div>
      <Container className="vh-100 d-flex align-items-center">
        <div className="gradient-img py-5 text-center w-100 br-10">
          <h3>
            <FormattedMessage id="Hd.NoActivePlan" />
          </h3>
          <p>
            <FormattedMessage id="SubHd.NoActivePlan" />
          </p>
          <div className="my-4">
            <Row className='justify-content-center'>
              <Col xl={3} md={4} >
                <img src={BUCKET_URL + userData.OrganizationLogo} alt="Organization" className="mb-3 w-100" />
              </Col>
            </Row>
            <h5>{userData.OrganizationName}</h5>
            <h6>{userData.OrganizationAddress}</h6>
          </div>
          <Button variant="primary" onClick={() => navigate('/planlist')}>
            <FormattedMessage id="Button.ChoosePlan" />
          </Button>
        </div>
      </Container>
    </div>
  );
};
export default ChoosePlan;
