import React from 'react';
const LinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.906" height="13.453" viewBox="0 0 26.906 13.453">
            <path id="link_FILL1_wght400_GRAD0_opsz48" d="M92.444,389.453H86.727a6.727,6.727,0,1,1,0-13.453h5.718v2.018H86.727a4.709,4.709,0,1,0,0,9.417h5.718Zm-4.2-5.718v-2.018H98.666v2.018Zm6.222,5.718v-2.018h5.718a4.709,4.709,0,1,0,0-9.417H94.462V376h5.718a6.727,6.727,0,1,1,0,13.453Z" transform="translate(-80 -376)" fill="#fff" />
        </svg>
    );
}

export default LinkIcon;
