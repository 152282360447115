/**
 * @file   mirrorapp_admin\src\components\NotificationCard.tsx
 * @brief  Notification Card page.
 * @date   JUL, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React from "react";
import '../assets/css/NotificationCard.scss';
import IcnNotification from '../assets/img/icon/Menu_Notification';

const NotificationCard = (props: any) => {
    return (
        <div className="notification-main d-flex align-items-center">
            <div className="notification-icon">
            <IcnNotification />
            </div>
            <div className="notification-text"><p>{props.content}</p>
            <small><span className="pe-2">{props.date}</span></small>
            </div>
        </div>
    );
}
export default NotificationCard;