/**
 * @file   src\components\Students Details.tsx
 * @brief  This component used as a layout for the content which can be replaced.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Col, Row, Breadcrumb, Button, Nav, NavDropdown, Table, ProgressBar } from 'react-bootstrap';
import '../assets/css/ProfileCreation.scss';
import '../assets/css/StudentsDetails.scss'
import { slide as Filter } from 'react-burger-menu';
import Close from "../assets/img/Close.svg";
import { useIntlMessages } from '../utils/helper';
import Input from '../components/MAInput';
import Select from '../components/MASelect';
import { FormattedMessage } from 'react-intl';
import '../assets/css/myprof.scss';
import proImg from '../assets/img/pro_img.png';
import More from "../assets/img/More.svg";
import { Link, useNavigate } from 'react-router-dom';
import { end } from "@popperjs/core";
import Tktstar from '../assets/img/TicketStar.png';
import Arrowrgt from '../assets/img/icon-double caret.svg';
import Accordion from 'react-bootstrap/Accordion';
import Sort from '../assets/img/icon/Sort';
import ViewIcon from '../assets/img/icon/View';
import UserImg from '../assets/img/Userimg.jpg';
import icnTask from '../assets/img/icn-doc.svg';
import icnCalendar from '../assets/img/icn_calendar_small.svg';
import levIcnBlue from "../assets/img/level-icn-blue.svg";
import levIcnGrn from "../assets/img/level-icn-grn.svg";
import levIcnRd from "../assets/img/level-icn-rd.svg"


const Studentsdetails = () => {
    const navigate = useNavigate();
    const organization = [
        { label: 'Schools', value: '1' },
        { label: 'Manufacturing companies', value: '2' },
        { label: 'University', value: '3' },
    ];
    return (
        <>
            <div className='page-title d-flex justify-content-between align-items-center'>
                <h3>Students Details</h3>
                <Col md="auto">
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item >
                            <FormattedMessage id="Hd.Users" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Student Details
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </div>
            <div className='myprof-cont'>
                <Row className='mb-4'>
                    <Col xl={4} lg={5}>
                        <div className='mypro-sec-L '>
                            <div className='pro-bg '></div>
                            <img src={proImg} className='prof-wrp' alt="" />
                            <div className='mng-pro-det'>
                                <h6>John Doe</h6>
                                <span>Org. Admin/Manager</span>
                                {/* <div className='d-flex justify-content-center m-3'>
                                    <div className='me-2'><img src={Tktstar} alt="" /></div>
                                    <p className='m-0 text-white'>17 Kudos Points</p>
                                    <div className='ms-2'><img src={Arrowrgt} alt="" /></div>
                                </div> */}
                                <div className='d-flex justify-content-center bt-sec mt-3'>
                                    <div className='custom-berger-menu custom-berger-menu-kudos'>
                                        <Filter width={450} right pageWrapId={"filter-wrapper"} outerContainerId={"outer-container"} customCrossIcon={<img src={Close} />}
                                            customBurgerIcon={
                                                <div className='d-flex justify-content-center m-3'>
                                                    <div className='me-2'><img src={Tktstar} alt="" /></div>
                                                    <p className='m-0 text-white'>17 Kudos Points</p>
                                                    <div className='ms-2'><img src={Arrowrgt} alt="" /></div>
                                                </div>
                                            }>
                                            <div id='filter-wrapper' className='filter-main'>
                                                <Row>
                                                    <Col lg={7} md={7}><h4 className='mb-5 float-start'>Kudos Level</h4></Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12} md={12}>
                                                        <div className='kud-pnt-bg-bl d-flex'>
                                                            <div className='sec-left-lev d-flex'>
                                                                <img src={levIcnBlue} alt="" />
                                                            </div>
                                                            <div className='font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev'>Level 1 - Kudos 1 to 20</div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-4'>
                                                    <h6 className='text-start'>Earn 3 more kudos to get Level 2 Badge</h6>
                                                    <Col lg={12} md={12}>
                                                        <div className='kud-pnt-bg-bl-g d-flex'>
                                                            <div className='sec-left-lev d-flex'>
                                                                <img src={levIcnGrn} alt="" />
                                                            </div>
                                                            <div className='font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev'>Level 1 - Kudos 1 to 20</div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} md={12}>
                                                    <div className='kud-pnt-bg-rd d-flex mt-3'>
                                                            <div className='sec-left-lev d-flex'>
                                                                <img src={levIcnRd} alt="" />
                                                            </div>
                                                            <div className='font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev'>Level 1 - Kudos 1 to 20</div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4 pb-4">
                                                    <Col>
                                                        <Button variant="primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                                                            <FormattedMessage id="Button.Submit" />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Filter>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center bt-sec mt-3'>
                                    <div className='custom-berger-menu custom-berger-menu-btn'>
                                        <Filter width={450} right pageWrapId={"filter-wrapper"} outerContainerId={"outer-container"} customCrossIcon={<img src={Close} />}
                                            customBurgerIcon={<div className="d-flex align-items-center multiple-user text-white m-0">Give Kudos</div>}>
                                            <div id='filter-wrapper' className='filter-main'>
                                                <Row>
                                                    <Col lg={7} md={7}><h4 className='mb-5 float-start'>Kudos Points</h4></Col>
                                                    <Col lg={5} md={5} className="form-sm">
                                                        <Select
                                                            options={organization}
                                                            value=''
                                                            placeholder="Select"
                                                            onChange={() => { console.log('sss') }}
                                                        // error="Error"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={8} md={8}>
                                                        <div className='kud-pnt-bg-bl d-flex'>
                                                            <div className='sec-left-lev d-flex'>
                                                                <img src={levIcnBlue} alt="" />
                                                                <p className='mt-3 text-white font-xs pl-1'>Level 1</p>
                                                            </div>
                                                            <div className='font-xs d-flex align-items-center p-3 text-start text-white'>Earn 3 more kudos to get next badge</div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={4}>
                                                        <div className='kud-pnt-bg-gr'>
                                                            <div className='d-flex justify-content-center'>
                                                                <img src={Tktstar} alt="" /><h3><b>17</b></h3>
                                                            </div>
                                                            <div className='font-sm'>Kudos Points</div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <div className='pt-4'>
                                                        <h5 className='text-start'>Kudos History</h5>
                                                        <div className='inn-bg p-3 mb-2'>
                                                            <Row className='d-flex align-items-center'>
                                                                <Col lg={9} md={9} className='text-start'>
                                                                    <p className='m-0 font-sm'>Completed Goal</p>
                                                                    <p className='m-0 text-white'>Complete College Degree</p>
                                                                </Col>
                                                                <Col lg={3} md={3}><div className='pts-txt'>10 pts</div></Col>
                                                            </Row>
                                                        </div>
                                                        <div className='inn-bg p-3 mb-2'>
                                                            <Row className='d-flex align-items-center'>
                                                                <Col lg={9} md={9} className='text-start'>
                                                                    <p className='m-0 font-sm'>Completed Survey</p>
                                                                    <p className='m-0 text-white'>Survey on your health</p>
                                                                </Col>
                                                                <Col lg={3} md={3}><div className='pts-txt'>5 pts</div></Col>
                                                            </Row>
                                                        </div>
                                                        <div className='inn-bg p-3 mb-2'>
                                                            <Row className='d-flex align-items-center'>
                                                                <Col lg={9} md={9} className='text-start'>
                                                                    <p className='m-0 font-sm'>Appreciated by Teacher</p>
                                                                    <p className='m-0 text-white'>Awarded by Sofia Watson (You)</p>
                                                                </Col>
                                                                <Col lg={3} md={3}><div className='pts-txt'>2 pts</div></Col>
                                                            </Row>
                                                        </div>
                                                        <div className='inn-bg p-3 mb-2'>
                                                            <Row className='d-flex align-items-center'>
                                                                <Col lg={9} md={9} className='text-start'>
                                                                    <p className='m-0 font-sm'>Awarded by Accountability Partner</p>
                                                                    <p className='m-0 text-white'>Awarded by Mathew Jem</p>
                                                                </Col>
                                                                <Col lg={3} md={3}><div className='pts-txt'>1 pts</div></Col>
                                                            </Row>
                                                        </div>
                                                        <Row className='mt-4'>
                                                            <Col lg={12} className='text-start'>
                                                                <Input
                                                                    label={useIntlMessages('Points')}
                                                                    id="Name"
                                                                    name="Name"
                                                                    type="text"
                                                                    placeholder={useIntlMessages('5')}
                                                                    maxLength={200}
                                                                    value=''
                                                                    onChange={() => { console.log('sss') }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Row>
                                                <Row className="mt-4 pb-4">
                                                    <Col>
                                                        <Button variant="outline-primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                                                            <FormattedMessage id="Button.Cancel" />
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button variant="primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                                                            <FormattedMessage id="Button.Submit" />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Filter>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xl={8} lg={7}>
                        <div className='content-sub h-100 '>
                            <div className='content-sub-header d-flex justify-content-between align-items-center'>
                                <div>
                                    <h4><FormattedMessage id="Hd.PersInfo" /></h4>
                                </div>
                                <Col xs="auto">
                                    <Nav>
                                        <NavDropdown
                                            align={end}
                                            className='icon-btn-more'
                                            title={
                                                <img src={More} alt="more" />
                                            }
                                        >
                                            <NavDropdown.Item onClick={() => window.alert("Not Implemented")}><FormattedMessage id="Link.EditProfile" /></NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => navigate('/changepassword')}><FormattedMessage id="Link.Change Password" /></NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav>
                                </Col>
                            </div>
                            <div className='content-area-padding'>
                                <Row className='label-with-content mb-3'>
                                    <Col xl lg={6} md={3} sm={6} >
                                        <label>Organization Name</label>
                                        <p>Modern American School</p>
                                    </Col>
                                    <Col xl lg={6} md={3} sm={6}>
                                        <label><FormattedMessage id="Label.DateofBirth" /></label>
                                        <p>12/12/1987</p>
                                    </Col>
                                    <Col xl lg={6} md={3} sm={6}>
                                        <label><FormattedMessage id="Label.Gender" /></label>
                                        <p><FormattedMessage id="Label.Male" /></p>
                                    </Col>
                                    <Col xl lg={6} md={3} sm={6}>
                                    </Col>
                                </Row>
                                <Row className='label-with-content mb-3'>
                                    <Col xl lg={6} md={3} sm={6}>
                                        <label><FormattedMessage id="Label.Email" /></label>
                                        <p>mirroradmin@zco.com</p>
                                    </Col>
                                    <Col xl lg={6} md={3} sm={6}>
                                        <label><FormattedMessage id="Label.PhoneNum" /></label>
                                        <p>+1 (800) 555‑0175</p>
                                    </Col>
                                    <Col xl lg={6} md={3} sm={6}>
                                        <label>Date of Joining</label>
                                        <p>04/25/2023</p>
                                    </Col>
                                    <Col xl lg={6} md={3} sm={6}>
                                    </Col>
                                </Row>
                                <Row className='label-with-content mb-3'>
                                    <Col xl lg={6} md={3} sm={6}>
                                        <label><FormattedMessage id="Label.Address" /></label>
                                        <p>2989 Oak Ridge Ln <br></br>Street Line One</p>
                                    </Col>
                                    <Col xl lg={6} md={3} sm={6}>
                                        <label><FormattedMessage id="Label.City" /></label>
                                        <p>Milwaukee</p>
                                    </Col>
                                    <Col xl lg={6} md={3} sm={6} className='d-flex'>
                                        <div>
                                            <label><FormattedMessage id="Label.State" /></label>
                                            <p>Wisconsin</p>
                                        </div>
                                    </Col>
                                    <Col xl lg={6} md={3} sm={6} className='d-flex'>
                                        <div className='zip-col'>
                                            <label><FormattedMessage id="Label.Zip" /></label>
                                            <p>53211</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='label-with-content mb-3'>
                                    <Col xl lg={6} md={3} sm={6}>
                                        <label>Highest Education</label>
                                        <p>MBA - Human Resource Managment</p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='content-sub mt-2'>
                    <div className='content-sub-header d-flex justify-content-between align-items-center'>
                        <h4>Completed Goals</h4>
                    </div>
                    <div className='content-area-padding'>
                        <div className='bdr-content-area'>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><h6>Monthly (1)</h6></Accordion.Header>
                                    <Accordion.Body className='p-3'>
                                        <Table striped hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <FormattedMessage id="Label.GoalName" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="Label.Category" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="Label.SubCategory" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="Label.CreatedBy" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="Label.Status" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                                    </th>
                                                    <th className="w-50px"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Prepare for exam
                                                    </td>
                                                    <td>Education</td>
                                                    <td>All Category</td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <span className="td-user-image">
                                                                <img src={UserImg} className="w-100" alt="user" />
                                                            </span>
                                                            Joey Barlow
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center multiple-user">
                                                            <ProgressBar now={60} />{' '}
                                                            <span className="ps-2">
                                                                <label className="td-progressbar">
                                                                    50% <span className="inprogress">In Progress</span>
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Button variant="dark" size="sm" className="icon-btn" onClick={() => navigate('/userDetails', {
                                                            state: {
                                                                tabName: "Student"
                                                            }
                                                        })}>
                                                            <ViewIcon />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
                <div className='content-sub mt-2'>
                    <div className='content-sub-header d-flex justify-content-between align-items-center'>
                        <h4>Completed Goals</h4>
                    </div>
                    <div className='content-area-padding'>
                        <div className='bdr-content-area  p-3 '>
                            <div className='d-flex justify-content-center inn-bg p-3 mb-3'>
                                <Col lg={10} md={10} className='d-flex align-items-center'> <img src={icnTask} alt="" className='pe-2' /><p className='m-0 text-white'>Task Name</p></Col>
                                <Col lg={2} md={2}>
                                    <div className="d-flex align-items-center multiple-user float-end">
                                        <ProgressBar now={60} />{' '}
                                        <span className="ps-2 ">
                                            <label className="td-progressbar">60% </label>
                                        </span>
                                    </div>
                                </Col>
                            </div>
                            <div className='d-flex justify-content-center inn-bg p-3'>
                                <Col lg={10} md={10} className='d-flex align-items-center'> <img src={icnCalendar} alt="" className='pe-2' /><p className='m-0 text-white'>Task Name</p></Col>
                                <Col lg={2} md={2}>
                                    <div className="d-flex align-items-center multiple-user float-end">
                                        <ProgressBar now={60} />{' '}
                                        <span className="ps-2 ">
                                            <label className="td-progressbar">60% </label>
                                        </span>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content-sub mt-2'>
                    <div className='content-sub-header d-flex justify-content-between align-items-center'>
                        <h4>Completed Surveys</h4>
                    </div>
                    <div className='content-area-padding'>
                        <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="Label.GoalName" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                    </th>
                                    <th>
                                        <FormattedMessage id="Label.Category" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                    </th>
                                    <th>
                                        <FormattedMessage id="Label.SubCategory" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                    </th>
                                    <th>
                                        <FormattedMessage id="Label.CreatedBy" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                    </th>
                                    <th>
                                        <FormattedMessage id="Label.Status" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                    </th>
                                    <th className="w-50px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Prepare for exam
                                    </td>
                                    <td>Education</td>
                                    <td>All Category</td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            Joey Barlow
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center multiple-user">
                                            <ProgressBar now={60} />{' '}
                                            <span className="ps-2">
                                                <label className="td-progressbar">
                                                    50% <span className="inprogress">In Progress</span>
                                                </label>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <Button variant="dark" size="sm" className="icon-btn" onClick={() => navigate('/userDetails', {
                                            state: {
                                                tabName: "Student"
                                            }
                                        })}>
                                            <ViewIcon />
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Studentsdetails;