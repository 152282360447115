import React from 'react';

const CompetenciesIcon = () => {
    return (
        <svg width="23.386" height="19.573" viewBox="0 0 23.386 19.573">
            <defs>
                <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#fad900" />
                    <stop offset="1" stop-color="#f5933d" />
                </linearGradient>
            </defs>
            <path id="Icn_Competencies" d="M241.525,222.225l-3.025,5.5-.825,7.7h-9.35l-.825-7.7-3.025-5.5-2.475-4.4,1.65-.825,5.5,8.525,3.85.825,3.85-.825,5.5-8.525,1.65.825Zm-8.388,2.2a3.713,3.713,0,1,1,3.712-3.713A3.712,3.712,0,0,1,233.138,224.425Z" transform="translate(-221.307 -216.352)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd" fill="url(#linear-gradient)" />
        </svg>
    );
}

export default CompetenciesIcon;
