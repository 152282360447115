import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { IntlProvider } from 'react-intl';
import { ToastContainer } from "react-toastify";
import LocaleMessages from './assets/strings/English.json';
import "react-toastify/dist/ReactToastify.css";
import { Provider } from 'react-redux';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <IntlProvider locale="en" messages={LocaleMessages}>
      {/* <React.StrictMode> */}
        <App />
        <ToastContainer />
      {/* </React.StrictMode> */}
    </IntlProvider>
  </Provider>
);
