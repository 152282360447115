import React from 'react';
import '../assets/css/OrganizationCard.scss';
import Phone from '../assets/img/Phone.svg';
import Active from '../assets/img/GreenTick.svg';
import Inactive from '../assets/img/RedClose.svg';
import Location from '../assets/img/Location.svg';
import { useNavigate } from 'react-router-dom';
import Default from '../assets/img/default.jpg';
import { BUCKET_URL } from '../utils/constants';

const PerfomanceCard = (props: any) => {
  // Navigate object creation.
  const navigate = useNavigate();
  return (
    <div
      className="card-org-main"
      key={props.organizationId}
      onClick={() =>
        navigate('/vieworganization', {
          state: {
            organizationId: props.organizationId,
            parentPage: 'organization',
          },
        })
      }
    >
      <div className="org-img-nav">
        <img src={props.orgImage ? BUCKET_URL + props.orgImage : Default} alt="Org" />
      </div>
      <h5>{props.organizationname}</h5>
      <span className="category">{props.category}</span>
      <div className="phone">
        <img src={Phone} alt="Phone" />
        {props.countrycode}-{props.phone}
      </div>
      <div className="phone px-3">
        <img src={Location} alt="Location" />
        {props.location}
      </div>
      <div className="status">
        {props?.active == 1 ? (
          <span className="active">
            <img src={Active} alt="Active" /> Active
          </span>
        ) : (
          <span className="inactive">
            <img src={Inactive} alt="Inactive" /> Inactive
          </span>
        )}
      </div>
      <div className="subscription-plan">
        {' '}
        <p>
          Subscription Plan -{' '}
          {props?.planduration ? (
            <>
              {' '}
              {props.planduration} ({props.planstatus})
            </>
          ) : (
            'Nil'
          )}
        </p>
      </div>
    </div>
  );
};
export default PerfomanceCard;
