/**
 * @file   src\validations\organisationSchema.ts
 * @brief  This file is responsible for defining organisation validation schemas.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';

// Organisation Form Schema
export const ASSIGNMENT_SCHEMA = yup.object({
  Reason: yup
    .string()
    .required(useIntlActionMessages('Form.RejectReason.Required'))
    .max(1000, useIntlActionMessages('Form.RejectReason.Max'))
    .min(5, useIntlActionMessages('Form.Address.Min')),
});
