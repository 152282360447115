/**
 * @file   src\components\ImageUpload.tsx
 * @brief Component to upload picture
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useDropzone } from 'react-dropzone';
import React, { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { UploaderProps } from '../utils/enums';
import Upload from '../assets/img/icon/Upload';
import { BUCKET_URL } from '../utils/constants';

// props interface for component
interface IFileUploadDropZone {
  setError: any;
  setPicture: any;
  imageFile: any;
  acceptedFileTypes: any;
  maxSize?: number;
  invalidTypeMessage: string;
  invalidSizeMessage: string;
  mode: string;
}
// Component to upload image
const DropzoneImageUpload = ({ setError, setPicture, imageFile, maxSize, acceptedFileTypes, invalidTypeMessage, invalidSizeMessage, mode }: IFileUploadDropZone) => {
  const [files, setFiles] = useState([]);
  const [error, setUploadError] = useState('');
  const [filemode, setFilemode] = useState<string>(mode);
  // handle image select
  const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
    setFilemode('add');
    const renamedAcceptedFiles = acceptedFiles.map((file: any) => {
      const nameParts = file?.name?.split('.');
      const fileName = nameParts.slice(0, -1).join('.');
      const fileExtension = nameParts[nameParts.length - 1];
      // Modify the filename by appending the timestamp
      const modifiedFileName = `${fileName}_${+new Date().getTime()}.${fileExtension}`;
      // Replace special characters with underscores
      const formattedFilename = modifiedFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
      return new File([file], formattedFilename, { type: file.type });
    });

    setFiles(
      renamedAcceptedFiles.map((item: any) =>
        Object.assign(item, {
          preview: URL.createObjectURL(item),
        }),
      ),
    );
    if (Object.keys(rejectedFiles).length !== 0) {
      rejectedFiles.forEach((file: any) => {
        if (file.errors[0].code === 'file-too-large') {
          setUploadError(invalidSizeMessage);
        } else {
          setUploadError(invalidTypeMessage);
        }
      });
    } else {
      setUploadError('');
    }
  }, []);
  // set props
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    multiple: false,
    maxSize,
  });

  // const {acceptedFiles, getRootProps, getInputProps, inputRef} = useDropzone();
  // show image preview
  const imagepreview = () => {
    // if (imageFile?.length > 0 && filemode=='edits') {
    //   return (
    //     <div key="img">
    //       <img src={imageFile} alt="profile" />
    //     </div>
    //   );
    // } else
    if (imageFile?.length > 0 && (filemode == 'edit' || filemode == 'editresource')) {
      return (
        <div key="img">
          <img src={BUCKET_URL + imageFile} alt="Image not found" />
        </div>
      );
    }
    return files.map((item: any) => (
      <div key={item.name}>
        <img src={item.preview} alt={item.name} />
      </div>
    ));
  };
  // set picture
  useEffect(() => {
    if (files.length === 1) {
      setPicture(files[0]);
    }
  }, [files]);
  // Set error
  useEffect(() => {
    if (error.length > 0) {
      setError(error);
    } else {
      setError('');
    }
  }, [error]);
  const resetFiles = () => {
    setFiles([]);
  };
  return (
    <section className="image-uploader">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div>
          {files?.length === 0 && imageFile?.length === 0 && (
            <div className="text-center">
              <div className="drag-icon">
                <Upload />
              </div>
              <div className="upload">{mode === 'addresource' ? <FormattedMessage id="Image.Upload.Thumbnail" /> : <FormattedMessage id="Image.Upload.Text" />}</div>
            </div>
          )}
          {imagepreview()}
        </div>
      </div>
      {files.length > 0 && (
        <button className="hidebtn" id="resetUploadedFile" onClick={resetFiles}>
          Reset
        </button>
      )}
    </section>
  );
};

export default DropzoneImageUpload;
DropzoneImageUpload.defaultProps = {
  maxSize: UploaderProps.IMAGE_MAX_SIZE,
};
