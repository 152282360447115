/**
 * @file   src\containers\Login.tsx
 * @brief  Login page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import HomeLayout from '../components/HomeLayout';
import NotFound from "../assets/img/icon/404";
import { Button } from 'react-bootstrap';

const PageNotFound = () => {

  return (
    <HomeLayout>
      <div className="login-main">
        <div className='pagenotfound'><NotFound/> 404</div>
        <h1 className='mb-3'>
        Page not found
        </h1>
        <h6 className='mb-5'>Oops! The page you are looking for does not exist. It might have been moved or delete. </h6>
        <Button  variant="primary">
            Back to Login
          </Button>
      </div>
    </HomeLayout>
  );
};
export default PageNotFound;
