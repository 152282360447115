import React from "react";
import LoaderImg from '../assets/img/Loader.gif';
const Loader = () => {
    return (
        <div className="loader-outer">
            <div className="p-3 text-center">
                <img src={LoaderImg} alt="loading" />
            </div>
        </div>
    );
}
export default Loader;