/**
 * @file   src\containers\organizations\AddTaskEvent.tsx
 * @brief  Add Task Event Compnent For Goal Step 3 and 4
 * @date   Apr, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from 'react-bootstrap';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import { Scrollbars } from 'react-custom-scrollbars';
import { useIntlMessages, dateDiffInHours } from '../../utils/helper';
import DatePicker from 'react-datepicker';
import { IAddEventTaskProps } from '../../interfaces/OrganisationInterface';
import { ISelectOptionsNumber, ISelectOption } from '../../interfaces/GeneralInterface';
import { NumberValues, TaskEventContext } from '../../utils/enums';
// import { Form } from 'react-bootstrap';
import '../../assets/css/Input.scss';

const AddTaskEvent: React.FC<IAddEventTaskProps> = ({
  context,
  categories,
  subcategories,
  changeCategory,
  changeSubCategory,
  changeDate,
  validateField,
  taskFormErrors,
  eventFormErrors,
  errorVisible,
  eventData,
  taskData,
  startDate,
  endDate,
  category,
  subCategory,
  isGeneral,
  goalStartDate,
  goalEndDate,
  addEdit,
  startDateError,
  endDateError,
}) => {
  // Labels and placeholder texts
  const taskNameLabel = useIntlMessages('Label.NameofTask');
  const eventNameLabel = useIntlMessages('Label.NameofEvent');
  const subCategoryLabel = useIntlMessages('Label.SubCategory');
  const selectPlaceholder = useIntlMessages('PH.Select');
  const eventNamePlaceholder = useIntlMessages('Event.EnterName');
  const taskNamePlaceholder = useIntlMessages('Task.EnterName');
  const descriptionPlaceholder = useIntlMessages('Label.Description');
  const eventDescriptionPlaceholder = useIntlMessages('Event.EnterDescription');
  const taskDescriptionPlaceholder = useIntlMessages('Task.EnterDescription');
  const categoryLabel = useIntlMessages('Label.Category');
  const startDatePlaceholder = useIntlMessages('StartDate.Required.Error');
  const endDatePlaceholder = useIntlMessages('EndDate.Required.Error');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // get error message for fields
  const getErrorMessage = (field: string) => {
    let errorMsg = '';
    if (errorVisible && context === TaskEventContext.EVENT) {
      errorMsg = eventFormErrors[field];
    } else if (errorVisible && context === TaskEventContext.TASK) {
      errorMsg = taskFormErrors[field];
    }
    return errorMsg;
  };
  // get error message for subcategory
  const getErrorSubCategory = () => {
    let errorMsg = '';
    if (errorVisible && context === TaskEventContext.EVENT) {
      errorMsg = eventFormErrors['SubCategoryIds'];
    } else if (errorVisible && context === TaskEventContext.TASK) {
      errorMsg = taskFormErrors['SubCategories'];
    }
    return errorMsg;
  };
  // Calculate durtion value
  const getDurationValue = () => {
    let duration = 0;
    if (endDate !== null && startDate !== null) {
      duration = dateDiffInHours(endDate, startDate);
    }
    return `${duration} Hrs`;
  };

  return (
    <div id="filter-wrapper" className="filter-main">
      <Scrollbars className="h-80vh">
        <h4>
          <FormattedMessage
            id={
              addEdit === 'add'
                ? context === TaskEventContext.EVENT
                  ? 'Button.AddEvent'
                  : 'Button.AddTask'
                : context === TaskEventContext.EVENT
                ? 'Button.EditEvent'
                : 'Button.EditTask'
            }
          />
        </h4>
        <Col className="form-sm mt-4">
          <Input
            label={context === TaskEventContext.TASK ? taskNameLabel : eventNameLabel}
            id="Resource"
            name="Name"
            type="text"
            placeholder={context === TaskEventContext.EVENT ? eventNamePlaceholder : taskNamePlaceholder}
            maxLength={NumberValues.NUM_100}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => validateField(e, context)}
            errorMessage={getErrorMessage('Name')}
            value={context === TaskEventContext.TASK ? taskData.Name : eventData.Name}
          />
        </Col>
        <Col className="addOrg-textarea">
          <Input
            label={descriptionPlaceholder}
            id="Description"
            name="Description"
            type="text"
            placeholder={context === TaskEventContext.EVENT ? eventDescriptionPlaceholder : taskDescriptionPlaceholder}
            maxLength={NumberValues.NUM_1000}
            as="textarea"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => validateField(e, context)}
            errorMessage={getErrorMessage('Description')}
            value={context === TaskEventContext.TASK ? taskData.Description : eventData.Description}
          />
          <span className="txt-count">
            {context === TaskEventContext.TASK ? taskData.Description.length : eventData.Description.length}/{NumberValues.NUM_1000}
          </span>
        </Col>
        {!isGeneral && (
          <div className="d-flex">
            <Col className="form-sm pe-2">
              <Select
                label={categoryLabel}
                options={categories}
                value={category}
                placeholder={selectPlaceholder}
                onChange={(e: ISelectOption) => changeCategory(e, context)}
                error={getErrorMessage('CategoryId')}
                isDisabled={true}
              />
            </Col>
            <Col className="form-sm ps-2">
              <Select
                label={subCategoryLabel}
                options={subcategories}
                value={subCategory}
                placeholder={selectPlaceholder}
                onChange={(e: ISelectOptionsNumber) => changeSubCategory(e, context)}
                error={getErrorSubCategory()}
                isMulti
              />
            </Col>
          </div>
        )}
        <div className="custom-calendar form-sm mb-4">
          <label className="form-label">
            <FormattedMessage id="Label.StartDateTime" />
          </label>
          <DatePicker
            selected={startDate}
            minDate={goalStartDate ? goalStartDate : null}
            maxDate={endDate}
            onChange={(date) => {
              if (context === TaskEventContext.EVENT) {
                changeDate(date, 'Event_Startdate');
              } else {
                changeDate(date, 'Task_Startdate');
              }
            }}
            showTimeSelect
            timeIntervals={1}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            placeholderText={startDatePlaceholder}
            onKeyDown={(e) => e.preventDefault()}
          />
          {context === TaskEventContext.TASK && taskFormErrors?.StartDateWithTime.length > 0 && errorVisible ? (
            <label className="error">{taskFormErrors.StartDateWithTime}</label>
          ) : null}
          {context === TaskEventContext.EVENT && eventFormErrors?.EventStartDate.length > 0 && errorVisible ? (
            <label className="error">{eventFormErrors.EventStartDate}</label>
          ) : null}
          {startDateError?.length > 0 ? <label className="error">{startDateError}</label> : null}
        </div>
        <div className="custom-calendar form-sm mb-4">
          <label className="form-label">
            <FormattedMessage id="Label.EndDateTime" />
          </label>
          <DatePicker
            selected={endDate}
            minDate={startDate}
            maxDate={goalEndDate ? goalEndDate : null}
            onChange={(date) => {
              if (context === TaskEventContext.TASK) {
                changeDate(date, 'Task_Enddate');
              } else {
                changeDate(date, 'Event_Enddate');
              }
            }}
            showTimeSelect
            timeIntervals={1}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            placeholderText={endDatePlaceholder}
            onKeyDown={(e) => e.preventDefault()}
          />
          {context === TaskEventContext.TASK && taskFormErrors?.EndDateWithTime.length > 0 && errorVisible ? (
            <label className="error">{taskFormErrors.EndDateWithTime}</label>
          ) : null}
          {context === TaskEventContext.EVENT && eventFormErrors?.EventEndDate.length > 0 && errorVisible ? <label className="error">{eventFormErrors.EventEndDate}</label> : null}
          {endDateError?.length > 0 ? <label className="error">{endDateError}</label> : null}
        </div>
        {context === TaskEventContext.EVENT && (
          <div className="custom-calendar form-sm mb-4">
            <label className="form-label">
              <FormattedMessage id="Label.Duration" />
            </label>
            <Input name="duration" value={getDurationValue()} status={true} />
          </div>
        )}
      </Scrollbars>
    </div>
  );
};

export default AddTaskEvent;
