/**
 * @file   src\containers\Login.tsx
 * @brief  Login page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';
import icnEye from '../assets/img/icnEye.svg';
import eyeOff from '../assets/img/eye-off.svg';
import Input from '../components/MAInput';
import Checkbox from '../components/MACheck';
import HomeLayout from '../components/HomeLayout';
import { IAuthRequest } from '../interfaces/AuthInterface';
import { validateForm } from '../utils/formValidation';
import { SIGNIN_SCHEMA } from '../validations/authSchema';
import { MessageToaster } from "../utils/ToastUtil";
import { loginUser } from "../store/actions/authActions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { RootState } from '../store';
import Loader from '../components/Loader';
import { setItemLocalStorage,removeItemLocalStorage,useIntlMessages } from '../utils/helper';

// Toast object creation.
const toast = new MessageToaster();

const Login = () => {

  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();

  const loginWithCredentials = () => dispatch(loginUser({
    "Email": loginForm.username.trim(),
    "Password": loginForm.password
  }))
  // Access redux state variables
  const { loginApiData, loginApiLoading, loginApiSuccess, responseCode, responseMessage } = useAppSelector(
    (state: RootState) => state.authentication,
  );
  // Ref object creation.
  const buttonRef = useRef<any>();
  // Initialize component state variables.
  const [loginForm, setLoginForm] = useState<IAuthRequest>({
    username: '',
    password: '',
  });
  const [errorFields, setErrorFields] = useState<any>({});
  const [showPassword, setShowPassword] = useState<boolean>(false);
  // handle focus
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      const updateForm = { ...loginForm, username: storedUsername, password: '' };
      setLoginForm(updateForm);
    }
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        buttonRef?.current?.focus();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  // handle validation message
  useEffect(()=>{
    if(responseCode===200 && loginApiSuccess){
      setItemLocalStorage("MI_USR_DATA",loginApiData);
      navigate('/home')
    }
    if (responseCode > 200 && responseMessage?.length > 0) {
      toast.toastError(responseMessage);
    }
  }, [loginApiLoading])

  // handle form fields validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLoginForm((info) => ({
      ...info,
      [name]: value,
    }));
    const validateObj = {
      [name]: value,
    };
    const errorresult = await validateForm(validateObj, SIGNIN_SCHEMA, errorFields);
    setErrorFields(errorresult);
  };
  // Login form submit event.
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errorresult = await validateForm(loginForm, SIGNIN_SCHEMA, errorFields);
    if (Object.keys(errorresult).length > 0) {
      setErrorFields(errorresult);
    } else {
      loginWithCredentials();
    }
  };
  // Show/hide password text.
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  // Function to handle "Remember Me" checkbox state change
  const handleRememberMeChange = (event: any) => {
    if (!event.target.checked) {
      removeItemLocalStorage('username');
    }
    else {
      localStorage.setItem('username', loginForm.username);
    }
  };

  return (
    <HomeLayout>
      <div className="login-main">
        <h1>
          <FormattedMessage id="Hd.SystemAdmin" />
        </h1>
        <h2>
          <FormattedMessage id="Hd.Signin" />
        </h2>
        <form name="loginform" onSubmit={onSubmit} >
          <Col>
            <Input
              label={useIntlMessages('Label.Email')}
              id="username"
              name="username"
              type="text"
              placeholder={useIntlMessages('PH.Email')}
              maxLength={200}
              errorMessage={errorFields?.username}
              onChange={onInputHandleChange}
              value={loginForm.username}
            />
          </Col>
          <Col className="position-relative">
            <Input
              label={useIntlMessages('Label.Password')}
              id="Password"
              name="password"
              type={!showPassword ? "password" : "text"}
              placeholder={useIntlMessages('PH.Password')}
              maxLength={200}
              errorMessage={errorFields?.password}
              onChange={onInputHandleChange}
              value={loginForm.password}
            />
            <Button variant="outline-secondary" className="btn-eye" onClick={togglePassword}>
              <img src={!showPassword ? icnEye : eyeOff} alt="" />
            </Button>
          </Col>
          <Col className="mb-4">
            <Checkbox type="Checkbox" label={useIntlMessages('Label.RememberMe')} onChange={handleRememberMeChange}  />
          </Col>

          <Button className="w-100 mb-4" variant="primary" type="submit" ref={buttonRef}>
            <FormattedMessage id="Button.Signin" />
          </Button>

          <div className="text-center">
            <Link to="/forgotpassword" className="text-center">
              <FormattedMessage id="Link.ForgorPassword" />
            </Link>
          </div>
        </form>
      </div>
      {loginApiLoading && <Loader/>}
    </HomeLayout>
  );
};
export default Login;
