/**
 * @file   src\validations\organisationSchema.ts
 * @brief  This file is responsible for defining organisation validation schemas.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { COUNTRY_CODE, EMAIL_REGEX, PHONE_NUMBER_REGEX, ZIP_REGEX } from '../utils/constants';

// Organisation Form Schema
export const ORGANISATION_SCHEMA = yup.object({
  Name: yup
    .string()
    .trim()
    .min(3, useIntlActionMessages('Form.OrganisationName.Min'))
    .max(100, useIntlActionMessages('Form.OrganisationName.Max'))
    .required(useIntlActionMessages('Form.OrganisationName.Required')),
  OrganizationTypeId: yup.number().min(1, useIntlActionMessages('Form.OrgType.Required')),
  AdminEmail: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Form.Email.Required')),
  CountryCode: yup.string().required(useIntlActionMessages('Form.CountryCode.Required')).matches(COUNTRY_CODE, useIntlActionMessages('Form.CountryCode.Required')),
  PhoneNo: yup.string().required(useIntlActionMessages('Form.Phoneno.Required')).matches(PHONE_NUMBER_REGEX, useIntlActionMessages('Form.Phoneno.Required')),
  Address: yup
    .string()
    .required(useIntlActionMessages('Form.Address.Required'))
    .max(1000, useIntlActionMessages('Form.Address.Max'))
    .min(5, useIntlActionMessages('Form.Address.Min')),
  CityName: yup.string().required( useIntlActionMessages('Form.City.Required')),
  // zip: yup.number().required(useIntlActionMessages('Form.Zip.Required')),
  zip: yup.string().required(useIntlActionMessages('Form.Zip.Required')).matches(ZIP_REGEX, useIntlActionMessages('Form.Zip.Invalid')),
  OrganizationStatus: yup.string().required(useIntlActionMessages('Form.Org.Status.Required')),
  State: yup.number().min(1, useIntlActionMessages('Form.State.Required')),
});

// Add task schema in add goal
export const ADD_TASK_SCHEMA = yup.object({
  Name: yup.string().required().max(100).min(4),
  Description: yup.string().required().max(1000).min(5),
  StartDateWithTime: yup.string().required(),
  EndDateWithTime: yup.string().required(),
  GoalId: yup.number().required(),
  CategoryId: yup.number().required(),
  SubCategories: yup.array().required(),
});
// Add event schema in add goal
export const ADD_EVENT_SCHEMA = yup.object({
  Name: yup.string().required().max(100).min(4),
  Description: yup.string().required().max(1000).min(5),
  CategoryId: yup.number().required(),
  EventEndDate: yup.string().required(),
  GoalId: yup.number().required(),
  EventStartDate: yup.string().required(),
  SubCategoryIds: yup.array().required(),
});
// Add task schema in add goal
export const ADD_TASK_SCHEMA_GENERAL = yup.object({
  Name: yup.string().required().max(100).min(4),
  Description: yup.string().required().max(200).min(5),
  StartDateWithTime: yup.string(),
  EndDateWithTime: yup.string(),
  GoalId: yup.number().required(),
});
// Add event schema in add goal
export const ADD_EVENT_SCHEMA_GENERAL = yup.object({
  Name: yup.string().required().max(100).min(4),
  Description: yup.string().required().max(200).min(5),
  EventEndDate: yup.string(),
  GoalId: yup.number().required(),
  EventStartDate: yup.string(),
});
