const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="20" height="18.667" viewBox="0 0 20 18.667">
      <defs>
        <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fad900" />
          <stop offset="1" stop-color="#f5933d" />
        </linearGradient>
      </defs>
      <g id="logout" transform="translate(-1 -1)">
        <path id="Path_10466" data-name="Path 10466" d="M1,1H12V6.333H6v8h6v5.333H1Z" fill="url(#linear-gradient)" />
        <path id="Path_10467" data-name="Path 10467" d="M11,8.75V5l5,5.333-5,5.333v-3.75H2V8.75Z" transform="translate(5)" fill="url(#linear-gradient)" />
      </g>
    </svg>
  );
};
export default LogoutIcon;