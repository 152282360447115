/**
 * @file   src/containers/profile/UserHierarchyList.tsx
 * @brief  User hierarchy list component
 * @date   Sep, 2024
 * @author ZCO
 * @copyright (c) 2024, ZCO */

import { Button, Row, Col, Nav, NavDropdown } from 'react-bootstrap';
import addUser from '../../assets/img/add-usr-icn.svg';
import { end } from '@popperjs/core';
import { FormattedMessage } from 'react-intl';
import More from '../../assets/img/More.svg';
import Pagination from '../../components/Pagination';
import { useEffect } from 'react';
import { BUCKET_URL } from '../../utils/constants';
import { IUserHierarchyProps, IUserHierarchyMap } from '../../interfaces/UserInterface';
import { useParams, useNavigate } from 'react-router-dom';
import { RoleTypeIds } from '../../utils/enums';
import { resetUserHierarchyAcPartners, resetUserHierarchyStudents } from '../../store/slices/userSlice';
import { useAppDispatch } from '../../hooks';

const UserHierarchyList = ({ usersList, roleName, count, currentPage, setCurrentPage, pageSize, totalItems }: IUserHierarchyProps) => {
  // route params object creation
  const params = useParams();
  // Navigation object creation
  const navigate = useNavigate();
  // Action dispatch object
  const dispatch = useAppDispatch();
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="content-sub">
      <div className="content-sub-header d-flex justify-content-between align-items-center">
        <div>
          <h4>
            {roleName} <span className="count">({count})</span>
          </h4>
        </div>
        <div className="d-flex justify-content-end">
          <Button className="add-usr-btn" variant="outline-secondary" id="button-addon2" onClick={() => window.alert('Not Implemented')}>
            <img src={addUser} />
          </Button>
        </div>
      </div>
      <div className="content-area-padding content-prof-user">
        <Row>
          {usersList.length == 0 && <h6>No Users To Display</h6>}
          {usersList.map((user: IUserHierarchyMap) => (
            <Col lg={4} className="mb-3" key={user.userid}>
              <div className="user-wrap d-flex">
                <div className="usr-image">
                  <img src={BUCKET_URL + user.prof_image} alt="" />
                </div>
                <div className="usr-name">
                  <p>{user.name}</p>
                  <span className="usr-status-admin small-text">{user.status ? 'Active' : 'Inactive'}</span>
                </div>
                <div className="dot-drop">
                  <Nav>
                    <NavDropdown align={end} className="icon-btn-more" title={<img src={More} alt="more" />}>
                      <NavDropdown.Item
                        onClick={() => {
                          if (Number(params.roleTypeId) === RoleTypeIds.ACCOUNTABILITY_PARTNER) {
                            navigate(`/userDetailsTab/${user.userid}/${RoleTypeIds.APP_USER}`);
                            dispatch(resetUserHierarchyAcPartners());
                          } else if (Number(params.roleTypeId) === RoleTypeIds.APP_USER) {
                            navigate(`/userDetailsTab/${user.userid}/${RoleTypeIds.ACCOUNTABILITY_PARTNER}`);
                            dispatch(resetUserHierarchyStudents());
                          }
                        }}
                      >
                        <FormattedMessage id="Link.viewdetails" />
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </div>
              </div>
            </Col>
          ))}
          {totalItems > pageSize && (
            <Pagination
              pageSize={pageSize}
              totalitems={totalItems}
              pageLimit={pageSize}
              setCurrentPage={(page: number) => setCurrentPage(page)}
              currentPage={currentPage - 1}
              prevPage={-1}
              itemsDisplayed={usersList.length}
            />
          )}
        </Row>
      </div>
    </div>
  );
};

export default UserHierarchyList;
