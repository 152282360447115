import React from 'react';
const RemoveIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="2.929" viewBox="0 0 19 2.929">
            <path id="Remove" d="M208.036,305.964H200v-1.929h18v1.929h-9.964Z" transform="translate(-199.5 -303.536)" fill="#a9e8ff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" />
        </svg>
    );
}

export default RemoveIcon;
