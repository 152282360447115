import React from 'react';

const SubscriptionIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22">
            <defs>
                <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#fad900" />
                    <stop offset="1" stop-color="#f5933d" />
                </linearGradient>
            </defs>
            <path id="Icn_Subscription" d="M89.739-861.966h1.444v-1.365a4.387,4.387,0,0,0,2.494-.984,2.733,2.733,0,0,0,.893-2.139,3.12,3.12,0,0,0-.761-2.179,7.319,7.319,0,0,0-2.572-1.6,11.263,11.263,0,0,1-2.205-1.129,1.575,1.575,0,0,1-.683-1.339,1.559,1.559,0,0,1,.591-1.286,2.516,2.516,0,0,1,1.614-.472,2.488,2.488,0,0,1,1.365.367,2.874,2.874,0,0,1,.971,1.1l1.26-.6a3.735,3.735,0,0,0-1.181-1.444,3.565,3.565,0,0,0-1.732-.63v-1.339H89.791v1.339a3.5,3.5,0,0,0-2.113.984A2.74,2.74,0,0,0,86.9-872.7a2.716,2.716,0,0,0,.787,2.047,7.631,7.631,0,0,0,2.363,1.417,9.116,9.116,0,0,1,2.415,1.326,1.883,1.883,0,0,1,.656,1.457,1.6,1.6,0,0,1-.7,1.352,2.866,2.866,0,0,1-1.746.512,3.04,3.04,0,0,1-1.824-.577,2.994,2.994,0,0,1-1.116-1.575L86.4-866.3a5.5,5.5,0,0,0,1.352,1.9,4.891,4.891,0,0,0,1.982,1.011ZM90.5-859a10.16,10.16,0,0,1-4.069-.827,10.65,10.65,0,0,1-3.347-2.258,10.65,10.65,0,0,1-2.257-3.347A10.16,10.16,0,0,1,80-869.5a10.224,10.224,0,0,1,.827-4.095,10.494,10.494,0,0,1,2.257-3.334,10.764,10.764,0,0,1,3.347-2.244A10.159,10.159,0,0,1,90.5-880a10.224,10.224,0,0,1,4.1.827,10.6,10.6,0,0,1,3.334,2.244,10.6,10.6,0,0,1,2.244,3.334A10.224,10.224,0,0,1,101-869.5a10.16,10.16,0,0,1-.827,4.069,10.764,10.764,0,0,1-2.244,3.347,10.493,10.493,0,0,1-3.334,2.258A10.225,10.225,0,0,1,90.5-859Z" transform="translate(-79.5 880.5)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill="url(#linear-gradient)" />
        </svg>
    );
}

export default SubscriptionIcon;
