import React from 'react'
import { Form } from 'react-bootstrap';
import '../assets/css/Input.scss';
import InputMask from 'react-input-mask';

const Search = (props: any) => {
    return (
        <Form>
            <label className='form-label'>{props.label}</label>
            <div>
                <InputMask mask={props.mask} maskChar=" " className='form-control' />
            </div>
        </Form>
    )
};

export default Search;

