import React from 'react';
const ViewIcon = () => {
    return (
        <svg width="16.742" height="12.449" viewBox="0 0 16.742 12.449">
            <g id="Icon_feather-eye" data-name="Icon feather-eye" transform="translate(0.5 0.5)">
                <path id="Path_11111" data-name="Path 11111" d="M9.371,17.949a8.529,8.529,0,0,1-6.178-3.02,13.975,13.975,0,0,1-2.14-2.98.5.5,0,0,1,0-.447,13.975,13.975,0,0,1,2.14-2.98A8.529,8.529,0,0,1,9.371,5.5a8.529,8.529,0,0,1,6.178,3.02,13.975,13.975,0,0,1,2.14,2.98.5.5,0,0,1,0,.447,13.975,13.975,0,0,1-2.14,2.98A8.529,8.529,0,0,1,9.371,17.949Zm-7.3-6.224A13.6,13.6,0,0,0,3.94,14.263a7.454,7.454,0,0,0,5.431,2.685c4.1,0,6.723-4.2,7.3-5.224A13.6,13.6,0,0,0,14.8,9.185,7.454,7.454,0,0,0,9.371,6.5C5.272,6.5,2.648,10.7,2.069,11.724Z" transform="translate(-1.5 -6)" fill="#fff" />
                <path id="Path_11112" data-name="Path 11112" d="M15.647,13A2.647,2.647,0,1,1,13,15.647,2.65,2.65,0,0,1,15.647,13Zm0,4.293A1.647,1.647,0,1,0,14,15.647,1.648,1.648,0,0,0,15.647,17.293Z" transform="translate(-7.776 -9.922)" fill="#fff" />
            </g>
        </svg>
    );
}

export default ViewIcon;
