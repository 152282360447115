import React from 'react';

const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17.157" viewBox="0 0 24 17.157">
            <defs>
                <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#fad900" />
                    <stop offset="1" stop-color="#f5933d" />
                </linearGradient>
            </defs>
            <path id="Icn_Users" d="M38,291.157v-2.446a3.03,3.03,0,0,1,.468-1.652,2.85,2.85,0,0,1,1.3-1.106,20.63,20.63,0,0,1,3.421-1.2,13.424,13.424,0,0,1,3.135-.364,13.26,13.26,0,0,1,3.122.364,20.806,20.806,0,0,1,3.408,1.2,2.929,2.929,0,0,1,1.314,1.106,2.97,2.97,0,0,1,.481,1.652v2.446Zm18.213,0v-2.446a4.211,4.211,0,0,0-.833-2.693,6.094,6.094,0,0,0-2.186-1.7,25.806,25.806,0,0,1,3.382.611,11.923,11.923,0,0,1,2.576.924,4.027,4.027,0,0,1,1.353,1.223A2.852,2.852,0,0,1,61,288.712v2.446Zm-9.887-8.352a3.743,3.743,0,1,1,2.81-1.093A3.8,3.8,0,0,1,46.326,282.805Zm9.367-3.9a3.743,3.743,0,0,1-3.9,3.9,5.823,5.823,0,0,1-.637-.039,2.753,2.753,0,0,1-.637-.143,4.27,4.27,0,0,0,.95-1.6,6.516,6.516,0,0,0,.325-2.12,6.056,6.056,0,0,0-.325-2.068,5.531,5.531,0,0,0-.95-1.652,5.493,5.493,0,0,1,.637-.13A4.388,4.388,0,0,1,51.79,275a3.743,3.743,0,0,1,3.9,3.9Z" transform="translate(-37.5 -274.5)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill="url(#linear-gradient)" />
        </svg>
    );
}

export default SearchIcon;
