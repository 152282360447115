/**
 * @file   src\validations\subscriptionSchema.ts
 * @brief  This file is responsible for defining subscription validation schemas.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';

// Subscription Form Schema
export const SUBSCRIPTION_SCHEMA = yup.object({
  Title: yup.string().trim().required(useIntlActionMessages('Form.Plan.Name.Required')).max(100, useIntlActionMessages('Form.Plan.Name.Max')),
  Validity: yup.number().notOneOf([0], useIntlActionMessages('Form.Plan.Validity.Required')).required(useIntlActionMessages('Form.Plan.Validity.Required')),
  Amount: yup.number().required(useIntlActionMessages('Form.Amount.Required')).min(0.01, useIntlActionMessages('Form.Amount.Required')),
  WebUserCount: yup.number().integer().min(1, useIntlActionMessages('Form.WebUserCount.Required')),
  AppUserCount: yup.number().integer().min(1, useIntlActionMessages('Form.AppUserCount.Required')),
  OrganizationTypeId: yup.number().notOneOf([0], useIntlActionMessages('Form.OrgType.Required')).required(useIntlActionMessages('Form.OrgType.Required')),
  Organizations: yup.number().notOneOf([0], useIntlActionMessages('Form.SelectOrganizationName.Required')).required(useIntlActionMessages('Form.SelectOrganizationName.Required')),
});
// Subscription Form Schema for general plan
export const SUBSCRIPTION_GENERAL_SCHEMA = yup.object({
  Title: yup.string().trim().required(useIntlActionMessages('Form.Plan.Name.Required')).max(100, useIntlActionMessages('Form.Plan.Name.Max')),
  Validity: yup.number().notOneOf([0], useIntlActionMessages('Form.Plan.Validity.Required')).required(useIntlActionMessages('Form.Plan.Validity.Required')),
  Amount: yup.number().required(useIntlActionMessages('Form.Amount.Required')).min(0.01, useIntlActionMessages('Form.Amount.Required')),
  WebUserCount: yup.number().integer().min(1, useIntlActionMessages('Form.WebUserCount.Required')),
  AppUserCount: yup.number().integer().min(1, useIntlActionMessages('Form.AppUserCount.Required')),
});