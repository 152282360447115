import React from 'react';
const EventIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.399" height="19.332" viewBox="0 0 17.399 19.332">
            <path id="event_FILL1_wght400_GRAD0_opsz48" d="M131.523,191.949a2.318,2.318,0,1,1,1.667-.677A2.253,2.253,0,0,1,131.523,191.949Zm-10.073,3.383a1.487,1.487,0,0,1-1.45-1.45V178.9a1.487,1.487,0,0,1,1.45-1.45h1.571V176h1.571v1.45h8.216V176h1.571v1.45h1.571a1.487,1.487,0,0,1,1.45,1.45v14.983a1.487,1.487,0,0,1-1.45,1.45Zm0-1.45h14.5V183.491h-14.5Z" transform="translate(-120 -176)" fill="#fff" />
        </svg>
    );
}

export default EventIcon;
