/**
 * @file   src\validations\IncentiveResourceSchema.ts
 * @brief  This file is responsible for defining incentive resource validation schemas.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';

// Incentive Resource Schema
export const FINISH_GOAL_SCHEMA = yup.object({
  Points: yup
    .number()
    .typeError(useIntlActionMessages('Form.Goals.kudos.Number'))
    .required(useIntlActionMessages('Form.Goals.kudos.Required'))
    .min(1, useIntlActionMessages('Form.Goals.kudos.Number'))
});
