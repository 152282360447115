const HelpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
      <defs>
        <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fad900" />
          <stop offset="1" stop-color="#f5933d" />
        </linearGradient>
      </defs>
      <path
        id="help"
        d="M110.107,211.778a.8.8,0,0,0,.594-.25.827.827,0,0,0,.244-.6.812.812,0,0,0-.244-.588.8.8,0,0,0-.594-.249.815.815,0,0,0-.6.25.807.807,0,0,0-.249.589.851.851,0,0,0,.849.848Zm-.729-3.619h1.2a3.469,3.469,0,0,1,.2-1.159,3.214,3.214,0,0,1,.975-1.181,5.012,5.012,0,0,0,1.117-1.312,2.825,2.825,0,0,0,.332-1.36,2.754,2.754,0,0,0-.851-2.1,3.081,3.081,0,0,0-2.2-.8,3.649,3.649,0,0,0-2.069.6,3.59,3.59,0,0,0-1.328,1.569l1.1.425a2.509,2.509,0,0,1,.853-1.1,2.3,2.3,0,0,1,1.363-.393,2.073,2.073,0,0,1,1.508.527,1.742,1.742,0,0,1,.543,1.3,1.882,1.882,0,0,1-.322,1.057,5.035,5.035,0,0,1-.929,1.01,5.158,5.158,0,0,0-1.131,1.35A3.241,3.241,0,0,0,109.378,208.159ZM110,216a9.665,9.665,0,0,1-3.879-.787,10.108,10.108,0,0,1-5.335-5.333,10,10,0,0,1,0-7.778,9.992,9.992,0,0,1,2.15-3.176,10.274,10.274,0,0,1,3.183-2.138,10,10,0,0,1,7.778,0,9.985,9.985,0,0,1,5.314,5.312,10,10,0,0,1,0,7.778,10.278,10.278,0,0,1-2.137,3.184,9.986,9.986,0,0,1-3.174,2.151A9.732,9.732,0,0,1,110,216Z"
        transform="translate(-99.501 -195.501)"
        stroke="rgba(0,0,0,0)"
        stroke-miterlimit="10"
        stroke-width="1"
        fill="url(#linear-gradient)"
      />
    </svg>
  );
};
export default HelpIcon;
