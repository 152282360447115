/**
 * @file   src\components\Layout.tsx
 * @brief  This component used as a layout for the content which can be replaced.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/HomeLayout.scss'
import Logo from "../assets/img/Logo.svg";
import logvector from "../assets/img/log-vector.svg";

const HomeLayout = ({ ...props }) => {
  return (
    <Container fluid>
      <Row>
        <Col md={12} lg={6} className='log-lft-bg d-flex align-items-center  justify-content-center'>
          <div>
            <div className='mr-logo text-center'>
              <img src={Logo} alt="" />
            </div>
            <div className='log-vector-lt'>
              <img src={logvector} alt="" />
            </div>
          </div>
        </Col>
        <Col md={12} lg={6} className='lg-rgt-b'>
          <div className='lg-rgt d-flex align-items-center justify-content-center'>
            <Col lg={7} md={7}>
              {props.children}
            </Col>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default HomeLayout;