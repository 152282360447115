import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { SettingsApis } from '../../utils/apiUrls';
import { ISaveSettingsRequest } from '../../interfaces/SettingsInterface';

// API call to save settings.
export const saveSettings = createAsyncThunk('/saveSettings', async (request: ISaveSettingsRequest, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${SettingsApis.MANAGE_SETTINGS}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

// API call to get all onboarding process columns.
export const getOnboardingProcessColumns = createAsyncThunk('/getOnboardingProcessColumns', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${SettingsApis.GET_ONBOARDING_PROCESS_COLUMNS}`,{});
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

// API call to get user role permissions.
export const getUserRolePermissions = createAsyncThunk('/getUserRolePermissions', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${SettingsApis.GET_USERROLE_PERMISSIONS}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

// API call to get settings.
export const getSettings = createAsyncThunk('/getSettings', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${SettingsApis.GET_SETTINGS}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

// API call to CREATE or UPDATE system admin permissions.
export const createOrUpdateSystemAdminPermissions = createAsyncThunk('/createOrUpdateSystemAdminPermissions', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${SettingsApis.CREATE_OR_UPDATE_SYSTEM_ADMIN_PERMISSIONS}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

// API call to get system admin permissions.
export const getSystemAdminPermissions = createAsyncThunk('/getSystemAdminPermissions', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${SettingsApis.GET_SYSTEM_ADMIN_PERMISSIONS}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});