import React from 'react';

const SearchIcon = () => {
    return (
        <svg width="17.208" height="17.207" viewBox="0 0 17.208 17.207">
            <defs>
                <linearGradient id="linear-gradient" x1="0.58" y1="0.363" x2="0.94" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#fad900" />
                    <stop offset="1" stop-color="#f5933d" />
                </linearGradient>
            </defs>
            <path id="search_FILL0_wght400_GRAD0_opsz48" d="M135.022,231l-5.844-5.844a4.9,4.9,0,0,1-1.555.9,5.741,5.741,0,1,1,3.444-3.522,5.64,5.64,0,0,1-.933,1.667L136,230.022Zm-9.311-5.956a4.156,4.156,0,0,0,3.069-1.278,4.379,4.379,0,0,0,0-6.156,4.156,4.156,0,0,0-3.069-1.278,4.356,4.356,0,1,0,0,8.711Z" transform="translate(-119.5 -214.5)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill="url(#linear-gradient)" />
        </svg>

    );
}

export default SearchIcon;
