import { Link } from 'react-router-dom';

const LinkMailTo = (props: any) => {
  return (
    <Link
      to="#"
      className={props?.className}
      onClick={(e) => {
        window.location.href = props.mailTo;
        e.preventDefault();
      }}
    >
      {props.label}
    </Link>
  );
};
export default LinkMailTo;
