/**
 * @file   src\utils\constants.ts
 * @brief  This file is responsible define constants.
 * @date   NOV, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^-_&*?])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const PHONE_NUMBER_REGEX = /^\d{10}$/;
export const ZIP_REGEX = /^\d{5}(?:-\d{4})?$/;
export const ZIP_REGEX_NEW = /^(\d{5})?$/;
export const COUNTRY_CODE = /^\+\d{1,3}$/;
// export const COUNTRY_CODE = /^(\+\d{1,3}|\d{1,4})$/;
export const NAME_REGEX = /^[a-zA-Z_][a-zA-Z_\d\s]*$/;
export const FIRST_NAME_REGEX = /^[a-zA-Z]+(?:[-\s][a-zA-Z]+)*$/;
export const USERNAME_REGEX = /^[a-zA-Z0-9_.]+$/;
export const NUMBER_REGEX = /\d+$/;
export const STATUS_400 = 400;
export const STATUS_401 = 401;
export const API_TIMEOUT = 30000;
export const API_MAXCONTENTLENGTH = 1000;
export const API_REDIRECTS = 5;
export const AUTH_API_URL = 'https://devauth.mirrorim.com';
export const ADMIN_API_URL = 'https://dev-admin.mirrorim.com';
export const APP_USER_URL = 'https://devappuser.mirrorim.com';
// export const AUTH_API_URL = 'https://betaauth.mirrorim.com';
// export const ADMIN_API_URL = 'https://betaadmin.mirrorim.com';
// export const APP_USER_URL = 'https://betaappuser.mirrorim.com';
export const AUTH_NOT_REQUIRED_URLS = [AUTH_API_URL + '/auth/login', AUTH_API_URL + '/auth/forgotpassword', AUTH_API_URL + '/auth/verify-email-and-change-password'];
export const REFRESH_TOKEN_URLS = [AUTH_API_URL + '/auth/refreshtoken'];
export const BUCKET_URL = 'https://betaassets.mirrorim.com/';
export const PAGE_SIZE_6 = 6;
export const PAGE_SIZE_9 = 9;
export const PAGE_SIZE = 10;
export const PAGE_SIZE_12 = 12;
export const DEFAULT_PAGE_INDEX = 1;
export const INPUT_MAX_LENGTH_200 = 200;
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ssZ';
export const DATE_TIME_FORMAT_DISPLAY = 'MMM DD, YYYY | hh:mm A';
export const DATE_FORMAT1 = 'YYYY-MM-DD';
export const DEFAULT_SELECT_OPTION_NUMBER = { label: 'Select', value: 0 };
export const DATE_TIME_FORMAT2 = 'YYYY-MM-DD hh:mm:ss';
export const DATE_TIME_FORMAT_DATEPICKER = 'MM/dd/yyyy h:mm aa';
export const PAGE_SIZE_SORT_50 = 50;
export const MAIL_TO_ADDRESS = 'support@mirrorim.com';
export const PAGE_SIZE_REVIEW_PROOF = 2;
