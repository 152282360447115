/**
 * @file   src\store\actions\goalActions.ts
 * @brief  This file is responsible for creating goal based api call.
 * @date   MAY, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { GoalApis } from '../../utils/apiUrls';
import { IGetAssignmentStudentsList, IAssignmentAttachmentApi,IAssignmentStatusCountApi,IApproveAssignmentApi,IRejectAssignmentApi,IMessageAssignmentApi } from '../../interfaces/GoalInterface';

// Api call to list competencies under goal
export const listGoalCompetencies = createAsyncThunk('/listGoalCompetencies', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.LIST_GOAL_COMPETENCIES}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to list tasks and events under goal
export const listGoalTasksEvents = createAsyncThunk('/listGoalTasksEvents', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.LIST_GOAL_TASKS_EVENTS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Assign goal to users
export const assignGoalToUsers = createAsyncThunk('/assignGoalToUsers', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.ASSIGN_GOAL_TO_USERS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to get assigned users to goal
export const getAssignmentStudentsList = createAsyncThunk('/getAssignmentStudentsList', async (request: IGetAssignmentStudentsList, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.GET_ASSIGNMENT_STUDENTS_LIST}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get assigned users to goal
export const getAssignmentProofs = createAsyncThunk('/getAssignmentProofs', async (request: IAssignmentAttachmentApi, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.GET_ASSIGNMENT_PROOFS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to approve assignment
export const approveAssignment = createAsyncThunk('/approveAssignment', async (request: IApproveAssignmentApi, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.APPROVE_ASSIGNMENT}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to reject assignment
export const rejectAssignment = createAsyncThunk('/rejectAssignment', async (request: IRejectAssignmentApi, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.REJECT_ASSIGNMENT}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to get assignment count for each status
export const getAssignmentStatusCount = createAsyncThunk('/getAssignmentStatusCount', async (request: IAssignmentStatusCountApi, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.GET_ASSIGNMENT_STATUS_COUNT}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to reject assignment
export const sendAssignmentMessage = createAsyncThunk('/sendAssignmentMessage', async (request: IMessageAssignmentApi, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.SEND_ASSIGNMENT_MESSAGE}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to get user list with goal/survey assigned status
export const listUsersWithSurveyGoalAssignmentDetails = createAsyncThunk('/listUsersWithSurveyGoalAssignmentDetails', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.LIST_USERS_WITH_SURVEY_GOAL_ASSIGNMENT_DETAILS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
