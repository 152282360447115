/**
 * @file   src\containers\organizations\View.tsx
 * @brief  Organization details view page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from 'react';
import '../../assets/css/org.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, setItemLocalStorage, getFromLocalStorage, useIntlActionMessages, isUserAdmin } from '../../utils/helper';
import { Col, Row, Breadcrumb, Nav, NavDropdown, Button, Tab, Tabs, Table, Accordion } from 'react-bootstrap';
import Select from '../../components/MASelect';
import PerfomanceCard from '../../components/PerfomanceCard';
import CompetenciesCard from '../../components/CompetenciesCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import More from '../../assets/img/More.svg';
import { end } from '@popperjs/core';
import RightIcon from '../../assets/img/icon/RightArrow';
import Close from '../../assets/img/Close.svg';
import Search from '../../components/MASearch';
import Pagination from '../../components/Pagination';
import { slide as Filter } from 'react-burger-menu';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Checkbox from '../../components/MACheck';
import Sort from '../../assets/img/icon/Sort';
import ProductivityGraph from './ProductivityGraph';
import { RootState } from '../../store';
import Loader from '../../components/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getOrganizationInfo, deleteOrganization, getStudentProductivity, getStudentProductiveTimeData } from '../../store/actions/organizationActions';
import Default from '../../assets/img/default.jpg';
import { MessageToaster } from '../../utils/ToastUtil';
import { IDeleteOrganizationApiParams, IViewOrganizationInfoApiParams, IOrganization } from '../../interfaces/OrganisationInterface';
import UserTableRows from '../manageusers/UserTable';
import { UsersSortFields, SortOrder, RoleTypeIds } from '../../utils/enums';
import { fetchUsersList, getStudentTags } from '../../store/actions/userActions';
import { IUserApiParams, IStudentFilters } from '../../interfaces/UserInterface';
import { getUserRolesByOrgType, getCategoryDetailsById, getUserTagsByOrgType } from '../../store/actions/organizationTypeActions';
import { ISelectOption, ISelectOptionsNumber, ISelectOptionBoolean } from '../../interfaces/GeneralInterface';
import useDebounce from '../../hooks/useDebounce';
import { PAGE_SIZE, BUCKET_URL } from '../../utils/constants';
import { resetGoalListApiData, resetGetStudentProductivity, resetGetStudentProductiveTimeData } from '../../store/slices/organizationSlice';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import { isTeacherWebUser, getCurrentOrgDetails, isOrgAdmin, isOrgAdminManager } from '../../utils/helper';
import { getTopCompetenciesList } from '../../store/actions/competencyActions';
import { getUserCountByOrganizationType, getOverallPerformanceBySubcategory } from '../../store/actions/dashboardActions';
import { getUserGoalsAndSurveysOverview } from '../../store/actions/overviewAction';
import moment from 'moment';
const defaultApiParams = {
  Availability: true,
  Category: [],
  Completed: false,
  DOJ: '',
  InProgress: false,
  NotStarted: false,
  OrganizationId: '',
  Overdue: false,
  Page: 0,
  PageSize: 10,
  Rescheduled: false,
  RoleTypeId: 1,
  Search: '',
  SortColumn: UsersSortFields.NAME,
  SortOrder: SortOrder.ASC,
  SubCategories: [],
  Tags: [],
};

const defaultStudentFilters = {
  Completed: false,
  Rescheduled: false,
  InProgress: false,
  NotStarted: false,
  Overdue: false,
};

const availabilityOptions = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
];
// Declare default params for listing top 6 competencies
const defaultTopListApiParams = {
  DateRange: '',
  OrganizationID: '',
  TagOptions: [],
  StartDate: '',
  EndDate: '',
};

const defaultGetStudentProductivityApiParams = {
  OrganizationID: '',
  DateRange: 'Last_7_days',
  Tags: [],
};

const defaultGetStudentProductiveTimeDataApiParams = {
  OrganizationID: '',
  DateRange: 'Last_7_days',
  Tags: [],
};
// Declare default params for overall performance
const defaultOverallPerformanceApiParams = {
  OrganizationID: '',
  DateRange: 'Last_7_days',
  Tags: [],
};
const goalsAndSurveysOverviewDefaultParams = {
  OrganizationID: '',
  Tags: [],
};
const ViewOrganization = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Location object
  const location = useLocation();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();

  // Access redux state variables
  const {
    organizationDetailApiData,
    organizationDetailApiLoading,
    organizationDetailApiSuccess,
    deleteOrganizationApiLoading,
    deleteOrganizationApiSuccess,
    deleteOrganizationResponseCode,
    deleteOrganizationResponseMessage,
    getStudentProductivityApiLoading,
    getStudentProductivityApiSuccess,
    getStudentProductivityApiResponseCode,
    getStudentProductivityApiData,
    getStudentProductiveTimeDataApiData,
  } = useAppSelector((state: RootState) => state.organization);

  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');

  // Accessing redux state variables
  const { userListApiData, userListApiLoading, userListApiSuccess, getStudentTagsApiData, getStudentTagsApiLoading } = useAppSelector((state: RootState) => state.user);
  const { rolesByOrgTypeData, rolesByOrgTypeLoading, categoryDetailsByOrgIdLoading, categoryDetailsByOrgIdData, tagsByOrgTypeData, tagsByOrgTypeLoading } = useAppSelector(
    (state: RootState) => state.organizationType,
  );
  const { competenciesTopListData, competenciesTopListApiSuccess, competenciesTopListApiLoading } = useAppSelector((state: RootState) => state.competency);
  const {
    userCountListData,
    userCountListApiSuccess,
    subcategorywiseoverallPerformanceListApiSuccess,
    subcategorywiseoverallPerformanceListData,
    subcategorywiseoverallPerformanceListApiLoading,
  } = useAppSelector((state: RootState) => state.dashboard);
  const {
    getUserGoalsAndSurveysOverviewApiLoading,
    getUserGoalsAndSurveysOverviewApiResponseCode,
    getUserGoalsAndSurveysOverviewApiSuccess,
    getUserGoalsAndSurveysOverviewApiData,
  } = useAppSelector((state: RootState) => state.overview);
  // Declare constants
  const selectProductivityDays = [
    { label: 'Last 7 days', value: 'Last_7_days' },
    { label: 'Last 10 days', value: 'Last_10_days' },
    { label: 'Last 15 days', value: 'Last_15_days' },
  ];
  const selectDays = [
    { label: 'Last 7 days', value: 'Last_7_days' },
    { label: 'Last 10 days', value: 'Last_10_days' },
    { label: 'Last 15 days', value: 'Last_15_days' },
  ];

  // Initialize setting values
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Initialize component state variables.
  const [dateFilter2, setDateFilter2] = useState<any>(selectDays[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteStatus, setDeleteStatus] = useState<boolean>(false);
  const [apiParams, setApiParams] = useState<IUserApiParams>(defaultApiParams);
  const [dateFilter, setDateFilter] = useState<Date | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [dateString, setDateString] = useState<string>('');
  const [categories, setCategories] = useState<ISelectOption[]>([]);
  const [subcategories, setSubCategories] = useState<ISelectOptionsNumber[]>([]);
  const [studentFilters, setStudentFilters] = useState<IStudentFilters>(defaultStudentFilters);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOption[]>([]);
  const [selectedClass, setSelectedClass] = useState<ISelectOptionsNumber[]>([]);
  const [selectedStudentTag, setSelectedStudentTag] = useState<string[]>([]);
  const [availability, setAvailability] = useState<ISelectOptionBoolean[]>([availabilityOptions[0]]);
  const [previousApiParams, setPreviousApiParams] = useState<IUserApiParams>(defaultApiParams);
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const [apiTopListParams, setTopListApiParams] = useState<any>(defaultTopListApiParams);
  const [apiOverallPerformanceApiParams, setOverallPerformanceApiParams] = useState<any>(defaultOverallPerformanceApiParams);
  const [orgId, setOrgId] = useState<string>('');
  const [productivityDays, setProductivityDays] = useState<any>(selectProductivityDays[0]);
  const [tagOptions, setTagOptions] = useState<ISelectOption[]>([]);
  const [studentTagOptions, setStudentTagOptions] = useState<ISelectOption[]>([]);
  const [getStudentProductivityApiParams, setGetStudentProductivityApiParams] = useState<any>(defaultGetStudentProductivityApiParams);
  const [getStudentProductiveTimeDataApiParams, setGetStudentProductiveTimeDataApiParams] = useState<any>(defaultGetStudentProductiveTimeDataApiParams);
  const [selectedTags, setSelectedTags] = useState<ISelectOption[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);

  const debouncedSearch = useDebounce(searchText, 500);
  const [goalsAndSurveysOverviewApiParams, setGoalsAndSurveysOverviewApiParams] = useState<any>(goalsAndSurveysOverviewDefaultParams);
  const [totalGoalCount, setTotalGoalCount] = useState<number>(0);
  const [totalSurveyCount, setTotalSurveyCount] = useState<number>(0);
  const [totalTaskCount, setTotalTaskCount] = useState<number>(0);
  const [totalEventCount, setTotalEventCount] = useState<number>(0);
  const [goalsAndSurveysCompletionPercentage, setGoalsAndSurveysCompletionPercentage] = useState<number>(0);
  const [selfCreatedGoalsCompletionPercentage, setSelfCreatedGoalsCompletionPercentage] = useState<number>(0);
  const [completedCount, setCompletedCount] = useState<number>(0);
  const [inProgressCount, setInProgressCount] = useState<number>(0);
  const [notStartedCount, setNotStartedCount] = useState<number>(0);
  const [overdueCount, setOverdueCount] = useState<number>(0);

  const [productivityGraphSeriesData, setProductivityGraphSeriesData] = useState<number[]>([]);
  const [productivityGraphXaxisCategories, setProductivityGraphXaxisCategories] = useState<string[]>([]);
  const [isProductivityLoading, setIsProductivityLoading] = useState<boolean>(true);
  const [isOpenOrganizationFilter, setIsOpenOrganizationFilter] = useState<boolean>(false);
  const labelNill = useIntlMessages('Label.Nill');
  const labelNoData = useIntlMessages('Label.Nodata');
  const noPlans = useIntlMessages('Label.NoPlans');
  const NoUserdataText = useIntlMessages('Label.NoUserdataText');
  // Handle top 6 tile style
  const tileClasses = ['dash-tile students', 'dash-tile accountability', 'dash-tile teachers', 'dash-tile managers', 'dash-tile admins'];

  // works on unmounting.
  useEffect(() => {
    return () => {
      dispatch(resetGetStudentProductivity());
      dispatch(resetGetStudentProductiveTimeData());
    };
  }, []);

  // Get organization details
  useEffect(() => {
    window.scrollTo(0, 0);
    const userData = getFromLocalStorage('MI_USR_DATA');
    let orgId = '';
    if (location?.state?.organizationId) {
      const viewOrganizationInfoParams: IViewOrganizationInfoApiParams = {
        OrganizationId: location?.state?.organizationId,
      };
      orgId = viewOrganizationInfoParams.OrganizationId;
      dispatch(getOrganizationInfo(viewOrganizationInfoParams));
    } else if (isTeacherWebUser() || userData.RoleTypeId === RoleTypeIds.ORGANIZATION_MANGER) {
      const viewOrganizationInfoParams: IViewOrganizationInfoApiParams = {
        OrganizationId: userData.OrganizationId,
      };
      orgId = viewOrganizationInfoParams.OrganizationId;
      dispatch(getOrganizationInfo(viewOrganizationInfoParams));
    } else if (isOrgAdmin()) {
      const viewOrganizationInfoParams: IViewOrganizationInfoApiParams = {
        OrganizationId: getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : userData.OrganizationId,
      };
      orgId = viewOrganizationInfoParams.OrganizationId;
      dispatch(getOrganizationInfo(viewOrganizationInfoParams));
    }
    setSelectedTags(location?.state?.selectedTags);
    const tags = location?.state?.selectedTags ? location?.state?.selectedTags.map((tag: any) => tag.value) : [];
    setOrgId(orgId);
    setOverallPerformanceApiParams((apiParams: any) => ({
      ...apiParams,
      OrganizationID: orgId,
      Tags: tags,
    }));
    setTopListApiParams((apiParams: any) => ({
      ...apiParams,
      OrganizationID: orgId,
      TagOptions: tags,
    }));
    setGoalsAndSurveysOverviewApiParams((apiParams: any) => ({
      ...apiParams,
      OrganizationID: orgId,
      Tags: tags,
    }));
    dispatch(
      getUserTagsByOrgType({
        OrganizationID: orgId,
        searchText: '',
      }),
    );
    dispatch(
      getStudentTags({
        OrganizationID: orgId,
        searchText: '',
      }),
    );
  }, [localStorage.getItem('CURRENT_ORG')]);

  useEffect(() => {
    if (goalsAndSurveysOverviewApiParams.OrganizationID != '') dispatch(getUserGoalsAndSurveysOverview(goalsAndSurveysOverviewApiParams));
  }, [goalsAndSurveysOverviewApiParams]);

  // Get tags by organization type
  useEffect(() => {
    if (getUserGoalsAndSurveysOverviewApiSuccess && getUserGoalsAndSurveysOverviewApiResponseCode > 0) {
      if (getUserGoalsAndSurveysOverviewApiData) {
        if (getUserGoalsAndSurveysOverviewApiData?.OverviewList?.length > 0) {
          setTotalGoalCount(getUserGoalsAndSurveysOverviewApiData.OverviewList[0]?.TotalGoalCount);
          setTotalSurveyCount(getUserGoalsAndSurveysOverviewApiData.OverviewList[0]?.TotalSurveyCount);
          setTotalTaskCount(getUserGoalsAndSurveysOverviewApiData.OverviewList[0]?.TotalTaskCount);
          setTotalEventCount(getUserGoalsAndSurveysOverviewApiData.OverviewList[0]?.TotalEventCount);
          setGoalsAndSurveysCompletionPercentage(getUserGoalsAndSurveysOverviewApiData.OverviewList[0]?.GoalsAndSurveysCompletionPercentage);
          setSelfCreatedGoalsCompletionPercentage(getUserGoalsAndSurveysOverviewApiData.OverviewList[0]?.SelfCreatedGoalsCompletionPercentage);
        }
        if (getUserGoalsAndSurveysOverviewApiData?.ToDoItems?.length > 0) {
          setCompletedCount(getUserGoalsAndSurveysOverviewApiData?.ToDoItems[0]?.Completed);
          setInProgressCount(getUserGoalsAndSurveysOverviewApiData?.ToDoItems[0]?.InProgress);
          setNotStartedCount(getUserGoalsAndSurveysOverviewApiData?.ToDoItems[0]?.NotStarted);
          setOverdueCount(getUserGoalsAndSurveysOverviewApiData?.ToDoItems[0]?.Overdue);
        }
      }
    }
  }, [getUserGoalsAndSurveysOverviewApiLoading]);

  // Get tags by organization type
  useEffect(() => {
    if (tagsByOrgTypeData && tagsByOrgTypeData.Tags) {
      const options = tagsByOrgTypeData.Tags.map(
        (tag: string): ISelectOption => ({
          value: tag,
          label: tag.charAt(0).toUpperCase() + tag.slice(1),
        }),
      );
      setTagOptions(options);
    } else {
      setTagOptions([]);
    }
  }, [tagsByOrgTypeLoading, tagsByOrgTypeData]);

  // Get tags of student
  useEffect(() => {
    if (getStudentTagsApiData && getStudentTagsApiData.Tags) {
      const options = getStudentTagsApiData.Tags.map(
        (tag: string): ISelectOption => ({
          value: tag,
          label: tag.charAt(0).toUpperCase() + tag.slice(1),
        }),
      );
      setStudentTagOptions(options);
    } else {
      setStudentTagOptions([]);
    }
  }, [getStudentTagsApiLoading, getStudentTagsApiData]);

  useEffect(() => {
    if (organizationDetailApiSuccess && organizationDetailApiData) {
      setItemLocalStorage('ORG_DETAIL', organizationDetailApiData);
      dispatch(
        getUserRolesByOrgType({
          OrganizationTypeId: organizationDetailApiData.OrganizationTypeId,
        }),
      );
      // get user count
      dispatch(
        getUserCountByOrganizationType({
          OrganizationTypeId: 0,
          OrganizationId: organizationDetailApiData.OrganizationId,
        }),
      );
      const params = apiParams;
      params.OrganizationId = organizationDetailApiData.OrganizationId;
      params.RoleTypeId = RoleTypeIds.APP_USER;
      dispatch(fetchUsersList(params));
      dispatch(
        getCategoryDetailsById({
          OrganizationId: organizationDetailApiData.OrganizationId,
        }),
      );
    }
  }, [organizationDetailApiLoading]);

  useEffect(() => {
    setApiParams({ ...apiParams, Search: searchText, Page: 1 });
  }, [debouncedSearch]);

  useEffect(() => {
    if (rolesByOrgTypeData.length > 0) {
      const element = rolesByOrgTypeData.filter((role: any) => role.RoleTypeID === RoleTypeIds.APP_USER);
      setGetStudentProductivityApiParams((apiParams: any) => ({
        ...apiParams,
        OrganizationID: orgId,
        DateRange: productivityDays.value,
      }));
      setGetStudentProductiveTimeDataApiParams((apiParams: any) => ({
        ...apiParams,
        OrganizationID: orgId,
        DateRange: productivityDays.value,
      }));
      const params = defaultApiParams;
      params.RoleTypeId = element[0].RoleTypeID;
      setApiParams({ ...apiParams, RoleTypeId: element[0].RoleTypeID });
    }
  }, [rolesByOrgTypeLoading]);
  // Show error messages upon delete api failure
  useEffect(() => {
    if (deleteStatus && deleteOrganizationResponseCode > 0 && deleteOrganizationResponseMessage && !deleteOrganizationApiSuccess) {
      toast.toastError(deleteOrganizationResponseMessage);
      setDeleteStatus(false);
    } else if (deleteStatus && deleteOrganizationResponseCode > 0 && deleteOrganizationApiSuccess) {
      // Redirect to org listing after updating file status
      setDeleteStatus(false);
      toast.toastSuccess(deleteOrganizationResponseMessage);
      navigate('/manageorganization');
    }
  }, [deleteOrganizationResponseCode, deleteOrganizationResponseMessage]);

  useEffect(() => {
    const params = apiParams;
    if (orgId !== '') {
      params.OrganizationId = orgId;
    }
    params.Page = currentPage;
    dispatch(fetchUsersList(params));
  }, [apiParams.RoleTypeId, apiParams.Search, currentPage, apiParams.SortColumn, apiParams.SortOrder]);

  // set category and subcategory data
  useEffect(() => {
    if (categoryDetailsByOrgIdData && categoryDetailsByOrgIdData.length > 0) {
      const categoryData = categoryDetailsByOrgIdData.map(
        (category: IOrganization): ISelectOption => ({
          label: category.CategoryName,
          value: category.CategoryId,
        }),
      );
      setCategories(categoryData);
    } else if (categoryDetailsByOrgIdData && categoryDetailsByOrgIdData.length === 0) {
      setCategories([]);
      setSubCategories([]);
    }
  }, [categoryDetailsByOrgIdLoading]);

  // get Student Productivity data
  useEffect(() => {
    try {
      if (getStudentProductivityApiParams.OrganizationID != '') {
        dispatch(getStudentProductivity(getStudentProductivityApiParams));
      }
    } catch (e) {
      toast.toastError(unexpectedErrorMessage);
    }
  }, [getStudentProductivityApiParams]);
  // get over all performance
  useEffect(() => {
    try {
      if (apiOverallPerformanceApiParams.OrganizationID != '') {
        dispatch(getOverallPerformanceBySubcategory(apiOverallPerformanceApiParams));
      }
    } catch (e) {
      toast.toastError(unexpectedErrorMessage);
    }
  }, [apiOverallPerformanceApiParams]);
  // get top 6 competency
  useEffect(() => {
    try {
      if (apiTopListParams.OrganizationID != '') {
        dispatch(getTopCompetenciesList(apiTopListParams));
      }
    } catch (e) {
      toast.toastError(unexpectedErrorMessage);
    }
  }, [apiTopListParams]);
  // get Student Productive Time Data
  useEffect(() => {
    try {
      if (getStudentProductiveTimeDataApiParams.OrganizationID != '') {
        dispatch(getStudentProductiveTimeData(getStudentProductiveTimeDataApiParams));
      }
    } catch (e) {
      toast.toastError(unexpectedErrorMessage);
    }
  }, [getStudentProductiveTimeDataApiParams]);

  // bind Student Productivity data
  useEffect(() => {
    if (getStudentProductivityApiSuccess && getStudentProductivityApiResponseCode > 0) {
      try {
        const seriesData: number[] = [];
        const categoryData: string[] = [];
        getStudentProductivityApiData.ProductivityData.forEach((data: any) => {
          seriesData.push(data.Value);
          const datev = moment(data.Date).local().format('MM/DD');
          categoryData.push(datev);
        });
        setProductivityGraphSeriesData(seriesData);
        setProductivityGraphXaxisCategories(categoryData);
        setIsProductivityLoading(false);
      } catch (e) {
        toast.toastError(unexpectedErrorMessage);
      }
    }
  }, [getStudentProductivityApiLoading]);

  const handleEditOrganization = () => {
    navigate('/editorganization', { state: { organizationId: location?.state?.organizationId } });
  };

  const handleDateChange = (date: any) => {
    setDateFilter(date);
    const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}-` : `${date.getMonth() + 1}-`;
    const newDate = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const dateFormatted = `${date.getFullYear()}-` + month + newDate;
    setDateString(dateFormatted);
  };
  // Handle DateRange dropdown change for subcategory wise performance
  const handleChangeDateFilter2 = (event: any) => {
    const updatedDateRange = event.value;
    const updatedParams = {
      ...apiOverallPerformanceApiParams,
      DateRange: updatedDateRange,
    };
    setDateFilter2(event);
    setOverallPerformanceApiParams(updatedParams);
  };
  // get the label for tabs
  const getTabLabel = (userLevel: number) => {
    let tabLabel = '';
    if (rolesByOrgTypeData.length > 0) {
      const element = rolesByOrgTypeData.filter((role: any) => userLevel === role.RoleTypeID);
      tabLabel = element.length === 1 ? element[0].Name : '';
    } else {
      tabLabel = '';
    }
    return tabLabel;
  };
  // changes user role type id when tab changes
  const changeUserRoleId = (roleTypeId: string | null) => {
    setSearchText('');
    setCurrentPage(1);
    setDateFilter(null);
    if (rolesByOrgTypeData.length > 0 && roleTypeId === '1') {
      const id = roleTypeId !== null ? parseInt(roleTypeId) : 0;
      const element = rolesByOrgTypeData.filter((role: any) => id === role.RoleTypeID);
      setApiParams({ ...apiParams, RoleTypeId: element[0].RoleTypeID, Search: '', SortColumn: UsersSortFields.NAME, SortOrder: SortOrder.ASC, Page: 1 });
    } else if (rolesByOrgTypeData.length > 0 && roleTypeId === '7') {
      setOrgId(apiParams.OrganizationId);
      setApiParams({ ...apiParams, RoleTypeId: 7, Search: '', SortColumn: UsersSortFields.NAME, SortOrder: SortOrder.ASC, Page: 1, OrganizationId: '' });
    } else if (rolesByOrgTypeData.length > 0 && roleTypeId !== '1') {
      const id = roleTypeId !== null ? parseInt(roleTypeId) : 0;
      const element = rolesByOrgTypeData.filter((role: any) => id === role.RoleTypeID);
      setApiParams({ ...apiParams, RoleTypeId: element[0].RoleTypeID, Search: '', Page: 1 });
    }
  };

  // category change . get subcategories based on category
  const handleCategoryChange = (event: ISelectOption) => {
    const actualCategoryId = event.value;
    setSelectedCategory([event]);
    const category = categoryDetailsByOrgIdData.find((category: any) => category.CategoryId === actualCategoryId);
    if (category) {
      const subcategories = category.Subcategory.map((subcategory: any) => ({
        label: subcategory.classname,
        value: subcategory.classid,
      }));
      setSubCategories(subcategories);
      setSelectedClass([]);
    }
  };
  // search text change
  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
    setApiParams({ ...apiParams, Page: 1 });
    setCurrentPage(1);
  };
  // search button click
  const handleSearchClick = () => {
    setApiParams({ ...apiParams, Search: searchText });
  };
  // sub category change in filter
  const handleSubcategoryChange = (event: any) => {
    setSelectedClass(event);
  };
  // handle tag changes
  const handleTagChange = (tags: any) => {
    setSelectedTags(tags);
  };
  // Tag change for student in filter
  const handleStudentTagChange = (selectedOptions: ISelectOption[]) => {
    // Format the selected options as an array of strings
    const formattedTags = selectedOptions.map((option) => option.value);
    setSelectedStudentTag(formattedTags);
    // setFormattedTags(formattedTags);
  };
  // availability change in filter
  const handleAvailabilityChange = (event: ISelectOptionBoolean) => {
    setAvailability([event]);
  };
  // apply filters for app users
  const applyFiltersStudent = () => {
    setIsOpen(false);
    setFilterApplied(true);
    const requestParams = apiParams;
    setPreviousApiParams(requestParams);
    requestParams.InProgress = studentFilters.InProgress;
    requestParams.NotStarted = studentFilters.NotStarted;
    requestParams.Completed = studentFilters.Completed;
    requestParams.Overdue = studentFilters.Overdue;
    requestParams.Rescheduled = studentFilters.Rescheduled;
    requestParams.DOJ = dateString;
    requestParams.Tags = selectedStudentTag;
    requestParams.Availability = availability[0] ? availability[0].value : false;
    requestParams.Category = selectedCategory[0] ? [{ categoryid: parseInt(selectedCategory[0].value) }] : [];
    const subcategories: any = [];
    selectedClass.forEach((element) => subcategories.push({ classid: element.value }));
    // requestParams.SubCategories = selectedClass[0] ? [{ classid: selectedClass[0].value }] : [];
    requestParams.SubCategories = subcategories;
    dispatch(fetchUsersList(requestParams));
  };
  // apply filter for other users
  const applyFiltersOtherUsers = () => {
    setIsOpen(false);
    setFilterApplied(true);
    const requestParams = apiParams;
    setPreviousApiParams(requestParams);
    requestParams.DOJ = dateString;
    requestParams.Availability = availability[0] ? availability[0].value : false;
    dispatch(fetchUsersList(requestParams));
  };
  // to clear all applied filters
  const clearFilters = () => {
    setIsOpen(false);
    setFilterApplied(false);
    setSelectedCategory([]);
    setSelectedClass([]);
    setAvailability([availabilityOptions[0]]);
    setDateFilter(new Date());
    setStudentFilters(defaultStudentFilters);
    setSelectedClass([]);
    const params = previousApiParams;
    params.Category = [];
    params.SubCategories = [];
    params.DOJ = '';
    params.Availability = true;
    params.Tags = [];
    dispatch(fetchUsersList(params));
  };
  // handle checkboxes in filter
  const handleStudentCheckboxes = (label: string) => {
    const currentValue = (studentFilters as any)[label];
    setStudentFilters({ ...studentFilters, [label]: !currentValue });
  };
  // handle sort field change
  const changeSortField = (field: string) => {
    let newSortOrder = SortOrder.ASC;
    if (field === apiParams.SortColumn) {
      newSortOrder = apiParams.SortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }
    setApiParams({ ...apiParams, SortColumn: field, SortOrder: newSortOrder });
  };
  // handle activities button
  const handleActivitiesClick = () => {
    dispatch(resetGoalListApiData());
    navigate('/activities', {
      state: {
        organizationId: organizationDetailApiData.OrganizationId,
        parentPage: 'orgview',
      },
    });
  };
  // Handle delete confirmation
  const ShowDeleteConfirmPopup = (event: any) => {
    setIsShowDeleteConfirmPopup(true);
  };
  // Handle delete organization
  const handleDeleteOrganizationClick = () => {
    setDeleteStatus(true);
    const orgParams: IDeleteOrganizationApiParams = {
      OrganizationID: location?.state?.organizationId,
    };
    dispatch(deleteOrganization(orgParams));
  };
  // Navigate to organization's plan details
  const handleViewSubscriptionPlan = () => {
    if (organizationDetailApiData.SubscriptionPlanID) {
      navigate('/subscriptiondetails', {
        state: {
          PlanID: organizationDetailApiData.SubscriptionPlanID,
          parentPage: 'organizationview',
          organizationId: organizationDetailApiData.OrganizationId,
        },
      });
    } else {
      toast.toastInfo(noPlans);
    }
  };

  // Handle DateRange dropdown change
  const handleSelectProductivityDays = (event: any) => {
    setIsProductivityLoading(true);
    setProductivityDays(event);
    getProductivityData(event.value);
  };

  // get Productivity Data
  const getProductivityData = (daysVal: string) => {
    setGetStudentProductivityApiParams((apiParams: any) => ({
      ...apiParams,
      OrganizationID: orgId,
      DateRange: daysVal,
    }));
    setGetStudentProductiveTimeDataApiParams((apiParams: any) => ({
      ...apiParams,
      OrganizationID: orgId,
      DateRange: daysVal,
    }));
  };

  // Handle apply filter for organization summary
  const handleFilterChangeOrganization = () => {
    setIsOpenOrganizationFilter(false);
    const tags: string[] = [];
    selectedTags.forEach((data: any) => {
      tags.push(data.value);
    });

    if (JSON.stringify(getStudentProductivityApiParams.Tags) !== JSON.stringify(tags)) {
      setIsProductivityLoading(true);
      setGetStudentProductivityApiParams((apiParams: any) => ({
        ...apiParams,
        Tags: tags,
      }));

      setGetStudentProductiveTimeDataApiParams((apiParams: any) => ({
        ...apiParams,
        Tags: tags,
      }));
      setOverallPerformanceApiParams((apiParams: any) => ({
        ...apiParams,
        Tags: tags,
      }));
      setTopListApiParams((apiParams: any) => ({
        ...apiParams,
        TagOptions: tags,
      }));
      setGoalsAndSurveysOverviewApiParams((apiParams: any) => ({
        ...apiParams,
        Tags: tags,
      }));
    }
  };

  // Handle reset filter for organization summary
  const handleFilterResetOrganization = () => {
    setIsOpenOrganizationFilter(false);
    setSelectedTags([]);
    if (getStudentProductivityApiParams.Tags.length > 0) {
      setIsProductivityLoading(true);
      setGetStudentProductivityApiParams((apiParams: any) => ({
        ...apiParams,
        Tags: [],
      }));
      setGetStudentProductiveTimeDataApiParams((apiParams: any) => ({
        ...apiParams,
        Tags: [],
      }));
      setOverallPerformanceApiParams((apiParams: any) => ({
        ...apiParams,
        Tags: [],
      }));
      setTopListApiParams((apiParams: any) => ({
        ...apiParams,
        TagOptions: [],
      }));
      setGoalsAndSurveysOverviewApiParams((apiParams: any) => ({
        ...apiParams,
        Tags: [],
      }));
    }
  };

  // Handle organization summary Filter open.
  const handleOrganizationFilterClick = () => {
    setIsOpenOrganizationFilter(true);
  };

  // Handle organization summary Filter close.
  const handleOrganizationFilterClose = () => {
    setIsOpenOrganizationFilter(false);
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>{isTeacherWebUser() ? <FormattedMessage id="Hd.Dashboard" /> : <FormattedMessage id="Hd.ViewOrganization" />}</h3>
        {location?.state?.parentPage == 'organization' && (
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
              <FormattedMessage id="Hd.Organization" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Hd.ViewOrganization" />
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
        {location?.state?.parentPage == 'orgtypeview' && (
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
              <FormattedMessage id="Hd.ManageOrganizations" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate('/listorganizationtypes')}>
              <FormattedMessage id="Label.ViewOrgType" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate(`/orgtypedetails/${organizationDetailApiData.OrganizationTypeId}`)}>
              <FormattedMessage id="Label.OrgTypeDetails" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Hd.ViewOrganization" />
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
      </div>
      {isTeacherWebUser() ? null : (
        <div className="content-sub">
          <div className="content-sub-header d-flex justify-content-between align-items-center">
            <div>
              <h4>
                <FormattedMessage id="Hd.OrganizationInfo" />
              </h4>
            </div>
            <Col xs="auto">
              {isUserAdmin() && (
                <Nav>
                  <NavDropdown align={end} className="icon-btn-more" title={<img src={More} />}>
                    <NavDropdown.Item onClick={handleViewSubscriptionPlan}>
                      <FormattedMessage id="Link.ViewSubscriptionPlan" />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={handleEditOrganization}>
                      <FormattedMessage id="Link.EditOrganizationInfo" />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={(e) => ShowDeleteConfirmPopup(e)}>
                      <FormattedMessage id="Link.DeleteOrganization" />
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              )}
              {isOrgAdminManager() && (
                <Nav>
                  <NavDropdown align={end} className="icon-btn-more" title={<img src={More} />}>
                    <NavDropdown.Item onClick={handleEditOrganization}>
                      <FormattedMessage id="Link.EditOrganizationInfo" />
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              )}
            </Col>
          </div>
          <div className="content-area-padding">
            <Row>
              <Col className="org-img">
                <img src={organizationDetailApiData?.ImageURL ? BUCKET_URL + organizationDetailApiData?.ImageURL : Default} className="w-100" />
              </Col>
              <Col className="org-details">
                <h5>{organizationDetailApiData?.Name}</h5>
                <span className="category">{organizationDetailApiData?.OrganizationType}</span>
                <div className="phone">
                  {organizationDetailApiData?.CountryCode} {organizationDetailApiData?.PhoneNo}
                </div>
                <div className="phone">{organizationDetailApiData?.Address}</div>
              </Col>
            </Row>
          </div>
          <div className="content-area-padding border-top">
            <Row className="label-with-content">
              <Col xl lg={3} md={4}>
                <label>
                  <FormattedMessage id="Label.OrganizationStatus" />
                </label>
                <p>{organizationDetailApiData.Status}</p>
              </Col>
              <Col xl lg={3} md={4}>
                <label>
                  <FormattedMessage id="Label.address" />
                </label>
                <p>{organizationDetailApiData.Address}</p>
              </Col>
              <Col xl lg={3} md={4}>
                <label>
                  <FormattedMessage id="Label.City" />
                </label>
                <p>{organizationDetailApiData.City}</p>
              </Col>
              <Col xl lg={3} md={4}>
                <label>
                  <FormattedMessage id="Label.State" />
                </label>
                <p>{organizationDetailApiData.State}</p>
              </Col>
              <Col xl lg={3} md={4}>
                <label>
                  <FormattedMessage id="Label.Zip" />
                </label>
                <p>{organizationDetailApiData.Zip}</p>
              </Col>
              <Col xl lg={3} md={4}>
                <label>
                  <FormattedMessage id="Label.Email" />
                </label>
                <p>{organizationDetailApiData?.AdminEmail}</p>
              </Col>
              <Col xl lg={3} md={4}>
                <label>
                  <FormattedMessage id="Label.SubscriptionPlan" />
                </label>
                <p>
                  {organizationDetailApiData?.SubscriptionPlan
                    ? `${organizationDetailApiData?.SubscriptionPlan} (${organizationDetailApiData?.SubscriptionPlanStatus})`
                    : labelNill}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      )}
      <div className="content-sub content-sub-secondary">
        <div className="content-sub-header header-light-bg d-flex justify-content-end align-items-center">
          <Filter
            onOpen={handleOrganizationFilterClick}
            onClose={handleOrganizationFilterClose}
            width={450}
            right
            pageWrapId={'filter-wrapper'}
            outerContainerId={'outer-container'}
            customCrossIcon={<img src={Close} />}
            isOpen={isOpenOrganizationFilter}
          >
            <div id="filter-wrapper" className="filter-main ">
              <h4 className="mb-5">Filter</h4>
              <Col className="form-sm">
                <Row>
                  <Col>
                    <Select label="Tags" options={tagOptions} placeholder="Select Tags" onChange={handleTagChange} value={selectedTags} isMulti />
                  </Col>
                </Row>
              </Col>
              <Row className="mt-4">
                <Col>
                  <Button variant="outline-primary" className="w-100" onClick={handleFilterResetOrganization}>
                    {useIntlActionMessages('Button.ClearFilter')}
                  </Button>
                </Col>
                <Col>
                  <Button variant="primary" className="w-100" onClick={handleFilterChangeOrganization}>
                    {useIntlActionMessages('Button.ApplyFilters')}
                  </Button>
                </Col>
              </Row>
            </div>
          </Filter>
        </div>
        <div className="content-area-padding">
          <Row className="row_padding">
            <Col xl lg={12} className="overview-main">
              <div className="content-sub">
                <div className="content-sub-header d-flex justify-content-between align-items-center">
                  <div>
                    <h4>
                      <FormattedMessage id="Hd.Overview" />
                    </h4>
                    <p>
                      <FormattedMessage id="SubHd.Goals/Surveys" />
                      {getTabLabel(RoleTypeIds.APP_USER)}
                    </p>
                  </div>
                  <Col xs="auto">
                    <Button
                      variant="dark"
                      size="sm"
                      className="icon-btn"
                      onClick={() =>
                        navigate('/overview', {
                          state: {
                            organizationId: organizationDetailApiData.OrganizationId,
                            parentPage: 'orgview',
                            selectedTags: selectedTags,
                          },
                        })
                      }
                    >
                      <RightIcon />
                    </Button>
                  </Col>
                </div>
                <div className="content-area-padding">
                  <Row className="mb-3">
                    <Col lg={4}>
                      <PerfomanceCard percentage={goalsAndSurveysCompletionPercentage} />
                    </Col>
                    <Col>
                      <Row className="d-flex justify-content-between pb-1">
                        <Col xs="auto" className="overview_goals_survay ps-0">
                          <span>{totalGoalCount}</span>
                          <FormattedMessage id="Label.Goals" />
                        </Col>
                        <Col xs="auto" className="overview_task_event">
                          <p>
                            <FormattedMessage id="Label.NoOfTasks" />
                            <span>{totalTaskCount}</span>
                          </p>
                          <p>
                            <FormattedMessage id="Label.NoOfEvents" />
                            <span>{totalEventCount}</span>
                          </p>
                        </Col>
                        <Col xs="auto" className="overview_goals_survay">
                          <span>{totalSurveyCount}</span>
                          <FormattedMessage id="Label.Surveys" />
                        </Col>
                      </Row>
                      <Row className="border-top overview_completion pt-2">
                        <Col xs="auto" className="percentage">
                          {selfCreatedGoalsCompletionPercentage}%
                        </Col>
                        <Col>
                          <p>
                            <FormattedMessage id="Label.AvgSelfCreatedGoals" />
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <h6>
                    <FormattedMessage id="SubHd.studentswithTo-DoItems1" />
                    {getTabLabel(RoleTypeIds.APP_USER)}
                    <FormattedMessage id="SubHd.studentswithTo-DoItems2" />
                  </h6>
                  <Row className="todo-main">
                    <Col>
                      <div className="todo-status completed">
                        {completedCount}
                        <span>
                          <FormattedMessage id="Status.Completed" />
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <div className="todo-status inprogress">
                        {inProgressCount}
                        <span>
                          <FormattedMessage id="Status.INProgress" />
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <div className="todo-status notstarted">
                        {notStartedCount}
                        <span>
                          <FormattedMessage id="Status.NotStarted" />
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <div className="todo-status overdue">
                        {overdueCount}
                        <span>
                          <FormattedMessage id="Status.Overdue" />
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <div className="content-area-padding border-top text-center students-goals">
                  <span>82</span> <FormattedMessage id="Label.rescheduledGoals" />
                </div> */}
              </div>
            </Col>
            <Col xl lg={12} className="productivity-main">
              <div className="content-sub">
                <div className="content-sub-header d-flex justify-content-between align-items-center">
                  <div>
                    <h4>
                      <FormattedMessage id="Hd.Productivity" />
                    </h4>
                    <p>
                      <FormattedMessage id="SubHd.TodoLsistCompleted" />
                      {getTabLabel(RoleTypeIds.APP_USER)}
                    </p>
                  </div>
                  <Col xl={4} lg={4} md={4} className="form-sm">
                    <Select options={selectProductivityDays} value={productivityDays} placeholder="Select" onChange={(e: any) => handleSelectProductivityDays(e)} />
                  </Col>
                </div>
                <div className="content-area-padding">
                  {isProductivityLoading === false ? (
                    <ProductivityGraph
                      orgId={organizationDetailApiData?.OrganizationId}
                      selectedDays={productivityDays}
                      xAxisCategories={productivityGraphXaxisCategories}
                      seriesData={productivityGraphSeriesData}
                      productiveTimeData={getStudentProductiveTimeDataApiData.ProductiveTimeData}
                      yAxisTitle={getTabLabel(RoleTypeIds.APP_USER)}
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </Col>
            <Col xl lg={12} className="competencies-main">
              <div className="content-sub">
                <div className="content-sub-header d-flex justify-content-between align-items-center">
                  <div>
                    <h4>
                      <FormattedMessage id="Hd.Competencies" />
                    </h4>
                    <p>
                      <FormattedMessage id="SubHd.CompetenciesSelected" />
                      {getTabLabel(RoleTypeIds.APP_USER)}
                    </p>
                  </div>
                  <Col xs="auto">
                    <Button
                      variant="dark"
                      size="sm"
                      className="icon-btn"
                      onClick={() =>
                        navigate('/competencies', {
                          state: {
                            organizationId: organizationDetailApiData.OrganizationId,
                            OrganizationTypeId: organizationDetailApiData.OrganizationTypeId,
                            Name: organizationDetailApiData.Name,
                            OrganizationType: organizationDetailApiData.OrganizationType,
                            parentPage: 'orgview',
                            selectedTags: selectedTags,
                          },
                        })
                      }
                    >
                      <RightIcon />
                    </Button>
                  </Col>
                </div>
                <div className="content-area-padding">
                  {competenciesTopListApiSuccess && competenciesTopListData && competenciesTopListData.length > 0 ? (
                    competenciesTopListData.map((competency: any) => (
                      <CompetenciesCard count={competency?.Count} competencies={competency?.CompetencyName} key={competency?.CompetencyID} />
                    ))
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <div className="content-sub content-area-padding text-center">
                            <h6>{labelNoData}</h6>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <div className="content-sub">
            <div className="content-sub-header d-flex justify-content-between align-items-center">
              <div>
                <h4>
                  <FormattedMessage id="SubHD.DepartmentOverallPerformance" />
                </h4>
              </div>
              <Col xl={2} lg={4} md={4} className="form-sm max-w-140">
                <Select options={selectDays} value={dateFilter2} placeholder="Select" onChange={(event: any) => handleChangeDateFilter2(event)} />
              </Col>
            </div>
            <div className="perfomance-main">
              <Slider {...settings}>
                {subcategorywiseoverallPerformanceListApiSuccess && subcategorywiseoverallPerformanceListData?.Organizations?.length > 0 ? (
                  subcategorywiseoverallPerformanceListData?.Organizations?.map((performance: any, index: number) => (
                    <PerfomanceCard percentage={performance.PerformancePercentage} label={performance.ClassName} key={index} />
                  ))
                ) : (
                  <div className="content-sub content-area-padding text-center p-4">
                    <h6>{labelNoData}</h6>
                  </div>
                )}
              </Slider>
            </div>
          </div>
          <div className="content-sub mb-0">
            <div className="content-sub-header d-flex justify-content-between align-items-center border-0">
              <div>
                <h4>
                  <FormattedMessage id="Hd.Activities" />
                </h4>
              </div>
              <Col md="auto" className="btn-container">
                <Button variant="primary" size="sm" onClick={() => handleActivitiesClick()}>
                  <FormattedMessage id="Button.ShowAllActivities" />
                </Button>
              </Col>
            </div>
          </div>
        </div>
      </div>
      <div className="content-sub">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h4>{useIntlMessages('Label.Terminologies.Head')}</h4>
            </Accordion.Header>
            <Accordion.Body>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>{useIntlMessages('Label.Category')}</th>
                    <th>{useIntlMessages('Label.SubCategories')}</th>
                  </tr>
                </thead>
                {organizationDetailApiData.CategoriesAndSubCategories && organizationDetailApiData.CategoriesAndSubCategories.length > 0 ? (
                  <tbody>
                    {organizationDetailApiData.CategoriesAndSubCategories?.map((category: any) => (
                      <tr key={category.CategoryId}>
                        <td>
                          <div className="d-flex align-items-center">{category.CategoryName}</div>
                        </td>
                        <td>
                          {category.SubCategory?.map((subCategory: any, index: number) => (
                            <span key={subCategory.SubCategoryId}>
                              {subCategory.SubCategoryName}
                              {index !== category.SubCategory.length - 1 && ', '}
                            </span>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <div className="content-sub content-area-padding border-top text-center">
                          <h6>{labelNoData}</h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="dashboard-tiles-main">
        <Row>
          {userCountListApiSuccess && userCountListData?.length > 0 ? (
            userCountListData.map((user: any, index: number) => (
              <Col xl lg={4} md={4}>
                <div className={tileClasses[index % tileClasses?.length]}>
                  <div className="dash-tile-bg">
                    <h2>{user?.RoleWiseCount}</h2>
                    <div className="d-flex">
                      <span>{user?.RoleName}</span>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="content-sub content-area-padding border-top text-center p-4">
              <h6>{NoUserdataText}</h6>
            </div>
          )}
        </Row>
      </div>
      <div className="content-sub">
        <div className="content-sub-header header-light-bg d-flex justify-content-between align-items-center">
          <div>
            <h4>
              <FormattedMessage id="Hd.UsersintheOrganization" />
            </h4>
          </div>
          <Col className="btn-container d-flex justify-content-end">
            <Button
              variant="primary"
              size="sm"
              onClick={() =>
                navigate('/adduser', {
                  state: {
                    organizationId: organizationDetailApiData.OrganizationId,
                    Name: organizationDetailApiData.Name,
                    OrganizationTypeId: organizationDetailApiData.OrganizationTypeId,
                    OrganizationType: organizationDetailApiData.OrganizationType,
                    parentPage: 'orgview',
                  },
                })
              }
            >
              <FormattedMessage id="Button.AddUser" />
            </Button>
          </Col>
        </div>
        <div className="content-area-padding">
          <Tabs defaultActiveKey="6" transition={false} id="noanim-tab-example" className="mb-3" onSelect={(key: string | null) => changeUserRoleId(key)}>
            <Tab eventKey="6" title={getTabLabel(RoleTypeIds.APP_USER)}>
              <>
                <Col className="d-flex justify-content-end mb-3">
                  <Col lg={3} className="form-sm">
                    <Search placeholder={useIntlMessages('PH.SearchName')} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                  </Col>
                  <Filter
                    width={450}
                    right
                    pageWrapId={'filter-wrapper'}
                    outerContainerId={'outer-container'}
                    customCrossIcon={<img src={Close} />}
                    isOpen={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    burgerButtonClassName={filterApplied ? 'active' : ''}
                  >
                    <div id="filter-wrapper" className="filter-main">
                      <h4 className="mb-4">{useIntlMessages('Label.FilterUsers')}</h4>
                      <Col className="form-sm">
                        <Select
                          label="Availability"
                          options={availabilityOptions}
                          placeholder="Select"
                          onChange={(e: any) => handleAvailabilityChange(e)}
                          value={availability}
                          // error="Error"
                        />
                        <Row>
                          <Col>
                            <Select
                              label="Category"
                              options={categories}
                              placeholder="Select"
                              onChange={(e: ISelectOption) => handleCategoryChange(e)}
                              value={selectedCategory}
                              // error="Error"
                            />
                          </Col>
                          <Col>
                            <Select
                              label="Sub Category"
                              options={subcategories}
                              placeholder="Select"
                              onChange={(e: any) => handleSubcategoryChange(e)}
                              value={selectedClass}
                              isMulti
                              // error="Error"
                            />
                          </Col>
                        </Row>
                        <Col>
                          <Select
                            label="Tags"
                            options={studentTagOptions}
                            placeholder="Select Tags"
                            onChange={handleStudentTagChange}
                            // error="Error"
                            isMulti
                          />
                        </Col>
                        <div className="custom-calendar form-sm mb-4">
                          <label className="form-label">{useIntlMessages('Label.DateofJoining')}</label>
                          <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                        </div>
                        <h5 className="mb-3">{useIntlMessages('Label.AdditionalFilters')}</h5>
                        <div className="check-sm">
                          <Checkbox
                            type="Checkbox"
                            label={getTabLabel(RoleTypeIds.APP_USER) + ' with Completed To-Do items'}
                            checked={studentFilters.Completed}
                            onChange={() => handleStudentCheckboxes('Completed')}
                          />
                          <Checkbox
                            type="Checkbox"
                            label={getTabLabel(RoleTypeIds.APP_USER) + ' with In Progress To-Do items'}
                            checked={studentFilters.InProgress}
                            onChange={() => handleStudentCheckboxes('InProgress')}
                          />
                          <Checkbox
                            type="Checkbox"
                            label={getTabLabel(RoleTypeIds.APP_USER) + ' with Overdue To-Do items'}
                            checked={studentFilters.Overdue}
                            onChange={() => handleStudentCheckboxes('Overdue')}
                          />
                          <Checkbox
                            type="Checkbox"
                            label={getTabLabel(RoleTypeIds.APP_USER) + ' with Not Started To-Do items'}
                            checked={studentFilters.NotStarted}
                            onChange={() => handleStudentCheckboxes('NotStarted')}
                          />
                          <Checkbox
                            type="Checkbox"
                            label={getTabLabel(RoleTypeIds.APP_USER) + ' who Rescheduled their Goals'}
                            checked={studentFilters.Rescheduled}
                            onChange={() => handleStudentCheckboxes('Rescheduled')}
                          />
                        </div>
                      </Col>
                      <Row className="mt-4 mb-4">
                        <Col>
                          <Button variant="outline-primary" className="w-100" onClick={clearFilters}>
                            {useIntlMessages('Button.ClearFilter')}
                          </Button>
                        </Col>
                        <Col>
                          <Button variant="primary" className="w-100" onClick={() => applyFiltersStudent()}>
                            {useIntlMessages('Button.ApplyFilters')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Filter>
                </Col>
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="Label.Name" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.Username" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.USERNAME)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.Categories" />
                        <Link to="" className="ms-1">
                          {/* <Sort /> */}
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.SubCategories" />
                        <Link to="" className="ms-1">
                          {/* <Sort /> */}
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.DateofJoining" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                          <Sort />
                        </Link>
                      </th>
                      <th className="w-50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <UserTableRows userListApiData={userListApiData} userListApiSuccess={userListApiSuccess} roleLevel="six" roleTypeId={apiParams.RoleTypeId} />
                  </tbody>
                </Table>
                {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalitems={userListApiData[0].TotalCount}
                    pageLimit={PAGE_SIZE}
                    setCurrentPage={(page: number) => setCurrentPage(page)}
                    currentPage={currentPage - 1}
                    prevPage={-1}
                    itemsDisplayed={userListApiData?.length}
                  />
                )}
              </>
            </Tab>
            <Tab eventKey="5" title={getTabLabel(RoleTypeIds.ACCOUNTABILITY_PARTNER)}>
              <>
                <Col className="d-flex justify-content-end mb-3">
                  <Col lg={3} className="form-sm">
                    <Search placeholder={useIntlMessages('PH.SearchName')} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                  </Col>
                  <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />}>
                    <div id="filter-wrapper" className="filter-main">
                      <h4 className="mb-4">{useIntlMessages('Label.FilterUsers')}</h4>
                      <Col className="form-sm">
                        <Select
                          label={useIntlMessages('Label.Availability')}
                          options={availabilityOptions}
                          value={availability}
                          placeholder={useIntlMessages('PH.Select')}
                          onChange={(e: any) => handleAvailabilityChange(e)}
                        />
                        <div className="custom-calendar form-sm mb-4">
                          <label className="form-label">{useIntlMessages('Label.DateofJoining')}</label>
                          <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                        </div>
                      </Col>
                      <Row className="mt-4 mb-4">
                        <Col>
                          <Button variant="outline-primary" className="w-100" onClick={clearFilters}>
                            {useIntlMessages('Button.ClearFilter')}
                          </Button>
                        </Col>
                        <Col>
                          <Button variant="primary" className="w-100" onClick={() => applyFiltersOtherUsers()}>
                            {useIntlMessages('Button.ApplyFilters')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Filter>
                </Col>
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="Label.Name" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.Username" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.USERNAME)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        {useIntlMessages('Label.NoStudentsAssigned')}
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NO_OF_USERS)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.DateofJoining" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                          <Sort />
                        </Link>
                      </th>
                      <th className="w-50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <UserTableRows userListApiData={userListApiData} userListApiSuccess={userListApiSuccess} roleLevel="5-3" roleTypeId={apiParams.RoleTypeId} />
                  </tbody>
                </Table>
                {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalitems={userListApiData[0].TotalCount}
                    pageLimit={PAGE_SIZE}
                    setCurrentPage={(page: number) => setCurrentPage(page)}
                    currentPage={currentPage - 1}
                    prevPage={-1}
                    itemsDisplayed={userListApiData?.length}
                  />
                )}
              </>
            </Tab>
            <Tab eventKey="4" title={getTabLabel(RoleTypeIds.APP_USER_MANAGER)}>
              <>
                <Col className="d-flex justify-content-end mb-3">
                  <Col lg={3} className="form-sm">
                    <Search placeholder={useIntlMessages('PH.SearchName')} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                  </Col>
                  <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />}>
                    <div id="filter-wrapper" className="filter-main">
                      <h4 className="mb-4">{useIntlMessages('Label.FilterUsers')}</h4>
                      <Col className="form-sm">
                        <Select
                          label={useIntlMessages('Label.Availability')}
                          options={availabilityOptions}
                          value={availability}
                          placeholder={useIntlMessages('PH.Select')}
                          onChange={(e: any) => handleAvailabilityChange(e)}
                        />
                        <div className="custom-calendar form-sm mb-4">
                          <label className="form-label">{useIntlMessages('Label.DateofJoining')}</label>
                          <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                        </div>
                      </Col>
                      <Row className="mt-4 mb-4">
                        <Col>
                          <Button variant="outline-primary" className="w-100" onClick={clearFilters}>
                            {useIntlMessages('Button.ClearFilter')}
                          </Button>
                        </Col>
                        <Col>
                          <Button variant="primary" className="w-100" onClick={() => applyFiltersOtherUsers()}>
                            {useIntlMessages('Button.ApplyFilters')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Filter>
                </Col>
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="Label.Name" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.Username" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.USERNAME)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        {useIntlMessages('Label.NoStudentsAssigned')}
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NO_OF_USERS)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.DateofJoining" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                          <Sort />
                        </Link>
                      </th>
                      <th className="w-50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <UserTableRows userListApiData={userListApiData} userListApiSuccess={userListApiSuccess} roleLevel="5-3" roleTypeId={apiParams.RoleTypeId} />
                  </tbody>
                </Table>
                {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalitems={userListApiData[0].TotalCount}
                    pageLimit={PAGE_SIZE}
                    setCurrentPage={(page: number) => setCurrentPage(page)}
                    currentPage={currentPage - 1}
                    prevPage={-1}
                    itemsDisplayed={userListApiData?.length}
                  />
                )}
              </>
            </Tab>
            <Tab eventKey="3" title={getTabLabel(RoleTypeIds.ORGANIZATION_MANGER)}>
              <>
                <Col className="d-flex justify-content-end mb-3">
                  <Col lg={3} className="form-sm">
                    <Search placeholder={useIntlMessages('PH.SearchName')} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                  </Col>
                  <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />}>
                    <div id="filter-wrapper" className="filter-main">
                      <h4 className="mb-4">{useIntlMessages('Label.FilterUsers')}</h4>
                      <Col className="form-sm">
                        <Select
                          label={useIntlMessages('Label.Availability')}
                          options={availabilityOptions}
                          value={availability}
                          placeholder={useIntlMessages('PH.Select')}
                          onChange={(e: any) => handleAvailabilityChange(e)}
                        />
                        <div className="custom-calendar form-sm mb-4">
                          <label className="form-label">{useIntlMessages('Label.DateofJoining')}</label>
                          <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                        </div>
                      </Col>
                      <Row className="mt-4 mb-4">
                        <Col>
                          <Button variant="outline-primary" className="w-100" onClick={clearFilters}>
                            {useIntlMessages('Button.ClearFilter')}
                          </Button>
                        </Col>
                        <Col>
                          <Button variant="primary" className="w-100" onClick={() => applyFiltersOtherUsers()}>
                            {useIntlMessages('Button.ApplyFilters')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Filter>
                </Col>
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="Label.Name" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.Username" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.USERNAME)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        {useIntlMessages('Label.NoStudentsAssigned')}
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NO_OF_USERS)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.DateofJoining" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                          <Sort />
                        </Link>
                      </th>
                      <th className="w-50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <UserTableRows userListApiData={userListApiData} userListApiSuccess={userListApiSuccess} roleLevel="5-3" roleTypeId={apiParams.RoleTypeId} />
                  </tbody>
                </Table>
                {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalitems={userListApiData[0].TotalCount}
                    pageLimit={PAGE_SIZE}
                    setCurrentPage={(page: number) => setCurrentPage(page)}
                    currentPage={currentPage - 1}
                    prevPage={-1}
                    itemsDisplayed={userListApiData?.length}
                  />
                )}
              </>
            </Tab>
            <Tab eventKey="2" title={getTabLabel(RoleTypeIds.ORGANIZATION_ADMIN)}>
              <>
                <Col className="d-flex justify-content-end mb-3">
                  <Col lg={3} className="form-sm">
                    <Search placeholder={useIntlMessages('PH.SearchName')} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                  </Col>
                  <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />}>
                    <div id="filter-wrapper" className="filter-main">
                      <h4 className="mb-4">{useIntlMessages('Label.FilterUsers')}</h4>
                      <Col className="form-sm">
                        <Select
                          label={useIntlMessages('Label.Availability')}
                          options={availabilityOptions}
                          value={availability}
                          placeholder={useIntlMessages('PH.Select')}
                          onChange={(e: any) => handleAvailabilityChange(e)}
                        />
                        <div className="custom-calendar form-sm mb-4">
                          <label className="form-label">{useIntlMessages('Label.DateofJoining')}</label>
                          <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                        </div>
                      </Col>
                      <Row className="mt-4 mb-4">
                        <Col>
                          <Button variant="outline-primary" className="w-100" onClick={clearFilters}>
                            {useIntlMessages('Button.ClearFilter')}
                          </Button>
                        </Col>
                        <Col>
                          <Button variant="primary" className="w-100" onClick={() => applyFiltersOtherUsers()}>
                            {useIntlMessages('Button.ApplyFilters')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Filter>
                </Col>
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="Label.Name" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.Username" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.USERNAME)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        {useIntlMessages('Label.NoStudentsAssigned')}
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NO_OF_USERS)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.DateofJoining" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                          <Sort />
                        </Link>
                      </th>
                      <th className="w-50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <UserTableRows userListApiData={userListApiData} userListApiSuccess={userListApiSuccess} roleLevel="5-3" roleTypeId={apiParams.RoleTypeId} />
                  </tbody>
                </Table>
                {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalitems={userListApiData[0].TotalCount}
                    pageLimit={PAGE_SIZE}
                    setCurrentPage={(page: number) => setCurrentPage(page)}
                    currentPage={currentPage - 1}
                    prevPage={-1}
                    itemsDisplayed={userListApiData?.length}
                  />
                )}
              </>
            </Tab>
            <Tab eventKey="7" title="System Admin">
              <>
                <Col className="d-flex justify-content-end mb-3">
                  <Col lg={3} className="form-sm">
                    <Search placeholder={useIntlMessages('PH.SearchName')} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                  </Col>
                  <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />}>
                    <div id="filter-wrapper" className="filter-main">
                      <h4 className="mb-4">{useIntlMessages('Label.FilterUsers')}</h4>
                      <Col className="form-sm">
                        <Select
                          label={useIntlMessages('Label.Availability')}
                          options={availabilityOptions}
                          value={availability}
                          placeholder={useIntlMessages('PH.Select')}
                          onChange={(e: any) => handleAvailabilityChange(e)}
                        />
                        <div className="custom-calendar form-sm mb-4">
                          <label className="form-label">{useIntlMessages('Label.DateofJoining')}</label>
                          <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                        </div>
                      </Col>
                      <Row className="mt-4 mb-4">
                        <Col>
                          <Button variant="outline-primary" className="w-100" onClick={clearFilters}>
                            {useIntlMessages('Button.ClearFilter')}
                          </Button>
                        </Col>
                        <Col>
                          <Button variant="primary" className="w-100" onClick={() => applyFiltersOtherUsers()}>
                            {useIntlMessages('Button.ApplyFilters')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Filter>
                </Col>
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="Label.Name" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.Email" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.EMAIL)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        {useIntlMessages('Label.PhoneNum')}
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.PHONE)}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.DateofJoining" />
                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                          <Sort />
                        </Link>
                      </th>
                      <th className="w-50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <UserTableRows userListApiData={userListApiData} userListApiSuccess={userListApiSuccess} roleLevel="7" roleTypeId={apiParams.RoleTypeId} />
                  </tbody>
                </Table>
                {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalitems={userListApiData[0].TotalCount}
                    pageLimit={PAGE_SIZE}
                    setCurrentPage={(page: number) => setCurrentPage(page)}
                    currentPage={currentPage - 1}
                    prevPage={-1}
                    itemsDisplayed={userListApiData?.length}
                  />
                )}
              </>
            </Tab>
          </Tabs>
        </div>
        <DeleteConfirmPopup
          show={isShowDeleteConfirmPopup}
          handleClose={() => setIsShowDeleteConfirmPopup(false)}
          onCancel={() => setIsShowDeleteConfirmPopup(false)}
          onOkay={() => handleDeleteOrganizationClick()}
          title={useIntlMessages('Text.DeleteConfirmation.Organization')}
          content={useIntlMessages('Text.DeleteConfirmation.Organization.Desc')}
        />
      </div>
      {(organizationDetailApiLoading || deleteOrganizationApiLoading || userListApiLoading || competenciesTopListApiLoading || subcategorywiseoverallPerformanceListApiLoading) && (
        <Loader />
      )}
    </>
  );
};
export default ViewOrganization;
