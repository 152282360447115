/**
 * @file   src\general\GenericNotFound.tsx
 * @brief  404 page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React from 'react';

const GenericNotFound = () => {
  return (
    <div className="content-nav-sub not-found">
      <h3>Page Not Found</h3>
    </div>
  );
};

export default GenericNotFound;
