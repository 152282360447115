import React from 'react';
const ImageIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21.73" height="19.047" viewBox="0 0 21.73 19.047">
            <path id="ResourceImg_" data-name="ResourceImg " d="M19,32H2.674A2.713,2.713,0,0,0-.009,34.721v13.6a2.713,2.713,0,0,0,2.683,2.721H19a2.721,2.721,0,0,0,2.721-2.721v-13.6A2.7,2.7,0,0,0,19,32ZM4.715,34.721a2.041,2.041,0,0,1,2.041,2.041A2.017,2.017,0,0,1,4.715,38.8a2.017,2.017,0,0,1-2.041-2.041A2.065,2.065,0,0,1,4.715,34.721ZM18.957,47.969a.679.679,0,0,1-.6.357H3.478a.68.68,0,0,1-.55-1.081L5.9,43.163a.68.68,0,0,1,1.1,0l1.38,1.892,3.967-5.914a.643.643,0,0,1,.566-.339.682.682,0,0,1,.566.3l5.442,8.163A.68.68,0,0,1,18.957,47.969Z" transform="translate(0.009 -32)" fill="#9fe6ea" />
        </svg>

    );
}

export default ImageIcon;
