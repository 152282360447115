import React, { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import '../assets/css/Input.scss'
const Input = forwardRef((props: any, ref) => {
  return (
    // <Form>
    <div className='form'>
      <Form.Group>
        <Form.Label>
          {props.label}
        </Form.Label>
        <Form.Control
          id={props.id}
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          onChange={props.onChange}
          value={props.value}
          ref={ref} // Use the forwarded ref
          disabled={props.status}
          readOnly={props.readOnly}
          className={props.errorMessage && props.errorMessage !== '' ? 'error' : ""}
          onKeyDown={props.onKeyDown}
          onKeyPress={props.onKeyPress}
          maxLength={props.maxLength}
          autoCapitalize={props.autoCapitalize}
          onBlur={props.onBlur}
          onSubmit={props.onSubmit}
          autoComplete={props.autoComplete}
          onClick={props.onClick}
          onPointerMove={props.onPointerMove}
          as={props.as}
          rows={props.rows}
          autoFocus={props.autoFocus}
          pattern={props.pattern}
        />
        {props.errorMessage && props.errorMessage !== '' ? <Form.Text className='error'>
          {props.errorMessage}
        </Form.Text> : ""}
      </Form.Group>
      </div>
  )
});

export default Input;
