
/**
 * @file   src\store\actions\authActions.ts
 * @brief  This file is responsible for creating asynchronous authentication based api call.
 * @date   JAN, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */


import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { AuthApis } from '../../utils/apiUrls';

export const loginUser = createAsyncThunk('/login', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${AuthApis.LOGIN}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});
// Change Password
export const changePassword = createAsyncThunk('/changePassword', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${AuthApis.CHANGE_PASSWORD}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});
// Forgot Password
export const forgotPassword = createAsyncThunk('/forgotpassword', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${AuthApis.FORGOT_PAASSWORD}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});
// Reset Password
export const resetPassword = createAsyncThunk('/resetPassword', async (request: any, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(`${AuthApis.RESET_PASSWORD}`,request);
        return data;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});