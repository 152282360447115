import { useState } from 'react';
import Chart from 'react-apexcharts';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
const ProductivityGraph = (props: any) => {
  const maxYval = Math.max(...props.seriesData);
  const [chartOptions, setChartOptions] = useState({
    series: [
      {
        name: props.yAxisTitle,
        data: props.seriesData,
      },
    ],
    options: {
      chart: {
        height: 350,
        foreColor: 'white',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: '12px',
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#A53FE6'],
      xaxis: {
        title: {
          text: 'Date',
        },
        categories: props.xAxisCategories,
        tickPlacement: 'on',
      },
      yaxis: {
        stepSize: maxYval > 8 ? undefined : 1,
        title: {
          text: props.yAxisTitle,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#8AC2F6'],
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [40, 70, 100],
        },
      },
    },
  });

  return (
    <div>
      <Chart options={chartOptions.options} series={chartOptions.series} type="bar" width="400" />
      <h6 className="border-bottom">
        <FormattedMessage id="SubHd.ProductiveTime" />
      </h6>
      {props?.productiveTimeData !== undefined && (
        <Row className="productive-time">
          <Col>
            <h5>{props.productiveTimeData[0].Value}%</h5>
            <p>12 AM - 6 AM</p>
          </Col>
          <Col>
            <h5>{props.productiveTimeData[1].Value}%</h5>
            <p>6 AM - 12 PM</p>
          </Col>
          <Col>
            <h5>{props.productiveTimeData[2].Value}%</h5>
            <p>12 PM - 6 PM</p>
          </Col>
          <Col>
            <h5>{props.productiveTimeData[3].Value}%</h5>
            <p>6 PM - 12 AM</p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProductivityGraph;
