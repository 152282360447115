/**
 * @file   src\validations\addGoalStepOneTypeSchema.ts
 * @brief  This file is responsible for defining organisation type validation schemas.
 * @date   Feb, 20224
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';

// Add Goal Step 1 Form Schema
export const ADD_GENERAL_GOAL_STEP_ONE_SCHEMA = yup.object({
    GoalName: yup
        .string()
        .trim()
        .required(useIntlActionMessages('Form.GoalName.Required')),
    GoalTypeID: yup
        .number()
        .required(useIntlActionMessages('Form.GoalTypeID.Required')).min(1, useIntlActionMessages('Form.GoalTypeID.Required')),
    Description: yup
        .string()
        .trim()
        .required(useIntlActionMessages('Form.GoalDescription.Required')),
    Priority: yup
        .number()
        .required(useIntlActionMessages('Form.Priority.Required')).min(1, useIntlActionMessages('Form.Priority.Required')),
    StartDateWithTime: yup
        .string()
        .trim()
        .required(useIntlActionMessages('Form.StartDateWithTime.Required')),
    EndDateWithTime: yup
        .string()
        .trim()
        .required(useIntlActionMessages('Form.EndDateWithTime.Required')),
});
// Add Goal Step 1 Form Schema
export const ADD_GOAL_STEP_ONE_SCHEMA = yup.object({
    GoalName: yup
        .string()
        .trim()
        .required(useIntlActionMessages('Form.GoalName.Required')),
    GoalTypeID: yup
        .number()
        .required(useIntlActionMessages('Form.GoalTypeID.Required')).min(1, useIntlActionMessages('Form.GoalTypeID.Required')),
    Description: yup
        .string()
        .trim()
        .required(useIntlActionMessages('Form.GoalDescription.Required')),
    CategoryID: yup
        .number()
        .required(useIntlActionMessages('Form.CategoryID.Required')).min(1, useIntlActionMessages('Form.CategoryID.Required')),
    ClassID: yup
        .array()
        .required(useIntlActionMessages('Form.ClassID.Required')).min(1, useIntlActionMessages('Form.ClassID.Required')),
    Priority: yup
        .number()
        .required(useIntlActionMessages('Form.Priority.Required')).min(1, useIntlActionMessages('Form.Priority.Required')),
    StartDateWithTime: yup
        .string()
        .trim()
        .required(useIntlActionMessages('Form.StartDateWithTime.Required')),
    EndDateWithTime: yup
        .string()
        .trim()
        .required(useIntlActionMessages('Form.EndDateWithTime.Required')),
});
