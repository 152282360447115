import React from 'react';
const TaskThumbIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="34" viewBox="0 0 28 34">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_1754" data-name="Rectangle 1754" width="28" height="34" transform="translate(-0.238)" fill="none" />
                </clipPath>
            </defs>
            <g id="Group_12119" data-name="Group 12119" transform="translate(0.238)" clip-path="url(#clip-path)">
                <path id="Path_10329" data-name="Path 10329" d="M15.751,26.09a16.24,16.24,0,0,1,7.726-2.81v9.1a27.2,27.2,0,0,0-7.726,2.81Z" transform="translate(-1.286 -1.335)" fill="#fa0" fill-rule="evenodd" />
                <path id="Path_10330" data-name="Path 10330" d="M13.727,26.09A16.24,16.24,0,0,0,6,23.28v9.1a27.2,27.2,0,0,1,7.726,2.81Z" transform="translate(-0.667 -1.335)" fill="#fa0" fill-rule="evenodd" />
                <path id="Path_10331" data-name="Path 10331" d="M4.215,28.709A14.355,14.355,0,0,1,.007,26.69L0,23.846c-.011-2.988.124-4.7,3.72-5.492,3.941-.87,8.148-1.851,5.958-4.947C4.2,5.65,8.542,0,14.048,0s9.828,5.607,4.369,13.409c-2.147,3.074,1.933,4.057,5.96,4.947,3.61.8,3.733,2.516,3.719,5.525v2.81a14.34,14.34,0,0,1-4.214,2.019V20.281l-1.52.125a17.611,17.611,0,0,0-8.313,2.985A17.607,17.607,0,0,0,5.735,20.4l-1.52-.125v8.433Z" transform="translate(-0.286 0.143)" fill="#3d58f0" fill-rule="evenodd" />
            </g>
        </svg>
    );
}

export default TaskThumbIcon;
