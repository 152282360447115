/**
 * @file   src\containers\Login.jsx
 * @brief  Login page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages } from '../utils/helper';
import { Link } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';
import Input from '../components/MAInput';
import HomeLayout from '../components/HomeLayout';
import { FORGOT_PWD_SCHEMA } from '../validations/authSchema';
import { validateForm } from '../utils/formValidation';
import { IForgotPwdForm } from '../interfaces/AuthInterface';
import { useAppDispatch, useAppSelector } from '../hooks';
import { forgotPassword } from '../store/actions/authActions';
import { MessageToaster } from '../utils/ToastUtil';
import { RootState } from '../store';
import { resetForgotPassword } from '../store/slices/authSlice';
const forgotPasswordDefaultParams = {
  DeviceType: 'web',
  Email: '',
};
const ForgotPassword = () => {
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  const { forgotPasswordApiLoading, forgotPasswordApiSuccess, forgotPasswordResponseCode, forgotPasswordResponseMessage } = useAppSelector(
    (state: RootState) => state.authentication,
  );
  // Component state variables
  const [forgotPwdForm, setForgotPwdForm] = useState<IForgotPwdForm>(forgotPasswordDefaultParams);
  const [errorFields, setErrorFields] = useState<any>({});

  // // Function to handle Enter key press
  // const handleKeyPress = (event: any) => {
  //   if (event.key === 'Enter') {
  //     loadResetPasswordPage();
  //   }
  // };

  // works on unmounting.
  useEffect(() => {
    return () => {
      dispatch(resetForgotPassword());
    };
  }, []);

  // Handle forgot password Success/Failure.
  useEffect(() => {
    if (forgotPasswordResponseCode > 0 && forgotPasswordApiSuccess) {
      toast.toastSuccess(forgotPasswordResponseMessage);
    } else if (forgotPasswordResponseCode > 0 && !forgotPasswordApiSuccess) {
      toast.toastError(forgotPasswordResponseMessage);
    }
  }, [forgotPasswordApiLoading]);

  // handle form fields validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForgotPwdForm((info) => ({
      ...info,
      [name]: value,
    }));
    const validateObj = {
      [name]: value,
    };
    const errorresult = await validateForm(validateObj, FORGOT_PWD_SCHEMA, errorFields);
    setErrorFields({ ...errorresult });
  };
  // // load reset password page
  // const loadResetPasswordPage = () => {
  //   setErrorVisible(true);
  //   if (Object.keys(formErrors).length === 0 && forgotPwdForm.email.length > 0) {
  //     navigate('/resetpassword');
  //   } else {
  //     // If there are validation errors, focus on the input field
  //     const inputField = document.getElementById('email');
  //     if (inputField) {
  //       inputField.focus();
  //     }
  //   }
  // };
  // Handle reset password button click
  const onSubmit = async () => {
    let errorresult: any = null;
    errorresult = await validateForm(forgotPwdForm, FORGOT_PWD_SCHEMA, errorFields);
    if (Object.keys(errorresult).length === 0) {
      dispatch(forgotPassword(forgotPwdForm));
      setForgotPwdForm((info) => ({
        ...info,
        Email: '',
      }));
    } else {
      setErrorFields({ ...errorresult });
    }
  };

  return (
    <HomeLayout>
      <div className="login-main ForgotP">
        <h2>
          <FormattedMessage id="Hd.ForgotP" />
        </h2>
        <p>
          <FormattedMessage id="tit.ForgotP" />
        </p>
        <Col>
          <Input
            autoFocus
            label={useIntlMessages('Label.Email')}
            id="Email"
            name="Email"
            type="text"
            placeholder={useIntlMessages('PH.Email')}
            maxLength={200}
            value={forgotPwdForm.Email}
            errorMessage={errorFields?.Email}
            onChange={onInputHandleChange}
          />
        </Col>
        <Col className="position-relative"></Col>
        <Button className="w-100 mb-4" variant="primary" onClick={onSubmit}>
          <FormattedMessage id="Button.Reset" />
        </Button>
        <div className="text-center">
          <Link to="/signin" className="text-center">
            <FormattedMessage id="Link.BacktoSignIn" />
          </Link>
        </div>
      </div>
    </HomeLayout>
  );
};
export default ForgotPassword;
