/**
 * @file   src\containers\manageusers\UserDetails.tsx
 * @brief  User  Details.
 * @date   Dec, 2023
 * @author ZCO
 * @copyright (c) 2023, ZCO */

import { useEffect, useState } from 'react';
import '../../assets/css/myprof.scss';
import '../../assets/css/org.scss';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Breadcrumb, Button, Table, ProgressBar, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import {
  userdetails,
  usersHierarchyStudents,
  usersHierarchyAcPartners,
  listSurveysForAppUser,
  listGoalsForAppUser,
  showKudosPoints,
  addKudosPoints,
  listKudosPoints,
} from '../../store/actions/userActions';
import { setItemLocalStorage, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import moment from 'moment';
import Loader from '../../components/Loader';
import { BUCKET_URL, DATE_TIME_FORMAT_DISPLAY, DATE_FORMAT, PAGE_SIZE, DEFAULT_PAGE_INDEX } from '../../utils/constants';
import UserImg from '../../assets/img/Userimg.jpg';
import { resetUserDetails, resetUserHierarchyAcPartners, resetUserHierarchyStudents, resetGoalsSurveysTasks, resetKudosData } from '../../store/slices/userSlice';
import UserHierarchyList from './UserHierarchyList';
import { RoleTypeIds } from '../../utils/enums';
import { IUserHierarchyStudentsRequest, IUserHierarchyAcPartnersRequest, IUserHierarchyMapData } from '../../interfaces/UserInterface';
import { slide as Filter } from 'react-burger-menu';
import Select from '../../components/MASelect';
import Search from '../../components/MASearch';
import Sort from '../../assets/img/icon/Sort';
import ViewIcon from '../../assets/img/icon/View';
import Close from '../../assets/img/Close.svg';
import { ISurveyListRequest, IGoalListRequest, IGoalMapInfo, ISurveyMapInfo } from '../../interfaces/UserInterface';
import Pagination from '../../components/Pagination';
import useDebounce from '../../hooks/useDebounce';
import { getFromLocalStorage, getCurrentOrgDetails, isAdminTeacherManager, isUserSuperAdmin } from '../../utils/helper';
import levIcnBlue from '../../assets/img/level-icn-blue.svg';
import levIcnGrn from '../../assets/img/level-icn-grn.svg';
import levIcnRd from '../../assets/img/level-icn-rd.svg';
import Tktstar from '../../assets/img/TicketStar.png';
import Arrowrgt from '../../assets/img/icon-double caret.svg';
import Input from '../../components/MAInput';
import { MessageToaster } from '../../utils/ToastUtil';

const kudosDateOptions = [
  {
    label: 'Last 24 hours',
    value: 'Last_24_hours',
  },
  {
    label: 'Last 48 hours',
    value: 'Last_48_hours',
  },
  {
    label: 'Last 7 days',
    value: 'Last_7_days',
  },
  {
    label: 'Last 30 days',
    value: 'Last_30_days',
  },
  {
    label: 'Last 1 month',
    value: 'Last_1_month',
  },
  {
    label: 'Last 3 months',
    value: 'Last_3_months',
  },
  {
    label: 'Last 6 months',
    value: 'Last_6_month',
  },
  {
    label: 'Last 1 year',
    value: 'Last_1_year',
  },
];

const UserDetails = () => {
  // Navigation object
  const navigate = useNavigate();
  // Location object
  const location = useLocation();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Create route param object to access the route parameters.
  const params = useParams();
  const toastObj = new MessageToaster();
  // Access redux state variables
  const {
    userDetail,
    userDetailApiLoading,
    userHierarchyStudentsApiData,
    userHierarchyStudentsApiLoading,
    userDetailViewApiSuccess,
    userHierarchyStudentsApiSuccess,
    userHierarchyAcPartnersApiData,
    userHierarchyAcPartnersApiLoading,
    userHierarchyAcPartnersApiSuccess,
    listGoalsApiData,
    listGoalsApiLoading,
    listGoalsApiSuccess,
    listSurveysApiData,
    listSurveysApiSuccess,
    listSurveysApiLoading,
    showKudosPointsApiData,
    showKudosHistoryApiData,
    showKudosHistoryApiSuccess,
    addKudosApiLoading,
    addKudosApiResponseMessage,
    addKudosApiSuccess,
    showKudosHistoryApiLoading,
  } = useAppSelector((state: RootState) => state.user);
  const organization = [
    { label: 'Modern American School', value: '1' },
    { label: 'Empower Academy', value: '2' },
    { label: 'American University', value: '3' },
    { label: "Children's American School", value: '3' },
  ];

  const userData = getFromLocalStorage('MI_USR_DATA');
  const userId: string = params.userId || '0';

  let orgId;
  if (userData.RoleTypeId === RoleTypeIds.ORGANIZATION_ADMIN) {
    orgId = getCurrentOrgDetails().OrganizationID;
  } else {
    orgId = userData.OrganizationId;
  }
  // Initialize component state variables.
  const [organizationValue, setOrganizationValue] = useState<any>(organization[0]);
  const [currentPageGoalSurvey, setCurrentPageGoalSurvey] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [userList, setUserList] = useState<IUserHierarchyMapData[]>([]);
  const [userListStudents, setUserListStudents] = useState<IUserHierarchyMapData[]>([]);
  const [hierarchyStudentsApiParams, setHierarchyStudentsApiParams] = useState<IUserHierarchyStudentsRequest>({
    AppUserID: '',
    OrganizationID: '',
    Page: currentPage,
  });
  const [hierarchyAcPartnersApiParams, setHierarchyAcPartnersApiParams] = useState<IUserHierarchyAcPartnersRequest>({
    AccPartnerUserId: '',
    Category: [],
    Page: currentPage,
    Search: '',
    SubCategories: [],
  });
  const [listSurveyRequest, setListSurveyRequest] = useState<ISurveyListRequest>({
    OrganizationID: orgId,
    Page: 1,
    PageSize: PAGE_SIZE,
    Status: 0,
    UserID: userId,
    Search: '',
  });
  const [listGoalsRequest, setListGoalsRequest] = useState<IGoalListRequest>({
    OrganizationID: orgId,
    Page: 1,
    SearchText: '',
    Status: '',
    UserId: userId,
  });
  const [searchTextGoal, setSearchTextGoal] = useState<string>('');
  const [searchTextSurvey, setSearchTextSurvey] = useState<string>('');
  const [kudosPoint, setKudosPoint] = useState<string>('');
  const [overlay, setOverlay] = useState<boolean>(false);
  const [kudosError, setKudosError] = useState<string>('Enter a value greater than zero');
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [dateOption, setDateOption] = useState<any>(kudosDateOptions[0]);
  const [kudosHistoryApiRequest, setKudosHistoryApiRequest] = useState<any>({
    DateRange: kudosDateOptions[0].value,
    Page: 1,
    UserId: userId,
  });
  // Invoke debounce component on intervel.
  const debouncedSearch = useDebounce(searchTextGoal, 500);
  const debouncedSearchSurvey = useDebounce(searchTextSurvey, 500);
  // Initialize language variables.
  const NillText = useIntlMessages('Label.Nill');
  const OrgNameText = useIntlActionMessages('Label.OrgName');
  const SelectText = useIntlActionMessages('PH.Select');
  const noData = useIntlMessages('Label.Nodata');
  const roleId: any = Number(params.roleTypeId);

  // call view api ,user hierarchy apis  on initial loading
  useEffect(() => {
    dispatch(
      userdetails({
        UserId: userId,
      }),
    );
    dispatch(
      showKudosPoints({
        AppUserID: userId,
      }),
    );
  }, [location]);

  useEffect(() => {
    if (userDetailViewApiSuccess && userDetail) {
      if (roleId === RoleTypeIds.APP_USER && params.userId !== undefined) {
        const stuApiParams = {
          AppUserID: params.userId || '0',
          OrganizationID: userDetail.OrganizationID,
          Page: currentPage,
        };
        setHierarchyStudentsApiParams(stuApiParams);
        dispatch(usersHierarchyStudents(stuApiParams));
        dispatch(resetUserHierarchyAcPartners());
      } else if (roleId === RoleTypeIds.ACCOUNTABILITY_PARTNER && params.userId !== undefined) {
        const accApiParams = {
          AccPartnerUserId: params.userId || '0',
          Category: [],
          Page: currentPage,
          Search: '',
          SubCategories: [],
        };
        setHierarchyAcPartnersApiParams(accApiParams);
        dispatch(usersHierarchyAcPartners(accApiParams));
        dispatch(resetUserHierarchyStudents());
      }
    }
  }, [userDetailApiLoading]);
  // scroll to top. reset data on component unmounting
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetUserDetails());
      dispatch(resetUserHierarchyAcPartners());
      dispatch(resetUserHierarchyStudents());
      dispatch(resetGoalsSurveysTasks());
      dispatch(resetKudosData());
    };
  }, []);
  // set data for user list - user hierarchy for students
  useEffect(() => {
    if (userHierarchyStudentsApiSuccess && userHierarchyStudentsApiData?.AccountabilityPartners) {
      const userlist = userHierarchyStudentsApiData.AccountabilityPartners.map((user: any) => ({
        name: user.Name,
        status: user.Status,
        prof_image: user.Image,
        userid: user.Id,
      }));
      setUserList(userlist);
    }
  }, [userHierarchyStudentsApiLoading]);
  // set data for user list - user hierarchy for accountability partners
  useEffect(() => {
    if (userHierarchyAcPartnersApiSuccess && userHierarchyAcPartnersApiData?.AppUsersUnderMentor) {
      const userlist = userHierarchyAcPartnersApiData.AppUsersUnderMentor.map((user: any) => ({
        name: user.Name,
        status: true,
        prof_image: user.ProfileImage,
        userid: user.UserId,
      }));
      setUserListStudents(userlist);
    }
  }, [userHierarchyAcPartnersApiLoading]);
  // set new page number for pagination
  useEffect(() => {
    setHierarchyStudentsApiParams((prev: IUserHierarchyStudentsRequest) => ({
      ...prev,
      Page: currentPage,
    }));
    setHierarchyAcPartnersApiParams((prev: IUserHierarchyAcPartnersRequest) => ({
      ...prev,
      Page: currentPage,
    }));
  }, [currentPage]);
  // call user hierarchy apis when page changes
  useEffect(() => {
    if (roleId === RoleTypeIds.APP_USER && userHierarchyStudentsApiSuccess) {
      dispatch(usersHierarchyStudents(hierarchyStudentsApiParams));
    } else if (roleId === RoleTypeIds.ACCOUNTABILITY_PARTNER && userHierarchyAcPartnersApiSuccess) {
      dispatch(usersHierarchyAcPartners(hierarchyAcPartnersApiParams));
    }
  }, [hierarchyStudentsApiParams.Page, hierarchyAcPartnersApiParams.Page]);

  // call list goal api when list goal api params changes
  useEffect(() => {
    if (roleId === RoleTypeIds.APP_USER && isAdminTeacherManager()) {
      dispatch(listGoalsForAppUser(listGoalsRequest));
    }
  }, [listGoalsRequest]);
  // call list survey api when list survey api params changes
  useEffect(() => {
    if (roleId === RoleTypeIds.APP_USER && isAdminTeacherManager()) {
      dispatch(listSurveysForAppUser(listSurveyRequest));
    }
  }, [listSurveyRequest]);

  // Set API params on bebounced search goal list.
  useEffect(() => {
    setListGoalsRequest((apiParams: IGoalListRequest) => ({
      ...apiParams,
      SearchText: searchTextGoal,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPageGoalSurvey(DEFAULT_PAGE_INDEX);
  }, [debouncedSearch]);

  // Set API params on bebounced search survey list.
  useEffect(() => {
    setListSurveyRequest((apiParams: ISurveyListRequest) => ({
      ...apiParams,
      Search: searchTextSurvey,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPageGoalSurvey(DEFAULT_PAGE_INDEX);
  }, [debouncedSearchSurvey]);

  // api call to kudos history when date option changes
  useEffect(() => {
    dispatch(listKudosPoints(kudosHistoryApiRequest));
  }, [kudosHistoryApiRequest.DateRange]);
  // show add kudos success message and add kudos failure message
  useEffect(() => {
    if (addKudosApiSuccess) {
      toastObj.toastSuccess(addKudosApiResponseMessage);
      dispatch(listKudosPoints(kudosHistoryApiRequest));
      dispatch(
        showKudosPoints({
          AppUserID: userId,
        }),
      );
      setOverlay(false);
      setKudosPoint('0');
      setErrorVisible(false);
    } else if (!addKudosApiSuccess && !addKudosApiLoading && addKudosApiResponseMessage.length > 0) {
      toastObj.toastError(addKudosApiResponseMessage);
    }
  }, [addKudosApiLoading]);

  // convert sub categories into a comma separated string
  const getSubCategories = (subCategories: any) => {
    if (subCategories !== null) {
      let finalString = '';
      subCategories.forEach((sub: { classid: number; classname: string }) => (finalString = finalString + sub.classname + ','));
      return finalString;
    }
  };
  // update search text
  const handleSearchChange = (event: any, context: string) => {
    if (context === 'goal') {
      setSearchTextGoal(event.target.value);
    } else {
      setSearchTextSurvey(event.target.value);
    }
  };
  // navigate to assign goal page
  const handleAssignGoalClick = () => {
    navigate('/assigngoal');
  };
  // navigate to assign survey page
  const handleAssignSurveyClick = () => {
    navigate('/assignsurvey');
  };
  // handle tabs change
  const handleTabChange = (key: string) => {
    if (key == 'Surveys') {
      setListSurveyRequest((apiParams: ISurveyListRequest) => ({
        ...apiParams,
        Search: '',
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPageGoalSurvey(DEFAULT_PAGE_INDEX);
    } else if (key == 'Goals') {
      setListGoalsRequest((apiParams: IGoalListRequest) => ({
        ...apiParams,
        SearchText: '',
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPageGoalSurvey(DEFAULT_PAGE_INDEX);
    }
  };
  // set params upon enter key press
  const handleSearchKeyDown = (event: any, context: string) => {
    if (event.key === 'Enter' && context === 'goals') {
      event.preventDefault();
      setListGoalsRequest((apiParams: IGoalListRequest) => ({
        ...apiParams,
        SearchText: searchTextGoal,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPageGoalSurvey(DEFAULT_PAGE_INDEX);
    } else if (event.key === 'Enter' && context === 'survey') {
      event.preventDefault();
      setListSurveyRequest((apiParams: ISurveyListRequest) => ({
        ...apiParams,
        Search: searchTextSurvey,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPageGoalSurvey(DEFAULT_PAGE_INDEX);
    }
    if (context === 'goals') {
      setSearchTextGoal(event.target.value);
    } else {
      setSearchTextSurvey(event.target.value);
    }
  };
  // handle search button click
  const handleSearchClick = (context: string) => {
    if (context === 'goals') {
      setListGoalsRequest((apiParams: IGoalListRequest) => ({
        ...apiParams,
        SearchText: searchTextGoal,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPageGoalSurvey(DEFAULT_PAGE_INDEX);
    } else {
      setListSurveyRequest((apiParams: ISurveyListRequest) => ({
        ...apiParams,
        Search: searchTextSurvey,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPageGoalSurvey(DEFAULT_PAGE_INDEX);
    }
  };
  // handle pagination change
  const handlePageChange = (pageNo: number, tab: string) => {
    if (tab === 'goals') {
      setListGoalsRequest((apiParams: IGoalListRequest) => ({
        ...apiParams,
        Page: pageNo,
      }));
      setCurrentPageGoalSurvey(pageNo);
    } else {
      setListSurveyRequest((apiParams: ISurveyListRequest) => ({
        ...apiParams,
        Page: pageNo,
      }));
      setCurrentPageGoalSurvey(pageNo);
    }
  };
  const handleSortClick = () => {
    console.log('sort');
  };
  // handle kudos date select box change
  const handleKudosDateChange = (option: { label: string; value: string }) => {
    setDateOption(option);
    setKudosHistoryApiRequest((prev: any) => ({
      ...prev,
      DateRange: option.value,
    }));
  };
  // handle kudos point change
  const handleKudosPointChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0) {
      setKudosPoint(event.target.value);
      setKudosError('');
    } else {
      setKudosPoint(event.target.value);
    }
  };
  //  api call to add kudos point to app suer
  const addKudosToStudent = () => {
    if (kudosPoint !== '0' && kudosPoint !== '') {
      setOverlay(false);
      dispatch(
        addKudosPoints({
          KudosPoints: Number(kudosPoint),
          UserID: userId,
        }),
      );
    } else {
      setErrorVisible(true);
    }
  };
  // show current kudos level badge
  const getCurrentLevel = () => {
    let levelBadge;
    if (showKudosPointsApiData.TotalKudos > 0 && showKudosPointsApiData.TotalKudos < 21) {
      levelBadge = (
        <div className="kud-pnt-bg-bl d-flex">
          <div className="sec-left-lev d-flex">
            <img src={levIcnBlue} alt="" />
            <p className="mt-3 text-white font-xs pl-1">Level 1</p>
          </div>
          <div className="font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev">Earn {getNextBadgePoints()} more kudos to get next badge</div>
        </div>
      );
    } else if (showKudosPointsApiData.TotalKudos > 20 && showKudosPointsApiData.TotalKudos < 41) {
      levelBadge = (
        <div className="kud-pnt-bg-bl-g d-flex">
          <div className="sec-left-lev d-flex">
            <img src={levIcnGrn} alt="" />
            <p className="mt-3 text-white font-xs pl-1">Level 2</p>
          </div>
          <div className="font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev">Earn {getNextBadgePoints()} more kudos to get next badge</div>
        </div>
      );
    } else if (showKudosPointsApiData.TotalKudos > 40) {
      levelBadge = (
        <div className="kud-pnt-bg-rd d-flex ">
          <div className="sec-left-lev d-flex">
            <img src={levIcnRd} alt="" />
          </div>
          <div className="font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev">Level 3 - Kudos 40 above</div>
        </div>
      );
    }
    return levelBadge;
  };
  // calculate points for next kudos badge
  const getNextBadgePoints = () => {
    let points;
    if (showKudosPointsApiData.TotalKudos > 0 && showKudosPointsApiData.TotalKudos < 20) {
      points = 20 - showKudosPointsApiData.TotalKudos;
    } else if (showKudosPointsApiData.TotalKudos > 20 && showKudosPointsApiData.TotalKudos < 40) {
      points = 40 - showKudosPointsApiData.TotalKudos;
    }
    return points;
  };
  // prevent characters in kudos field
  const handleKeyPress = (event: any) => {
    if (event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  };
  return (
    <>
      {(userDetailApiLoading ||
        userHierarchyAcPartnersApiLoading ||
        userHierarchyStudentsApiLoading ||
        listGoalsApiLoading ||
        listSurveysApiLoading ||
        addKudosApiLoading ||
        showKudosHistoryApiLoading) && <Loader />}
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          {userDetail && userDetail?.RoleName} <FormattedMessage id="Label.Details" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageuser')}>
              <FormattedMessage id="Hd.ManageUsers" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {userDetail && userDetail?.RoleName} <FormattedMessage id="Label.Details" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="myprof-cont">
        <Row className='mb-3'>
          <Col xl={4} lg={5} className="mypro-sec-L ">
            <div>
              <div className="pro-bg "></div>
              <img src={userDetail && userDetail?.LogoImage ? BUCKET_URL + userDetail?.LogoImage : UserImg} className="prof-wrp" alt="" />
              <div className="mng-pro-det">
                <h6>{userDetail && userDetail?.FullName}</h6>
                <span>{userDetail && userDetail?.RoleName}</span>
                {userDetail?.LastLoginTime != null && isUserSuperAdmin() && (
                  <span className="text-center d-block mt-2">
                    {useIntlMessages('Label.LastLogin')}: {moment(userDetail.LastLoginTime).local().format(DATE_TIME_FORMAT_DISPLAY)}
                  </span>
                )}
                <div className="d-flex justify-content-center bt-sec mt-2 mb-3">
                  {userData.RoleTypeId === RoleTypeIds.SUPER_ADMIN && location?.state?.tabName === 'System Admin' && (
                    <Button variant="primary" size="sm" onClick={() => navigate(`/managepermission/${params.userId}`)}>
                      <FormattedMessage id="Button.MngPermissions" />
                    </Button>
                  )}
                  {roleId === RoleTypeIds.ORGANIZATION_ADMIN && (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        navigate(`/assignorgs/${userDetail.OrganizationTypeId}/${params.userId}`);
                        setItemLocalStorage('org_admin_org_id', userDetail.OrganizationID);
                      }}
                    >
                      <FormattedMessage id="Button.AddOrganization" />
                    </Button>
                  )}
                  {roleId === RoleTypeIds.APP_USER && isAdminTeacherManager() && (
                    <div className="custom-berger-menu custom-berger-menu-kudos">
                      <Filter
                        width={450}
                        right
                        pageWrapId={'filter-wrapper'}
                        outerContainerId={'outer-container'}
                        customCrossIcon={<img src={Close} />}
                        customBurgerIcon={
                          <div className="d-flex justify-content-center m-2">
                            <div className="me-2">
                              <img src={Tktstar} alt="" />
                            </div>
                            <p className="m-0 text-white">{showKudosPointsApiData && showKudosPointsApiData.TotalKudos ? showKudosPointsApiData.TotalKudos : 0} Kudos Points</p>
                            <div className="ms-2">
                              <img src={Arrowrgt} alt="" />
                            </div>
                          </div>
                        }
                      >
                        <div id="filter-wrapper" className="filter-main">
                          <Row>
                            <Col lg={7} md={7}>
                              <h4 className="mb-5 float-start">Kudos Level</h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} md={12}>
                              <div className="kud-pnt-bg-bl d-flex">
                                <div className="sec-left-lev d-flex">
                                  <img src={levIcnBlue} alt="" />
                                </div>
                                <div className="font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev">Level 1 - Kudos 1 to 20</div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            {/* <h6 className="text-start">Earn 3 more kudos to get Level 2 Badge</h6> */}
                            <Col lg={12} md={12}>
                              <div className="kud-pnt-bg-bl-g d-flex">
                                <div className="sec-left-lev d-flex">
                                  <img src={levIcnGrn} alt="" />
                                </div>
                                <div className="font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev">Level 1 - Kudos 20 to 40</div>
                              </div>
                            </Col>
                            <Col lg={12} md={12}>
                              <div className="kud-pnt-bg-rd d-flex mt-3">
                                <div className="sec-left-lev d-flex">
                                  <img src={levIcnRd} alt="" />
                                </div>
                                <div className="font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev">Level 3 - Kudos 40 above</div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Filter>
                    </div>
                  )}
                </div>
                {roleId === RoleTypeIds.APP_USER && isAdminTeacherManager() && (
                  <div className="d-flex justify-content-center bt-sec mt-2">
                    <div className="custom-berger-menu custom-berger-menu-btn mb-3">
                      <Filter
                        width={450}
                        right
                        isOpen={overlay}
                        onOpen={() => setOverlay(true)}
                        onClose={() => setOverlay(false)}
                        pageWrapId={'filter-wrapper'}
                        outerContainerId={'outer-container'}
                        customCrossIcon={<img src={Close} />}
                        customBurgerIcon={<div className="d-flex align-items-center multiple-user text-white m-0">Give Kudos</div>}
                      >
                        <div id="filter-wrapper" className="filter-main">
                          <Row>
                            <Col lg={7} md={7}>
                              <h4 className="mb-5 float-start">Kudos Points</h4>
                            </Col>
                            <Col lg={5} md={5} className="form-sm">
                              <Select
                                options={kudosDateOptions}
                                value={dateOption}
                                placeholder="Select"
                                onChange={(e: any) => handleKudosDateChange(e)}
                                // error="Error"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={8} md={8}>
                              {showKudosPointsApiData && showKudosPointsApiData.TotalKudos && getCurrentLevel()}
                            </Col>
                            <Col lg={4} md={4}>
                              <div className="kud-pnt-bg-gr">
                                <div className="d-flex justify-content-center">
                                  <img src={Tktstar} alt="" />
                                  <h3>
                                    <b>{showKudosPointsApiData && showKudosPointsApiData.TotalKudos && showKudosPointsApiData.TotalKudos}</b>
                                  </h3>
                                </div>
                                <div className="font-sm">Kudos Points</div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <div className="pt-4">
                              <h5 className="text-start">Kudos History</h5>
                              {showKudosHistoryApiSuccess &&
                                showKudosHistoryApiData &&
                                showKudosHistoryApiData.Kudos.map((kudos: any) => (
                                  <div className="inn-bg p-3 mb-2">
                                    <Row className="d-flex align-items-center">
                                      <Col lg={9} md={9} className="text-start">
                                        <p className="m-0 font-sm">{kudos.KudosType}</p>
                                        <p className="m-0 text-white">{kudos.GivenFor}</p>
                                      </Col>
                                      <Col lg={3} md={3}>
                                        <div className="pts-txt">{kudos.KudosPoints} pts</div>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              <Row className="mt-4">
                                <Col lg={12} className="text-start">
                                  <Input
                                    label={useIntlMessages('Points')}
                                    id="Name"
                                    name="Name"
                                    type="text"
                                    placeholder={useIntlMessages('5')}
                                    maxLength={200}
                                    value={kudosPoint}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleKudosPointChange(e)}
                                    errorMessage={errorVisible ? kudosError : ''}
                                    onKeyPress={handleKeyPress}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Row>
                          <Row className="mt-4 pb-4">
                            <Col>
                              <Button
                                variant="outline-primary"
                                className="w-100"
                                onClick={() => {
                                  setOverlay(false);
                                  setKudosPoint('');
                                  setErrorVisible(false);
                                }}
                              >
                                <FormattedMessage id="Button.Cancel" />
                              </Button>
                            </Col>
                            <Col>
                              <Button variant="primary" className="w-100" onClick={addKudosToStudent}>
                                <FormattedMessage id="Button.Submit" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Filter>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col className="content-sub mb-0">
            <div>
              <div className="content-sub-header d-flex justify-content-between align-items-center">
                <div>
                  <h4>
                    <FormattedMessage id="Hd.PersInfo" />
                  </h4>
                </div>
              </div>
              <div className="content-area-padding">
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.OrgName" />
                    </label>
                    <p>{userDetail && userDetail?.OrganizationName ? userDetail?.OrganizationName : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.DateofBirth" />
                    </label>
                    <p>{userDetail && userDetail?.Dob ? moment(userDetail.Dob).local().format(DATE_FORMAT) : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Gender" />
                    </label>
                    <p>{userDetail && userDetail.Gender ? (userDetail.Gender === 'F' ? 'Female' : userDetail.Gender === 'M' ? 'Male' : NillText) : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}></Col>
                </Row>
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Email" />
                    </label>
                    <p>{userDetail && userDetail?.EmailId ? userDetail?.EmailId : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.PhoneNum" />
                    </label>
                    <p>
                      {userDetail && userDetail?.PhoneNumber ? null : NillText}
                      {userDetail && userDetail?.PhonenumCountryCode} {userDetail && userDetail?.PhoneNumberDisplay}
                    </p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.DateofJoining" />
                    </label>
                    <p>{userDetail && userDetail?.JoiningDate ? moment(userDetail?.JoiningDate).local().format(DATE_FORMAT) : NillText}</p>
                  </Col>
                  {location?.state?.tabName === 'Student' && (
                    <Col xl lg={6} md={3} sm={6}>
                      <label>
                        <FormattedMessage id="Label.Tags" />
                      </label>
                      <p>{userDetail && userDetail?.Tags && userDetail?.Tags.length > 0 ? userDetail.Tags.join(', ') : NillText}</p>
                    </Col>
                  )}
                </Row>
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Address" />
                    </label>
                    <p>{userDetail && userDetail?.Address ? userDetail?.Address : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.City" />
                    </label>
                    <p>{userDetail && userDetail?.CityName ? userDetail?.CityName : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6} className="d-flex">
                    <div>
                      <label>
                        <FormattedMessage id="Label.State" />
                      </label>
                      <p>{userDetail && userDetail?.StateName ? userDetail?.StateName : NillText}</p>
                    </div>
                  </Col>
                  <Col xl lg={6} md={3} sm={6} className="d-flex">
                    <div className="zip-col">
                      <label>
                        <FormattedMessage id="Label.Zip" />
                      </label>
                      <p>{userDetail && userDetail?.ZipName ? userDetail?.ZipName : NillText}</p>
                    </div>
                  </Col>
                </Row>
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.HighestEducation" />
                    </label>
                    <p>{userDetail && userDetail?.HighestEducation ? userDetail?.HighestEducation : NillText}</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        </div>
        {roleId === RoleTypeIds.APP_USER && isAdminTeacherManager() && (
          <div className="content-sub">
            <div className="content-sub-header header-light-bg d-flex justify-content-end align-items-center">
              <Col md="auto" className="btn-container">
                <Button variant="primary" size="sm" onClick={() => handleAssignGoalClick()}>
                  <FormattedMessage id="Button.AssignGoal" />
                </Button>
                <Button variant="primary" size="sm" onClick={() => handleAssignSurveyClick()}>
                  <FormattedMessage id="Button.AssignSurvey" />
                </Button>
              </Col>
            </div>
            <div className="content-area-padding">
              <Tabs defaultActiveKey={'Goals'} transition={false} id="noanim-tab-example" className="mb-3" onSelect={(key: any) => handleTabChange(key)}>
                <Tab eventKey="Goals" title={useIntlMessages('Label.AllGoals')}>
                  <div className="d-flex justify-content-end mb-3">
                    <Col lg={3} className="form-sm">
                      <Search
                        onChange={(event: any) => handleSearchChange(event, 'goal')}
                        onKeyDown={(event: any) => handleSearchKeyDown(event, 'goals')}
                        onClick={() => handleSearchClick('goals')}
                        placeholder={useIntlMessages('PH.SearchGoal')}
                        size="sm"
                      />
                    </Col>
                  </div>
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="Label.GoalName" />
                          <Link to="" className="ms-1" onClick={handleSortClick}>
                            <Sort />
                          </Link>
                        </th>
                        <th>
                          <FormattedMessage id="Label.Category" />
                          <Link to="" className="ms-1" onClick={handleSortClick}>
                            <Sort />
                          </Link>
                        </th>
                        <th>
                          <FormattedMessage id="Label.SubCategory" />
                          <Link to="" className="ms-1"></Link>
                        </th>
                        <th>
                          <FormattedMessage id="Label.CreatedBy" />
                          <Link to="" className="ms-1" onClick={handleSortClick}>
                            <Sort />
                          </Link>
                        </th>
                        <th>
                          <FormattedMessage id="Label.Status" />
                          <Link to="" className="ms-1"></Link>
                        </th>
                        <th className="w-50px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {listGoalsApiSuccess &&
                        listGoalsApiData.Goals &&
                        listGoalsApiData.Goals.map((goal: IGoalMapInfo) => (
                          <tr>
                            <td>{goal.GoalName}</td>
                            <td>{goal.CategoryName}</td>
                            <td>{getSubCategories(goal.GoalClasses)}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span className="td-user-image">
                                  <img src={UserImg} className="w-100" alt="user" />
                                </span>
                                {goal.CreatedBy}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center multiple-user">
                                <ProgressBar now={goal.CompletedPercentage} />{' '}
                                <span className="ps-2">
                                  <label className="td-progressbar">
                                    {goal.CompletedPercentage}% <span className="inprogress">{goal.UserStatus}</span>
                                  </label>
                                </span>
                              </div>
                            </td>
                            <td>
                              <Button variant="dark" size="sm" className="icon-btn" onClick={() => navigate(`/goaldetail/${goal.GoalId}`)}>
                                <ViewIcon />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      {listGoalsApiSuccess && listGoalsApiData.Goals === null && (
                        <tr>
                          <td colSpan={8}>
                            <div className="content-sub content-area-padding border-top text-center">
                              <h6>{noData}</h6>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {listGoalsApiData && listGoalsApiData.Goals && listGoalsApiData.Goals.length > 0 && listGoalsApiData.TotalCount > PAGE_SIZE && (
                    <Pagination
                      pageSize={PAGE_SIZE}
                      totalitems={listGoalsApiData.TotalCount}
                      pageLimit={PAGE_SIZE}
                      setCurrentPage={(page: number) => handlePageChange(page, 'goals')}
                      currentPage={currentPageGoalSurvey - 1}
                      prevPage={-1}
                      itemsDisplayed={listGoalsApiData.Goals.length}
                    />
                  )}
                </Tab>
                <Tab eventKey="Surveys" title={useIntlMessages('Label.AllSurveys')}>
                  <div className="d-flex justify-content-end mb-3">
                    <Col lg={3} className="form-sm">
                      <Search
                        onChange={(event: any) => handleSearchChange(event, 'goals')}
                        onKeyDown={(event: any) => handleSearchKeyDown(event, 'survey')}
                        onClick={() => handleSearchClick('survey')}
                        placeholder={useIntlMessages('PH.SearchSurvey')}
                        size="sm"
                      />
                    </Col>
                  </div>
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        <th className="fix-width">
                          <FormattedMessage id="Label.SurveyName" />
                          <Link to="" className="ms-1" onClick={handleSortClick}>
                            <Sort />
                          </Link>
                        </th>
                        <th>
                          <FormattedMessage id="Label.Category" />
                          <Link to="" className="ms-1" onClick={handleSortClick}>
                            <Sort />
                          </Link>
                        </th>
                        <th>
                          <FormattedMessage id="Label.SubCategories" />
                          <Link to="" className="ms-1"></Link>
                        </th>
                        <th>
                          <FormattedMessage id="Label.CreatedBy" />
                          <Link to="" className="ms-1" onClick={handleSortClick}>
                            <Sort />
                          </Link>
                        </th>
                        <th>
                          <FormattedMessage id="Label.Status" />
                          <Link to="" className="ms-1"></Link>
                        </th>
                        <th className="w-50px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {listSurveysApiSuccess &&
                        listSurveysApiData.SurveyList &&
                        listSurveysApiData.SurveyList.map((survey: ISurveyMapInfo) => (
                          <tr>
                            <td>{survey.SurveyName}</td>
                            <td>{survey.CategoryName}</td>
                            <td>{getSubCategories(survey.SurveyClasses)}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span className="td-user-image">
                                  <img src={UserImg} className="w-100" alt="user" />
                                </span>
                                {survey.CreatedBy}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center multiple-user">
                                <ProgressBar now={60} />{' '}
                                <span className="ps-2">
                                  <label className="td-progressbar">
                                    <span className="inprogress">{survey.Status}</span>
                                  </label>
                                </span>
                              </div>
                            </td>
                            <td>
                              <Button variant="dark" size="sm" className="icon-btn" onClick={() => navigate(`/surveydetails/${survey.SurveyID}`)}>
                                <ViewIcon />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      {listSurveysApiSuccess && listSurveysApiData.SurveyList === null && (
                        <tr>
                          <td colSpan={8}>
                            <div className="content-sub content-area-padding border-top text-center">
                              <h6>{noData}</h6>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {listSurveysApiData && listSurveysApiData.SurveyList && listSurveysApiData.SurveyList.length > 0 && listSurveysApiData.TotalCount > PAGE_SIZE && (
                    <Pagination
                      pageSize={PAGE_SIZE}
                      totalitems={listSurveysApiData.TotalCount}
                      pageLimit={PAGE_SIZE}
                      setCurrentPage={(page: number) => handlePageChange(page, 'survey')}
                      currentPage={currentPageGoalSurvey - 1}
                      prevPage={-1}
                      itemsDisplayed={listSurveysApiData.SurveyList.length}
                    />
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        )}
        <>
          {(roleId === RoleTypeIds.APP_USER || roleId === RoleTypeIds.ACCOUNTABILITY_PARTNER) && (
            <Row>
              <Col>
                <div className="content-sub">
                  <div className="content-sub-header d-flex justify-content-between align-items-center">
                    <div>
                      <h4>
                        <FormattedMessage id="Hd.UserHierarchy" />
                      </h4>
                    </div>
                    {/* <Col xl={3} lg={6} md={4}>
                    <Select label={OrgNameText} options={organization} value={organizationValue} placeholder={SelectText} onChange={(e: any) => handleOrganizationChange(e)} />
                  </Col> */}
                  </div>
                  {/* <div className="content-area-padding content-prof-user">
                  <h6>
                    <FormattedMessage id="Hd.SystemAdmin" />
                  </h6>
                  <Col lg={4}>
                    <div className="user-wrap d-flex">
                      <div className="usr-image">
                        <img src={proImg} alt="" />
                      </div>
                      <div className="usr-name">
                        <p>John Doe</p>
                        <span className="usr-status-admin small-text">Active</span>
                      </div>
                    </div>
                  </Col>
                </div> */}
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              {userHierarchyStudentsApiSuccess && userHierarchyStudentsApiData && (
                <UserHierarchyList
                  usersList={userList}
                  roleName="Accountability Partners"
                  count={userHierarchyStudentsApiSuccess ? userHierarchyStudentsApiData.TotalCount : 0}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={10}
                  totalItems={userHierarchyStudentsApiSuccess ? userHierarchyStudentsApiData.TotalCount : 0}
                />
              )}
              {userHierarchyAcPartnersApiSuccess && userHierarchyAcPartnersApiData && (
                <UserHierarchyList
                  usersList={userListStudents}
                  roleName="Students"
                  count={userHierarchyAcPartnersApiSuccess ? userHierarchyAcPartnersApiData.TotalCount : 0}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={10}
                  totalItems={userHierarchyAcPartnersApiSuccess ? userHierarchyAcPartnersApiData.TotalCount : 0}
                />
              )}
            </Col>
          </Row>
        </>
    </>
  );
};
export default UserDetails;
