import React from 'react';
const EventThumbIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.03" height="30.726" viewBox="0 0 23.03 30.726">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_1755" data-name="Rectangle 1755" width="23.03" height="30.726" transform="translate(0 0.001)" fill="none" />
                </clipPath>
            </defs>
            <g id="Group_12772" data-name="Group 12772" transform="translate(0 0)">
                <g id="Group_12121" data-name="Group 12121" clip-path="url(#clip-path)">
                    <path id="Path_10332" data-name="Path 10332" d="M11.515,0A11.515,11.515,0,1,0,23.03,11.515,11.514,11.514,0,0,0,11.515,0" fill="#1c7c67" />
                    <path id="Path_10333" data-name="Path 10333" d="M11.515,16.632a5.118,5.118,0,1,1,5.118-5.118,5.117,5.117,0,0,1-5.118,5.118" fill="#fa0" />
                    <path id="Path_10334" data-name="Path 10334" d="M14.074,11.514a2.559,2.559,0,1,1-2.559-2.559,2.559,2.559,0,0,1,2.559,2.559" fill="#fff" />
                    <path id="Path_10335" data-name="Path 10335" d="M22.513,27.508a1.932,1.932,0,0,1-1.853,3.174c-2.3-.313-4.14-2.178-9.144-2.178s-6.847,1.865-9.144,2.178A1.932,1.932,0,0,1,.519,27.509l3.575-4.057a13.949,13.949,0,0,0,14.841,0l3.577,4.058" fill="#fa0" />
                </g>
            </g>
        </svg>
    );
}

export default EventThumbIcon;
